export function setIsOuterMap(state, isOuterMap) {
    state.isOuterMap = isOuterMap;
}
export function setShouldReauthorize(state, shouldReauthorize) {
    state.shouldReauthorize = shouldReauthorize;
}
export function setCurrentMgq(state, payload) {
    if (undefined != payload.properties) {
        payload.properties = JSON.parse(payload.properties);
    }
    state.currentMgq = payload;
}

export function setZoomLevel(state, payload) {
    state.zoomLevel = payload;
}

export function setMindsetsNonLocated(state, payload) {
    state.mindsetsNonLocated = payload;
}

export function setVectorAnimation(state, payload) {
    state.vectorAnimation = payload;
}
export function incrementCurrentScore(state, payload) {
    state.currentScore += payload;
}
export function setCurrentScore(state, payload) {
    state.currentScore = payload;
}
export function renewCurrentScore(state) {
    state.currentScore = 0;
}
export function setGameScreenWidthHeight(state, payload) {
    state.gameScreenWidthHeight = payload;
}
export function initializeBackgroundAudio(state) {
    if (state.backgroundAudio == null) {
        state.backgroundAudio = new Audio();
        state.backgroundAudio.autoplay = true;
        state.backgroundAudio.volume = .2;
        state.backgroundAudio.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
        state.backgroundAudio.play();
    }
}

export function initializeEffectsAudio(state) {
    if (state.effectsAudio == null) {
        state.effectsAudio = new Audio();
        state.effectsAudio.autoplay = true;
        state.effectsAudio.volume = .2;
        state.effectsAudio.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
        state.effectsAudio.play();
    }
}

export function setBackgroundAudio(state,payload) { 
            var url = URL.createObjectURL( payload );
            state.backgroundAudio.src = url;
}

export function replayBackgroundAudio(state) { 
    state.backgroundAudio.currentTime = 0;
    state.backgroundAudio.play();
}


export function setEffectsAudio(state, payload) {
    if (state.effectsAudio != null) {
        state.effectsAudio.src = payload;
    }
}

export function stopBackgroundAudio(state) {
    if (state.backgroundAudio != null) {
        state.backgroundAudio.pause();
        state.backgroundAudio.currentTime = 0;
        state.startedAudioUrl = "";

    }
}

export function stopEffectsAudio(state) {
    if (state.effectsAudio != null) {
        state.effectsAudio.pause();
        state.effectsAudio.currentTime = 0;
    }
}

export function setStartedAudioUrl(state,payload) {
        state.startedAudioUrl = payload;
}







