<template>
    <div>
        <div>{{ mso.payload.title }}</div>
        <div>
            <input type="color" v-model="selected" />
            <input class="input mt-2" type="text" v-model="selected" @keyup="checkHexColor" />
        </div>

    </div>
</template>
<script>
import { ref, onBeforeUnmount, computed, } from "vue";
import { useStore } from "vuex";
/**
 * 
 * mso with payload with title and array of checkboxes, first checked
 * 
*/
export default {
    setup() {
        const store = useStore();
        const mso = computed(() => store.getters["core/getModalDynamicObject"]);
        const selected = ref("#ffffff");

        onBeforeUnmount(() => {
            let obj = mso.value;
            obj.content = selected.value;
            store.commit("core/setModalDynamicObject", obj);
        });

        function checkHexColor() {
          if (selected.value.length>6){
            var reg = /^#([0-9a-f]{3}){1,2}$/i;
            if (!reg.test(selected.value)) {
                selected.value = "#ffffff";
            }
          } 
        }

        /*eslint-disable no-unused-vars*/
        function checkSubmit(submitted) {
            return true;
        }

        return {
            mso,
            selected,
            checkHexColor,
            checkSubmit,

        };
    }
}
</script>