<template>
  <div>
    <div class="major_container p-4">
      <div class="select m-2" style="align-self: center;">
        <select v-model="sorting" @change="compareMgqs">
          <option value="title">Title</option>
          <option value="startTime">Start time</option>
          <option value="endTime">End time</option>
          <option value="price">Price</option>
          <option value="virtual">Virtual</option>
        </select>
      </div>
      <div v-if="loaded">
        <div v-if="isLoggedIn && currentMgq.mindgluequestId != 1">
          <div class="css-selector p-2" style="color: #abfaff; font-weight: bold; cursor: pointer;"
            @click="goToCurrent">
            Current playing: <span style="color:white;  font-style: oblique;"> {{ currentMgq.title }}</span></div>
        </div>
        <div v-for="item in mindgluequests" :key="item.mindgluequestId">
          <MgqTile :mgq="item" :mgqStatus="'normal'" class="pb-2" :outer="true" />
        </div>
      </div>
      <div v-else>
        <div v-for="item in 10" :key="item">
          <GhostMgqTile class="pb-2" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import MgqTile from "@/components/parts/MgqTile.vue";
import GhostMgqTile from "@/components/parts/GhostMgqTile.vue";
import * as mixins from "@/utils/mixins";
import * as gameMixins from "@/utils/gameMixins";
import * as LocationService from "@/utils/LocationService";
import * as DBProvider from "@/utils/DBProvider";

export default {
  components: { MgqTile, GhostMgqTile },
  setup() {
    const store = useStore();
    const router = useRouter();
    let loaded = ref(false);
    const commands = computed(() => store.getters["core/getTemporaryContent"].commands);
    onBeforeMount(async () => {
      await checkCommands();

      let received = null;
      if (isLoggedIn.value) {
        received = await store.dispatch("gameApi/getAllMgqsLogged");
      }
      else {
        received = await store.dispatch("core/getAllMgqs");
      }
      loaded.value = received;
      compareMgqs();
    });

    const isLoggedIn = computed(() => store.getters["core/getUser"].userid != undefined);

    const currentMgq = computed(() => store.getters["gameApi/getCurrentMgq"]);
    const sorting = ref("title");
    const mindgluequestsGetter = computed(
      () => store.getters["core/getMindgluequests"]
    );
    const mindgluequests = ref([]);

    async function goToCurrent() {
      if (currentMgq.value.active) {
        router.push('/qd/' + currentMgq.value.mindgluequestId);
      }
      else {
        /*  store.commit("core/setUserLatLong",{
             latitude: currentMgq.value.latitude,
             longitude: currentMgq.value.longitude,
           });
           router.push('/outergame'); */
        let shouldTerminateQuest = await mixins.alertConfirm("Would you like to terminate this quest?", true);
        if (shouldTerminateQuest) {
          await gameMixins.setUsMindgluequest(currentMgq.value, store.getters["core/getUserLatitude"], store.getters["core/getUserLatitude"]);
        }
      }

    }

    async function checkCommands() {
      if (commands.value != undefined) {
        while (commands.value.indexOf("newPassword") > -1) {
          let givenPass1 = await mixins.makeModalDynamic("DynamicOneTextfield", { title: "Please enter your desired new password", isPassword: true });
          if (givenPass1 != null) {
            let firstPass = givenPass1.content;
            let isValid = mixins.validatePassword(firstPass);
            if (!isValid) {
              await mixins.alertConfirm("  The password you entered is not valid. It should consist of at least 8 characters and should contain uppercase latin, lowercase latin, numbers and special characters.")
            }
            else {
              let givenPass2 = await mixins.makeModalDynamic("DynamicOneTextfield", { title: "Please re-enter your desired password", isPassword: true });
              if (givenPass2 != null) {
                let secondPass = givenPass2.content;
                if (secondPass == firstPass) {
                  let isPasswordSuccessfullyChanged = await store.dispatch("core/renewPassword", { password: secondPass });
                  if (isPasswordSuccessfullyChanged) {
                    let newCommands = mixins.cloneArray(commands.value);
                    newCommands.splice(newCommands.indexOf("newPassword"));
                    let newTempContent = mixins.cloneObject(store.getters["core/getTemporaryContent"]);
                    newTempContent.commands = newCommands;
                    store.commit("core/setTemporaryContent", newTempContent);
                  }
                }
              }
            }
          }
        }
        if (commands.value.indexOf("askForCoordinates") > -1) {
          let coords = await LocationService.askForCoordinates();
          store.commit("core/setUserLatLong", coords);
          await DBProvider.writeDBKeyValue("user_info", "user", JSON.stringify(store.getters["core/getUser"]));
        }
        while (commands.value.indexOf("checkPasswordEmail") > -1) {
          let passwordToDo = await mixins.makeModalDynamic("DynamicMultipleRadiobuttons", { title: "You have been sent an email to help you renew your password. Use on the link in the email and then select Refresh. If you don't care about the renewal or wish to log in with another account, select Log out.", checkboxTitles: ["Refresh", "Log out"] });
          if (passwordToDo == null) {
            location.reload();
          }
          else {
            if (passwordToDo.content == "Refresh") {
              location.reload();
            }
            else {
              await DBProvider.clearAll();
              store.commit('core/renewLog');
              location.reload();
            }
          }
        }
      }

    }


    function compareMgqs() {
      let mgqs = mixins.cloneArray(mindgluequestsGetter.value);
      if (sorting.value == "title") {
        mgqs.sort(function (a, b) { return (a.title > b.title) ? 1 : -1 });
      }
      else if (sorting.value == "startTime") {
        mgqs.sort(function (a, b) { return a.startTime - b.startTime });
      }
      else if (sorting.value == "endTime") {
        mgqs.sort(function (a, b) { return a.endTime - b.endTime });
      }
      else if (sorting.value == "price") {
        mgqs.sort(function (a, b) { return a.price - b.price });
      }
      else if (sorting.value == "virtual") {
        mgqs.sort(function (a, b) { return a.virtual != b.virtual });
      } else if (sorting.value == "forContest") {
        mgqs.sort(function (a, b) { return a.forContest != b.forContest });
      }
      mindgluequests.value = mgqs;
    }

    return {
      mindgluequests,
      loaded,
      sorting,
      isLoggedIn,
      compareMgqs,
      currentMgq,
      goToCurrent,
    };
  },
};
</script>
<style scoped>
.major_container {
  flex: 1 0 auto;
}


.css-selector {
  background: linear-gradient(270deg, #af00ff, #6bfeff);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  -o-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@-o-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}
</style>
