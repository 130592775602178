<template>
  <div style="width: 100%; height: 100%">
    <div class="boxy">
      <PageHeader :with_login="false" />
      <div class="rowy contenty">
        <div class="title_div" @click="openStartLongDescr">{{ getCurrentMgq.title }}</div>
        <div ref="gamemapWrapper" style="width: 100%; height: 100%; position: relative">
          <div class="floating_widget" v-if="shouldShowMap">
            <CountdownClock ref="countDownClock" :shouldCount="shouldCount" />
            <ScoreCounter ref="scoreCounter" class="mt-2" />
          </div>
          <GameMap v-if="shouldShowMap" ref="gameMap" :zoomLevel="zoomLevel" @makeFinishGame="makeFinishGame"
            @changeMgq="changeMgqEffect" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import { computed, onBeforeMount, onMounted, ref, } from "vue";
import { useRouter } from "vue-router";
import * as mixins from "@/utils/mixins";
import * as gameMixins from "@/utils/gameMixins";
import * as DBProvider from "@/utils/DBProvider";
import GameMap from "@/components/parts/GameMap.vue";
import PageHeader from "@/components/parts/PageHeader.vue";
import CountdownClock from "@/components/parts/CountdownClock.vue";
import ScoreCounter from "@/components/parts/ScoreCounter.vue";

export default {
  components: {
    GameMap,
    PageHeader,
    CountdownClock,
    ScoreCounter,
  },
  setup() {

    const store = useStore();
    const router = useRouter();

    const gameMap = ref(null);
    const gamemapWrapper = ref(null);
    const scoreCounter = ref(null);
    const countDownClock = ref(null);
    const zoomLevel = ref(13);
    const getCurrentMgq = computed(() => store.getters["gameApi/getCurrentMgq"]);
    const shouldCount = ref(false);
    const shouldShowMap = ref(false);

    onBeforeMount(async () => {
      await mixins.fetchMgqDrawings(getCurrentMgq.value);
      await preloadImages(getCurrentMgq.value);
      await handleBackgroundAudio(getCurrentMgq.value);

      shouldShowMap.value = true;
      openLongDescr();
      shouldCount.value = true;
    });

    onMounted(async () => {

      let width = gamemapWrapper.value.getBoundingClientRect().width;
      let height = gamemapWrapper.value.getBoundingClientRect().height;
      store.commit("gameApi/setGameScreenWidthHeight", { width: width, height: height });
    });

    // const images = ref(mixins.images);
    const baseUrl = mixins.baseUrl;


    async function handleBackgroundAudio(mgq) {
      if (mgq.properties.audioUrl != undefined && mgq.properties.audioUrl != "") {
        if (store.getters["gameApi/getStartedAudioUrl"] != mgq.properties.audioUrl) {
          let assetSaved = await DBProvider.readDBKeyValue("mgq_assets", mgq.properties.audioUrl);
          if (assetSaved != undefined) {
            playBackgroundAudio(assetSaved, mgq.properties.audioUrl);
          }
          else {
            let fetchedAudio = await store.dispatch("gameApi/fetchExternalAsset", mgq.properties.audioUrl,);
            if (fetchedAudio != null) {
              playBackgroundAudio(fetchedAudio, mgq.properties.audioUrl);
            }
          }
        }
        else {
          let curTime = store.getters["gameApi/getBackgroundAudio"].currentTime;
          let dur = store.getters["gameApi/getBackgroundAudio"].duration;
          console.log(" curTime dur "+curTime+" "+dur);

          if (curTime == dur){
          console.log(" audio played all");
            store.commit("gameApi/replayBackgroundAudio");
          }
        }
      }
    }







    function playBackgroundAudio(blob, audioUrl) {
      if (getCurrentMgq.value.properties.audioUrl != undefined) {
        if (getCurrentMgq.value.properties.audioUrl != "") {
          store.commit("gameApi/setBackgroundAudio", blob);
          store.commit("gameApi/setStartedAudioUrl", audioUrl);
        }
      }
      else {
        store.commit("gameApi/stopBackgroundAudio");
      }
    }



    async function preloadImages() {
      for (let image of mixins.images.value) {
        let url = baseUrl + '/mgqmaps/' + image.properties.name;
         let isImgLoaded = await gameMixins.preloadImage(url);
       // console.log(mgq.mindgluequestId);
       // let imgBlob = await store.dispatch("gameApi/fetchExternalAsset", url);
       // image.blob = imgBlob;
       // console.log("img blob " + imgBlob);
       // if (imgBlob != null) {
            if (isImgLoaded) {
          continue;
        }
      }
    }


    async function changeMgqEffect(nextMindset) {
      gamemapWrapper.value.style.opacity = 0;
      gamemapWrapper.value.style.transition = "all 1s";
      let event = gameMixins.whichTransitionEvent();
      gamemapWrapper.value.addEventListener(event, doAfterOldMgqDisappearing, { once: true });
      gamemapWrapper.value.nextMindset = nextMindset;
    }

    async function doAfterOldMgqDisappearing(evt) {
      shouldCount.value = false;
      let mindset = evt.currentTarget.nextMindset;
      gameMap.value.isReady = false;
      let splitMindsetGamecode = mindset.gamecode.split("_");
      let mgqNext = await store.dispatch("gameApi/requestOneMgq", splitMindsetGamecode[0]);
      await handleBackgroundAudio(mgqNext);
      await mixins.fetchMgqDrawings(mgqNext);
      await preloadImages(mgqNext);
      await gameMixins.setUsMindgluequest(mgqNext, mindset.latitude, mindset.longitude);
      await gameMap.value.loadAll();
      gameMap.value.isReady = true;
      gamemapWrapper.value.style.opacity = 1;
      shouldCount.value = true;

    }

    async function openStartLongDescr() {
      if (getCurrentMgq.value.longDescr != "" && getCurrentMgq.value.longDescr != "<p><br></p>") {
        let descrOrStartCoord = await mixins.makeModalDynamic("DynamicMultipleRadiobuttons", { title: "", checkboxTitles: ["Show the description", "Take me to the start"] });
        if (descrOrStartCoord.content == "Show the description") {
          await openLongDescr();
        }
        else if (descrOrStartCoord.content == "Take me to the start") {
          await gameMixins.takeMeToPlace(getCurrentMgq.value, getCurrentMgq.value.longitude, getCurrentMgq.value.latitude,);
          gameMap.value.renewStartCoord();
        }
      }
      else {
        await gameMixins.takeMeToPlace(getCurrentMgq.value, getCurrentMgq.value.longitude, getCurrentMgq.value.latitude,);
        gameMap.value.renewStartCoord();

      }
    }

    async function openLongDescr() {
      if (getCurrentMgq.value.longDescr != "" && getCurrentMgq.value.longDescr != "<p><br></p>") {
        await mixins.makeModalDynamic('DynamicHtmlContentPresenter', { title: "Description", content: getCurrentMgq.value.longDescr });
      }
    }

    async function handleLocalScore(finalScore, currentMgqScore) {
      let shouldGoOn = false;
      if (getCurrentMgq.value.properties.secondsToScore != undefined) {
        let scorePerSecond = Number(getCurrentMgq.value.properties.secondsToScore);
        let secondsLeft = countDownClock.value.leftTime.days * 86400 + countDownClock.value.leftTime.hours * 3600 + countDownClock.value.leftTime.minutes * 60 + countDownClock.value.leftTime.seconds;
        let scoreFromSeconds = await mixins.makeModalDynamic('DynamicSecondsScoreCountDown', { seconds: secondsLeft, starsPerSecond: scorePerSecond, startScore: finalScore });
        finalScore = scoreFromSeconds.finalScore;
        currentMgqScore.timeBonus = finalScore - scoreCounter.value.currentScore;
        await DBProvider.writeDBKeyValue("mgq_scores", getCurrentMgq.value.mindgluequestId.toString(), currentMgqScore);
        store.commit("gameApi/setCurrentScore", finalScore);
        if (scoreFromSeconds) {
          shouldGoOn = true;
        }
      }
      else {
        shouldGoOn = true;
      }
      return shouldGoOn;
    }

    async function handleServerScore() {
      let chainedScoreFromLast = await gameMixins.retrieveChainedMgqIdArrAndScoreFromLastMgqId([getCurrentMgq.value.mindgluequestId], { totalScore: 0, penalty: null });
      let firstMgqId = chainedScoreFromLast.chainedMgqIdArr[chainedScoreFromLast.chainedMgqIdArr.length - 1];
      let success = await store.dispatch("gameApi/saveMgqScore", { lastMgqId: getCurrentMgq.value.mindgluequestId, firstMgqId: firstMgqId, score: chainedScoreFromLast.scoreAndPenalty.totalScore });
      return success;
    }



    async function makeFinishGame(payload) {
      if (payload.nextMindset == null) {
        shouldCount.value = false;
        /*   let successAudio = new Audio(require('@/assets/short_success.mp3'));
           successAudio.volume = .2;
           successAudio.play();*/
        store.commit("gameApi/setEffectsAudio", require('@/assets/short_success.mp3'));

        await mixins.makeVectorAnimation({ src: require("@/assets/starAnim.json"), center: { x: 0, y: 0 }, shouldCenter: false, shouldWait: true });
      }
      let finalScore = scoreCounter.value.currentScore;
      await handleLocalScore(finalScore, payload.currentMgqScore);
      await handleServerScore();

      if (payload.nextMindset == null) {
        if (getCurrentMgq.value.properties != undefined) {
          if (getCurrentMgq.value.properties.chain != undefined) {
            await gameMixins.dbSaveChains(payload.currentMgqScore, getCurrentMgq.value.properties.chain);
            let nextMindset = { longitude: "", latitude: "", gamecode: getCurrentMgq.value.properties.chain + "_1" };
            await changeMgqEffect(nextMindset);
          }
          else {
            await finishGame();

          }
        }
        else {
          await finishGame();

        }
      }
      else {
        await gameMixins.dbSaveChains(payload.currentMgqScore, payload.nextMindset.gamecode.split("_")[0]);
        await changeMgqEffect(payload.nextMindset);
      }
    }



    async function finishGame() {
      store.commit("gameApi/setEffectsAudio", require('@/assets/cheers_sound.mp3'));

      await mixins.makeVectorAnimation({ src: require("@/assets/finishStarAnim.json"), center: { x: 0, y: 0 }, shouldCenter: false, shouldWait: true });

      setTimeout(async () => {
        await gameMixins.setUsMindgluequest(getCurrentMgq.value, store.getters["core/getUserLatitude"], store.getters["core/getUserLatitude"]);
        store.commit("gameApi/stopEffectsAudio");

        router.push("/");

      }, 3000)

    }

    const counterValue = ref(-1);

    function stopCounterCounting() {
      // shouldCount.value = false;
      counterValue.value = 1000;
    }

    return {
      gameMap,
      zoomLevel,
      getCurrentMgq,
      gamemapWrapper,
      scoreCounter,
      shouldCount,
      countDownClock,
      changeMgqEffect,
      stopCounterCounting,
      makeFinishGame,
      openStartLongDescr,
      counterValue,
      shouldShowMap,
    };
  },
};
</script>

<style>
div {
  box-sizing: border-box;
}

body {
  margin: 0;
}


.floating_widget {
  position: absolute;
  top: 20px;
  z-index: 900;
  max-width: 20vw;
  right: 20px;
  pointer-events: none;
}

.float_below {
  top: 80px;

}

.title_div {
  font-weight: bold;
  background-color: white;
  color: #007acc;
  cursor: pointer;
}
</style>