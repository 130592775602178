<template>
    <div>
        <div class="control has-icons-right">
            <input class="input" type="text" :placeholder="placeholder" v-model="query" @keyup.enter="search" />
            <span class="icon is-right">
                <i class="fas fa-search"></i>
            </span>
        </div>
    </div>
</template>
<script>
import {
    ref,
} from "vue";
export default {
    setup(props, { emit }) {
        const query = ref("");
const placeholder = ref("Search place");
        async function search() {
            let url = "https://nominatim.openstreetmap.org/search?q=";
            let paramsEnding = "&format=jsonv2&limit=1";
            let searchQuery = query.value.replaceAll(" ", "+");
            let utf8SearchQuery = encodeURIComponent(searchQuery);
            let myquery = url + utf8SearchQuery + paramsEnding;
            let rawResponse = await fetch(myquery);
            const toReturn = await rawResponse.json();
            if (toReturn.length>0) {
                console.log("place found: " +toReturn[0]["lat"]+","+ toReturn[0]["lon"]);
                emit("setSearchedCoordinates", { lat: toReturn[0]["lat"], long: toReturn[0]["lon"] });
            }
            else {
                query.value = "";
                placeholder.value="Not found";
            }
        }


        return {
            query,
            placeholder,
            search,
        };
    }
}
</script>