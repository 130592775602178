<template>
  <div style="z-index:15;">
    <div class="field">
      <div class="control has-icons-left">
        <input class="input" type="text" placeholder="Search mindset" v-model="text" @keydown="makeSuggestions"
          @keyup="makeSuggestions" @keypress="makeSuggestions" />
        <span class="icon is-small is-left">
          <i class="fas fa-search"></i>
        </span>
      </div>
      <div>
        <div v-for="sug in suggestions" :key="sug.id">
          <div class="box" style="cursor: pointer" @click="setSelected(sug.id)">
            <span style="color: black">{{ sug.textArray[0] }}</span><span style="color: lime"> {{ sug.textArray[1]
            }}</span><span style="color: black">{{ sug.textArray[2] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted,
    toRefs,
    watch,
   } from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  setup(props, { emit }) {
    const getAllMindsets = computed(
      () => store.getters["core/getFirstMindsets"]
    );
    const store = useStore();
    const text = ref("");
    const suggestions = ref([]);
    const flatMindsets = computed(() => getAllMindsets.value.flat());
    const selectedSuggestion = ref("");
    const isHereOpen = toRefs(props).isOpen;
    onMounted(() => {
      emit("setOpenedMindsetSearch", true);
    });


    watch(
      () => props.isOpen,
       () => {
        if (!props.isOpen){
          suggestions.value=[];
          text.value="";
        }
        else {
          emit("setOpenedMindsetSearch",false);
        }
      }
    );
    function findInQuestionObj(qob, toFind) {
      let toReturn = "";
      if (undefined != qob.answerlist) {
        if (qob.answerlist.length > 0) {
          for (let ansob of qob.answerlist) {
            if (undefined != ansob.answername) {
              if (ansob.answername.toLowerCase().indexOf(toFind) > -1) {
                toReturn = ansob.answername;
                break;
              }
            }
          }
        }
      }
      return toReturn;
    }

    function findInQSet(qset, toFind) {
      toFind = toFind.toLowerCase();
      let toReturn = "";
      if (qset.title.toLowerCase().indexOf(toFind) > -1) {
        toReturn = qset.title;
      } else {
        if (undefined != qset.questionlist) {
          if (qset.questionlist.length > 0) {
            for (let qob of qset.questionlist) {
              if (undefined != qob.questionname) {
                if (qob.questionname.toLowerCase().indexOf(toFind) > -1) {
                  toReturn = qob.questionname;
                  break;
                } else {
                  toReturn = findInQuestionObj(qob, toFind);
                }
              } else {
                toReturn = findInQuestionObj(qob, toFind);
              }
            }
          }
        }
      }
      return toReturn;
    }

    function makeSuggestions() {
      if (isHereOpen.value) {
        let thissuggestions = [];
        if (text.value != "") {
          if (flatMindsets.value.length > 0) {
            for (let qs of flatMindsets.value) {
              let foundInQSet = findInQSet(qs, text.value);
              if (foundInQSet != "") {
                let sug = {};
                sug["id"] = qs.id;
                sug["textArray"] = makeTextFromFoundInQSet(
                  foundInQSet,
                  text.value
                );
                thissuggestions.push(sug);
              }
            }
          }
          suggestions.value = thissuggestions;
        } else {
          suggestions.value = [];
        }
      }
      
    }

    function makeTextFromFoundInQSet(foundInQSet, word) {
      let toReturn = ["", "", ""];
      if (foundInQSet.toLowerCase().indexOf(word.toLowerCase()) > -1) {
        let firstIndex = foundInQSet.toLowerCase().indexOf(word.toLowerCase());
        toReturn[0] =
          firstIndex > 30
            ? "..." + foundInQSet.substring(firstIndex - 30, firstIndex)
            : foundInQSet.substring(0, firstIndex);
        toReturn[1] = word;
        toReturn[2] =
          foundInQSet.length - firstIndex - word.length > 30
            ? foundInQSet.substring(
              firstIndex + word.length,
              firstIndex + word.length + 30
            )
            : foundInQSet.substring(firstIndex + word.length);
      }
      return toReturn;
    }

    function setSelected(id) {
      /*  store.commit("core/setMindsetSelected", {
         mindset: { id: id },
         exclusive: false,
       }); */

      let mindsetArrayIdx = -1;
      let mindsetIdx = -1;

      for (let i = 0; i < getAllMindsets.value.length; i++) {
        let shouldBreakOuterLoop = false;
        for (let j = 0; j < getAllMindsets.value[i].length; j++) {
          if (getAllMindsets.value[i][j].id == id) {
            mindsetArrayIdx = i;
            mindsetIdx = j;
            shouldBreakOuterLoop = true;
            break;
          }
        }
        if (shouldBreakOuterLoop) {
          break;
        }
      }
      mixins.setSelectedArrayMindset(mindsetIdx, mindsetArrayIdx, false);
      text.value = "";
      suggestions.value = [];
    }

    return {
      text,
      suggestions,
      selectedSuggestion,
      flatMindsets,
      setSelected,
      makeSuggestions,
    };
  },
};
</script>
