<template>
  <div class="boxy">
    <PageHeader :with_login="true" />
    <div class="rowy contenty">
      <div class="boxy">
        <div class="rowy headery">
          <h1 class="title pt-4">
            <span v-html="titleFirstHalf"></span
            ><span style="font-style: oblique; color: purple">{{
              titleSecondHalf
            }}</span>
             <span>&nbsp;id:</span
            ><span style="font-style: oblique; color: purple">{{
              getSelectedMgq.mindgluequestId
            }}</span>
          </h1>
        </div>
        <div class="rowy contenty">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>
<script>
import PageFooter from "@/components/parts/PageFooter.vue";
import PageHeader from "@/components/parts/PageHeader.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  components: {
    PageHeader,
    PageFooter,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    let titleFirstHalf = computed(() => {
      let toReturn = "Set ";
      if (route.matched.some(({ path }) => path == "/mgq_attributes")) {
        toReturn = "<i class='fas fa-clipboard-list'></i>&nbsp;";
      } else if (route.matched.some(({ path }) => path == "/drawmindsets")) {
        toReturn = "<i class='fas fa-map-marked'></i>&nbsp;";
      }
      return toReturn;
    });

    let titleSecondHalf = computed(() => {
      if (
        getSelectedMgq.value !== undefined &&
        getSelectedMgq.value !== null &&
        Object.keys(getSelectedMgq.value).length > 0
      )
        return getSelectedMgq.value.title;
      else return "";
    });

    const getSelectedMgq = computed(
      () => store.getters["core/getterSelectedMgq"]
    );
    router.push("/mgq_attributes");

    return {
      titleFirstHalf,
      titleSecondHalf,
      getSelectedMgq,
    };
  },
};
</script>
<style scoped>
.second_router_wrapper {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow-y: auto;
}
</style>