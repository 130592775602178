import store from '../store';
import router from '@/router'
import { computed } from "vue";

import * as mixins from "@/utils/mixins";
import * as Encr from "@/utils/Encr";

export function makeHeaders() {
  return {
    "requestid": buildRequestID(), "mytoken": store.getters["core/getJwt"]
  };
}

/* export async function myFetch(url, payload) {
  const thisrouter = router;
  store.commit("core/setIsLoading", true);
  let toReturn = await fetch(url, payload);
  store.commit("core/setIsLoading", false);
  let clonedResponse = toReturn.clone();
  let content = await toReturn.text();
  if (content=="reauthorize"){
    await mixins.alertConfirm("Your session is over, please log in again.",false);
    thisrouter.push("/login");
    return;
  }
  else return clonedResponse;
} */

const myUser = computed(
  () => store.getters["core/getUser"]
);
const isOuterMap = computed(
  () => store.getters["outermap/getIsOuterMap"]
);

export async function myFetch(url, payload) {
  const thisrouter = router;
  store.commit("core/setIsLoading", true);
  let toReturn = await fetch(url, payload);
  let firstUrl = JSON.stringify(url);
  let isURLSearchParams = false;
  let urlSearchParamsObj = null;
  if (payload) {
    if (payload.body) {
      if (payload.body.constructor.toString().indexOf("URLSearchParams")>0){
        isURLSearchParams = true;
        urlSearchParamsObj = Object.fromEntries(payload.body);
        payload.body = urlSearchParamsObj;
      }
    }
  }
  let firstPayload = JSON.stringify(payload);
  store.commit("core/setIsLoading", false);
  let clonedResponse = toReturn.clone();
  let content = await toReturn.text();
  if (content == "reauthorize") {
    if (isOuterMap.value) {
      store.commit("outermap/setShouldReauthorize", true);
      return clonedResponse;
    }
    else {
      let reauthorize = await mixins.alertConfirm("Your session is about to be over, would you like to continue?", true);
      if (reauthorize) {
        let passwdDecr = await Encr.dencr(myUser.value.password);
        await store.dispatch("core/loginSignup", {
          email: myUser.value.email,
          password: passwdDecr,
          username: myUser.value.username,
          social: myUser.value.social,
          comesFromLogin: true,
          longitude: myUser.value.longitude != undefined ? myUser.value.longitude : 0.0,
          latitude: myUser.value.latitude != undefined ? myUser.value.latitude : 0.0,
        });
        firstPayload = JSON.parse(firstPayload);
        if (isURLSearchParams){
          firstPayload.body = new URLSearchParams(firstPayload.body);
        }
        firstPayload.headers = makeHeaders();
        let newToReturn = await fetch(JSON.parse(firstUrl), firstPayload);
        clonedResponse = newToReturn;
        return newToReturn;
        // clonedResponse = newToReturn; 
      }
      else {
        thisrouter.push("/login");
        return;
      }
    }
  }
  else return clonedResponse;
}




export function buildRequestID() {
  let user = store.getters["core/getUser"];
  // console.log("userid "+user.userid);
  let digits = user.userid;
  let datenow = new Date();
  let date = Number(datenow.getUTCDate());
  let rounds = date % 10;
  let adder = rounds * rounds * date * (Math.floor(date / 10) + 2);

  if (rounds % 2 == 0) {
    for (let i = 0; i < rounds; i++) {
      digits = digitsUping(digits);
    }
  }
  else {
    for (let i = 0; i < rounds; i++) {
      digits = digitsLowering(digits);
    }
  }
  let firsthashedInt = Number(digits);
  let firsthashedIntAdded = firsthashedInt + adder * 2;
  let endHashed = adder.toString() + createZerosBuffer(digits, firsthashedInt) + firsthashedIntAdded.toString();
  return endHashed;
}
export function getUseridFromH(hashed) {
  let datenow = new Date();
  let date = Number(datenow.getUTCDate());
  let rounds = date % 10;
  let adder = rounds * rounds * date * (Math.floor(date / 10) + 2);
  let deFirstHashed = hashed.substring(adder.toString().length);
  let deFirstHashedInt = Number(deFirstHashed);
  let deFirstHashedIntSubtrackted = deFirstHashedInt - adder * 2;
  let digits = createZerosBuffer(deFirstHashed, deFirstHashedInt) + deFirstHashedIntSubtrackted.toString();
  if (rounds % 2 == 0) {
    for (let i = 0; i < rounds; i++) {
      digits = digitsLowering(digits);
    }
  }
  else {
    for (let i = 0; i < rounds; i++) {
      digits = digitsUping(digits);
    }
  }
  return digits;
}

function createZerosBuffer(numberStringBeforeIntParse, numberAfterIntParse) {
  let zerosToAdd = 0;
  let zerosStringToAdd = "";
  if (numberStringBeforeIntParse.length > numberAfterIntParse.toString().length) {
    zerosToAdd = numberStringBeforeIntParse.length - numberAfterIntParse.toString().length;
    for (let l = 0; l < zerosToAdd; l++) {
      zerosStringToAdd = zerosStringToAdd + "0";
    }
  }
  return zerosStringToAdd;
}

function digitsUping(digits) {
  let newDigits = "";
  for (let i = 0; i < digits.length; i++) {
    let num = Number(digits[i]);
    if (num != 9) { num = num + 1; }
    else num = 0;
    newDigits = newDigits + num.toString();
  }
  return newDigits;
}

function digitsLowering(digits) {
  let newDigits = "";
  for (let i = 0; i < digits.length; i++) {
    let num = Number(digits[i]);
    if (num != 0) { num = num - 1; }
    else num = 9;
    newDigits = newDigits + num.toString();
  }
  return newDigits;
}