<template>
  <div>
    <div class="trophies_container">
      <div class="button is-link mt-4" @click="addEmptyTrophy">
        Add trophy
      </div>
      <div class="button is-primary ml-4 mt-4" @click="finilize">
          Finish
        </div>
      <div v-for="trophy in getTrophies" :key="trophy">
        <MgqTrophy :trophy="trophy" :ref="setItemRef" />
      </div>
      <div>
        
      </div>
    </div>
  </div>
</template>
<script>
import MgqTrophy from "@/components/parts/MgqTrophy.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onBeforeUpdate, onMounted,  } from "vue";
import * as mixins from "@/utils/mixins";

export default {
  components: {
    MgqTrophy,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const getTrophies = computed(() => store.getters["core/getMgqTrophies"]);

    let itemRefs = [];
    const setItemRef = (el) => {
      if (el) {
        itemRefs.push(el);
      }
    };
    onBeforeUpdate(() => {
      itemRefs = [];
    });
    onMounted(()=>{
      requestTrophies();
    });

    async function requestTrophies(){
      await store.dispatch("core/requestTrophies")
    }
  

    async function addEmptyTrophy() {
                  let res = await mixins.alertConfirm("Trophies are not necessary for a mindgluequest. However you can use them to make the mindgluequest more interesting. By clicking OK you acknowledge that you are solely responsible before Mindglue, Mindgluequest and Mindglue's and Mindgluequest's Users for the fulfilment of all the obligations that stem from the trophies created by you.",true);

       if (res){
      store.commit("core/addEmptyTrophy");
      
      }
    }

    async function finilize() {
      let validatedTrophies = [];
      for (let item of itemRefs) {
        validatedTrophies.push(item["validateFields"]());
      }
      if (!validatedTrophies.includes(false)) {
        const success = await store.dispatch("core/saveUpdateTrophies");
        if (success) {
          store.commit("core/renewAfterCreate");
          router.replace("dashboard");
        }
      }
    }
    return {
      getTrophies,
      itemRefs,
      addEmptyTrophy,
      setItemRef,
      finilize,
    };
  },
};
</script>
<style scoped>
.trophies_container{
  flex:1 1 auto
}
</style>