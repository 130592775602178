<template>
  <div>
    <div class="outer_auth_container">
      <PageHeader :with_login="false" />
      <div class="centered_center">Authenticating, please wait...</div>
    </div>
  </div>
</template>
<script>
import {
  onBeforeMount,
  //ref, computed
} from "vue";
import { useRouter } from "vue-router";
import PageHeader from "@/components/parts/PageHeader.vue";
import { useStore } from "vuex";
export default {
  components: {
    PageHeader,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const email = router.currentRoute.value.params.email;
    let password = router.currentRoute.value.params.password;
    let route = router.currentRoute.value.params.route;
    let message = router.currentRoute.value.params.message;

    onBeforeMount(() => {
      password = password.replace(/_/g, "/");
             

      tryLogin();
    });

    async function tryLogin() {
      await store.dispatch("core/loginSignup", {
        email: email,
        password: password,
        social: "",
        isLogin: true,
        comesFromLogin: false,
      });
      const compIsLoggedIn = store.getters["core/getUser"].userid!=undefined;
      if (compIsLoggedIn) {
        if (route != "") {
        
          store.commit("core/setOuterMessage", message);
          router.push("/" + route);
        } else {
          router.push("/dashboard");
        }
      } else {
        router.push("/login");
      }
    }
  },
};
</script>
<style>
.outer_auth_container {
  height: 100%;
  width: 100%;
}
.centered_center {
  margin: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}
</style>