<template>
  <div style="height: 100%; width: 100%; position: relative">
    <div class="outer_map_div mb-4">
      <l-map id="map_div" ref="thisMap" :zoom="zoomLevel" :center="startCoord" @click="mapClicked"
        :use-global-leaflet="true" :options="mapOptions" :crs="crs" @zoom="updateZoom">
        <div v-if="withMapBackground">
          <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" :tileSize="getTileSize"
            :options="tileOptions" :attribution="openAttribution" :maxZoom=99></l-tile-layer>
        </div>
        <div v-else>
          <l-tile-layer url="" :minZoom=0 :maxZoom=99></l-tile-layer>
        </div>

        <!--  <l-wms-tile-layer
          v-else-if="getSelectedMgq.properties != ''"
          :base-url="baseUrl"
          :layers="getSelectedMgq.properties"
        >
        </l-wms-tile-layer> -->
        <l-layer-group ref="groupImages">
          <div v-for="(image, idx) in images" :key="idx">
            <l-image-overlay v-if="mixins.shouldShowOnMap(image.zoomLevels.min, image.zoomLevels.max, currentZoomLevel)"
              :url="baseUrl + '/mgqmaps/' + image.name" :bounds="image.latlngs" :interactive="true" :opacity="1" />
          </div>
        </l-layer-group>
        <div v-for="(polygon, idx) in polygons" :key="idx">
          <l-polygon :lat-lngs="polygon.latlngs" :fill-color="polygon.fillColor" :fillOpacity="polygon.opacity"
            :color="polygon.borderColor" :stroke="true" :stroke-width="6" :fill="true">
          </l-polygon>
        </div>
        <div v-for="(rectangle, idx) in rectangles" :key="idx">
          <l-rectangle :bounds="rectangle.latlngs" :color="rectangle.borderColor" :fillOpacity="rectangle.opacity"
            :stroke="true" :stroke-width="6" :fill-color="rectangle.fillColor" :fill="true"></l-rectangle>
        </div>
        <div v-for="(circle, idx) in circles" :key="idx">
          <l-circle :lat-lng="circle.latlngs[0]" :radius="mixins.makeDistanceFromLatLng(
            circle.latlngs[0][0],
            circle.latlngs[0][1],
            circle.latlngs[1][0],
            circle.latlngs[1][1]
          )
            " :fillOpacity="circle.opacity" :color="circle.borderColor" :stroke="true" :stroke-width="6"
            :fill-color="circle.fillColor" :fill="true"></l-circle>
        </div>
        <div v-for="(polyline, idx) in polylines" :key="idx">
          <l-polyline :lat-lngs="polyline.latlngs" :color="polyline.borderColor" :stroke="true" :stroke-width="6">
          </l-polyline>
        </div>
        <div v-for="(label, idx) in labels" :key="idx">
          <l-marker :lat-lng="label.latlngs[0]">
            <l-icon>
              <div class="label_icon" :style="'color:' + label.borderColor" style="font-size: 1.5em">
                {{ label.content }}
              </div>
            </l-icon>
          </l-marker>
        </div>
        <!-- <div v-for="(signal, idx) in signals" :key="idx">
          <l-marker :lat-lng="signal.latlngs[0]" :icon="getSignalIcon(signal)">
            <l-popup>
              <div class="popup_div">
                <div>
                  <div style="width: 5em">
                    {{ signal.content }}
                  </div>
                </div>
              </div>
            </l-popup>
          </l-marker>
        </div> -->
        <l-marker :lat-lng="[
          parseFloat(selectedLocation.split(',')[0]),
          parseFloat(selectedLocation.split(',')[1]),
        ]" :draggable="false" :icon="getMyLocationIcon(false)">
        </l-marker>
        <div v-if="hasGuessed">
          <l-marker :lat-lng="[
            parseFloat(guessedPosition.split(',')[0]),
            parseFloat(guessedPosition.split(',')[1]),
          ]" :draggable="false" :icon="getMyLocationIcon(true)" opacity="0.5">
          </l-marker>
        </div>
        <l-layer-group ref="markers">
          <div v-for="mindset in bareQSets" :key="mindset.id">
            <l-circle-marker v-if="mindset.visible" :lat-lng="[
              parseFloat(mindset.latitude),
              parseFloat(mindset.longitude),
            ]" :radius="isWebDevice ? 25 : 50" :stroke="true" color="#FFFFFF" :weight="5" :fill="true" :fillColor="selectedMindset.id == mindset.id ? '#55d3ff' : '#e345ff'
  " :fillOpacity="Number(0.7)" @click="setSelectedMindset(mindset)" :options="{ pane: 'markerPane' }" />
          </div>
        </l-layer-group>

        <!-- -->



      </l-map>
    </div>
  </div>
</template>
<script>
import { getMarker } from "@/assets/marker";
import { getMarkerWithHeading } from "@/assets/markerWithHeading";
//import { getMindglueMarker } from "@/assets/mindglueMarker";
import { CRS } from "leaflet";
//import { signalMarker } from "@/assets/signalMarker";

import L from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LCircleMarker,
  //LTooltip,
  // LWmsTileLayer,
  // LPopup,
  LLayerGroup,
  LPolyline,
  LPolygon,
  LRectangle,
  LCircle,
  LImageOverlay,
  LIcon,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import {
  ref, computed, toRefs, onBeforeMount, watch
} from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircleMarker,
    // LWmsTileLayer,
    LPolyline,
    LPolygon,
    LRectangle,
    LCircle,
    LImageOverlay,
    LIcon,
    LLayerGroup,
  },

  props: {
    isWebDevice: {
      type: Boolean,
      default: true,
      required: false,
    },
    zoomLevel: {
      type: Number,
      default: 13,
      required: false,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const thisMap = ref(null);
    const crs = computed(() => {
      let toReturn = CRS.WGS84;
      if (!withMapBackground.value) toReturn = CRS.Simple;
      return toReturn;
    });

    const getSelectedMgq = computed(
      () => store.getters["core/getterSelectedMgq"]
    );
    const isModePositionCorrecting = ref(false);
    const isWebDeviceHere = toRefs(props).isWebDevice;
    const bareQSets = ref([]);
    const clickedOnMarker = ref(false);
    const withMapBackground = computed(() => {
      let toReturn = true;
      if (Object.keys(getSelectedMgq.value).length > 0) {
        if (getSelectedMgq.value.properties == "drawing") {
          toReturn = false;
        }
      }
      return toReturn;
    });
    const heading = ref(-1);
    const currentZoomLevel = ref(0);
    onBeforeMount(() => {
      startCoord.value = [
        parseFloat(getSelectedMgq.value.latitude),
        parseFloat(getSelectedMgq.value.longitude),
      ];
      selectedLocation.value =
        getSelectedMgq.value.latitude + "," + getSelectedMgq.value.longitude;
    });


    const guessedPosition = ref("");
    const hasGuessed = ref(false);
    const mapOptions = computed(() => {
      return {
        zoomControl: false,
      };
    });

    const openAttribution =
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors';
    const tileOptions = computed(() => {
      if (isWebDeviceHere.value) {
        return {
          zoomOffset: 0,
        };
      } else {
        return {
          zoomOffset: -1,
        };
      }
    });

    const isReal = false;

    const polylines = ref(mixins.polylines);
    const circles = ref(mixins.circles);
    const rectangles = ref(mixins.rectangles);
    const polygons = ref(mixins.polygons);
    const images = ref(mixins.images);
    // const signals = ref(mixins.signals);
    const labels = ref(mixins.labels);

    /*   function getSignalIcon() {
      let selected = false;
      let icon = new L.divIcon({
        // iconAnchor: [20, 20],
        className: "",
        html: `<div>${signalMarker(selected)}</div>`,
      });
      return icon;
    } */

    const baseUrl = mixins.baseUrl;

    let startCoord = ref([
      /*    parseFloat(getSelectedMgq.value.latitude),
      parseFloat(getSelectedMgq.value.longitude), */
    ]);

    const selectedLocation = ref("");

    const getTileSize = computed(() => (isWebDeviceHere.value ? 256 : 512));

    const selectedMindset = ref({
      id: 0,
    });

    watch(
      () => bareQSets.value,
      () => {
        updateMindetsVisibility();
      }
    );

    function mapClicked(e) {
      if (
        getSelectedMgq.value.mindgluequestId == -1 ||
        getSelectedMgq.value.virtual
      ) {
        if (!clickedOnMarker.value) {
          if (undefined != e.latlng) {
            selectedLocation.value =
              e.latlng.lat.toString() + "," + e.latlng.lng.toString();
            emit("setSelectedLocation");
          }
        } else {
          clickedOnMarker.value = false;
        }
      }
    }

    function zoomIn() {
      thisMap.value.leafletObject.zoomIn();
    }
    function zoomOut() {
      thisMap.value.leafletObject.zoomOut();
    }

    function setGuessedPosition(coordinates) {
      guessedPosition.value = coordinates;
      hasGuessed.value = true;
      setTimeout(() => {
        hasGuessed.value = false;
      }, 4000);
    }

    function setLocationFromSensors(coordinates) {
      selectedLocation.value = coordinates;
      thisMap.value.leafletObject.panTo([
        parseFloat(selectedLocation.value.split(",")[0]),
        parseFloat(selectedLocation.value.split(",")[1]),
      ]);
    }

    function setHeadingFromSensors(updatedHeading) {
      console.log("set heading from sensors " + updatedHeading);
      heading.value = updatedHeading;
    }

    /*
    function getMindsetsIcon(mindset) {
      const mindglueMarkerSvg =
        selectedMindset.value.id == mindset.id
          ? ref(getMindglueMarker("ff8300", isWebDeviceHere.value))
          : ref(getMindglueMarker("ba00c9", isWebDeviceHere.value));
      const myIcon = L.divIcon({
        className: "",
        html: `<div>${mindglueMarkerSvg.value}</div>`,
      });
      return myIcon;
    }
*/

    function getMyLocationIcon(isGuessed) {
      let color = isModePositionCorrecting.value ? "00c72a" : "2251ff";
      if (isGuessed) {
        color = "ff0000";
      }
      if (heading.value != -1) {
        const markerSvg = ref(
          getMarkerWithHeading(color, isWebDeviceHere.value, heading.value)
        );
        const myIcon = L.divIcon({
          className: "my_location_marker_big",
          html: `<div>${markerSvg.value}</div>`,
          iconAnchor: isWebDeviceHere.value ? [12, 12] : [36, 36],
        });
        return myIcon;
      } else {
        const markerSvg = ref(getMarker(color, isWebDeviceHere.value));
        const myIcon = L.divIcon({
          className: "my_location_marker_big",
          html: `<div>${markerSvg.value}</div>`,
          iconAnchor: isWebDeviceHere.value ? [12, 12] : [36, 36],
        });
        return myIcon;
      }
    }

    function moveToMindset(ifTargetMap) {
      let mindset = ifTargetMap["target"];
      let visiblityProps = JSON.parse(mindset["visibilityProps"]);
      thisMap.value.leafletObject.setView(
        new L.LatLng(
          parseFloat(mindset["latitude"]),
          parseFloat(mindset["longitude"])
        ),
        visiblityProps[1]
      );
      if (ifTargetMap["bareQSets"].length > 0) {
        bareQSets.value = ifTargetMap["bareQSets"];
      }
      selectedMindset.value = bareQSets.value.filter(
        (qs) => qs.id.toString() == mindset["id"]
      )[0];
      selectedLocation.value =
        selectedMindset.value.latitude + "," + selectedMindset.value.longitude;
      console.log(
        "before supposedly moved to " +
        selectedLocation.value +
        " equals " +
        selectedMindset.value.latitude +
        "," +
        selectedMindset.value.longitude
      );
    }


    function moveToLocation(ifTargetMap) {
      let location = ifTargetMap["location"];
      let locArray = location.split(",");
      thisMap.value.leafletObject.setView(
        new L.LatLng(
          parseFloat(locArray[0]),
          parseFloat(locArray[1])
        ),
        16
      );
    }

    function showMapRight() {
      setTimeout(function () {
        thisMap.value.leafletObject.invalidateSize(true);
      }, 50);
    }

    function setSelectedMindset(mindset) {
      clickedOnMarker.value = true;
      emit("setSelectedMindset", mindset.id.toString());
      selectedMindset.value = mindset;
    }

    function updateZoom() {
      updateMindetsVisibility();
      currentZoomLevel.value = thisMap.value.leafletObject.getZoom();
    }

    function updateMindetsVisibility() {
      for (let mindset of bareQSets.value) {
        if (mixins.shouldShowOnMap(Number(mindset.minZoom), Number(mindset.maxZoom), currentZoomLevel.value)) {
          mindset["visible"] = true;
        }
        else {
          mindset["visible"] = false;
        }
      }
    }

    return {
      setSelectedMindset,
      thisMap,
      showMapRight,
      //getAllMindsets,
      getSelectedMgq,
      startCoord,
      //getMindsetsIcon,
      getMyLocationIcon,
      baseUrl,
      mixins,
      polygons,
      polylines,
      rectangles,
      circles,
      labels,
      // signals,
      mapClicked,
      withMapBackground,
      images,
      getTileSize,
      mapOptions,
      tileOptions,
      isWebDeviceHere,
      selectedLocation,
      selectedMindset,
      zoomOut,
      zoomIn,
      moveToMindset,
      guessedPosition,
      hasGuessed,
      setGuessedPosition,
      bareQSets,
      openAttribution,
      isReal,
      //  getSignalIcon,
      crs,
      isModePositionCorrecting,
      setLocationFromSensors,
      setHeadingFromSensors,
      moveToLocation,
      updateZoom,
      currentZoomLevel,
    };
  },
};
</script>
<style scoped>
.my_location_marker_big {
  width: 200px;
  height: 200px;
}

#mindset_attr {
  z-index: 10;
}

#map_div {
  z-index: 1;
}

.popup_div {
  text-align: center;
}

.unset_mgq_map_region_name {
  color: grey;
  font-style: oblique;
  text-align: center;
}

.mgq_map_region_name {
  color: black;
  text-align: center;
}

.outerdiv {
  /*   height: 550px; 
  width: 900px;*/
  margin: auto;
}


/* @media only screen and (max-height: 700px) {
  .outerdiv {
    height: 350px;
  }
}
@media only screen and (max-height: 400px) {
  .outerdiv {
    height: 250px;
  }
}
@media only screen and (max-width: 1200px) {
  .outerdiv {
    width: 700px;
  }
}
@media only screen and (max-width: 1000px) {
  .outerdiv {
    width: 450px;
  }
}
@media only screen and (max-width: 700px) {
  .outerdiv {
    width: 300px;
  }
}
@media only screen and (max-width: 500px) {
  .outerdiv {
    width: 280px;
  }
}
@media only screen and (max-width: 400px) {
  .outerdiv {
    width: 240px;
  }
} */
</style>