<template>
  <div class="boxy">
    <PageHeader :with_login="true" />
    <div class="rowy contenty">
      <div v-if="mgq" class="title_play_container mt-4">
        <div class="down_arrow" :style="'color:' + colors[mgq.color]" @click="selectedMgq">
          <i :class="playButton" style="font-size: 3em"></i>
        </div>
        <div class="selected_title card" :style="'background-color:' + colors[mgq.color]">
          {{ mgq.title }}
        </div>

      </div>
      <div class="main_selected" v-if="more && mgq">
        <div class="left_side_wrapper">
          <div v-if="more.image != ''">
            <figure class="image pb-2">
              <!--  style="width: 300px; height: 300px" -->
              <img :src="more.image" class="roundedCorners" />
            </figure>
          </div>
          <div>
            <div class="card p-4">
              <div class="expl_row">
                <div class="expl_row">
                  <div class="has-text-weight-bold" :style="'color:' + colors[mgq.color]">
                    <i class="fas fa-mobile is-size-4"></i>
                    /
                    <i class="fas fa-walking is-size-4"></i>
                  </div>
                </div>
                <div class="expl_row">
                  <div class="is-justify-content-center">
                    <i :class="{
      'fas fa-walking is-size-4': !mgq.virtual,
      'fas fa-mobile is-size-4': mgq.virtual,
    }"></i>
                  </div>
                  <div class="has-text-weight-bold">
                    {{ mgq.virtual ? "Virtual" : "Real" }}
                  </div>
                </div>
              </div>
              <div class="expl_row has-text-weight-bold is-size-6">
                <i class="fas fa-user is-size-4" :style="'color:' + colors[mgq.color]"></i>
                <div>
                  {{ mgq.creator.username }}
                </div>
              </div>
              <div class="expl_row has-text-weight-bold is-size-6">
                <i class="fas fa-clock is-size-4" :style="'color:' + colors[mgq.color]"></i>
                <div>
                  {{
      mixins.setDate(mgq.mgqVersion.startTime) +
      " " +
      mixins.setTime(mgq.mgqVersion.startTime)
    }}
                </div>
              </div>
              <div class="expl_row has-text-weight-bold is-size-6">
                <i class="fas fa-stopwatch is-size-4" :style="'color:' + colors[mgq.color]"></i>
                <div>
                  {{
      mixins.setDate(mgq.mgqVersion.endTime) +
      " " +
      mixins.setTime(mgq.mgqVersion.endTime)
    }}
                </div>
              </div>


              <div v-if="mgq.price > 0" class="expl_row has-text-weight-bold is-size-6">
                <i class="fas fa-coins is-size-4" :style="'color:' + colors[mgq.color]"></i>
                <div>
                  {{
      mgq.price +
      " " +
      mixins.getCurrencySymbol(mgq.currency)
    }}
                </div>
              </div>



              <div class="expl_row has-text-weight-bold is-size-6">
                <div :style="'color:' + colors[mgq.color]">Contest</div>
                <i v-if="shouldShowNowIcon" class="fas fa-burn is-size-4" style="color: red; font-weight: bold"
                  @click="showContest"></i>
                <i v-else-if="shouldShowContestIcon" class="fas fa-burn is-size-4"
                  style="color: blue; font-weight: bold" @click="showContest"></i>
              </div>


              <div v-if="showPaypalButton">
                <PaypalButton :paypalCreds="paypalCreds" :paypalUrl="paypalUrl" :mgq="mgq"
                  :orderStateCreds="orderStateCredMap" @paymentDone="showPaypalButton = false" />
              </div>
              <div class="button is-primary mt-2" style="width:100%;" @click="selectedMgq">{{ enterStopLabel }}</div>
              <div class="button is-primary mt-2" style="width:100%;"
                @click="gameMixins.takeMeToPlace(mgq, mgq.longitude, mgq.latitude)">Take me to start</div>
            </div>
          </div>

          <div v-if="trophies.length > 0">
            <div class="trophies card p-2">
              <div class="trophies_title" :style="'color:' + colors[mgq.color]">
                <div class="has-text-weight-bold is-size-6 pr-4">Trophies</div>
                <div class="pr-4"><i class="fas fa-trophy is-size-4"></i></div>
              </div>
              <div v-for="trophy in trophies" :key="trophy.id">
                <div class="trophy_attr">
                  <div class="pr-4"><i class="fas fa-gift"></i></div>
                  <div>
                    {{ trophy.content }}
                  </div>
                </div>
                <div class="trophy_attr">
                  <div class="pr-4"><i class="fas fa-bullseye"></i></div>
                  <div>
                    {{ trophy.achievement }}
                  </div>
                </div>
                <div class="trophy_attr">
                  <div class="pr-4"><i class="fas fa-certificate"></i></div>
                  <div>
                    {{ trophy.providerName }}
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div class="card p-2 ml-2">
          <div class="pl-4">
            <div>
              <p class="subtitle is-4">
                <i class="fas fa-map-marker-alt is-size-4" style="font-weight: bold"></i>
                &nbsp;
                <span :style="'color:' + colors[mgq.color]">
                  {{ mgq.location }}</span>
              </p>
              <p class="shortDescr_p pr-2" :style="'color:' + colors[mgq.color]">
                {{ mgq.shortDescr }}
              </p>
            </div>
            <br />
            <div class="longDescr_p" v-html="mgq.longDescr"></div>
          </div>
        </div>
        <div></div>
        <div class="m-4">
          <div>
            <!-- <div class="badges">
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.mindglue.mindglue">
                
                  <img width="150" src="~@/assets/google_play_badge.svg" alt="google play download" />
                </a>
              </div>
              <div>
                <a href="https://apps.apple.com/us/app/mindglue/id1596355198">
                
                  <img width="130" src="~@/assets/app_Store_Badge_US-UK.svg" alt="appstore download" />
                </a>
              </div>
              <div>
                <a :href="'https://www.mindglueapp.com/#/mindgluequest/' +
                  mgq.mindgluequestId.toString()
                  ">
                  <img width="100" src="~@/assets/web_app_badge.svg" alt="web app" />
                </a>
              </div>
            </div> -->
            <!--   <div class="down_arrow" :style="'color:' + colors[mgq.color]" @click="selectedMgq">
              <i :class="playButton" style="font-size: 13em"></i>
            </div> -->
          </div>
          <div class="badges">
            <SocialShareButton :socialType="'facebook'" :innerUrl="'https://www.mindgluquest.com/qd/' +
      mgq.mindgluequestId.toString()
      " />
            <SocialShareButton :socialType="'twitter'" :innerUrl="'https://www.mindgluquest.com/qd/' +
      mgq.mindgluequestId.toString()
      " />
          </div>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import SocialShareButton from "@/components/widgets/SocialShareButton.vue";
import PageHeader from "@/components/parts/PageHeader.vue";
import PageFooter from "@/components/parts/PageFooter.vue";
import PaypalButton from "@/components/parts/PaypalButton.vue";
import * as mixins from "@/utils/mixins";
import * as DBProvider from "@/utils/DBProvider";
import * as gameMixins from "@/utils/gameMixins";
import { onBeforeMount, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import * as LocationService from "@/utils/LocationService";

export default {
  components: {
    PageHeader,
    PageFooter,
    SocialShareButton,
    PaypalButton,
  },
  setup() {



    const router = useRouter();
    const store = useStore();
    const mgqid = router.currentRoute.value.params.mgqid;
    const mgq = ref(null);
    const more = ref(null);
    const colors = mixins.colors;
    const showPaypalButton = ref(false);
    const paypalCreds = ref({});
    const paypalUrl = computed(() => 'https://www.paypal.com/sdk/js?client-id=' + paypalCreds.value['ppca'] + '&components=buttons&currency=' + mgq.value.currency);
    const shouldShowNowIcon = computed(() => {
      const datenow = new Date();
      if (
        mgq.value.mgqVersion.startTime < datenow &&
        datenow < mgq.value.mgqVersion.endTime
      )
        return true;
      else return false;
    });
    const shouldShowContestIcon = computed(() => {
      const datenow = new Date();
      if (mgq.value.mgqVersion.endTime < datenow) return true;
      else return false;
    });
    const trophies = ref(null);
    const orderStateCredMap = ref({});

    const playButton = computed(() =>
      mgq.value.mgqStatus == gameMixins.MgqStatus.STOPPED || mgq.value.mgqStatus == gameMixins.MgqStatus.PLAYED || mgq.value.mgqStatus == gameMixins.MgqStatus.UNSUBSCRIBED || mgq.value.mgqStatus == gameMixins.MgqStatus.UNSUBSCRIBED_OLD ? "fas fa-play-circle" : "fas fa-stop-circle"
    );


    const enterStopLabel = computed(() =>
      mgq.value.mgqStatus == gameMixins.MgqStatus.STOPPED || mgq.value.mgqStatus == gameMixins.MgqStatus.PLAYED || mgq.value.mgqStatus == gameMixins.MgqStatus.UNSUBSCRIBED || mgq.value.mgqStatus == gameMixins.MgqStatus.UNSUBSCRIBED_OLD ? "Enter" : "Stop"
    );

    function showContest() {
      router.push("/contestResults/" + mgq.value.mindgluequestId);
    }

    const isLoggedIn = computed(() => store.getters["core/getUser"].userid != undefined);

    onBeforeMount(async () => {
      makeStart();

    });

    async function makeStart() {
      let mgqFromOutside = store.getters["core/getMindgluequests"].filter((x) => x.mindgluequestId == mgqid)[0];
      let mgqFromCurrent = store.getters["gameApi/getCurrentMgq"].mindgluequestId == mgqid ? store.getters["gameApi/getCurrentMgq"].mindgluequestId : undefined;
      if (mgqFromOutside != undefined || mgqFromCurrent != undefined) {
        mgq.value = mgqFromOutside != undefined ? mgqFromOutside : mgqFromCurrent;
        if (isLoggedIn.value) {
          await getSelectedToPresentLogged();
        }
        else {
          await getSelectedToPresent();
        }
      }
      else {
        await getSelectedToPresent();
      }
      if (store.getters["core/getMindgluequests"].length == 0) {
        store.commit("core/setMindgluequests", [mgq.value]);
      }
      mgq.value.mgqStatus = gameMixins.makeMgqStatus(mgq.value);
    }


    async function selectedMgq() {
      store.commit("gameApi/initializeBackgroundAudio");
      store.commit("gameApi/initializeEffectsAudio");
      if (store.getters["core/getUser"].userid != undefined) {
        await handleOnJoinMgq(mgq.value);
      }
      else {
        /* let howToLogin = await mixins.makeModalDynamic("DynamicMultipleRadiobuttons", { title: "You have to be logged in to play. We can create a generic account for you if you just want to join now. Or if you have an account or would like to create one, select Log in.", checkboxTitles: ["Generic", "Log in"] });
         if (howToLogin != null) {
           if (howToLogin.content == "Log in") {
             let tempRoute = router.currentRoute.value.fullPath;
             store.commit("core/setTemporaryContent", { temporaryRoute: tempRoute });
             router.push("/login");
           }
           else {
             await joinGeneric();
           }
         }
         else {
           await joinGeneric();
         }*/

        await joinGeneric();

      }
    }




    async function joinGeneric() {
      await mixins.makeToast("Hurray, a guest account is created for you! You can change it in your account page.",3000);
      let successGeneric = await store.dispatch("core/loginSignup", { isSignup: true });
      if (successGeneric == "askForCoordinates") {
        //   let coords = await LocationService.askForCoordinates();
        let coords = { latitude: LocationService.defaultCoordinates[0].toString(), longitude: LocationService.defaultCoordinates[1].toString() };
        store.commit("core/setUserLatLong", coords);
        await DBProvider.writeDBKeyValue("user_info", "user", JSON.stringify(store.getters["core/getUser"]));

      }
      if (successGeneric == "askForCoordinates" || successGeneric == "ok!") {
        await handleOnJoinMgq(mgq.value);
      }
    }

    async function getSelectedToPresent() {
      let mymap = await store.dispatch(
        "core/requestSelectedPresentation",
        mgqid
      );
      mgq.value = JSON.parse(mymap["mgq"]);
      more.value = JSON.parse(mymap["more"]);
      mgq.value.longDescr = more.value.long_descr;
      trophies.value = JSON.parse(more.value.trophies);
    }

    async function getSelectedToPresentLogged() {
      more.value = await store.dispatch(
        "gameApi/requestSelectedPresentationLogged",
        { mgq: mgq.value }
      );
      /*  let image = _selectedMgqMap["image"];
     let longDescr = _selectedMgqMap["long_descr"]; */
      mgq.value.longDescr = more.value.long_descr;
      mgq.value.image.content = more.value.image;

      trophies.value = JSON.parse(more.value.trophies);
    }

    async function justDoAfterControlsDone() {
      let _status = await gameMixins.setUsMindgluequest(
        mgq.value, "", "");
      if (_status == "stopped") {
        mgq.value.mgqStatus = gameMixins.MgqStatus.STOPPED;
      }
    }





    async function handleOnJoinMgq(mgq) {
      let _locationPermissionFree = false;
      if (mgq.mgqStatus == gameMixins.MgqStatus.STOPPED || mgq.mgqStatus == gameMixins.MgqStatus.UNSUBSCRIBED || mgq.mgqStatus == gameMixins.MgqStatus.UNSUBSCRIBED_OLD || mgq.mgqStatus == gameMixins.MgqStatus.PLAYED) {
        let _isInFuture = await handleIfInFuture();
        if (!_isInFuture) {
          if (!mgq.virtual) {
            _locationPermissionFree = await handleLocationSensorPermission();
          }
          else {
            _locationPermissionFree = true;
          }
          if (_locationPermissionFree) {
            let _passwordFree = true;
            if (mgq.withPassword) {
              _passwordFree = false;
              _passwordFree = await handlePassword(mgq);
            }
            if (_passwordFree) {
              /*
              
              WE NEED to get it to gameMixins and check it on changeMgq instead of just hitting setUsMgq.
              for it, we need to create a DynamicPaypalButtons

              
              */
              if (mgq.price != 0) {
                await getOrderState(mgq);
                if (orderStateCredMap.value["state"] == "nonexistent") {
                  await hasConsentToPay();
                  /*  if (hasConsent){
                     showPaypalButton
                   } */
                }
                else if (orderStateCredMap.value["state"] == "approved") {
                  //makePAyment
                }
                else if (orderStateCredMap.value["state"] == "completed") {
                  await justDoAfterControlsDone();
                }
              }
              else {
                await justDoAfterControlsDone();
              }
            }
          }
        }
      }
      else {
        //  FirebaseHandler.unsubscribeFromTopic(mgq.mindgluequestId.toString()+"_"+mgq.mgqVersion.versionNr.toString()+"_"+mgq.mgqVersion.startTime.toString());
        await justDoAfterControlsDone();
      }
    }

    async function handleIfInFuture() {
      let toReturn = false;
      let millisToStart = mgq.value.mgqVersion.startTime - Date.now();
      if (millisToStart > 0) {
        let durationUnits = mixins.millisToDaysHoursSeconds(millisToStart);
        toReturn = true;
        let toSendStartNotification = await mixins.alertConfirm(
          "A little more patience. The quest " + mgq.value.title + " starts in " + durationUnits.days + " days " + durationUnits.hours + "h " + durationUnits.minutes + "m and " + durationUnits.seconds + "s . Would you like to get an email notification just before it is about to start, so you don't miss it?", true);
        if (toSendStartNotification) {
          let usr = store.getters["core/getUser"];
          if (usr.email.split("@")[1] == "temp.mindglue.io") {
            await mixins.alertConfirm("Your account is a generic one, so we can't send an email to you. Please update your account with your credentianls.", false);
          }
          let success = await store.dispatch("gameApi/makeStartNotificationSubscription", {
            mgq: mgq.value,
            whatToSend: "email",
          });
          if (success == "ok!" || success == "duplicate") {
            await mixins.alertConfirm("Saved!", false);
          }
          else if (success == "error") {
            await mixins.alertConfirm("An error occured.", false);
          }
        }
      }
      return toReturn;
    }


    async function handleLocationSensorPermission() {
      let toReturn = false;
      toReturn = await mixins.alertConfirm("This mindgluequest requires enabling your device's location sensors.", true);
      if (toReturn) {
        let hasPermission = await LocationService.getLocationFromLocationSensor();
        return hasPermission;
      }
      else {
        return false;
      }
    }

    async function handlePassword(mgq) {
      let _toReturn = false;
      let _foundCorrectPassword = await findMgqPassword(mgq);
      if (!_foundCorrectPassword) {
        let _fromRequest = await requestPassword(mgq);
        if (null == _fromRequest) {
          _fromRequest = false;
        }
        _toReturn = _fromRequest;
      }
      else {
        _toReturn = true;
      }
      return _toReturn;
    }

    async function findMgqPassword(mgq) {
      let key = "mgq_" + mgq.mindgluequestId.toString();
      let _savedPassword = window.localStorage.getItem(key);
      if (null != _savedPassword) {
        let isCorrectPassword = await store.dispatch("gameApi/checkMgqPassword", {
          mgq: mgq, givenPassword: _savedPassword, isNewPassword: true
        });
        return isCorrectPassword;
      }
      else {
        return false;
      }
    }




    async function requestPassword(mgq) {
      let _toReturn = false;
      let givenPass = await mixins.makeModalDynamic("DynamicOneTextfield", { title: "Please enter the password of the mindgluequest", isPassword: true });
      if (givenPass != null) {
        let _isCorrect = await store.dispatch("gameApi/checkMgqPassword", {
          mgq: mgq, givenPassword: givenPass.content, isNewPassword: true
        });
        if (!_isCorrect) {
          mixins.alertConfirm("Wrong password!", false);
        }
        else {
          _toReturn = true;
        }
      }
      return _toReturn;
    }

    async function getOrderState(mgq) {
      if (mgq.price != 0) {
        orderStateCredMap.value = await store.dispatch("gameApi/fetchIfPayedPayeeId", { mindgluequest: mgq });
      }
    }




    async function hasConsentToPay(mgq) {
      let curSymbol = mixins.getCurrencySymbol(mgq.currency);
      let agreesToPaypal = await mixins.alertConfirm("This Mindgluequest has a participation fee of " + mgq.price.toString() + " " + curSymbol + ". This fee goes directly to the Paypal account of " + mgq.creator.username + ", the creator of the mindgluequest " + mgq.title + ". If you tap on Ok, you will see the Paypal button which will guide you to your payment.", true);
      if (agreesToPaypal) {
        paypalCreds.value = await store.dispatch("core/fetchPaypalCreds");
        if (paypalCreds.value['ppca'] != undefined) {
          showPaypalButton.value = true;
        }
      }
    }




    return {
      mgqid,
      mgq,
      more,
      mixins,
      gameMixins,
      shouldShowNowIcon,
      shouldShowContestIcon,
      showPaypalButton,
      paypalCreds,
      orderStateCredMap,
      playButton,
      showContest,
      colors,
      selectedMgq,
      trophies,
      paypalUrl,
      enterStopLabel,
    };
  },
};
</script>

<style scoped>
.selected_presenter_wrapper {
  height: 100%;
}

.selected_presenter_inner {
  padding-top: 2em;
  min-height: 85%;
}

.main_selected {
  display: grid;
  padding: 2em;
  grid-template-columns: 1fr 3fr;
}

.selected_title {
  margin-left: 1em;
  margin-right: 1em;
  color: white;
  font-size: 2em;
}

.roundedCorners {
  border-radius: 50%;
}

.trophies {
  margin-top: 1.5em;
}

.longDescr_p {
  text-align: justify;
  padding-right: 1em;
  white-space: pre-wrap;
}

.shortDescr_p {
  text-align: justify;
}



.trophies_title {
  display: flex;
  justify-content: center;
}

.trophy_attr {
  display: flex;
  font-size: 0.9em;
}

.badges {
  display: flex;
  align-items: center;
  gap: 1em;
}

.title_play_container {
  display: grid;
  grid-template-columns: 1fr 8fr;
}

@media only screen and (max-width: 500px) {
  .main_selected {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 1em;
  }
}
</style>