<template>
  <div class="ms_attributes_wrapper">

    <div class="mindset_things">

      <div class="attrib_mchk_selector">
        <div style="cursor: pointer;" @click="showAttrib = true" :class="{ selector_selected: showAttrib }">Attributes
        </div>
        <div style="cursor: pointer;" @click="showAttrib = false" :class="{ selector_selected: !showAttrib }">Mindchunks
        </div>
      </div>
      <div class="mindset_attributes" v-show="showAttrib">
        <!--   <div class="field">
          <label class="label">Name</label>
          <div class="control has-icons-left">
            <input id="name_input" :disabled="isSocial" class="input" type="text" placeholder="Name"
              v-model="getMindsetToShow.name" />
            <span class="icon is-small is-left">
              <i class="fas fa-pen"></i>
            </span>
          </div>
        </div> -->

       <!--  <div class="field">
          <label class="label">Properties</label>
          <div @click="addProperty" :class="{ button: !isSocial, '': !isSocial }">
            <i class="fas fa-plus is-size-4"></i>
          </div>
          <div class="mt-2" v-for="(value, key) of properties" :key="key">
            <PropertyComponent :ref="setPropertiesRef" :propertyKey="key" :propertyValue="value" :isSocial="isSocial"
              @removeProperty="removeProperty" />

          </div>
        </div> -->

        <PropertiesComponent ref="propsComponent" :outerProperties="properties" :isSocial="isSocial"/>

        <hr />
        <div class="field">
          <label class="label">Title/Content</label>
          <div class="control">
            <textarea id="title_input" class="textarea" :disabled="isSocial" style="resize: none" rows="2"
              placeholder="Title/Content" v-model="getMindsetToShow.title"></textarea>
          </div>
        </div>
        <hr />
        <div class="checkboxes">
          <div class="field">
            <label class="label">Start</label>
            <input type="checkbox" :disabled="isSocial" v-model="getMindsetToShow.isStart" @change="handleIsStartOnce" />
          </div>
          <div class="field">
            <label class="label">Achievement</label>
            <input type="checkbox" v-model="suitableTargeted" @change="handleCheckIfCorrectAnswers"
              :disabled="isSocial" />
          </div>
          <div class="field">
            <label class="label">Marked</label>
            <input type="checkbox" :disabled="isSocial" v-model="getMindsetToShow.marked" />
          </div>
        </div>
        <div>
          <label class="label">Image file</label>
          <div class="input_and_result">
            <div class="field">
              <div class="file has-name is-justify-content-center" :class="{ 'is-danger': wrongImageNameFile }">
                <label class="file-label">
                  <input class="file-input" type="file" :disabled="isSocial" name="resume" accept="image/png, image/jpeg"
                    @change="setImageFile" />
                  <span class="file-cta">
                    <span class="file-icon">
                      <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label">Choose</span>
                  </span>
                  <span class="file-name">
                    {{ imageName }}
                  </span>
                </label>
              </div>
            </div>
            <div v-if="imageFile" class="ml-4">
              <img :src="mixins.baseUrl + mixins.qstImagesDir + imageName" :alt="imageName" width="50" />
            </div>
          </div>
        </div>

        <hr />

        <div>
          <label class="label">Embed media</label>
          <div class="input_and_result">
            <div class="field">
              <div class="file has-name is-justify-content-center" :class="{ 'is-danger': wrongEmbedContent }">
                <div class="control has-icons-left">
                  <input id="embed_content" class="input" :disabled="isSocial" :class="{ 'is-danger': wrongEmbedContent }"
                    type="text" @change="getEmbedSrc" placeholder="youtube, spotify, soundcloud" v-model="embedContent" />
                  <span class="icon is-small is-left">
                    <i class="fas fa-pen"></i>
                  </span>
                </div>
              </div>
            </div>
            <div v-if="embedContent != ''" class="ml-4">
              <iframe :src="embedContent" allow="autoplay"></iframe>
            </div>
          </div>
        </div>
        <hr />

        <div>
          <label class="label">Chain mindgluequest</label>
          <div class="input_and_result">
            <div class="field">
              <div class="field">
                <label class="label">Chained with</label>
                <input ref="chainCheckbox" :disabled="isSocial" :checked="chainCheck" type="checkbox"
                  @change="changeToChain" />

              </div>
              <div @click="addChain" :class="{ button: !isSocial, '': !isSocial }">
                <i class="fas fa-plus is-size-4"></i>
              </div>
            </div>

            <div v-if="getMindsetToShow.chain != ''" class="ml-4">
              <div v-for="(chain, idx) of chains" :key="idx">
                <MgqChain :ref="setMgqChainRef" :isSocial="isSocial" :chainProp="chain"
                  @removeMgqChain="removeMgqChain" />
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4" style="width:30%;">
          <label class="label">Visibility properties</label>
          <div class="input_and_result visibility_props">
            <div class="">
              Min. distance
            </div>
            <input class="input" style="width:5em;" min=0 max=10000000 v-model="minDistance" :disabled="isSocial"
              type="number" maxlength="10" />
          </div>
          <div class="input_and_result visibility_props">
            <div class="">
              Default zoom level
            </div>
            <input class="input" style="width:5em;" min=0 max=99 v-model="defaultZoom" :disabled="isSocial" type="number"
              @change="checkVisibilityProps" maxlength="2" size="1" />
          </div>
          <div class="input_and_result visibility_props">
            <div class="">
              Min. zoom level
            </div>
            <input class="input" style="width:5em;" min=0 max=99 v-model="minZoom" :disabled="isSocial" type="number"
              @change="checkVisibilityProps" maxlength="2" size="1" />
          </div>
          <div class="input_and_result visibility_props">
            <div class="">
              Max. zoom level
            </div>
            <input class="input" style="width:5em;" min=0 max=99 v-model="maxZoom" :disabled="isSocial" type="number"
              @change="checkVisibilityProps" maxlength="2" size="1" />
          </div>
        </div>

      </div>

      <div class="mindset_attributes" v-show="!showAttrib">
        <div class="field">
          <label class="label">Mindchunks</label>
          <div>
            <div class="control">
              <div @click="addMindchunkHere" :class="{ button: !isSocial, '': !isSocial }">
                <i class="fas fa-plus is-size-4"></i>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div v-for="(mindchunk, index) in getMindsetToShow.questionlist" :key="mindchunk.questionid">
            <MindChunk :mindchunkProp="mindchunk" :isSocial="isSocial" :ref="setMindchunkRef"
              @click="setSelectedMindchunkRef(index)" @removeMindchunk="removeMindchunk" class="mb-2"
              @changeInturn="changeInturn" />
          </div>
        </div>
      </div>


    </div>
    <div class="field" v-if="getSentences.length > 0">
      <label class="label">Text chunks</label>
      <div class="sentences">
        <div v-for="sentence in getSentences" :key="sentence">
          <div class="sentence m-4">
            <!--  <div class="sentence m-4" @click="appendText(sentence)"> -->
            {{ sentence }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, onBeforeUpdate, ref, onBeforeMount, nextTick } from "vue";
import { useStore } from "vuex";
import MindChunk from "@/components/parts/MindChunk.vue";
import MgqChain from "@/components/parts/MgqChain.vue";
import * as mixins from "@/utils/mixins";
import * as compareObjects from "@/utils/CompareMyObjects";
import PropertiesComponent from "./PropertiesComponent.vue";

export default {
  props: {
    mindsetProp: {
      type: Object,
      required: true,
    },
  },
  components: {
    MindChunk,
    MgqChain,
    PropertiesComponent,
  },
  setup(props) {
    const store = useStore();
    const getMindsetToShow = ref(null);
    const getSentences = computed(() => store.getters["core/getTextSentences"]);
    const showAttrib = ref(true);
    let mindchunkRefs = [];
    const setMindchunkRef = (el) => {
      if (el) {
        mindchunkRefs.push(el);
      }
    };

    const chains = ref([]);
    let mgqChainRefs = [];
    const setMgqChainRef = (el) => {
      if (el) {
        mgqChainRefs.push(el);
      }
    };

const propsComponent = ref(null);
   /*  let propertiesRefs = [];
    const setPropertiesRef = (el) => {
      if (el) {
        propertiesRefs.push(el);
      }
    }; */



    const embedContent = ref("");
    const imageName = ref("");
    const imageFile = ref("");
    const wrongImageNameFile = ref(false);
    const wrongEmbedContent = ref(false);
    const chainCheck = computed(() => getMindsetToShow.value.chain != "");
    const chainCheckbox = ref(null);
    const suitableTargeted = ref(false);
    const getAllMindsets = computed(() =>
      store.getters["core/getFirstMindsets"].flat()
    );
    const mgqChain = ref(null);
    const minDistance = ref(0);
    const minZoom = ref(0);
    const maxZoom = ref(0);
    const defaultZoom = ref(0);
    const properties = ref({});

    onBeforeMount(() => {
      getMindsetToShow.value = mixins.cloneObject(props.mindsetProp);
      /* if (getMindsetToShow.value.properties==undefined){
        getMindsetToShow.value.properties = null;
      }
      else {
        getMindsetToShow.value.properties = JSON.parse(getMindsetToShow.value.properties);
      } */
      properties.value = JSON.parse(getMindsetToShow.value.properties);
      let visProps = JSON.parse(getMindsetToShow.value.visibilityProps);
      minDistance.value = visProps[0];
      defaultZoom.value = visProps[1];
      minZoom.value = visProps[2];
      maxZoom.value = visProps[3];
      imageName.value = getMindsetToShow.value.image.name;
      imageFile.value = getMindsetToShow.value.image.content;
      embedContent.value = getMindsetToShow.value.embedContent;
      suitableTargeted.value = getMindsetToShow.value.targeted;
      if (getMindsetToShow.value.chain != "") {
        chains.value = JSON.parse(getMindsetToShow.value.chain);
      }
      sortQuestionListByInturns();
    });

    onMounted(() => document.addEventListener("focusin", focusChanged));
    onBeforeUpdate(async () => {
      mindchunkRefs = [];
      mgqChainRefs = [];
     // propertiesRefs = [];
    });
    const element = ref(null);
    const isSocial = computed(
      () => getMindsetToShow.value.gamecode.indexOf("#!soc!#") > -1
    );

    function focusChanged(event) {
      if (event.target.className.includes("ql-editor") || event.target.className.includes("textarea")) {
        element.value = event.target;
      }
      else {
        element.value = null;
      }
    }

    const selectedElement = ref(null);
    function setSelectedMindchunkRef(index) {
      selectedElement.value = mindchunkRefs[index];
    }

    /*
     function appendText(sentence) {

     
     will not work since we take values from components minchunk, reaction
     
     if (element.value) {
        element.value.value = element.value.value + sentence;
        element.value = null;
        if (selectedElement.value != null) {
          selectedElement.value.setQuestionname(
            selectedElement.value.mindchunkInner.questionname + sentence
          );
          selectedElement.value = null;
        }
      }

    } */

    function addMindchunkHere() {
      if (!isSocial.value) {
        if (!getMindsetToShow.value.questionlist) {
          makeQuestionlist();
        } else {
          addMindchunk();
        }
      }
    }

    function makeQuestionlist() {
      getMindsetToShow.value["questionlist"] = [
        { questionname: "", questionid: -1, inturn: 0 },
      ];
    }

    async function addMindchunk() {
      let smallestIdx = -1;
      let biggestInturn = 0;

      for (let qob of getMindsetToShow.value.questionlist) {
        if (qob.questionid <= smallestIdx) {
          smallestIdx = Number(qob.questionid) - 1;
        }
        if (qob.inturn >= biggestInturn) {
          biggestInturn = Number(qob.inturn) + 1;
        }
      }
      getMindsetToShow.value.questionlist.push({
        questionname: "",
        questionid: smallestIdx,
        questionnameid: -1,
        inturn: biggestInturn,
        answeredid: 0,
        comment: "",
        answerlist: [],
      });
    }

    async function removeMindchunk(questionId) {
      await gatherMindchunks();
      let mindchunk = getMindsetToShow.value.questionlist.filter(
        (x) => x.questionid == questionId
      )[0];
      let idx = getMindsetToShow.value.questionlist.indexOf(mindchunk);
      getMindsetToShow.value.questionlist.splice(idx, 1);
      normalizeInturns();
    }

    async function changeInturn(questionId, isUp) {
      await gatherMindchunks();
      let mindchunk = getMindsetToShow.value.questionlist.filter(
        (x) => x.questionid == questionId
      )[0];
      if (isUp) {
        if (mindchunk.inturn > 0) {
          for (let mch of getMindsetToShow.value.questionlist) {
            if (mch.inturn == mindchunk.inturn - 1) {
              let requestedInturn = mch.inturn;
              mch.inturn = mindchunk.inturn;
              mindchunk.inturn = requestedInturn;
              break;
            }
          }
        }
      }
      else {
        if (mindchunk.inturn < getMindsetToShow.value.questionlist.length) {
          for (let mch of getMindsetToShow.value.questionlist) {
            if (mch.inturn == mindchunk.inturn + 1) {
              let requestedInturn = mch.inturn;
              mch.inturn = mindchunk.inturn;
              mindchunk.inturn = requestedInturn;
              break;
            }
          }
        }
      }
      await gatherMindchunks();
      sortQuestionListByInturns();
    }

    function normalizeInturns() {
      sortQuestionListByInturns();
      for (let i = 0; i < getMindsetToShow.value.questionlist.length; i++) {
        getMindsetToShow.value.questionlist[i].inturn = i;
      }
    }

    async function sortQuestionListByInturns() {
      if (getMindsetToShow.value.questionlist) {
        if (getMindsetToShow.value.questionlist.length > 0) {
          getMindsetToShow.value.questionlist.sort(function (a, b) { return (a.inturn - b.inturn) })
        }
      }
    }


    async function removeMgqChain(mgqChain) {
      await gatherMgqChains();
      let chainsAlready = mixins.cloneArray(chains.value);
      chains.value = [];
      //  setTimeout(() => {
      await nextTick();
      for (let chain of chainsAlready) {
        if (!compareObjects.areSame(chain, mgqChain, [])) {
          chains.value.push(chain);
        }
      }
      // },10)

      getMindsetToShow.value.chain = JSON.stringify(chains.value);
    }



    async function gatherMindchunks() {
      let mchList = [];
      for (let mchk of mindchunkRefs) {
        let gatheredMchk = await mchk.provideMindchunk();
        mchList.push(gatheredMchk);
      }
      getMindsetToShow.value.questionlist = mchList;
    }

    async function gatherMgqChains() {
      let chainList = [];
      for (let chain of mgqChainRefs) {
        let gatheredChain = await chain.provideChain();
        chainList.push(gatheredChain);
      }
      if (chainList.length > 0) {
        getMindsetToShow.value.chain = JSON.stringify(chainList);
      }
      else {
        getMindsetToShow.value.chain = "";
      }
    }

    function checkVisibilityProps() {
      if (defaultZoom.value < minZoom.value) {
        mixins.alertConfirm("The default zoom level cannot be smaller than the min. zoom level!", false);
      }
      if (defaultZoom.value > maxZoom.value) {
        mixins.alertConfirm("The default zoom level cannot be bigger than the max. zoom level!", false);
      }
      if (minZoom.value > maxZoom.value) {
        mixins.alertConfirm("The min. zoom level cannot be bigger than the max. zoom level!", false);
      }
    }

    async function provideMindset() {
      getMindsetToShow.value.visibilityProps = JSON.stringify([minDistance.value, defaultZoom.value, minZoom.value, maxZoom.value]);
      let props = await propsComponent.value.provideProperties();
     // let props = await gatherProperties();
      getMindsetToShow.value.properties = JSON.stringify(props);
      gatherMgqChains();
      await gatherMindchunks();
      if (getMindsetToShow.value.chain != "") {
        let isCorrect = await mixins.checkIfCorrectAnswers(
          getMindsetToShow.value
        );
        if (!isCorrect) {
          getMindsetToShow.value.chain = "";
        }
      }
      return getMindsetToShow.value;
    }


    async function handleCheckIfCorrectAnswers() {
      properties.value = await propsComponent.value.provideProperties();
      await gatherMindchunks();
      if (!getMindsetToShow.value.targeted) {
        let isCorrect = false;
        if (properties.value.isImmediateGrading != undefined && properties.value.isImmediateGrading == "true") {
          isCorrect = true;
        }
        else {
          isCorrect = await mixins.checkIfCorrectAnswers(
            getMindsetToShow.value
          );
        }
        suitableTargeted.value = isCorrect;
        if (isCorrect) {
          getMindsetToShow.value.targeted = isCorrect;
        }
      } else {
        suitableTargeted.value = false;
        getMindsetToShow.value.targeted = false;
      }
    }

    function handleIsStartOnce() {
      let qsetIdStart = getMindsetToShow.value.id;
      for (let mindset of getAllMindsets.value) {
        if (mindset.id == qsetIdStart) {
          continue;
        } else {
          if (mindset.isStart) {
            mindset.isStart = false;
          }
        }
      }
    }

    function readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }

    async function toBase64(file) {
      let toReturn = "";
      try {
        let content = await readFileAsync(file);
        let newContent = content.split(";base64,")[1];
        toReturn = newContent;
      } catch (err) {
        console.log(err);
      }
      return toReturn;
    }

    async function setImageFile(event) {
      if (event.target.files[0]) {
        let justName = event.target.files[0].name;
        let supplement = "";
        if (getMindsetToShow.value.id < 0) {
          supplement = "tmp";
        }
        supplement;
        imageName.value =
          Date.now().toString() +
          Math.floor(Math.random() * 10000).toString() +
          "_" +
          justName.substring(0, justName.length > 700 ? 700 : justName.length);
        let file = event.target.files[0];
        let isValidated = false;
        isValidated = await mixins.validateSize(file, 1);
        if (isValidated) {
          imageFile.value = await toBase64(file);
          getMindsetToShow.value.image = {
            name: imageName.value,
            content: imageFile.value,
          };
        } else {
          imageName.value = "";
        }
      } else {
        imageName.value = "";
      }
    }

    function getEmbedSrc() {
      if (embedContent.value.indexOf("tiktok") > -1) {
        const regExp = RegExp('(data-video-id=")(\\d+)(")');
        let regexResult = regExp.exec(embedContent.value)[2];
        regexResult = "https://www.tiktok.com/embed/" + regexResult;
        embedContent.value = regexResult;
        // store.commit("core/setEmbedContent", regexResult);
        getMindsetToShow.value.embedContent = regexResult;
      } else if (
        embedContent.value.indexOf("<iframe") > -1 &&
        embedContent.value.indexOf("</iframe>") > -1
      ) {
        const regExp = RegExp('(?<=src=").*?(?=")');
        let regexResult = regExp.exec(embedContent.value)[0];
        regexResult = makeAutoplay(regexResult);
        embedContent.value = regexResult;
        //store.commit("core/setEmbedContent", regexResult);
        getMindsetToShow.value.embedContent = regexResult;

      } else {
        if (embedContent.value == "") {
          //store.commit("core/setEmbedContent", "");
          getMindsetToShow.value.embedContent = "";

        } else {
          wrongEmbedContent.value = true;
        }
      }
    }

    function makeAutoplay(urlSrc) {
      let autoplayPositive = "autoplay=1";
      let autoplayNegative = "autoplay=0";

      if (urlSrc.indexOf("soundcloud") > -1) {
        autoplayPositive = "auto_play=true";
        autoplayNegative = "auto_play=false";
      }

      if (
        urlSrc.indexOf(autoplayPositive) > -1 ||
        urlSrc.indexOf(autoplayNegative) > -1
      ) {
        if (urlSrc.indexOf(autoplayNegative) > -1) {
          let splitted = urlSrc.split(autoplayNegative);
          urlSrc =
            splitted[0] +
            autoplayPositive +
            (splitted.length > 1 ? splitted[1] : "");
        }
        return urlSrc;
      } else {
        if (urlSrc.indexOf("?") > -1) {
          urlSrc = urlSrc + "&" + autoplayPositive;
        } else {
          urlSrc = urlSrc + "?" + autoplayPositive;
        }
        return urlSrc;
      }
    }

    async function changeToChain() {
      if (chainCheckbox.value) {
        if (chainCheckbox.value.checked == true) {
          await gatherMindchunks();
          if (getMindsetToShow.value.targeted) {
            if (getMindsetToShow.value.chain != "") {
              getMindsetToShow.value.chain = "";
              chainCheckbox.value.checked = false;
            } else {
              getMindsetToShow.value.chain = "_";
              chainCheckbox.value.checked = true;
            }
          } else {
            let isCorrect = await mixins.checkIfCorrectAnswers(
              getMindsetToShow.value
            );
            chainCheckbox.value.checked = isCorrect;
            if (isCorrect) {
              getMindsetToShow.value.chain = "_";
              chainCheckbox.value.checked = isCorrect;
            }
          }
        } else {
          getMindsetToShow.value.chain = "";
        }
      }
    }

    function renewMindset(mindset) {
      getMindsetToShow.value = mixins.cloneObject(mindset);
      imageName.value = getMindsetToShow.value.image.name;
      imageFile.value = getMindsetToShow.value.image.content;
      embedContent.value = getMindsetToShow.value.embedContent;
    }

    function addChain() {
      chains.value.push({
        grade: 0,
        target: "|"
      });
    }

   /*  async function addProperty() {
      if (Object.keys(properties.value).length == 0) {
        properties.value[''] = '';
      }
      else {
        let props = await gatherProperties();
        properties.value = props;
        await nextTick();
        properties.value[''] = '';
      }
    }

    async function removeProperty(key) {
      let props = await gatherProperties();
      delete (props[key]);
      properties.value = {};
      await nextTick();
      for (let key in props) {
        let value = props[key];
        console.log("key " + key + ", value " + value);
        properties.value[key] = value;
      }
    }


    async function gatherProperties() {
      let props = {};
      for (let prpRef of propertiesRefs) {
        let gatheredPr = await prpRef.provideProperty();
        if (Object.keys(gatheredPr).length != 0) {
          props[gatheredPr.propertyKey] = gatheredPr.propertyValue;
        }
      }
      return props;
    } */




    return {
      showAttrib,
      getMindsetToShow,
      getSentences,
      element,
      focusChanged,
      selectedElement,
      //appendText,
      addMindchunkHere,
      setMindchunkRef,
      setSelectedMindchunkRef,
      //  setNameTitle,
      imageName,
      imageFile,
      setImageFile,
      wrongImageNameFile,
      embedContent,
      wrongEmbedContent,
      getEmbedSrc,
      changeToChain,
      chainCheck,
      chainCheckbox,
      isSocial,
      mixins,
      provideMindset,
      removeMindchunk,
      handleCheckIfCorrectAnswers,
      handleIsStartOnce,
      suitableTargeted,
      renewMindset,
      gatherMindchunks,
      mgqChain,
      chains,
      addChain,
      setMgqChainRef,
      removeMgqChain,
      changeInturn,
      minDistance,
      defaultZoom,
      minZoom,
      maxZoom,
      properties,
      checkVisibilityProps,
      propsComponent,
     // addProperty,
      //setPropertiesRef,
     // propertiesRefs,
     // removeProperty,
    };
  },
};
</script>
<style scoped>
.ms_attributes_wrapper {
  display: flex;
  height: 90%;
}

.attrib_mchk_selector {
  margin-bottom: .5em;
  display: flex;
  justify-items: center;
  justify-content: space-around;
}

.selector_selected {
  font-weight: bolder;
  color: rgb(16, 16, 129);
}


.mindset_things {
  width: 100%;
  height: 100%;
}

.checkboxes {
  display: flex;
  min-width: 50%;
  justify-content: space-evenly;
}

.mindset_attributes {
  overflow: auto;
  height: 100%;
  border-radius: 10px;
  background-color: rgb(245, 245, 245);
  padding: 1em;
}

.sentences {
  height: 100%;
  overflow: auto;
  scroll-behavior: auto;
}

.sentence {
  background-color: white;
  font-size: 0.8rem;
  border-radius: 5px;
  padding: 0.3rem;
}

.mindchunk_area {
  font-size: 0.9rem;
}



.input_and_result {
  justify-self: center;
  display: flex;
  align-items: center;
}

.input_and_result.visibility_props {
  justify-content: space-between;
  margin-bottom: .5em;
}

@media only screen and (max-height: 800px) {
  .questionlist {
    height: 150px;
  }

  .sentences {
    height: 450px;
  }
}
</style>