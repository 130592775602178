<template>
  <div>
    <div class="card">
      <div class="card-image"></div>
      <div class="card-content">
        <div class="media centerAligned">
          <div class="media-left">
            <figure class="image is-48x48"></figure>
            <div class="columns pt-4">
              <figure class="image is-48x48 pt-2">
                <i class="fas fa-mobile is-size-4"></i>
              </figure>
              <figure class="image is-48x48 pt-2">
                <i class="fas fa-walking is-size-4"></i>
              </figure>
            </div>
            <div class="columns">
              <i class="fas fa-clock"></i>
              <div class="is-size-7"></div>
            </div>
            <div class="columns">
              <i class="fas fa-stopwatch"></i>
              <div class="is-size-7"></div>
            </div>
          </div>
          <div class="media-content">
            <div class="pl-4">
              <p class="title is-4"></p>
              <p class="subtitle is-4"></p>
              <p class="shortDescr_p"></p>
            </div>
          </div>
          <div class="media-right editDeleteContainer">
            <div class="pl-4 editContainer">
              <i class="fas fa-edit is-size-4"></i>
            </div>
            <div class="pl-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>