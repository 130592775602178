<template>
        <PageHeader />

        <div class="p-6" style="height: calc(100% - 70px); overflow-y: auto;">
                <router-view></router-view>
        </div>
        <PageFoooter />

</template>
<script>
import PageFoooter from '@/components/parts/PageFooter.vue';
import PageHeader from '@/components/parts/PageHeader.vue';
export default {
        components: {
                PageFoooter,
                PageHeader
        },
}
</script>