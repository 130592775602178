<template>
  <div style="display: flex; align-items: center; column-gap: 1em;">
    <div class="field">
      <div class="control has-icons-left">
        <div class="select">
          <select v-model="mgqToChain" @change="setMgqToChain">
            <option :value="myMgq" v-for="myMgq in myMgqs" :key="myMgq.mindgluequestId">
              {{myMgq.title.length>50? myMgq.title.substring(0, 50)+"...":myMgq.title  }}, Id: {{ myMgq.mindgluequestId }}
            </option>
          </select>
        </div>
        <div v-if="mgq_selected" class="pt-2">
          <div class="select">
            <select v-model="chainedMindset" @change="setMindsetToChain">
              <option :value="mindset" v-for="mindset in mindsetsToChain" :key="mindset.id">
                {{mindset.title.length>50? mindset.title.substring(0, 50)+"...":mindset.title }}, Id:
                {{ mindset.id.toString() }}
              </option>
            </select>
          </div>
        </div>
        <div class="has-tooltip-right" :data-tooltip="withTooltips ? $t('tlt_chain_grade') : null
          ">
          <input type="number" min="0" max="100" step="1" v-model="chainInner.grade" style="width: 3.5em;" />
        </div>
      </div>
      
    </div>
    <div :class="{ button: !isSocial, '': !isSocial }" @click="removeMgqChain">
        <i class="fas fa-minus is-size-5"></i>
      </div>
  </div>
</template>
<script>
import { onBeforeMount, computed, ref, toRefs } from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";
export default {
  props: {
    chainProp: {
      type: Object,
      required: true,
    },
    isSocial: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const mgqToChain = ref(null);
    const chainInner = ref(null);
    const mgq_selected = ref(false);
    const isSocialInner = toRefs(props).isSocial;

    const getMindsetToShow = computed(() => store.getters["core/getMindsetToShow"]);
    const chainedMindset = ref(null);
    const mindsetsToChain = ref([]);
    const withTooltips = computed(() => store.getters['core/getUser'].showTooltips);

    const myMgqs = computed(() => store.getters["core/getMindgluequests"]);
    onBeforeMount(async () => {
      chainInner.value = mixins.cloneObject(props.chainProp);
      if (chainInner.value.target.indexOf("|") > -1) {
        let parts = chainInner.value.target.split("|");
        if (parts[1] != "") {
          mindsetsToChain.value = await store.dispatch(
            "core/requestMindsetsOfMgq",
            {
              qsetId: parts[1],
            }
          );
          chainedMindset.value = mindsetsToChain.value.filter(
            (x) => x.id == Number(parts[1])
          )[0];
          let mgqId = Number(parts[0]);
          mgqToChain.value = myMgqs.value.filter(
            (x) => x.mindgluequestId == mgqId
          )[0];
          mgq_selected.value = true;
        } else {
          mgqToChain.value = myMgqs.value.filter(
            (x) => x.mindgluequestId == Number(parts[0])
          )[0];
        }
      }
    });

    async function setMgqToChain() {
      await bringMindsetsOfMgq(mgqToChain.value.mindgluequestId.toString());
      chainInner.value.target = mgqToChain.value.mindgluequestId.toString() + ":";
      mgq_selected.value = true;
    }

    function setMindsetToChain() {
      chainInner.value.target = mgqToChain.value.mindgluequestId.toString() + "|" + chainedMindset.value.id.toString();
    }

    async function bringMindsetsOfMgq(mgqIdString) {
      let allMindsetsOfMgq = await store.dispatch("core/requestMindsetsOfMgq", {
        mindgluequestId: mgqIdString,
      });
      mindsetsToChain.value = allMindsetsOfMgq.filter(
        (mindset) => mindset.id != getMindsetToShow.value.id
      );
      return true;
    }

    function removeMgqChain() {
      if (!isSocialInner.value) {
        emit("removeMgqChain", chainInner.value);
      }
    }

    function provideChain() {
      return chainInner.value;
    }

    return {
      mgqToChain,
      myMgqs,
      setMgqToChain,
      setMindsetToChain,
      mgq_selected,
      mindsetsToChain,
      chainedMindset,
      provideChain,
      chainInner,
      withTooltips,
      removeMgqChain,
    };
  },
};
</script>