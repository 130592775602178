export function signalMarker(selected) {
   let color =selected?"a3ff6b":"00ff00";
   let signal = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
   <!-- Created with Inkscape (http://www.inkscape.org/) -->
   
   <svg
      width="8.2212019mm"
      height="8.2212019mm"
      viewBox="0 0 8.2212018 8.2212018"
      version="1.1"
      id="svg5"
      inkscape:version="1.1.1 (3bf5ae0d25, 2021-09-20)"
      sodipodi:docname="signalIcon.svg"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
     <sodipodi:namedview
        id="namedview7"
        pagecolor="#ffffff"
        bordercolor="#666666"
        borderopacity="1.0"
        inkscape:pageshadow="2"
        inkscape:pageopacity="0.0"
        inkscape:pagecheckerboard="0"
        inkscape:document-units="mm"
        showgrid="false"
        fit-margin-top="0"
        fit-margin-left="0"
        fit-margin-right="0"
        fit-margin-bottom="0"
        inkscape:zoom="2"
        inkscape:cx="-24.5"
        inkscape:cy="-84.75"
        inkscape:window-width="1360"
        inkscape:window-height="705"
        inkscape:window-x="-8"
        inkscape:window-y="-8"
        inkscape:window-maximized="1"
        inkscape:current-layer="layer1" />
     <defs
        id="defs2" />
     <g
        inkscape:label="Layer 1"
        inkscape:groupmode="layer"
        id="layer1"
        transform="translate(-104.41895,-92.230548)">
       <g
          id="g2239"
          transform="matrix(0.10185213,0,0,0.10185213,95.131075,88.064655)">
         <path
            id="path1746-6-3"
            style="display:inline;opacity:1;fill:#000000"
            inkscape:transform-center-x="2.6404095e-06"
            d="m 389.11719,210.43555 -4.47657,2.0039 -39.98632,104.83203 45.85156,102.4004 104.83203,39.98828 102.40039,-45.85157 1.52344,-3.99609 -97.92383,43.84766 -104.83203,-39.98828 -45.85156,-102.4004 z"
            transform="scale(0.26458333)" />
         <path
            id="path1746-7-3"
            style="opacity:1;fill:#e6e6e6"
            inkscape:transform-center-x="2.6404095e-06"
            d="m 499.04297,154.58789 -102.40039,45.85156 -1.52344,3.9961 97.92383,-43.84766 104.83203,39.98828 45.85156,102.40039 -38.46289,100.83594 4.47656,-2.00391 L 649.72656,296.97656 603.875,194.57617 Z"
            transform="scale(0.26458333)" />
         <path
            sodipodi:type="star"
            style="opacity:1;fill:#${color}"
            id="path1746-7-7"
            inkscape:flatsided="false"
            sodipodi:sides="4"
            sodipodi:cx="792.12286"
            sodipodi:cy="0"
            sodipodi:r1="146.5945"
            sodipodi:r2="146.5945"
            sodipodi:arg1="1.542502"
            sodipodi:arg2="2.3279002"
            inkscape:rounded="0"
            inkscape:randomized="0"
            transform="matrix(0.26458333,0,0,0.26458333,-78.034264,81.259814)"
            inkscape:transform-center-x="2.6404095e-06"
            d="M 796.2701,146.53582 691.43893,106.54901 645.58704,4.1472392 685.57385,-100.68394 787.97562,-146.53582 892.8068,-106.54901 938.65869,-4.1472392 898.67187,100.68394 Z" />
       </g>
     </g>
   </svg>   
`;
return signal;
}