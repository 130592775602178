<template>
    <div class="boxy" @click="generalClick">

        <div class="rowy headery">

            <div class="next_div">
                <div class="button  is-primary has-tooltip-right" @click="saveFromFirstMindsetsMap(true)"
                    :data-tooltip="withTooltips ? withTooltips ? $t('tlt_mgqmindsets_next') : null : null">
                    {{ $t('Next') }}
                </div>
                <div v-if="showBluetoothButtonOn" class="button" @click="getBluetooth"><i class="fas fa-bluetooth"
                        style="align-self:baseline;"></i>
                </div>
                <div @click="shouldDraw = !shouldDraw" class="has-tooltip-right"
                    style="cursor: pointer; display: flex;  align-items: center; padding-left:1em;"
                    :data-tooltip="withTooltips ? $t('tlt_toggle_drawing_content_tools') : null">
                    <i class="fas fas fa-map-marked" style="align-self:baseline;"
                        :class="{ 'icons_changing': !shouldDraw }"></i>
                    <i class="fas fas fa-brush" style="align-self:baseline;"
                        :class="{ 'icons_changing': shouldDraw }"></i>
                </div>

                <div v-if="shouldDraw" style="overflow-x: auto; overflow-y:hidden;width:100%; ">
                    <div style="display: flex; align-items: center; column-gap: 1em; padding:1em;">
                        <div class="button has-tooltip-right" @click="turnOnlyOneOn('map')"
                            :data-tooltip="withTooltips ? $t('tlt_drawingboard_add_map') : null">
                            <i class="fas fa-globe-africa" :style="'color:' + noMapBoardColor"></i>
                        </div>
                        <div class="button has-tooltip-right" @click="turnOnlyOneOn('image')"
                            :data-tooltip="withTooltips ? $t('tlt_drawingboard_add_image') : null">
                            <i class="fas fa-image" :style="'color:' + imageColor"></i>
                        </div>
                        <div class="button has-tooltip-right" @click="turnOnlyOneOn('signal')" :data-tooltip="withTooltips ? $t('tlt_drawingboard_add_signal') : null
        ">
                            <i class="fas fa-rss" :style="'color:' + signalColor"></i>
                        </div>
                        <div class="button has-tooltip-right" @click="turnOnlyOneOn('label')"
                            :data-tooltip="withTooltips ? $t('tlt_drawingboard_add_label') : null">
                            <i class="fas fa-font" :style="'color:' + labelColor"></i>
                        </div>
                        <div class="button has-tooltip-right" @click="turnOnlyOneOn('polyline')" :data-tooltip="withTooltips ? $t('tlt_drawingboard_add_polyline') : null
        ">
                            <i class="fas fa-project-diagram" :style="'color:' + polylineColor"></i>
                        </div>
                        <div class="button has-tooltip-right" @click="turnOnlyOneOn('polygon')" :data-tooltip="withTooltips ? $t('tlt_drawingboard_add_polygon') : null
        ">
                            <i class="fas fa-draw-polygon" :style="'color:' + polygonColor"></i>
                        </div>
                        <div class="button has-tooltip-right" @click="turnOnlyOneOn('rectangle')" :data-tooltip="withTooltips ? $t('tlt_drawingboard_add_rectangle') : null
        ">
                            <i class="fas fa-square-full" :style="'color:' + rectangleColor"></i>
                        </div>
                        <div class="button has-tooltip-right" @click="turnOnlyOneOn('circle')" :data-tooltip="withTooltips ? $t('tlt_drawingboard_add_circle') : null
        ">
                            <i class="fas fa-stroopwafel" :style="'color:' + circleColor"></i>
                        </div>

                        <div class="has-tooltip-right" :data-tooltip="withTooltips ? $t('tlt_drawingboard_set_outline_color') : null
        ">
                            <input type="color" id="head" name="head" v-model="borderColor" />
                        </div>
                        <div style="display: flex; align-items: center;">
                            <div class="has-tooltip-right" :data-tooltip="withTooltips ? $t('tlt_drawingboard_set_fill_color') : null
        ">
                                <input type="color" id="head" name="head" v-model="fillColor" />
                            </div>
                            <div class="has-tooltip-right" :data-tooltip="withTooltips ? $t('tlt_drawingboard_set_fill_opacity') : null
        ">
                                <input type="number" min="0.1" max="1" step="0.1" v-model="opacity"
                                    class="slider is-fullwidth" style="width: 3.5em;" />
                            </div>
                        </div>
                        <div class="button has-tooltip-right" @click="softDelete" :data-tooltip="withTooltips ? $t('tlt_drawingboard_erase_temporary') : null
        ">
                            <i class="fas fa-trash" style="color: red"></i>
                        </div>
                        <div class="button is-danger has-tooltip-left" @click="deleteSomething" :data-tooltip="withTooltips ? $t('tlt_drawingboard_erase_saved') : null
        ">
                            <i class="fas fa-trash" style="color: white"></i>
                        </div>
                    </div>
                </div>
                <div v-else class="buttons_wrapper">
                    <div class="button is-link has-tooltip-right" @click="createOneEmpty"
                        :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_add_one_mindset') : null">
                        <i class="fas fa-plus-circle"></i>
                    </div>
                    <div class="button is-link has-tooltip-right" @click="copySelectedMindset"
                        :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_clone_selected_mindset') : null">
                        <i class="fas fa-clone"></i>
                    </div>
                    <div class="button two_icons has-tooltip-right" :class="{ 'is-link': isMultipleClicked }"
                        @click="multipleClicked"
                        :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_add_multiple_mindsets') : null">
                        <i class="fas fa-plus-circle"></i>
                        <i class="fas fa-file-alt"></i>
                    </div>
                    <div id="region_button_id" class="button has-tooltip-right" @click="makePolyline"
                        :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_add_region') : null">
                        <i class="fas fa-draw-polygon" :style="'color:' + regionDrawingButtonColor"></i>
                    </div>
                    <div id="region_button_id" class="button has-tooltip-right" @click="selectMindsetsFromPolyline"
                        :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_select_mindsets_from_region') : null">
                        <i class="fas fa-broom"></i>
                    </div>
                    <div class="button has-tooltip-right" @click="moveToPolyline"
                        :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_move_to_region') : null">
                        <i class="fas fa-object-group"></i>
                    </div>
                    <div>
                        <div class="button has-tooltip-right" @click="handleCopy"
                            :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_forward_to_another') : null">
                            <i class="fas fa-copy"></i>
                            <i class="fas fa-arrow-right"></i>
                        </div>
                        <div v-if="copyOpen">
                            <MgqsMapRegionsSelector @copySelected="copySelected"
                                @setOpenedMgqsRegionsSelector="setOpenedMgqsRegionsSelector" />
                        </div>
                    </div>
                    <div class="button has-tooltip-right" @click="editSomething"
                        :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_edit_something') : null">
                        <i class="fas fa-edit"></i>
                    </div>
                    <div class="button is-danger has-tooltip-right" @click="deleteRegions"
                        :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_delete_region') : null">
                        <i class="fas fa-draw-polygon" style="color: white"></i>
                    </div>
                    <div class="button is-danger has-tooltip-left" @click="deleteMindsets"
                        :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_delete_mindsets') : null">
                        <i class="fas fa-map-marker" style="color: white"></i>
                    </div>
                    <MgqSearcher class="search" @click="setSearcherOpen" :isOpen="isSearcherOpen" />
                </div>
            </div>
            <i class="fas fa-angle-double-up" style="cursor: pointer" v-if="isMultipleClicked"
                @click="isMultipleClicked = !isMultipleClicked"></i>
            <div class="buttons_wrapper" v-if="isMultipleClicked">
                <div class="field">
                    <label class="label has-tooltip-right"
                        :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_source') : null">Source</label>
                    <div class="">
                        <div class="select mb-2" :class="{ 'is-danger': wrongTextFileName }">
                            <select v-model="sourceType" class="select" @change="eraseSource">
                                <option :value="'file'">File</option>
                                <option :value="'text'">Text</option>
                                <option :value="'ocr'">Image recognition</option>
                            </select>
                        </div>

                        <div v-if="sourceType != ''">
                            <div v-if="sourceType == 'ocr'">
                                <div class="control has-icons-left pb-2 has-tooltip-right"
                                    :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_image_selection') : null">
                                    <input class="input" v-model="selectedImageToOCRName" type="text" disabled="true"
                                        :class="{ 'is-danger': wrongTextFileName }" />
                                </div>
                                <div class="control has-icons-left">
                                    <label class="label has-tooltip-right"
                                        :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_with_index') : null">With
                                        Index</label>
                                    <input v-model="withIndex" type="checkbox" @change="makeWhitelistNumbers" />
                                </div>
                            </div>
                            <div v-if="sourceType == 'file' || (sourceType == 'ocr' && withIndex)
        ">
                                <label class="file-label">
                                    <input class="file-input" type="file" name="resume" :accept="withIndex
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        : 'text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        " @change="setTextFile" />
                                    <span class="file-cta">
                                        <span class="file-icon">
                                            <i class="fas fa-upload"></i>
                                        </span>
                                        <span class="file-label">{{
        withIndex ? "Spreadsheet of index" : "Text or spreadsheet"
    }}</span>
                                    </span>
                                    <span class="file-name">
                                        {{ textFileName }}
                                    </span>
                                </label>
                            </div>
                            <div v-else-if="sourceType == 'text'">
                                <div class="control has-icons-left">
                                    <textarea class="textarea" type="textarea" rows="4" cols="30"
                                        placeholder="Source text" v-model="sourceText" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="sourceType == 'ocr'">
                    <div>
                        <label class="label">Recognition Optimization</label>
                        <div>
                            <label class="label">Contains rotated text
                                <div class="field">
                                    <input v-model="rotatedText" type="checkbox" />
                                </div>
                            </label>
                        </div>
                        <div>
                            <label class="label has-tooltip-top"
                                :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_ocr_language') : null">Language
                                <div class="field">
                                    <div class="select mb-2">
                                        <select v-model="ocrLanguage" class="select">
                                            <option :value="'afr'">Afrikaans</option>
                                            <option :value="'ara'">Arabic</option>
                                            <option :value="'aze'">Azerbaijani</option>
                                            <option :value="'bel'">Belarusian</option>
                                            <option :value="'bos'">Bosnian</option>
                                            <option :value="'bul'">Bulgarian</option>
                                            <option :value="'ces'">Czech</option>
                                            <option :value="'chi_sim'">Chinese</option>
                                            <option :value="'dan'">Danish</option>
                                            <option :value="'deu'">German</option>
                                            <option :value="'ell'">Greek</option>
                                            <option :value="'eng'">English</option>
                                            <option :value="'equ'">Math</option>
                                            <option :value="'est'">Estonian</option>
                                            <option :value="'fas'">Persian</option>
                                            <option :value="'fin'">Finnish</option>
                                            <option :value="'fra'">French</option>
                                            <option :value="'gle'">Irish</option>
                                            <option :value="'heb'">Hebrew</option>
                                            <option :value="'hin'">Hindi</option>
                                            <option :value="'hrv'">Croatian</option>
                                            <option :value="'hun'">Hungarian</option>
                                            <option :value="'hye'">Armenian</option>
                                            <option :value="'ind'">Indonesian</option>
                                            <option :value="'isl'">Icelandic</option>
                                            <option :value="'ita'">Italian</option>
                                            <option :value="'jpn'">Japanese</option>
                                            <option :value="'kat'">Georgian</option>
                                            <option :value="'kor'">Korean</option>
                                            <option :value="'lav'">Latvian</option>
                                            <option :value="'lit'">Lithuanian</option>
                                            <option :value="'mlt'">Maltese</option>
                                            <option :value="'mon'">Mongolian</option>
                                            <option :value="'mya'">Burmese</option>
                                            <option :value="'nld'">Dutch; Flemish</option>
                                            <option :value="'nor'">Norwegian</option>
                                            <option :value="'pol'">Polish</option>
                                            <option :value="'por'">Portuguese</option>
                                            <option :value="'ron'">Romanian</option>
                                            <option :value="'rus'">Russian</option>
                                            <option :value="'slk'">Slovak</option>
                                            <option :value="'slv'">Slovenian</option>
                                            <option :value="'spa'">Spanish</option>
                                            <option :value="'sqi'">Albanian</option>
                                            <option :value="'srp_latn'">Serbian</option>
                                            <option :value="'swe'">Swedish</option>
                                            <option :value="'syr'">Syriac</option>
                                            <option :value="'tha'">Thai</option>
                                            <option :value="'tur'">Turkish</option>
                                            <option :value="'ukr'">Ukrainian</option>
                                            <option :value="'vie'">Vietnamese</option>
                                        </select>
                                    </div>
                                </div>
                            </label>
                        </div>

                        <div class="field">
                            <div class="select mb-2"
                                :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_exclude_chars') : null">
                                <select v-model="whiteBlackList" class="select" @change="whiteBlackListChange">
                                    <option :value="'whitelist'">
                                        Exclude all characters other than
                                    </option>
                                    <option :value="'blacklist'">Exclude these characters</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div class="field">
                                <input v-model="whiteBlackListText" type="text" class="input"
                                    placeholder="Enter characters, no separation necessary" />
                            </div>
                        </div>
                        <div class="mt-2">
                            <label class="label"
                                :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_ocr_confidence') : null">Recognition
                                confidence
                                <div class="field">
                                    <input v-model="ocrConfidence" type="number" />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div>
                        <label class="label has-tooltip-bottom"
                            :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_localize') : null">Localize</label>
                        <div class="select mb-2">
                            <select v-model="localizationType" class="select">
                                <option :value="'center'">randomly, from center</option>
                                <option :value="'region'">randomly, within region</option>
                                <option :value="'source'">in source</option>
                            </select>
                        </div>
                    </div>
                    <div class="field" v-if="localizationType == 'center'">
                        <label class="label">Center</label>
                        <MapButton ref="mapButtonMap" :outerCoords="outerCoords" />
                    </div>
                    <div class="radius_field" v-if="localizationType == 'center'">
                        <label class="label">Radius (m)</label>
                        <div class="control has-icons-left">
                            <input :class="{ 'is-danger': wrongRadius }" type="number" min="0" step="100"
                                v-model="radius" />
                        </div>
                    </div>
                </div>

                <div>
                    <label class="label">Advanced
                        <div class="field">
                            <input v-model="advanced" type="checkbox" />
                        </div>
                    </label>
                    <div class="field" v-if="advanced">
                        <label class="label" :class="{ 'is-danger': wrongDelimiters }"
                            :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_rows_delimiter') : null">Rows
                            delimiter</label>
                        <div class="control has-icons-left" :class="{ 'is-danger': wrongDelimiters }">
                            <input type="text" v-model="rowsDelim" />
                            regex
                            <input type="checkbox" v-model="rowsIsRegex" />
                        </div>
                        <label class="label"
                            :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_columns_delimiter') : null">Columns
                            delimiter</label>
                        <div class="control has-icons-left">
                            <input type="text" v-model="columnsDelim" :class="{ 'is-danger': wrongDelimiters }"
                                class="mr-2" />regex
                            <input type="checkbox" v-model="columnsIsRegex" />
                        </div>
                    </div>
                    <div class="field" v-if="advanced">
                        <label class="label">Mindset's name</label>
                        <div class="select mb-2">
                            <select v-model="mindsetNameType" class="select" @change="setMindsetName">
                                <option :value="'username'">My username</option>
                                <option :value="'random'">Randomize for each mindset</option>
                                <option :value="'source'">Automatic, from source</option>
                                <option :value="'set'">Set for all mindsets</option>
                            </select>
                        </div>
                        <div class="control has-icons-left" v-if="mindsetNameType == 'set'">
                            <input class="input" type="text" placeholder="Name" v-model="mindsetName" />
                            <span class="icon is-small is-left">
                                <i class="fas fa-user-circle"></i>
                            </span>
                        </div>
                    </div>

                    <div v-if="advanced">
                        <label class="label"
                            :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_number_mindsets') : null">Number of
                            mindsets
                            <div class="field">
                                <div class="select mb-2">
                                    <select v-model="withMindsetsNumber" class="select" id="select_id">
                                        <option :value="false">Automatic, from source</option>
                                        <option :value="true">Set</option>
                                    </select>
                                </div>
                            </div>
                        </label>
                        <div class="field" v-if="withMindsetsNumber">
                            <div class="control">
                                <div class="slider_wrapper">
                                    <div>1</div>
                                    <input type="range" min="1" max="200" v-model="numberOfMindsets"
                                        class="slider is-fullwidth" id="myRange" />
                                    <div>200</div>
                                </div>
                                <div>
                                    <input min="1" max="1000" type="number" :class="{ 'is-danger': tooBigNumber }"
                                        v-model="numberOfMindsets" @input="checkNumber" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field" v-if="advanced">
                        <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqmindsets_group_name') : null">Set
                            group
                            name</label>
                        <div class="control has-icons-left" :class="{ 'is-danger': wrongGroupName }">
                            <input class="input" type="text" placeholder="Group name" v-model="groupName" />
                            <span class="icon is-small is-left">
                                <i class="fas fa-lightbulb"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rowy contenty">
            <div style="height: 100%; position: relative">
                <div class="floating_list list_left zoom_div">{{ currentZoomLevel }}</div>
                <div class="floating_list list_left">
                    <MindsetGroupsList v-if="succeeded" />
                </div>
                <div class="floating_list list_right">
                    <MindsetsList v-if="succeeded" />
                </div>
                <div class="floating_search">
                    <AddressQueryWidget @setSearchedCoordinates="setSearchedCoordinates" />
                </div>
                <DrawMindsetsMap ref="dMindsetsMap" :isRegionDrawingOn="isRegionDrawingOn" :isLabelOn="isLabelOn"
                    :isPolylineOn="isPolylineOn" :isPolygonOn="isPolygonOn" :isRectangleOn="isRectangleOn"
                    :isCircleOn="isCircleOn" :isImageOn="isImageOn" :isNoMapBoardOn="isNoMapBoardOn"
                    :isSignalOn="isSignalOn" :borderColor="borderColor" :fillColor="fillColor" :opacity="opacity"
                    @invalidateImageOn="invalidateImageOn" @updateZoom="updateZoom" @deleteDrawing="deleteDrawing"
                    @noMapBoardOn="setNoMapBoardOn" />
            </div>
        </div>
    </div>
</template>
<script>
import { ref, computed, onBeforeMount, } from "vue";
import { useStore } from "vuex";
import DrawMindsetsMap from "@/components/parts/DrawMindsetsMap.vue";
import MindsetsList from "@/components/parts/MindsetsList.vue";
import MapButton from "@/components/parts/MapButton.vue";
import MgqsMapRegionsSelector from "@/components/parts/MgqsMapRegionsSelector.vue";
import MindsetGroupsList from "@/components/parts/MindsetGroupsList.vue";
import MgqSearcher from "@/components/parts/MgqSearcher.vue";
import { useRouter } from "vue-router";
import * as mixins from "@/utils/mixins";
import AddressQueryWidget from "@/components/parts/AddressQueryWidget.vue";
export default {
    components: {
        DrawMindsetsMap,
        MindsetsList,
        MindsetGroupsList,
        MapButton,
        MgqSearcher,
        MgqsMapRegionsSelector,
        AddressQueryWidget,
    },
    setup() {


        const showBluetoothButtonOn = ref(false);
        const router = useRouter();
        const store = useStore();
        const drawings = ref([]);
        const isLabelOn = ref(false);
        const isPolylineOn = ref(false);
        const isPolygonOn = ref(false);
        const isRectangleOn = ref(false);
        const isCircleOn = ref(false);
        const isImageOn = ref(false);
        const isNoMapBoardOn = ref(false);
        const isSignalOn = ref(false);
        const fillColor = ref("#6495ed");
        const borderColor = ref("#ed008c");
        const opacity = ref(1.0);
        const polylineColor = computed(() =>
            isPolylineOn.value ? "blue" : "black"
        );
        const labelColor = computed(() => (isLabelOn.value ? "blue" : "black"));
        const polygonColor = computed(() => (isPolygonOn.value ? "blue" : "black"));
        const rectangleColor = computed(() =>
            isRectangleOn.value ? "blue" : "black"
        );
        const circleColor = computed(() => (isCircleOn.value ? "blue" : "black"));
        const imageColor = computed(() => (isImageOn.value ? "blue" : "black"));
        const noMapBoardColor = computed(() => (isNoMapBoardOn.value ? "black" : "blue"));
        const signalColor = computed(() => (isSignalOn.value ? "blue" : "black"));
        const shouldDraw = ref(false);
        const radius = ref(500);
        const dMindsetsMap = ref(null);
        const wrongRadius = ref(false);
        const tooBigNumber = ref(false);
        const numberOfMindsets = ref(100);
        const groupName = ref("");
        const wrongGroupName = ref(false);
        const wrongDelimiters = ref(false);
        const textFileName = ref("");
        const wrongTextFileName = ref(false);
        const succeeded = ref(false);
        const hereIsEdit = ref(mixins.isEdit);
        const isRegionDrawingOn = ref(false);
        const columnsDelim = ref("");
        const rowsDelim = ref("");
        const rowsIsRegex = ref(false);
        const columnsIsRegex = ref(false);
        const regionDrawingButtonColor = computed(() =>
            isRegionDrawingOn.value ? "blue" : "black"
        );
        const getAllMindsets = computed(
            () => store.getters["core/getFirstMindsets"]
        );
        const getSelectedMgq = computed(
            () => store.getters["core/getterSelectedMgq"]
        );
        const getUser = computed(() => store.getters["core/getUser"]);
        const forContest = computed(() => getSelectedMgq.value.forContest);
        const getCreatedMgq = computed(
            () => store.getters["core/getterCreatedMgq"]
        );
        const getTemporaryContent = computed(
            () => store.getters["core/getTemporaryContent"]
        );
        const selectedImageToOCRName = computed(() => {
            if (mixins.getIsEmpty(getTemporaryContent.value)) {
                return getTemporaryContent.value.imageName;
            } else {
                return "";
            }
        });
        const withIndex = ref(false);
        const rotatedText = ref(false);
        const localizationType = ref("center");
        const outerCoords = ref([]);
        const isRealMap = computed(() => mixins.noMapBoard != null);
        const mapButtonMap = ref();
        const copyOpen = ref(false);
        const isMultipleClicked = ref(false);
        const sourceType = ref("");
        const sourceText = ref("");
        const withMindsetsNumber = ref(false);
        const advanced = ref(false);
        const mindsetNameType = ref("username");
        const mindsetName = ref("");
        const ocrConfidence = ref(0);
        const currentZoomLevel = ref(0);
        const getCurrentLatitude = computed(() => {
            if (undefined != mapButtonMap.value) {
                if (null != mapButtonMap.value) {
                    if (undefined != mapButtonMap.value["attrCoordinates"]) {
                        return mapButtonMap.value["attrCoordinates"][0];
                    } else
                        return getSelectedMgq.value.latitude == undefined
                            ? getCreatedMgq.value.latitude
                            : getSelectedMgq.value.latitude;
                } else
                    return getSelectedMgq.value.latitude == undefined
                        ? getCreatedMgq.value.latitude
                        : getSelectedMgq.value.latitude;
            } else
                return getSelectedMgq.value.latitude == undefined
                    ? getCreatedMgq.value.latitude
                    : getSelectedMgq.value.latitude;
        });
        const getCurrentLongitude = computed(() => {
            if (undefined != mapButtonMap.value) {
                if (null != mapButtonMap.value) {
                    if (undefined != mapButtonMap.value["attrCoordinates"]) {
                        return mapButtonMap.value["attrCoordinates"][1];
                    } else
                        return getSelectedMgq.value.longitude == undefined
                            ? getCreatedMgq.value.longitude
                            : getSelectedMgq.value.longitude;
                } else
                    return getSelectedMgq.value.longitude == undefined
                        ? getCreatedMgq.value.longitude
                        : getSelectedMgq.value.longitude;
            } else
                return getSelectedMgq.value.longitude == undefined
                    ? getCreatedMgq.value.longitude
                    : getSelectedMgq.value.longitude;
        });
        const ocrLanguage = ref("eng");
        const whiteBlackList = ref("whitelist");
        const whiteBlackListText = ref("");
        const withTooltips = computed(() => store.getters['core/getUser'].showTooltips);
        let textFile = null;
        const copyOpenedTimes = ref(0);
        const isSearcherOpen = ref(true);
        const mindsetSearchOpenedTimes = ref(0);




        /**
         * DrawingBoard functions
         */
        function turnOnlyOneOn(value) {
            if (value == "label") {
                isLabelOn.value = !isLabelOn.value;
                isPolylineOn.value = false;
                isPolygonOn.value = false;
                isRectangleOn.value = false;
                isCircleOn.value = false;
                isImageOn.value = false;
                isSignalOn.value = false;
            } else if (value == "polyline") {
                isLabelOn.value = false;
                isPolylineOn.value = !isPolylineOn.value;
                isPolygonOn.value = false;
                isRectangleOn.value = false;
                isCircleOn.value = false;
                isImageOn.value = false;
                isSignalOn.value = false;
            } else if (value == "polygon") {
                isLabelOn.value = false;
                isPolylineOn.value = false;
                isPolygonOn.value = !isPolygonOn.value;
                isRectangleOn.value = false;
                isCircleOn.value = false;
                isImageOn.value = false;
                isSignalOn.value = false;
            } else if (value == "rectangle") {
                isLabelOn.value = false;
                isPolylineOn.value = false;
                isPolygonOn.value = false;
                isRectangleOn.value = !isRectangleOn.value;
                isCircleOn.value = false;
                isImageOn.value = false;
                isSignalOn.value = false;
            } else if (value == "circle") {
                isLabelOn.value = false;
                isPolylineOn.value = false;
                isPolygonOn.value = false;
                isRectangleOn.value = false;
                isCircleOn.value = !isCircleOn.value;
                isImageOn.value = false;
                isSignalOn.value = false;
            } else if (value == "image") {
                isLabelOn.value = false;
                isPolylineOn.value = false;
                isPolygonOn.value = false;
                isRectangleOn.value = false;
                isCircleOn.value = false;
                isImageOn.value = !isImageOn.value;
                isSignalOn.value = false;
            } else if (value == "signal") {
                isLabelOn.value = false;
                isPolylineOn.value = false;
                isPolygonOn.value = false;
                isRectangleOn.value = false;
                isCircleOn.value = false;
                isImageOn.value = false;
                isSignalOn.value = !isSignalOn.value;
            }
            else if (value == "map") {
                isNoMapBoardOn.value = !isNoMapBoardOn.value;

            }
        }
        async function deleteSomething() {
            if (
                dMindsetsMap.value.selectedDrawing != undefined &&
                Object.keys(dMindsetsMap.value.selectedDrawing).length > 0
            ) {
                await deleteDrawing();
            } else {
                if (
                    dMindsetsMap.value.labels.length > 0 ||
                    dMindsetsMap.value.polylines.length > 0 ||
                    dMindsetsMap.value.polygons.length > 0 ||
                    dMindsetsMap.value.rectangles.length > 0 ||
                    dMindsetsMap.value.circles.length > 0 ||
                    dMindsetsMap.value.images.length > 0 ||
                    dMindsetsMap.value.signals.length > 0
                ) {
                    await deleteAllDrawings();
                }
            }
        }

        async function editSomething() {
            if (
                dMindsetsMap.value.selectedDrawing != undefined &&
                Object.keys(dMindsetsMap.value.selectedDrawing).length > 0
            ) {
                let drawingProps = mixins.cloneObject(dMindsetsMap.value.selectedDrawing.properties);
                let imgName = drawingProps.name;
                let newDrawingName = await mixins.makeModalDynamic("DynamicOneTextfield", { title: "", isPassword: false, initialContent: imgName });

                if (newDrawingName != null) {
                    if (newDrawingName.content != imgName) {
                        drawingProps.name = newDrawingName.content;
                        let successEdit = await store.dispatch("core/editMgqDrawing", { mgqDrawingId: dMindsetsMap.value.selectedDrawing.id, properties: JSON.stringify(drawingProps) });
                        if (successEdit) {
                            await mixins.makeToast("Saved!", 1000);
                        }
                        else {
                            await mixins.makeToast("Something went wrong...", 1000);
                        }
                        await mixins.fetchMgqDrawings(getSelectedMgq.value);
                    }
                }
            }
            else {
                await mixins.makeToast("Nothing selected to edit", 2000);
            }
        }


        async function deleteDrawing() {
            let reallyToDelete = await mixins.alertConfirm(
                "Are you sure you want to delete the selected drawing?",
                true
            );
            if (reallyToDelete) {
                let success = await store.dispatch(
                    "core/deleteMgqDrawing",
                    dMindsetsMap.value.selectedDrawing.id
                );
                if (success) {
                    dMindsetsMap.value.selectedDrawing = {};
                    await mixins.alertConfirm("Drawing deleted", false);
                    mixins.removeAllLayersFromMap(
                        dMindsetsMap.value.drawMap,
                        dMindsetsMap.value.myTileLayer
                        //   dMindsetsMap.value.myWmsLayer
                    );
                    await mixins.fetchMgqDrawings(getSelectedMgq.value);
                } else {
                    await mixins.alertConfirm("Something went wrong", false);
                }
            }
        }

        function setNoMapBoardOn() {
            isNoMapBoardOn.value = true;
        }

        async function softDelete() {
            mixins.removeAllLayersFromMap(
                dMindsetsMap.value.drawMap,
                dMindsetsMap.value.myTileLayer
                //  dMindsetsMap.value.myWmsLayer
            );
            dMindsetsMap.value.renewDrawings();
            await mixins.fetchMgqDrawings(getSelectedMgq.value);
        }

        async function deleteAllDrawings() {
            let reallyToDelete = await mixins.alertConfirm(
                "Be carefull, this way you are going to delete ALL drawings of the mindgluequest! Are you sure you want to delete them ALL?",
                true
            );
            if (reallyToDelete) {
                let success = await store.dispatch("core/deleteMgqDrawing", -1);
                if (success) {
                    dMindsetsMap.value.selectedDrawing = {};
                    await mixins.alertConfirm("All drawings deleted", false);
                    mixins.removeAllLayersFromMap(
                        dMindsetsMap.value.drawMap,
                        dMindsetsMap.value.myTileLayer
                        //     dMindsetsMap.value.myWmsLayer
                    );
                } else {
                    await mixins.alertConfirm("Something went wrong", false);
                }
                mixins.removeAllLayersFromMap(
                    dMindsetsMap.value.drawMap,
                    dMindsetsMap.value.myTileLayer
                    //  dMindsetsMap.value.myWmsLayer
                );
                dMindsetsMap.value.renewDrawings();
            }
            await mixins.fetchMgqDrawings(getSelectedMgq.value);
        }

        function invalidateImageOn() {
            isImageOn.value = !isImageOn.value;
            dMindsetsMap.value.renewDrawings();
        }
        /**
         * End of DrawingBoard Functions
         * 
         */




        function whiteBlackListChange() {
            whiteBlackListText.value = "";
        }

        async function multipleClicked() {
            if (isMultipleClicked.value) {
                await getdata();
                isMultipleClicked.value = !isMultipleClicked.value;
            } else {
                isMultipleClicked.value = !isMultipleClicked.value;
            }
        }

        function checkNumber() {
            if (numberOfMindsets.value > 200) numberOfMindsets.value = 200;
        }

        function handleCopy() {
            copyOpen.value = !copyOpen.value;
        }

        function setTextFile(event) {
            if (event.target.files[0]) {
                textFileName.value = event.target.files[0].name;
                textFile = event.target.files[0];
            } else textFileName.value = "";
        }

        function eraseSource() {
            textFileName.value = "";
            textFile = null;
            sourceText.value = "";
            withIndex.value = false;
            wrongTextFileName.value = false;
            store.commit("core/setTemporaryContent", {});
        }

        function setMindsetName() {
            if (mindsetNameType.value == "username") {
                mindsetName.value = getUser.value.username;
            } else {
                mindsetName.value = "";
            }
        }

        function setSearchedCoordinates(payload) {
            dMindsetsMap.value.setSearchedCoordinates(payload);
        }

        async function validateFields() {
            let desiredSumOfMindsets = Number(
                Number(numberOfMindsets.value) +
                Number(getAllMindsets.value.flat().length)
            );
            if (desiredSumOfMindsets > 1000) {
                tooBigNumber.value = true;
                await mixins.alertConfirm(
                    desiredSumOfMindsets.toString() +
                    " desired Mindsets. Unfortunately a mindgluequest cannot contain more than 1000 mindsets all in all.",
                    false
                );
            } else tooBigNumber.value = false;
            if (Number(radius.value) < 100) wrongRadius.value = true;
            else wrongRadius.value = false;
            if (sourceType.value == "text") {
                if (sourceText.value == "") {
                    wrongTextFileName.value = true;
                } else {
                    wrongTextFileName.value = false;
                }
            } else if (sourceType.value == "file") {
                if (textFileName.value == "") wrongTextFileName.value = true;
                else {
                    wrongTextFileName.value = false;
                }
            } else if (sourceType.value == "ocr") {
                if (selectedImageToOCRName.value == "") {
                    wrongTextFileName.value = true;
                } else {
                    wrongTextFileName.value = false;
                }
            } else if (sourceType.value == "") {
                wrongTextFileName.value = true;
            }

            if (
                groupName.value.length > 6 ||
                mixins.checkStringInputForSpecial(groupName.value) ||
                mixins.checkAlreadyExistingGroupName(
                    groupName.value,
                    getAllMindsets.value
                ) ||
                groupName.value.includes(" ")
            )
                wrongGroupName.value = true;
            else wrongGroupName.value = false;
            if (wrongGroupName.value)
                await mixins.alertConfirm(
                    "The set name must not contain special characters or spaces, be longer than 6 characters or be already used.",
                    false
                );
            if (sourceType.value == "file") {
                if (localizationType.value == "source") {
                    if (null != textFile) {
                        if (textFile.type.indexOf("text/plain") > 0) {
                            if (rowsDelim.value == "" || columnsDelim.value == "") {
                                wrongDelimiters.value = true;
                            }
                        }
                    }
                }
            }

            if (
                (rowsDelim.value != "" && columnsDelim.value == "") ||
                (columnsDelim.value != "" && rowsDelim.value == "")
            ) {
                wrongDelimiters.value = true;
            } else {
                wrongDelimiters.value = false;
            }
            if (rowsDelim.value != "" || columnsDelim.value != "") {
                if (rowsDelim.value == columnsDelim.value) {
                    wrongDelimiters.value = true;
                } else {
                    wrongDelimiters.value = false;
                }
            }

            if (wrongDelimiters.value) {
                await mixins.alertConfirm(
                    "Both delimeter for rows (different entities, that translates to different mindsets) and for columns (properties for each mindset, like title, latitude, longitude etc.) should be set.",
                    false
                );
            }

            return (
                !wrongRadius.value &&
                !wrongTextFileName.value &&
                !wrongGroupName.value &&
                !wrongDelimiters.value &&
                !tooBigNumber.value
            );
        }
        const signals = ref(mixins.signals);

        onBeforeMount(async () => {

            await mixins.fetchMgqDrawings(getSelectedMgq.value);
            if (signals.value.length > 0) {
                showBluetoothButtonOn.value = true;
            }
            if (!forContest.value) {
                numberOfMindsets.value = 0;
            }
            if (hereIsEdit.value) {
                await retrieveMadeMindsets();
            }
            outerCoords.value = [
                getSelectedMgq.value.latitude,
                getSelectedMgq.value.longitude,
            ];

            mindsetName.value = getUser.value.username;
        });




        /*  onBeforeUnmount(()=>{
          if (dMindsetsMap.value.innerDrawMindsetsMap.leafletObject && dMindsetsMap.value.innerDrawMindsetsMap.value.leafletObject.remove) {
            dMindsetsMap.value.innerDrawMindsetsMap.value.leafletObject.off();
            dMindsetsMap.value.innerDrawMindsetsMap.value.leafletObject.remove();
          }
        }); */

        async function getBluetooth() {
            let nav = navigator;
            let blAvail = await nav.bluetooth.getAvailability();
            if (blAvail) {
                let blDevices= await navigator.bluetooth.getDevices();
               if (blDevices.length>0){
                alert("bl length "+blDevices.length);

               }
               else {
                alert("bl length 0");
               }
            }
            else {
                console.log("bl Unavailable");
                alert("bl Unavailable");
            }
        }

        function makeWhitelistNumbers() {
            if (withIndex.value) {
                whiteBlackList.value = "whitelist";
            } else {
                whiteBlackListText.value = "";
            }
        }

        async function copySelected(mgqOrWithMapRegion) {
            copyOpen.value = !copyOpen.value;
            if (searchIfAnyMindsetSelected()) {
                let mgqMapRegion = mgqOrWithMapRegion.mgqMapRegion;
                if (!mgqMapRegion) {
                    mgqOrWithMapRegion.mgqMapRegion = { id: 0 };
                }
                let copied = await store.dispatch(
                    "core/copyMindsets",
                    mgqOrWithMapRegion
                );
                if (copied) {
                    await mixins.alertConfirm(
                        "The selected mindsets were successfully copied.",
                        false
                    );

                    return true;
                } else {
                    return false;
                }
            } else {
                await mixins.alertConfirm("Nothing selected to forward", false);
            }
        }

        async function retrieveMadeMindsets() {
            if (undefined != getSelectedMgq.value.mindgluequestId) {
                succeeded.value = await store.dispatch("core/requestMadeMindsets", {
                    mindgluequestId: getSelectedMgq.value.mindgluequestId.toString(),
                });
                if (succeeded.value) setMarkedFromApp();
            }
        }

        function setMarkedFromApp() {
            store.commit("core/setMarkedFromApp");
        }

        function makePolyline() {
            isRegionDrawingOn.value = !isRegionDrawingOn.value;
        }

        function selectMindsetsFromPolyline() {
            if (dMindsetsMap.value.selectedRegion == undefined ||
                Object.keys(dMindsetsMap.value.selectedRegion).length == 0) {
                return;
            }
            else {
                let top = 0;
                let bottom = 0;
                let left = 0;
                let right = 0;
                if (dMindsetsMap.value.selectedRegion.latlngs.length > 0) {
                    top = dMindsetsMap.value.selectedRegion.latlngs[0][0];
                    bottom = dMindsetsMap.value.selectedRegion.latlngs[0][0];
                    left = dMindsetsMap.value.selectedRegion.latlngs[0][1];
                    right = dMindsetsMap.value.selectedRegion.latlngs[0][1];
                    for (let coord of dMindsetsMap.value.selectedRegion.latlngs) {
                        if (coord[0] > top) {
                            top = coord[0];
                        }
                        if (coord[0] < bottom) {
                            bottom = coord[0];
                        }
                        if (coord[1] > right) {
                            right = coord[1];
                        }
                        if (coord[1] < left) {
                            left = coord[1];
                        }
                    }

                    if (undefined != getAllMindsets.value) {
                        for (let mindsetsArray of getAllMindsets.value) {
                            if (mindsetsArray.length > 0) {
                                for (let mindset of mindsetsArray) {
                                    if (Number(mindset.longitude) < right && Number(mindset.longitude) > left && Number(mindset.latitude) < top && Number(mindset.latitude) > bottom) {
                                        mindset.isSelected = true;
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }


        async function createOneEmpty() {
            let firstElementsForEmpty = createFirstElementsForEmptyMindset();
            let numOfMindsets = firstElementsForEmpty.numOfMindsets;
            let gamecode = firstElementsForEmpty.gamecode;

            let qsetId = Number(-numOfMindsets);
            let bounds = dMindsetsMap.value.innerDrawMindsetsMap.leafletObject.getBounds();
            let currentCenter = bounds.getCenter();
            let emptyQSet = mixins.makeEmptyMindet(
                qsetId,
                currentCenter.lat,
                currentCenter.lng,
                gamecode
            );
            if (getAllMindsets.value.flat().length == 1 && getAllMindsets.value.flat()[0].id == 0) {
                emptyQSet.isStart = true;
            }

            store.commit("core/pushOneFirstMindset", emptyQSet);
            if (getAllMindsets.value.flat().length == 1 && getAllMindsets.value.flat()[0].id == 0) {
                await saveFromFirstMindsetsMap(false);
            }
        }


        function createFirstElementsForEmptyMindset() {
            let numOfMindsets = 0;
            let currentEnding = 0;
            let selectedGamecode = "";
            let biggestNumberGamecodeEnding = Number(0);
            if (undefined != getAllMindsets.value) {
                for (let mindsetsArray of getAllMindsets.value) {
                    numOfMindsets = numOfMindsets + mindsetsArray.length;
                    if (mindsetsArray.length > 0) {
                        currentEnding = Number(mindsetsArray[0].gamecode.split("_")[1]);
                    }
                    if (mindsetsArray.length > 0) {
                        if (mindsetsArray[0].isSelected) {
                            selectedGamecode = mindsetsArray[0].gamecode;
                        }
                    }
                    if (currentEnding) {
                        if (typeof currentEnding == "number") {
                            if (currentEnding > biggestNumberGamecodeEnding) {
                                biggestNumberGamecodeEnding = currentEnding;
                            }
                        }
                    }
                }
                biggestNumberGamecodeEnding = biggestNumberGamecodeEnding + 1;
            }
            let mgqId =
                getSelectedMgq.value.mindgluequestId == undefined
                    ? getCreatedMgq.value.mindgluequestId.toString()
                    : getSelectedMgq.value.mindgluequestId.toString();
            let gamecode = mgqId + "_" + biggestNumberGamecodeEnding.toString();
            if (selectedGamecode != "") {
                gamecode = selectedGamecode;
            }
            let toReturn = { gamecode: gamecode, numOfMindsets: numOfMindsets };
            return toReturn;
        }


        function findIfOnlyOneMindsetSelected() {
            let toReturn = null;
            let isSelectedCount = 0;
            for (let mindsetArray of getAllMindsets.value) {
                for (let mindset of mindsetArray) {
                    if (mindset.isSelected) {
                        toReturn = mindset;
                        isSelectedCount += 1;
                    }
                }
            }
            if (isSelectedCount > 1) {
                toReturn = null;
            }
            return toReturn;
        }

        async function copySelectedMindset() {
            let mindsetSelected = findIfOnlyOneMindsetSelected();
            if (mindsetSelected != null) {
                let firstElementsForEmpty = createFirstElementsForEmptyMindset();
                let numOfMindsets = firstElementsForEmpty.numOfMindsets;
                let copiedMindset = mixins.cloneObject(mindsetSelected);
                copiedMindset.id = -numOfMindsets;
                delete copiedMindset.isSelected;
                delete copiedMindset.isStart;
                store.commit("core/pushOneFirstMindset", copiedMindset);
            }
            else {
                await mixins.alertConfirm("One and only one mindset should be selected to be cloned!", false);
            }
        }

        async function getdata() {
            let validated = await validateFields();
            if (validated) {
                /*  if (
                  
                  makeMultipleFromRegion.value
                ) {
                  await makeMindsetsFromRegion();
                } else {
                  await makeMindsetsFromCenter();
                } */
                let succeeded = await makeMindsets();
                if (succeeded) {
                    //   renewValuesAfterGetData();
                    eraseSource();


                }
            }
            return validated;
        }


        async function makeMindsets() {
            let goOn = true;
            if (localizationType.value == "region") {
                if (
                    dMindsetsMap.value.selectedRegion == undefined ||
                    Object.keys(dMindsetsMap.value.selectedRegion).length == 0
                ) {
                    goOn = false;
                }
            }
            if (goOn) {
                let payload = {
                    textFile: textFile,
                    properties: {
                        radius: radius.value,
                        groupName: groupName.value,
                        numberOfMindsets: Number(numberOfMindsets.value),
                        mgqMapRegion: dMindsetsMap.value.selectedRegion.latlngs,
                        localizationType: localizationType.value,
                        latitude: getCurrentLatitude.value,
                        longitude: getCurrentLongitude.value,
                        sourceType: sourceType.value,
                        sourceText: sourceText.value,
                        rowsDelim: rowsDelim.value,
                        columnsDelim: columnsDelim.value,
                        withMindsetsNumber: withMindsetsNumber.value,
                        mindsetName: mindsetName.value,
                        mindsetNameType: mindsetNameType.value,
                        rowsIsRegex: rowsIsRegex.value,
                        columnsIsRegex: columnsIsRegex.value,
                        rotatedText: rotatedText.value,
                        ocrImage: getTemporaryContent.value,
                        ocrLanguage: ocrLanguage.value,
                        ocrConfidence: ocrConfidence.value,
                        whitelist:
                            whiteBlackList.value == "whitelist"
                                ? whiteBlackListText.value
                                : "",
                        blacklist:
                            whiteBlackList.value == "blacklist"
                                ? whiteBlackListText.value
                                : "",
                        withIndex: withIndex.value,
                    },
                };
                succeeded.value = await store.dispatch(
                    "core/requestFirstMindsets",
                    payload
                );
                return succeeded;
            } else {
                await mixins.alertConfirm("You haven't selected a region!");
                await mixins.divColorEmphasis("region_button_id", "red");
                await mixins.divColorEmphasis("select_id", "red");
            }
        }

        /* async function makeMindsetsFromRegion() {
          if (
            dMindsetsMap.value.selectedRegion != undefined &&
            Object.keys(dMindsetsMap.value.selectedRegion).length > 0
          ) {
            let payload = {
              textFile: textFile,
              radius: radius.value,
              setName: setName.value,
              mindsetsNumber: num.value,
              mgqMapRegion: dMindsetsMap.value.selectedRegion.latlngs,
              latitude: getCurrentLatitude.value,
              longitude: getCurrentLongitude.value,
            };
            succeeded.value = await store.dispatch(
              "core/requestFirstMindsets",
              payload
            );
            if (succeeded.value) {
              setName.value = "";
            }
          } else {
            await mixins.alertConfirm("You haven't selected a region!");
            await mixins.divColorEmphasis("region_button_id", "red");
            await mixins.divColorEmphasis("select_id", "red");
          }
        }
    
        async function makeMindsetsFromCenter() {
          let payload = {
            radius: radius.value,
            textFile: textFile,
            mindsetsNumber: num.value,
            setName: setName.value,
            latitude: getCurrentLatitude.value,
            longitude: getCurrentLongitude.value,
          };
          succeeded.value = await store.dispatch(
            "core/requestFirstMindsets",
            payload
          );
          if (succeeded.value) {
            setName.value = "";
          }
        } */

        /*  async function deleteSomething() {
          if (
            dMindsetsMap.value.selectedRegion != undefined &&
            Object.keys(dMindsetsMap.value.selectedRegion).length > 0
          ) {
            await deleteMgqMapRegion();
          } else {
            await deleteMindsets();
          }
        } */

        async function deleteRegions() {
            let reallyToDelete = false;
            let reallyToDeleteAll = false;
            let regionsLength = dMindsetsMap.value.regions.length;

            if (
                dMindsetsMap.value.selectedRegion != undefined &&
                Object.keys(dMindsetsMap.value.selectedRegion).length > 0
            ) {
                reallyToDelete = await mixins.alertConfirm(
                    "Are you sure you want to delete the selected region?",
                    true
                );
            } else {
                if (regionsLength > 0) {
                    reallyToDeleteAll = await mixins.alertConfirm(
                        "No region selected. Are you sure you want to delete ALL regions?",
                        true
                    );
                } else {
                    mixins.alertConfirm("Nothing to delete", false);
                }
            }
            let success = false;
            if (reallyToDelete) {
                success = await store.dispatch("core/deleteMgqMapRegion", {
                    mgqMapRegionId: dMindsetsMap.value.selectedRegion.id,
                });
            } else if (reallyToDeleteAll && regionsLength > 0) {
                success = await store.dispatch("core/deleteMgqMapRegion", {
                    mgqId: getSelectedMgq.value.mindgluequestId,
                });
            }
            if (success && reallyToDelete) {
                let regionIdx = dMindsetsMap.value.regions.indexOf(
                    dMindsetsMap.value.selectedRegion
                );
                dMindsetsMap.value.regions.splice(regionIdx, 1);
                dMindsetsMap.value.selectedRegion = {};
                removeLayerBeforeLast(2);
                await mixins.alertConfirm("Region deleted", false);

                //await dMindsetsMap.value.fetchMapRegions();
            } else if (success && reallyToDeleteAll) {
                dMindsetsMap.value.regions = [];
                await mixins.alertConfirm("Regions deleted", false);
                //await dMindsetsMap.value.fetchMapRegions();
            } else {
                await mixins.alertConfirm("Something went wrong", false);
            }
        }

        function removeLayerBeforeLast(beforeTimes) {
            let entries = Object.entries(
                dMindsetsMap.value.innerDrawMindsetsMap.leafletObject._layers
            );
            if (undefined != entries[entries.length - beforeTimes]) {
                let keyToDelete = entries[entries.length - beforeTimes][0];
                dMindsetsMap.value.innerDrawMindsetsMap.leafletObject.removeLayer(
                    dMindsetsMap.value.innerDrawMindsetsMap.leafletObject._layers[keyToDelete]
                );
            }
            mixins.showMapRight(true, dMindsetsMap.value.innerDrawMindsetsMap);
        }

        function searchIfAnyMindsetSelected() {
            for (let mindsetArray of getAllMindsets.value) {
                for (let mindset of mindsetArray) {
                    if (mindset.isSelected) {
                        return true;
                    }
                }
            }
            return false;
        }



        async function deleteMindsets() {
            if (searchIfAnyMindsetSelected()) {
                let reallyToDelete = false;
                reallyToDelete = await mixins.alertConfirm(
                    "Are you sure you want to delete the selected mindsets?",
                    true
                );
                if (reallyToDelete) {
                    let messageBack = "";
                    messageBack = await store.dispatch("core/deleteMindsets");
                    if (messageBack != "ok!") {
                        await mixins.alertConfirm(messageBack, false);
                    } else {

                        mixins.showMapRight(true, dMindsetsMap.value.innerDrawMindsetsMap);
                    }
                }
            } else {
                await mixins.alertConfirm("Nothing selected to delete", false);
            }
        }

        async function moveToPolyline() {
            if (
                dMindsetsMap.value.selectedRegion != undefined &&
                Object.keys(dMindsetsMap.value.selectedRegion).length > 0
            ) {
                if (searchIfAnyMindsetSelected()) {
                    const totalSelected = getAllMindsets.value
                        .flat()
                        .filter((mindset) => mindset.isSelected);
                    const coordArray = await store.dispatch("core/moveMindsetsInRegion", {
                        region: dMindsetsMap.value.selectedRegion.latlngs,
                        numberOfQSets: totalSelected.length,
                    });
                    for (let i = 0; i < totalSelected.length; i++) {
                        totalSelected[i].latitude = coordArray[i][0];
                        totalSelected[i].longitude = coordArray[i][1];
                    }
                } else {
                    await mixins.alertConfirm("Nothing selected to move.", false);
                }
            } else {
                await mixins.alertConfirm("You have to select a region first.", false);
            }
        }

        async function saveFromFirstMindsetsMap(shouldMoveOn) {
            let successfulSave = false;
            successfulSave = await store.dispatch("core/saveMindsets");
            if (successfulSave) {
                if (shouldMoveOn) {
                    await mixins.alertConfirm("Saved!", false);
                }
                if (getSelectedMgq.value.isContest) {
                    router.push("mgq_trophies");
                } else {
                    if (shouldMoveOn) {
                        router.replace("dashboard");
                    }
                    else {
                        await retrieveMadeMindsets();
                    }
                }
            }
        }

        function setOpenedMgqsRegionsSelector() {
            copyOpenedTimes.value = copyOpenedTimes.value + 1;
        }

        function setSearcherOpen() {
            if (!isSearcherOpen.value) {
                isSearcherOpen.value = true;
            }
        }

        function generalClick() {
            if (copyOpen.value) {
                copyOpenedTimes.value = copyOpenedTimes.value + 1;
                if (copyOpenedTimes.value > 2) {
                    copyOpen.value = false;
                    copyOpenedTimes.value = 0;
                }
            }
            else {
                if (isSearcherOpen.value) {
                    mindsetSearchOpenedTimes.value = mindsetSearchOpenedTimes.value + 1;
                    if (mindsetSearchOpenedTimes.value > 1) {
                        isSearcherOpen.value = false;
                        mindsetSearchOpenedTimes.value = 0;
                    }
                }
            }
        }

        function updateZoom(incomingZoom) {
            currentZoomLevel.value = incomingZoom;
        }

        return {
            copyOpenedTimes,
            setSearcherOpen,
            deleteSomething,
            deleteDrawing,
            drawings,
            isLabelOn,
            isPolylineOn,
            isPolygonOn,
            isRectangleOn,
            isCircleOn,
            isImageOn,
            isSignalOn,
            labelColor,
            polylineColor,
            polygonColor,
            rectangleColor,
            circleColor,
            imageColor,
            signalColor,
            turnOnlyOneOn,
            fillColor,
            opacity,
            borderColor,
            softDelete,
            invalidateImageOn,
            numberOfMindsets,
            dMindsetsMap,
            checkNumber,
            tooBigNumber,
            radius,
            wrongRadius,
            wrongTextFileName,
            textFileName,
            succeeded,
            setTextFile,
            groupName,
            wrongGroupName,
            getdata,
            createOneEmpty,
            mapButtonMap,
            outerCoords,
            deleteMindsets,
            deleteRegions,
            saveFromFirstMindsetsMap,
            isRealMap,
            makePolyline,
            isRegionDrawingOn,
            isNoMapBoardOn,
            regionDrawingButtonColor,
            forContest,
            moveToPolyline,
            copyOpen,
            handleCopy,
            copySelected,
            getSelectedMgq,
            isMultipleClicked,
            multipleClicked,
            localizationType,
            sourceType,
            sourceText,
            eraseSource,
            withMindsetsNumber,
            rowsDelim,
            columnsDelim,
            advanced,
            mindsetName,
            mindsetNameType,
            wrongDelimiters,
            setMindsetName,
            rowsIsRegex,
            columnsIsRegex,
            withIndex,
            rotatedText,
            selectedImageToOCRName,
            ocrLanguage,
            whiteBlackList,
            whiteBlackListText,
            getAllMindsets,
            makeWhitelistNumbers,
            whiteBlackListChange,
            ocrConfidence,
            withTooltips,
            selectMindsetsFromPolyline,
            setSearchedCoordinates,
            shouldDraw,
            setOpenedMgqsRegionsSelector,
            generalClick,
            //      setOpenedMindsetSearch,
            isSearcherOpen,
            mindsetSearchOpenedTimes,
            updateZoom,
            currentZoomLevel,
            setNoMapBoardOn,
            noMapBoardColor,
            copySelectedMindset,
            editSomething,
            showBluetoothButtonOn,
            getBluetooth,
        };
    },
};
</script>
<style scoped>
.two_icons {
    display: flex;
}

.floating_list {
    position: absolute;
    top: 110px;
    z-index: 4;
    max-width: 20vw;
    height: calc(100% - 100px);

}

.floating_list.list_left {
    left: 10px;

}


.floating_list.list_left.zoom_div {
    top: 80px;
    width: 30px;
    height: 20px;
    color: white;
    background-color: #566573;
    text-align: center;

}

.floating_list.list_right {
    right: 10px;
    max-width: 20vw;
}

.floating_search {
    position: absolute;
    bottom: 20px;
    z-index: 5;
    margin-left: 1em;
}

.slider_wrapper {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 6fr 1fr;
}

.icons_changing {
    color: purple;
    font-size: xx-large;
}



#region_button_id,
#select_id {
    transition: all 1s;
}
</style>