<template>
    <div v-if="shouldShow" class="countdownclock_div" :class="{ 'alarm_one': isAlarmOne, 'alarm_two': isAlarmTwo }">
        {{ computedLeftTime }}
    </div>
</template>

<script>

import { onBeforeMount, ref, computed, onBeforeUnmount, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import * as mixins from "@/utils/mixins";
import * as gameMixins from "@/utils/gameMixins";

export default {

    props: {
        shouldCount: {
            type: Boolean,
        },
      
    },
    setup(props) {

        watch(() => props.shouldCount, async () => {
            if (!props.shouldCount) {
                clearInterval(x);
            }
            else {
                initializeDurationEndTime();
                x = await setInterval(makeCountDown, 1000);
            }
        });
        const store = useStore();
        const router = useRouter();
        const currentMgq = computed(() => store.getters["gameApi/getCurrentMgq"]);
        const isAlarmOne = computed(() => leftTime.value.days == 0 && leftTime.value.hours == 0 && leftTime.value.minutes == 0);
        const isAlarmTwo = computed(() => leftTime.value.days == 0 && leftTime.value.hours == 0 && leftTime.value.minutes == 0 && leftTime.value.seconds < 10);

        onBeforeMount(async () => {
            initializeDurationEndTime();
            x = await setInterval(makeCountDown, 1000);
        });


        onBeforeUnmount(() => clearInterval(x));

        function initializeDurationEndTime() {
            duration.value = currentMgq.value.mgqVersion.endTime - currentMgq.value.mgqVersion.startTime;
            endTime.value = new Date(Date.now() + duration.value);
        }
        

        const duration = ref(0);

        const shouldShow = ref(false);
        const endTime = ref(0);
        let x = null;
        const computedLeftTime = computed(() => {
            let toReturn = leftTime.value.seconds + "sec";
            if (leftTime.value.minutes != 0) {
                toReturn = leftTime.value.minutes + "min " + toReturn;
            }
            if (leftTime.value.hours != 0) {
                toReturn = leftTime.value.hours + "h " + toReturn;
            }
            if (leftTime.value.days != 0) {
                if (leftTime.value.days > 10) {
                    toReturn = "Many days " + toReturn;
                }
                else {
                    toReturn = leftTime.value.days + " d " + toReturn;
                }
            }
            return toReturn;
       
        });
        const distance = ref(0);
        const then = ref(0);
        const leftTime = ref({});

        async function makeCountDown() {
            let now = new Date().getTime();
            // then.value = new Date(currentMgq.value.usEndTime);
            then.value = endTime.value;
            // Find the distance between now and the count down date
            distance.value = then.value - now;

            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance.value / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance.value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance.value % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance.value % (1000 * 60)) / 1000);

            // Display the result in the element with id="demo"
            leftTime.value = { days: days, hours: hours, minutes: minutes, seconds: seconds };
            shouldShow.value = true;

            if (distance.value < 0) {
                router.push("/");
                shouldShow.value = false;
                clearInterval(x);
                await gameMixins.setUsMindgluequest(currentMgq.value, store.getters["core/getUser"].latitude, store.getters["core/getUser"].longitude);
                await mixins.alertConfirm("Unfortunately this quest has timed out.")
            }
        }

        return {
            leftTime,
            computedLeftTime,
            currentMgq,
            distance,
            then,
            isAlarmOne,
            isAlarmTwo,
            shouldShow,
        };
    }
}
</script>

<style>
.countdownclock_div {
    background-color: white;
    padding: .5em;
    border-radius: 0.5em;
}

.alarm_one {
    background-color: yellow;
    font-weight: bold;
}

.alarm_two {
    background-color: red;
    font-weight: bold;
    text-shadow: 2px 2px #1d1717;
    color: rgb(252, 252, 104);
}
</style>