export function getMarker(myColor,isWeb){
    let color = myColor;
    let width = isWeb?"24px":"72px";
    let height = isWeb?"24px":"72px";
    let marker = `<svg
    width="${width}"
    height="${height}"
    viewBox="0 0 6.35 6.35"
    version="1.1"
    id="svg5"
    inkscape:version="1.1.2 (0a00cf5339, 2022-02-04)"
    sodipodi:docname="newMarker.svg"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
   <sodipodi:namedview
      id="namedview7"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageshadow="2"
      inkscape:pageopacity="0.0"
      inkscape:pagecheckerboard="0"
      inkscape:document-units="mm"
      showgrid="false"
      width="24px"
      units="px"
      inkscape:zoom="16.912312"
      inkscape:cx="3.281633"
      inkscape:cy="4.8781031"
      inkscape:window-width="1920"
      inkscape:window-height="1020"
      inkscape:window-x="0"
      inkscape:window-y="0"
      inkscape:window-maximized="1"
      inkscape:current-layer="layer1" />
   <defs
      id="defs2" />
   <g
      inkscape:label="Layer 1"
      inkscape:groupmode="layer"
      id="layer1">
     <circle
        style="fill:#${color};stroke-width:0.130822"
        id="path4374"
        cx="3.175"
        cy="3.175"
        r="3.1124129" />
     <circle
        style="fill:#ffffff;stroke-width:0.126384"
        id="path4374-7"
        cx="3.175"
        cy="3.175"
        r="3.0068367" />
     <circle
        style="fill:#${color};stroke-width:0.100017"
        id="path4374-7-4"
        cx="3.175"
        cy="3.175"
        r="2.3795316" />
   </g>
 </svg>`;

 /* let marker = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="${width}" height="${height}" viewBox="0 0 26.184 37.182" enable-background="new 0 0 26.184 37.182" xml:space="preserve">
<g>
<path fill="#${color}" d="M13.091,37.182c-1.29-1.793-2.572-3.524-3.801-5.295C6.66,28.1,4.129,24.245,2.179,20.055
   c-0.767-1.646-1.442-3.369-1.889-5.125c-0.684-2.68-0.103-5.285,1.138-7.706c2.003-3.913,5.216-6.339,9.547-7.043
   C18.566-1.052,24.846,4.18,26.02,10.81c0.464,2.62-0.104,5.024-1.102,7.39c-1.854,4.392-4.368,8.411-7.043,12.333
   c-1.478,2.166-3.049,4.267-4.579,6.396C13.249,36.993,13.195,37.053,13.091,37.182z M13.097,19.363
   c4.059,0.002,7.198-3.234,7.354-7.024c0.168-4.067-3.039-7.628-7.305-7.64C8.99,4.687,5.952,7.983,5.793,11.721
   C5.618,15.851,8.935,19.342,13.097,19.363z"/>
</g>
</svg>`;  */

    return marker;
}