export function getMarkerWithHeading(myColor,isWeb,heading){
    let color = myColor;
    let width = isWeb?"72px":"144px";
    let height = isWeb?"72px":"144px";
    let myHeading = heading;
    let marker = `<svg
    width="${width}"
    height="${height}"
    viewBox="-8 -8 16 16"
    version="1.1"
    id="svg5"
    inkscape:version="1.1.2 (0a00cf5339, 2022-02-04)"
    sodipodi:docname="newMarkerWithHeading.svg"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
   <g
      inkscape:label="Layer 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="rotate(${myHeading} 0 0)">
     <circle
        style="fill:#${color}"
        id="path4374"
        cx="0"
        cy="0"
        r="3.1124129" />
     <circle
        style="fill:#ffffff"
        id="path4374-7"
        cx="0"
        cy="0"
        r="3.0068367" />
     <circle
        style="fill:#${color}"
        id="path4374-7-4"
        cx="0"
        cy="0"
        r="2.3795316" />
     <path
        id="rect7649"
        style="fill:#${color}"
        d="m -2.0773792,-7.9261648 c 1.38491952,0 2.76983892,0 4.1547584,0 -0.3685613,1.5440254 -0.7371225,3.0880509 -1.10568378,4.6320763 -0.6477966,0 -1.2955933,0 -1.9433899,0 C -1.3402561,-4.8381139 -1.7088176,-6.3821394 -2.0773792,-7.9261648 Z"
        sodipodi:nodetypes="ccccc" />
   </g>
 </svg>`;

 /* let marker = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="${width}" height="${height}" viewBox="0 0 26.184 37.182" enable-background="new 0 0 26.184 37.182" xml:space="preserve">
<g>
<path fill="#${color}" d="M13.091,37.182c-1.29-1.793-2.572-3.524-3.801-5.295C6.66,28.1,4.129,24.245,2.179,20.055
   c-0.767-1.646-1.442-3.369-1.889-5.125c-0.684-2.68-0.103-5.285,1.138-7.706c2.003-3.913,5.216-6.339,9.547-7.043
   C18.566-1.052,24.846,4.18,26.02,10.81c0.464,2.62-0.104,5.024-1.102,7.39c-1.854,4.392-4.368,8.411-7.043,12.333
   c-1.478,2.166-3.049,4.267-4.579,6.396C13.249,36.993,13.195,37.053,13.091,37.182z M13.097,19.363
   c4.059,0.002,7.198-3.234,7.354-7.024c0.168-4.067-3.039-7.628-7.305-7.64C8.99,4.687,5.952,7.983,5.793,11.721
   C5.618,15.851,8.935,19.342,13.097,19.363z"/>
</g>
</svg>`;  */

    return marker;
}