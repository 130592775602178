<template>
    <div>

        <a-scene>

            <a-camera :position="startPos" twoway-motion="speed: 35">
                <a-cursor></a-cursor>
            </a-camera>

            <a-entity v-for="(mindset, idx) in allMindsets" :key="idx" material="color: yellow" :position="mindset.pos">
                <a-entity :text="'value:' + mindset.title+'; color: red; align: right; width: 2;'"></a-entity>
            </a-entity>

            <a-sky :position="startPos" color="#72dbff"></a-sky>

        </a-scene>
    </div>
</template>
<script>

window.AFRAME.registerComponent("yellow-on-click", {
    init() {
        var isYellow = false;
        this.el.addEventListener("click", () => {
            if (!isYellow) {
                this.el.setAttribute("color", "#F4D634");
                isYellow = true;
            } else {
                this.el.setAttribute("color", "#E75A87");
                isYellow = false;
            }
        });
    },
});
import { ref, computed } from 'vue';
//import { useStore } from "vuex";
//import * as mixins from "@/utils/mixins";

export default {
    props: {
        allMindsets: {
            type: Array,
        },
        /* startPos:{
            type:String,
        } */
    },
    setup(props) {

        const startPos = computed(() => props.allMindsets[0].pos);


        /* onBeforeMount(()=>{
            for (let md of props.allMindsets){
                coords.value.push([latLonToWorld(md.latitude,md.longitude)]);
            }
        }); */
        const coords = ref([]);

        const spherePosition = ref("0 1.25 -5");
        const sphereAnim = ref("");
        function sphereClicked() {
            console.log("clicked");
            let sphPos = [0, 1.25, -5];
            sphPos[1] = sphPos[1] + 2;
            // sphPos[2]=Number(sphPos[2]+2);
            let newpos = sphPos[0].toString() + " " + sphPos[1].toString() + " " + sphPos[2].toString();

            sphereAnim.value = "property: position; to: " + newpos + "; dur: 2000; easing: linear";
            console.log("clicked 2");

        }




        return {
            spherePosition,
            sphereAnim,
            coords,
            startPos,
            sphereClicked
        };
    }
}
</script>