<template>
    <div>
        <div>
          <div class="head">Thank you for using Mindgluequest and creating content with it!</div>

<p>By setting a price for your mindgluequest, you, the creator of a mindgluequest (“Creator”) agree to the following terms of Mindglue UG (haftungsbeschränkt) (“Mindglue UG”):</p>

<div>
    <p>The set price in the set currency (“Asked Amount”) will be paid from each User joining your mindgluequest directly to the payment account you will define or have already defined. The payment fees that recur between the Creator and the payment account provider are not relevant to Mindglue UG.</p>
    
    <p>For each one of the transactions that are made through the Mindglue Platform (Quesdom applications) from Mindglue Users to you as a Creator, the Mindglue UG fees are 5% of the Asked Amount. Mindglue UG will issue monthly or quarterly invoices to you that pertain to the commited transactions so far. If you fail to meet your financial obligations to Mindglue UG, Mindglue UG may suspend your account, lock the particular mindgluequest or take other measures.</p>
    
    <p>
        When users join a priced mindgluequest of yours or subscribe to it, they must first pay to you the Asked Amount. At the same time they come into a contractual relationship with you. All liabilities that stem from such a contractual relationship affect you. Please take into account among other that:    </p>

        <p>- withdrawal of mindgluequests published before their due date and time</p>
        <p>- change of time or date and especially setting of an earlier start of mindgluequest already published and with incoming payments</p>
         might result in your obligation to compensate the users already subscribed.  

</div>
Please keep in mind that you are the sole responsible for the content of your mindgluequest. All the general Terms of Use of the Mindglue Platform are here applicable as well.   
        </div>
    </div>
</template>
<style scoped>
div {
  background-color: white;
  padding: 1em;
}
p {
  padding-top: 0.4em;
  text-align: justify;
}
.head {
    font-weight: bold;
}
</style>