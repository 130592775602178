//import store from '../store';

export function makePurchaseUnits(mgq, payeeId){
    let purchase_units = [];
    let purchase_unit_request = {};
    let payee = {};
    let amount = {};
   // amount.currency_code = mgq.currency;
    amount.value = mgq.price.toString();
    payee.email_address = payeeId;
    purchase_unit_request.payee = payee;
    purchase_unit_request.amount= amount;
    purchase_unit_request.reference_id= mgq.mindgluequestId.toString()+":"+mgq.mgqVersion.versionNr.toString();
    purchase_unit_request.description = mgq.title+", version "+mgq.mgqVersion.versionNr.toString();
    purchase_units.push(purchase_unit_request);
    return purchase_units;
  }


  