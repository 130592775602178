import { createStore,     createLogger   } from 'vuex';
import core from '@/store/core';
import gameApi from '@/store/gameApi';


export default createStore({

    modules: {
        core: core,
        gameApi: gameApi,
    },
    plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
  })