function makeKey() {
  return window.crypto.subtle.importKey(
    "jwk",
    {   
      kty: "oct",
     // k: "Y0zt37HgOx-BY7SQjYVmrqhPkO44Ii2Jcb9yydUDPfE",
      k: "sdfF23DcvGbgb5347V-VvgYVsd44549yydlk4FVv-gg",
     alg: "A256GCM",
      ext: true,
    },
    {   
      name: "AES-GCM",
    },
    false,
    ["encrypt", "decrypt"] 
  )
}


function encrypt(data, key, iv) {
  return window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
      tagLength: 64, 
    },
    key, 
    data 
  )
}

function decrypt(data, key, iv) {
  return window.crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: iv, 
      tagLength: 64, 
    },
    key, 
    data 
  )
}



export async function encr(msg){
  var keys = await makeKey()
  var iv = new Uint8Array([132,43,52,233,120,2,47,32,199,55,76,244])
  var enc = new TextEncoder();
  var data = enc.encode(msg)
  var encryptedData = await encrypt(data, keys, iv);
  let toReturn = String.fromCharCode.apply(null, new Uint16Array(encryptedData));
  return toReturn;
}

export async function dencr(encryptedString){
  let encryptedData = str2ab(encryptedString);
  var keys = await makeKey()
  var iv = new Uint8Array([132,43,52,233,120,2,47,32,199,55,76,244])
  var decryptedData = await decrypt(encryptedData, keys, iv)
  var denc = new TextDecoder();
  let string = denc.decode(decryptedData);
return string;
}

export function str2ab(str) {
  var buf = new ArrayBuffer(str.length*2);
  var bufView = new Uint16Array(buf);
  for (var i=0, strLen=str.length; i < strLen; i++) {
  bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

