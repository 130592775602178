let db = null;



export async function startDB() {
    db = await window.idb.openDB('mindglue', 1, {
        async upgrade(db) {
            if (!db.objectStoreNames.contains('user_info')) {
                await db.createObjectStore('user_info');
            }
            if (!db.objectStoreNames.contains('searchable_qsets')) {
                await db.createObjectStore('searchable_qsets');
            }
            if (!db.objectStoreNames.contains('searchable_qsets_deleted')) {
                await db.createObjectStore('searchable_qsets_deleted');
            }
            if (!db.objectStoreNames.contains('mgq_scores')) {
                await db.createObjectStore('mgq_scores');
            }
            if (!db.objectStoreNames.contains('mgq_chains')) {
                await db.createObjectStore('mgq_chains');
            }
            if (!db.objectStoreNames.contains('mgq_assets')) {
                await db.createObjectStore('mgq_assets');
            }
        },
    });
    return db;
}

export async function initializeDB() {
    if (!db.objectStoreNames.contains('user_info')) {
        await db.createObjectStore('user_info');
    }
    if (!db.objectStoreNames.contains('searchable_qsets')) {
        await db.createObjectStore('searchable_qsets');
    }
    if (!db.objectStoreNames.contains('searchable_qsets_deleted')) {
        await db.createObjectStore('searchable_qsets_deleted');
    }
    if (!db.objectStoreNames.contains('mgq_scores')) {
        await db.createObjectStore('mgq_scores');
    }
    if (!db.objectStoreNames.contains('mgq_chains')) {
        await db.createObjectStore('mgq_chains');
    }
    if (!db.objectStoreNames.contains('mgq_assets')) {
        await db.createObjectStore('mgq_assets');
    }
}

export async function clearTable(tableName){
    await db.clear(tableName);
}

export async function deleteKeyValue(tableName,key){
    await db.delete(tableName,key);
}

export async function clearAll() {
    await db.clear('user_info');
    await db.clear('searchable_qsets');
    await db.clear('searchable_qsets_deleted');
    await db.clear('mgq_scores');
    await db.clear('mgq_chains');
    await db.clear('mgq_assets');
    return true;
}


/* function readDBObject(objectStoreName) {
    let transaction = db.transaction(objectStoreName, "readonly");
    let objectStore = transaction.objectStore(objectStoreName);
    let getReq = objectStore.getAll();
    getReq.onsuccess = function (event) {
        toReturn = event.target.result;
    }
    return toReturn;
}


function readDBValueFromObjectStore(objectStoreName, key) {
    let toReturn = null;
    let transaction = db.transaction(objectStoreName, "readonly");
    let obj = transaction.objectStore(objectStoreName);
    let getReq = obj.get(key);
    getReq.onsuccess = function (event) {
        toReturn = event.target.result;
    }
    return toReturn;
} 

function writeDBObject(objectStoreName, payload) {
    let transaction = db.transaction(objectStoreName, "readwrite");
    let objectStore = transaction.objectStore(objectStoreName);
    let updateRequest = objectStore.put(payload);
    updateRequest.onsuccess = function (event) {
        return true;
    };
    updateRequest.onerror = function (event) {
        return false;
    };
}
*/




export async function writeDBKeyValue(objectStoreName, key, value) {
    let exists = await db.get(objectStoreName, key);
    if (exists!=undefined){
        await db.put(objectStoreName, value, key);
    }
    else {
        await db.add(objectStoreName, value, key);

    }
}

export async function readDBKeyValue(objectStoreName, key) {
    let toReturn = await db.get(objectStoreName, key);
    return toReturn;
}

export async function readAllValues(objectStoreName) {
    let toReturn = await db.getAll(objectStoreName);
    return toReturn;
}

export async function readAllKeys(objectStoreName) {
    let toReturn = await db.getAllKeys(objectStoreName);
    return toReturn;
}

export async function checkIsEmpty(objectStoreName) {
    let count = await db.count(objectStoreName);
    return count == 0 ? true : false;
}


export function saveSearchable(firstGamecode, mgqQSetListJson,) {
    let success = writeDBKeyValue("searchable_qsets", firstGamecode, mgqQSetListJson);
    return success;
}








/* export function deleteSearchable() {
    if (!checkIsEmpty("searchable_qsets")) {
        Map < String, String >? prevSearchables = await getAllSearchables();
        if (null != prevSearchables) {
            window.localStorage.getItem("searchable_qsets_deleted").putAll(prevSearchables);
        }
        window.localStorage.getItem("searchable_qsets").clear();
    }
}

static deleteSearchable()async{
    if (Hive.box("searchable_qsets").isNotEmpty) {
        Map < String, String >? prevSearchables = await getAllSearchables();
        if (null != prevSearchables) {
            Hive.box("searchable_qsets_deleted").putAll(prevSearchables);
        }
        Hive.box("searchable_qsets").clear();
    }
}



function getAllSearchables() {
      /*   Map<dynamic,dynamic> delFirstMap = Map();
        for (String key in Hive.box("searchable_qsets_deleted").keys){
          delFirstMap.putIfAbsent(key,()=>Hive.box("searchable_qsets_deleted").get(key));
        }
        
let delFirstMap = readDBObject("searchable_qsets_deleted");
if (delFirstMap.length == 0){
    db.createObjectStore('searchable_qsets_deleted');
}
let searchable_qsets_deleted = readDBObject("searchable_qsets_deleted");

      if (searchable_qsets_deleted.length > 0){
        searchable_qsets_deleted
      }
     //   if (await Hive.box("searchable_qsets_deleted").isNotEmpty) {
          if (await Hive.box("searchable_qsets_deleted").keys.contains(locator<AppInfo>().getCurrentMgq().mindgluequestId.toString())){
            bool currentSearchablePartOfDeleted = true;
            for (String key in Hive.box("searchable_qsets").keys){
              if (await !Hive.box("searchable_qsets_deleted").containsKey(key)){
                currentSearchablePartOfDeleted = false;
              }
            }
    
            if (currentSearchablePartOfDeleted){
              Map<dynamic,dynamic> delMap = Map();
              for (String key in await Hive.box("searchable_qsets_deleted").keys){
                delMap.putIfAbsent(key,()=>Hive.box("searchable_qsets_deleted").get(key));
              }
              await Hive.box("searchable_qsets").clear();
              await Hive.box("searchable_qsets").putAll(delMap);
            }
            else {
              await Hive.box("searchable_qsets_deleted").clear();
            }
          }
          else {
            await Hive.box("searchable_qsets_deleted").clear();
          }
        }
        if (await Hive.box("searchable_qsets").isNotEmpty) {
          Map<String,String> jsonMap = Map<String,String>();
          for (String key in  await Hive.box("searchable_qsets").keys){
            jsonMap.putIfAbsent(key,()=>Hive.box("searchable_qsets").get(key));
          }
          return jsonMap;
        }  */