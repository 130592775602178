<template>
  <div class="rowy contenty">
    <div class="pane_container">
      <ExplanationBanner @moveExplanation="moveExplanation" :style="'order:' + explOrder" />
      <MajorPane :style="'order:' + majOrder" />
    </div>
  </div>
</template>

<script>
import MajorPane from "@/components/parts/MajorPane.vue";
import ExplanationBanner from "@/components/parts/ExplanationBanner.vue";
import { ref } from "vue";
export default {
  components: {
    MajorPane,
    ExplanationBanner,
  },

  setup() {
    const explOrder = ref(1);
    const majOrder = ref(2);
    function moveExplanation() {
      if (explOrder.value == 1) {
        explOrder.value = 2;
        majOrder.value = 1;
      }
      else {
        explOrder.value = 1;
        majOrder.value = 2;
      }

    }
    return {
      explOrder,
      majOrder,
      moveExplanation,
    };
  }
};
</script>

<style scoped>
.pane_container {
  min-height: 85%;
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-columns: 4fr 1fr;  */
}

/* @media only screen and (max-width: 800px) {
  .pane_container {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }
} */
</style>
