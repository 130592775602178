<template>
    <div>
        <a
            class="fb-xfbml-parse-ignore"
            target="_blank"
            :href="address+innerUrl"
          >
            <div class="share_button box" :style="'background-color:'+color">
                <i :class="{'fab fa-facebook-f ':socialType=='facebook','fab fa-twitter':socialType=='twitter'}"></i>
                </div>
          </a>
    </div>
</template>
<script>
import { computed } from 'vue';
export default {
    props:{
        socialType:{
           type: String
        },
        innerUrl:{
            type:String
        }
    },
    setup(props) {
        const address = computed(()=>{
            let toRet = "";
            if (props.socialType=="facebook") toRet= "https://www.facebook.com/sharer/sharer.php?u=";
            if (props.socialType=="twitter") toRet= "https://twitter.com/share?url=";
            return toRet;
        });
        const color=computed(()=>{
            let toRet="";
              if (props.socialType=="facebook") toRet = "#4267B2";
            if (props.socialType=="twitter") toRet = "#1DA1F2";
            return toRet;
        });
        return {
            address,
            color,
        }
    },
}
</script>
<style scoped>
.share_button{
    display: flex;
    align-items: center;
    justify-content: center;
    height:1em;
    width: 1em;
    color:white;
    background-color: #4267B2;
    margin: 0;
}

</style>