export function getIsOuterMap(state) {
  return state.isOuterMap;
}
export function getShouldReauthorize(state) {
  return state.shouldReauthorize;
}
export function getCurrentMgq(state) {
  return state.currentMgq;
}
export function getZoomLevel(state) {
  return state.zoomLevel;
}
export function getVectorAnimation(state) {
  return state.vectorAnimation;
}
export function getCurrentScore(state) {
  return state.currentScore;
}
export function getGameScreenWidthHeight(state) {
  return state.gameScreenWidthHeight;
}

export function getBackgroundAudio(state) {
  return state.backgroundAudio;
}

export function getStartedAudioUrl(state) {
  return state.startedAudioUrl;
}

