
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'


const state = {
  mindgluequests: [],
  jwt: "",
  user:{},
  selectedMgq:{},
  selectedMindsetsArrayIndex:0,
  createdMgq:{},
  firstMindsets:[],
  originalMindsets:[],
  textSentences:[],
  mgqLanguage:"",
  mindsetToShow:{},
  myAppMindsets:[],
  mgqTrophies:[],
  mgqOriginalTrophies:[],
  isLoading:false,
  isConfirm:false,
  alertConfirmMessage:"",
  deletedMgqs:[],
  allMgqMapRegions:{},
  outerMessage:"",
  temporaryContent:{},
  modalDynamicObject:{},
  modalDynamicComponent:"",
  toast:{message:"",milliseconds:0},

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}