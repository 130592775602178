<template>
    <div class="modal_dynamic_max_inner">
        <l-map ref="realMap" :zoom="12" :use-global-leaflet="true" :center="mso.payload.startCoords">
            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
            <l-control-layers />
            <l-marker :lat-lng="coord" draggable @moveend="getFirstCoordinates">
            </l-marker>
        </l-map>
    </div>
</template>
<script>
import { ref, onBeforeUnmount, computed, onBeforeMount, } from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";
import {
    LMap,
    LTileLayer,
    LControlLayers,
    LMarker,
} from "@vue-leaflet/vue-leaflet";
//import OneMarkerMap from "@/components/parts/OneMarkerMap.vue";
/**
 * 
 * 
 * 
*/
export default {
    components: {
        LMap,
        LTileLayer,
        LControlLayers,
        LMarker,
    },
    setup() {
        const store = useStore();
        const mso = computed(() => store.getters["core/getModalDynamicObject"]);
        const realMap = ref(null);
        const coord = ref([]);

        onBeforeMount(() => {
            coord.value = mso.value.payload.startCoords;
        });

        onBeforeUnmount(() => {
            let obj = mso.value;
            obj.content = coord.value;
            store.commit("core/setModalDynamicObject", obj);
        });

        function getFirstCoordinates(e) {
            mixins.showMapRight(true, realMap.value);
            coord.value = [
                mixins.makeCoordinates(e.target._latlng.lat),
                mixins.makeCoordinates(e.target._latlng.lng),
            ];
        }
        
/*eslint-disable no-unused-vars*/
        function checkSubmit(submitted) {
            return true;
        }

        return {
            mso,
            coord,
            realMap,
            getFirstCoordinates,
            checkSubmit,
        };
    }
}
</script>