
export function setMindgluequests(state, mindgluequests) {
    for (let mgq of mindgluequests){
        mgq.properties = JSON.parse(mgq.properties);
    }
    state.mindgluequests = mindgluequests;
}

/* export function setLoggedIn(state) {
    state.loggedIn = true;
} */

export function setLoggedOut(state) {
    state.loggedIn = false;
}

export function setJwtToken(state, token) {
    state.jwt = token;
}

export function setUser(state, user) {
    state.user = user;
}

export function renewLog(state) {
    state.user = {};
    state.jwt = "";
    state.loggedIn = false;
    state.selectedMgq = {};
    state.createdMgq = {};
    state.firstMindsets = [];
    state.originalMindsets = [];
    state.textSentences = [];
    state.mgqLanguage = "";
    state.mindsetToShow = {};
    state.myAppMindsets = [];
    state.mgqTrophies = [];
    state.temporaryContent = {};
}


export function renewAfterCreate(state) {
    state.selectedMgq = {};
    state.createdMgq = {};
    state.firstMindsets = [];
    state.originalMindsets = [];
    state.textSentences = [];
    state.mgqLanguage = "";
    state.mindsetToShow = {};
    state.myAppMindsets = [];
    state.mgqTrophies = [];
}

export function setCreatedMgq(state, mgq) {
    state.createdMgq = mgq;
}
export function setSelectedMgq(state, mgq) {
    state.selectedMgq = mgq;
}
export function removeSelectedMgq(state) {
    state.selectedMgq = {};
}
export function removeCreatedMgq(state) {
    state.createdMgq = {};
}

export function setMgqId(state, id) {
    state.createdMgq["mindgluequestId"] = id;
}
export function setMgqCode(state, code) {
    state.createdMgq["code"] = code;
}

export function setOriginalMindsets(state, payload) {
    state.originalMindsets = payload;
}
export function setFirstMindsets(state, payload) {
    state.firstMindsets = payload;
}
export function pushFirstMindsets(state, payload) {
    for (let mindset of payload) {
        if (mindset.id == 0) {
            mindset["isStart"] = true;
            break;
        }
    }
    state.firstMindsets.push(payload);
}

export function pushOneFirstMindset(state, payload) {
    let foundPlace = false;
    for (let mindsetsArray of state.firstMindsets) {
        if (mindsetsArray.length > 0) {
            if (mindsetsArray[0].gamecode.split("_!@mono@!")[0] == payload.gamecode.split("_!@mono@!")[0]) {
                mindsetsArray.push(payload);
                foundPlace = true;
                break;
            }
        }
    }
    if (!foundPlace) {
        state.firstMindsets.push([payload]);
    }
}

export function setTextSentences(state, textSentences) {
    state.textSentences = textSentences;
}
export function setMgqLanguage(state, language) {
    state.mgqLanguage = language;
}


export function setMindsetCoordinates(state, payload) {
    let mindset = state.firstMindsets[state.selectedMindsetsArrayIndex].filter(x => x.id == payload.mindsetId);
    mindset[0].latitude = payload.latitude;
    mindset[0].longitude = payload.longitude;
}

export function setMindsetToShow(state, mindset) {
    //console.log("setMindsetToShow "+mindset.title);

    // state.mindsetToShow = state.firstMindsets[state.selectedMindsetsArrayIndex][mindsetIndex];
    state.mindsetToShow = mindset;
}


export function setMindsetSelected(state, payload) {
    for (let mindsetArray of state.firstMindsets) {
        for (let mindset of mindsetArray) {
            if (mindset.id == state.mindsetToShow.id) mindset["isSelected"] = !mindset["isSelected"];
            else {
                if (payload.exclusive) {
                    mindset["isSelected"] = false;
                }
            }
        }
    }
}

export function setEditedMindset(state, payload) {
    let mindsetIndex = 0;
    for (let idx = 0; idx < state.firstMindsets[state.selectedMindsetsArrayIndex].length; idx++) {
        if (state.firstMindsets[state.selectedMindsetsArrayIndex][idx].id == payload.id) {
            mindsetIndex = idx;
            break;
        }
    }
    state.firstMindsets[state.selectedMindsetsArrayIndex].splice(mindsetIndex, 1, payload);
}



export function setAllMindsetsOfArraySelected(state, payload) {
    for (let i = 0; i < state.firstMindsets.length; i++) {
        for (let mindset of state.firstMindsets[i]) {
            if (i == payload.index) mindset["isSelected"] = payload.value;
            else mindset["isSelected"] = false;
        }
    }
}

/* export function deleteFirstMindsets(state, payload) {
    if (payload.length > 1) {
        state.firstMindsets.splice(state.selectedMindsetsArrayIndex, 1);
    }
    else {
        let indexOfMindsetToDelete = state.firstMindsets[state.selectedMindsetsArrayIndex].indexOf(state.mindsetToShow);
        state.firstMindsets[state.selectedMindsetsArrayIndex].splice(indexOfMindsetToDelete, 1);
    }
    for (let j = 0; j < state.firstMindsets.length; j++) {
        if (state.firstMindsets[j].length == 0) {
            state.firstMindsets.splice(j, 1);
        }
    }
} */

export function deleteFirstMindsets(state, payload) {
    //make new
    // let indexArrayToDelete=[];
    for (let mindset of payload) {
        for (let mindsetsArray of state.firstMindsets) {
            let indexOfMindsetToDelete = mindsetsArray.indexOf(mindset);
            if (indexOfMindsetToDelete > -1) {
                mindsetsArray.splice(indexOfMindsetToDelete, 1);
                break;
            }
        }
    }
    /*   else {
          let indexOfMindsetToDelete = state.firstMindsets[state.selectedMindsetsArrayIndex].indexOf(state.mindsetToShow);
          state.firstMindsets[state.selectedMindsetsArrayIndex].splice(indexOfMindsetToDelete, 1);
      } */
    for (let j = 0; j < state.firstMindsets.length; j++) {
        if (state.firstMindsets[j].length == 0) {
            state.firstMindsets.splice(j, 1);
        }
    }
    if (state.firstMindsets.length > 0) {
        if (state.firstMindsets[0].length == 0) {
            state.firstMindsets = [];
        }
    }
}

export function setSelectedMgqMarkedAfterDelete(state, payload) {
    state.selectedMgq.marked = payload;
}


export function setSelectedMindsetsArrayIndex(state, payload) {
    state.selectedMindsetsArrayIndex = payload.index;
}
export function setMindsetMarked(state, payload) {
    state.mindsetToShow['marked'] = payload.value;
}
/* export function makeQuestionlist(state) {
    state.mindsetToShow['questionlist'] = [{ questionname: "", questionid: -1 },];
}
export function addMindchunk(state) {
    let smallestIdx = -1;
    for (let qob of state.mindsetToShow.questionlist) {
        if (qob.questionid <= smallestIdx)
            smallestIdx = Number(qob.questionid) - 1;
    }
    state.mindsetToShow.questionlist.push({
        questionname: "",
        questionid: smallestIdx,
        questionnameid: -1,
        inturn: 0,
        answeredid: 0,
        comment: "",
        answerlist: []
    });
} */

/* export function makeReactionlist(state, payload) {
    let mindchunk = state.mindsetToShow.questionlist.filter(x => x.questionid == payload);
    mindchunk[0]['answerlist'] = [{ answername: "", answerid: -1 },];
}
export function addReaction(state, payload) {
    let mindchunk = state.mindsetToShow.questionlist.filter(x => x.questionid == payload);
    let smallestIdx = -1;
    for (let ansob of mindchunk[0].answerlist) {
        if (ansob.answerid <= smallestIdx)
            smallestIdx = Number(ansob.answerid) - 1;
    }
    mindchunk[0].answerlist.push({
        answername: "",
        answerid: smallestIdx,
        answernameid: -1,
        inturn: 0,
        grade: 0,
    });
} */

/* export function setMindchunks(state, payload) {
    let mindset = state.firstMindsets[state.selectedMindsetsArrayIndex].filter(x => x.id == payload.mindset.id);
    mindset[0].questionlist = payload.mindchunks;
} */

/* export function removeQuestionlist(state, payload) {
    let mindset = state.firstMindsets[state.selectedMindsetsArrayIndex].filter(x => x.id == payload.id);
    delete mindset[0].questionlist;
} */


export function setNameTitle(state, payload) {
    state.mindsetToShow.name = payload.name;
    state.mindsetToShow.title = payload.title;
    state.mindsetToShow.targeted = false;
    if (payload.removeQuestionlist) {
        state.mindsetToShow['questionlist'] = [];
    }
}

export function setMindsetImage(state, payload) {
    state.mindsetToShow.image.name = payload.name;
    state.mindsetToShow.image.content = payload.content;
}


export function setMyAppMindsets(state, payload) {
    state.myAppMindsets = payload;
}
export function setSelectedMyMindset(state, payload) {
    state.mindsetToShow.name = payload.name;
    state.mindsetToShow.title = payload.title;
    state.mindsetToShow.questionlist = payload.questionlist;
    state.mindsetToShow.image = payload.image;
    state.mindsetToShow.embedContent = payload.embedContent;
}

export function setMgqTrophies(state, payload) {
    state.mgqTrophies = payload;
}
export function addEmptyTrophy(state) {
    const lastIndex = state.mgqTrophies.length > 0 ? state.mgqTrophies[state.mgqTrophies.length - 1].id : -1;
    state.mgqTrophies.push({ id: -Math.abs(lastIndex) - 1, providerId: 0 });
}
export function removeMgqTrophy(state, payload) {
    const indexes = state.mgqTrophies.map(x => x.id)
    let index = indexes.indexOf(payload.id);
    state.mgqTrophies.splice(index, 1);
    state.mgqOriginalTrophies.splice(index, 1);
}

export function setTrophy(state, payload) {
    const index = state.mgqTrophies.indexOf(payload.trophy);
    state.mgqTrophies[index]["achievement"] = payload.achievement;
    state.mgqTrophies[index]["content"] = payload.content;
    state.mgqTrophies[index]["providerName"] = payload.providerName;
}

export function setTrophies(state, payload) {
    state.mgqTrophies = payload;
}
export function setOriginalTrophies(state, payload) {
    state.mgqOriginalTrophies = payload;
}

export function setMarkedFromApp(state) {
    if (undefined != state.selectedMgq.marked) {
        const markedTargetedArray = JSON.parse(state.selectedMgq.marked);
        if (markedTargetedArray.length != 0) {
            for (let mindsetArray of state.firstMindsets) {
                for (let mindset of mindsetArray) {
                    if (markedTargetedArray[0].includes(mindset.id)) mindset["isStart"] = true;
                    if (markedTargetedArray[1].includes(mindset.id)) mindset["marked"] = true;
                    if (markedTargetedArray[2].includes(mindset.id)) mindset["targeted"] = true;
                }
            }
        }
    }
}

export function removeMindchunk(state, payload) {
    /*   let mindset = state.firstMindsets[state.selectedMindsetsArrayIndex].filter(x => x.id == payload.mindsetId);
      let mindchunkIndex = mindset[0].questionlist.indexOf(payload.mindchunk);
      mindset[0].questionlist.splice(mindchunkIndex, 1); */
    let mindchunkIndex = state.mindsetToShow.questionlist.indexOf(payload.mindchunk);
    state.mindsetToShow.questionlist.splice(mindchunkIndex, 1);
}

export function removeReaction(state, payload) {
    let mindchunk = state.mindsetToShow.questionlist.filter(x => x.questionid == payload.mindchunkId);
    let reactionIndex = mindchunk[0].answerlist.indexOf(payload.reaction);
    mindchunk[0].answerlist.splice(reactionIndex, 1);
}


export function setIsLoading(state, payload) {
    state.isLoading = payload;
}
export function setIsConfirm(state, payload) {
    state.isConfirm = payload;
}

export function setAlertConfirmMessage(state, payload) {
    state.alertConfirmMessage = payload;
}

export function makeNewVersionNr(state) {
    state.selectedMgq.mgqVersion.versionNr = 0;
}


export function setMgqCoordinates(state, payload) {
    state.selectedMgq.latitude = payload.latitude;
    state.selectedMgq.longitude = payload.longitude;
    state.selectedMgq.properties = payload.properties;
}

export function setDeletedMgqs(state, payload) {
    state.deletedMgqs = payload;
}

export function setAllMgqMapRegions(state, payload) {
    state.allMgqMapRegions = payload;
}

export function setOuterMessage(state, payload) {
    state.outerMessage = payload;
}

export function setUserLatLong(state, payload) {
    state.user.latitude = payload.latitude;
    state.user.longitude = payload.longitude;
}

export function setTemporaryContent(state, payload) {
    state.temporaryContent = payload;
}


export function setModalDynamicObject(state, payload) {
    state.modalDynamicObject = payload;
}

export function setModalDynamicComponent(state, payload) {
    state.modalDynamicComponent = payload;
}

export function setToast(state, payload) {
    state.toast = payload;
}








