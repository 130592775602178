<template>
  <div>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-content" style="width: 90%; height:90%;">
        <div class="ms_attributes_container p-4">
          <div style="height:5%;">
            <input type="checkbox" v-model="showCopy" />&nbsp;Copy Mindsets
          </div>
          <div style="height:5%;">
            <MindsetLoader @setSelectedMyMindset="setSelectedMyMindset" v-if="showCopy" />
          </div>
          <div style="height:80%;">
            <MindsetAttributes :mindsetProp="getMakerMindsetToShow" ref="mindsetAttributes" />
          </div>
          <div style="height:10%;"> <button class="button is-primary" @click="gatherAndClose">
              OK
            </button></div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="closeModal"></button>
    </div>
  </div>
</template>
<script>
import { computed, ref, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";
import MindsetAttributes from "./MindsetAttributes.vue";
import MindsetLoader from "./MindsetLoader.vue";

export default {
  components: {
    MindsetAttributes,
    MindsetLoader,
  },
  setup(props, { emit }) {
    const store = useStore();
    const getMindsetCompToShow = computed(
      () => store.getters["core/getMindsetToShow"]
    );
    const getMakerMindsetToShow = ref(null);

    const isSocial = computed(
      () => getMakerMindsetToShow.value.gamecode.indexOf("#!soc!#") > -1
    );
    const mindsetAttributes = ref(null);


    async function gatherAndClose() {
      let editedMindset = await mindsetAttributes.value.provideMindset();
      store.commit("core/setEditedMindset", editedMindset);
      closeModal();
    }

    function closeModal() {
      if (getMakerMindsetToShow.value.chain == "_") {
        store.commit("core/setChain", "");
      }
      store.commit("core/setMindsetToShow", {});
      emit("close");
    }

    onBeforeMount(() => {
      getMakerMindsetToShow.value = mixins.cloneObject(
        getMindsetCompToShow.value
      );
    });

    onMounted(() => {
      suitableTargeted.value = getMakerMindsetToShow.value.targeted;
    });

    const suitableTargeted = ref(false);
    const showCopy = ref(false);

    function setSelectedMyMindset(mindsetToCopy) {
      let id = getMakerMindsetToShow.value.id;
      let gamecode = getMakerMindsetToShow.value.gamecode;
      let copied = mixins.cloneObject(mindsetToCopy);
      copied.id = id;
      copied.gamecode = gamecode;
      getMakerMindsetToShow.value = copied;
      mindsetAttributes.value.renewMindset(getMakerMindsetToShow.value);
    }


    return {
      getMakerMindsetToShow,
      showCopy,
      suitableTargeted,
      isSocial,
      setSelectedMyMindset,
      closeModal,
      gatherAndClose,
      mindsetAttributes,
    };
  },
};
</script>
<style scoped>
.ms_attributes_container {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: rgb(231, 242, 255);
}
</style>