import * as mixins from "@/utils/mixins";

export function compareqsets(changed, originalFirst) {
    let original = mixins.cloneObject(originalFirst);
    /*   let originalMindset;
      let foundQSet=false;
      var parsed = json.decode(locator<AppInfo>().qsetresponse!) as List<dynamic>;
      for (var qset in parsed) {
        QSet temp = (QSet.fromJson(qset));
        if (temp.id ==changed.id) {
          original = temp;
          foundQSet = true;
        }
      } */

    let postparams = {};
    // DOES QSET ALREADY EXIST OR NEW
    //when the qset is new it tries to find original (non existant)

    //BAckend when there is no answer it gets a null answer back.. This is bad



    /*    if (foundQSet) { */
    // QSET ELEMENTS
    /*  if (changed != original) {
         postparams["qsetid"] = original.id.toString();
  */
    /* if (original!.id == -1) {
      postparams[
          "userid"] =locator<AppInfo>().userInfo!.userid.toString());
    } */
    //let keysArrOriginal = Object.keys(original);
    //console.log(keysArrOriginal.length);
    if (Object.keys(original).length === 0) return {}; 
    //|| Object.getPrototypeOf(original) == Object.prototype) return {}; 
    if (changed.title != original.title ||
        changed.properties != original.properties ||
        changed.onoff != original.onoff ||
        changed.shareContent != original.shareContent ||
        changed.randomize != original.randomize ||
        changed.placeTag != original.placeTag ||
        changed.receiveAnswers != original.receiveAnswers ||
        changed.image.name != original.image.name ||
        changed.embedContent != original.embedContent ||
        changed.gamecode != original.gamecode ||
        changed.chain != original.chain ||
        changed.visibilityProps != original.visibilityProps ||
        changed.longitude != original.longitude ||
        changed.latitude != original.latitude
    ) {
        postparams["questionset"] = {};
        postparams.questionset["qsetid"] = changed.id;
        postparams.questionset["qsetProperties"] = changed.properties;
        postparams.questionset["qsettitle"] = changed.title;
        postparams.questionset["qsetlongitude"] = changed.longitude;
        postparams.questionset["gamecode"] = changed.gamecode;
        postparams.questionset["qsetlatitude"] = changed.latitude;
        postparams.questionset["embedContent"] = changed.embedContent;
        postparams.questionset["gamecode"] = changed.gamecode;
        postparams.questionset["chain"] = changed.chain;
        postparams.questionset["visibilityProps"] = changed.visibilityProps;
        postparams.questionset["qsetshareContent_int"] = (changed.shareContent ? 1 : 0).toString();
        postparams.questionset["qsetonoff_int"] = (changed.onoff ? 1 : 0).toString();
        postparams.questionset[
            "qsetshareContent_int"] = (changed.shareContent ? 1 : 0).toString();
        postparams.questionset[
            "qsetrandomize_int"] = (changed.randomize ? 1 : 0).toString();
        postparams.questionset[
            "qsetplaceTag_int"] = (changed.placeTag ? 1 : 0).toString();
        postparams.questionset[
            "qsetreceiveAnswers_int"] = (changed.receiveAnswers ? 1 : 0).toString();
        postparams.questionset["image_name"] = changed.image.name;

    }

    if (changed.image.content != original.image.content) {
        postparams.questionset["image_content"] = changed.image.content;
    }

    // TO ADD LIST
    let toaddquestionobjs = [];

    for (let qob of changed.questionlist) {
        if (qob.questionid < 0 ){ //&& qob.questionname.trim().length != 0) {
            toaddquestionobjs.push((qob));
        }
    }

    if (toaddquestionobjs.length != 0) {
        postparams["qsetid"] = changed.id;
        postparams["toaddquestionobjs"] = JSON.stringify(toaddquestionobjs);
    }
    // * TO ADD LIST

    // TO REMOVE LIST (AND FIRST LEVEL OF TO CHANGE LIST)
    let toremovequestionobjs = [];
    let tochangedistilledquestionobjs = [];

    let placesOfQueObjSimilarity = [];
    for (let simOr = 0; simOr < original.questionlist.length; simOr++) {
        let foundqobj = false;
        for (let simCh = 0; simCh < changed.questionlist.length; simCh++) {
            if (original.questionlist[simOr].questionid == changed.questionlist[simCh].questionid) {
                let origJSONString = JSON.stringify(original.questionlist[simOr]);
                let changJSONString = JSON.stringify(changed.questionlist[simOr]);
                if (origJSONString == changJSONString){
                    placesOfQueObjSimilarity.push(simOr);
                }
                else {
                    let distqueobj = maketochangelist(
                        original.questionlist[simOr], changed.questionlist[simCh]);
                    tochangedistilledquestionobjs.push(distqueobj);
                }
                foundqobj = true;
                break;
            }
        }
        if (!foundqobj) {
            toremovequestionobjs.push((original.questionlist[simOr].questionid));
            placesOfQueObjSimilarity.push(simOr);
        }
    }
    for (let rqidx = placesOfQueObjSimilarity.length - 1; rqidx >
        -1; rqidx--) {
        original.questionlist.splice(placesOfQueObjSimilarity[rqidx], 1);
    }
    if (toremovequestionobjs.length != 0) {
        postparams["qsetid"] = changed.id;
        postparams["toremovequestionobjs"] = JSON.stringify(toremovequestionobjs);
    }
    if (tochangedistilledquestionobjs.length != 0) {
        postparams["qsetid"] = changed.id;
        postparams["tochangedistillledquestionobjs"] = JSON.stringify(tochangedistilledquestionobjs);
    }

    return postparams;
}

export function structuralClone(obj) {
    return new Notification('', { data: obj, silent: true }).data;
}




function maketochangelist(original, changed) {
    let distilledqueobj = {};
    distilledqueobj["questionid"] = changed.questionid;
    distilledqueobj["questionnameid"] = -1;
    distilledqueobj["questionname"] = "";
    distilledqueobj["inturn"] = -1;
    distilledqueobj["answeredid"] = -1;

    if (original.inturn != changed.inturn || original.questionnameid != changed.questionnameid || original.questionname != changed.questionname) {
        distilledqueobj.inturn = changed.inturn;
        distilledqueobj.questionnameid = changed.questionnameid;
        distilledqueobj.questionname = changed.questionname;
    }

    if (original.answerlist != changed.answerlist) {
        let answerstoaddlist = [];
        let answerstoremovelist = [];
        let answerstochangelist = [];

        // MAKE ANSWERSTOADDLIST
        for (let changansobj of changed.answerlist) {
            if (changansobj.answerid < 0) {
                answerstoaddlist.push(changansobj);
            }
        }
        if (answerstoaddlist.length != 0) {
            distilledqueobj.answerstoaddlist = answerstoaddlist;
        }

        let placesOfAnsObSimilarity = [];
        for (let simAnsOr = 0; simAnsOr < original.answerlist.length; simAnsOr++) {
            let foundAnsobj = false;
            for (let simAnsCh = 0; simAnsCh < changed.answerlist.length; simAnsCh++) {
                if (original.answerlist[simAnsOr].answerid ==
                    changed.answerlist[simAnsCh].answerid) {
                    if (JSON.stringify(original.answerlist[simAnsOr]) == JSON.stringify(changed.answerlist[simAnsCh])) {
                        placesOfAnsObSimilarity.push(simAnsOr);
                    }
                    else {
                        let distAnsobj = {};
                        distAnsobj["answerid"] = changed.answerlist[simAnsCh].answerid;
                        distAnsobj["answername"] = changed.answerlist[simAnsCh].answername;
                        distAnsobj["answernameid"] = changed.answerlist[simAnsCh].answernameid;
                        distAnsobj["inturn"] = changed.answerlist[simAnsCh].inturn;
                        distAnsobj["grade"] = changed.answerlist[simAnsCh].grade;
                        answerstochangelist.push(distAnsobj);
                    }
                    foundAnsobj = true;
                    break;
                }
            }
            if (!foundAnsobj) {
                answerstoremovelist.push(original.answerlist[simAnsOr].answerid);
                placesOfAnsObSimilarity.push(simAnsOr);
            }
        }
        for (let ransidx = placesOfAnsObSimilarity.length - 1; ransidx > -1; ransidx--) {
            original.answerlist.splice(placesOfAnsObSimilarity[ransidx], 1);
        }

        if (answerstoremovelist.length != 0) {
            distilledqueobj.answerstoremovelist = answerstoremovelist;
        }
        if (answerstochangelist.length != 0) {
            distilledqueobj.answerstochangelist = answerstochangelist;
        }
    }
    return distilledqueobj;
}



export function areSame(object1, object2, whatToExclude) {
    for (let key in object1) {
        if (whatToExclude.indexOf(key) > -1) {
            continue;
        }
        else {
            if (typeof object1[key] === 'object' && object1[key] !== null) {
                if (!areSame(object1[key], object2[key], whatToExclude)) {
                    return false;
                }
            }
            else {
                if (object1[key] != object2[key]) {
                   console.log("compare not same" + key);
                //    console.log("object1 " + key+": "+object1[key]);
                //    console.log("object2 " + key+": "+object2[key]);
                    return false;
                }
            }
        }   
        
    }
    return true;
}

