<template>
    <div class="boxy">
        <PageHeader :with_login="false" />
        <div class="rowy contenty">
            <div class="user_wrapper mt-4">
                <div v-if="user.userid != undefined" class="user_data">
                    <div class="title is-3">
                        Account data
                    </div>
                    <div class="expl_row">
                        <div class="label">Username</div>
                        <div>{{ user.username }}</div>
                    </div>
                    <div class="expl_row">
                        <div class="label">Email</div>
                        <div>{{ user.email }}</div>
                    </div>

                    <div class="expl_row">
                        <div class="label">Location</div>

                        <div v-if="user.userid != undefined" class="button" @click="getCoordinates">{{
                            user.latitude + ", " + user.longitude }}
                        </div>
                    </div>
                    <div class="expl_row">
                        <div class="label">Show advanced options in quest attributes</div>
                        <div> <input v-model="user.isAdvanced" type="checkbox" @change="changeUser" />
                        </div>
                    </div>
                    <div class="expl_row">
                        <div class="label">Show tooltips</div>
                        <div> <input v-model="user.showTooltips" type="checkbox" @change="changeUser" />
                        </div>
                    </div>
                   
                </div>



                <LogButton class="mt-6" />
                <div v-if="isGeneric" class="button is-primary mt-2" @click="goToSignUpGeneric">Update guest account
                </div>
                <div v-if="user.userid != undefined">
                    <hr style="border-top: 3px solid #bbb;">
                    <div>
                        <div class="title is-3">
                            Score
                        </div>
                        <div class="subtitle is-4 mt-4">{{ totalScore }} <span class="icon is-small is-left">
                                <i class="fas fa-star" style="color:yellow;  text-shadow: 1px 1px #1d1717;"></i>
                            </span></div>
                        <div v-for="(mgqScore, idx) of mgqScores" :key="idx">
                            <div class="expl_row">
                                <div>{{ mgqScore.title }}</div>
                                <div>{{ mgqScore.score }}<span class="icon is-small is-left">
                                        <i class="fas fa-star" style="color:yellow;  text-shadow: 1px 1px #1d1717;"></i>
                                    </span></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <PageFooter />
    </div>

</template>
<script>
import { computed, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import * as LocationService from "@/utils/LocationService";
import * as gameMixins from "@/utils/gameMixins";
import * as mixins from "@/utils/mixins";
import * as DBProvider from "@/utils/DBProvider";
import LogButton from '@/components/parts/LogButton.vue';
import PageHeader from '@/components/parts/PageHeader.vue';
import PageFooter from '@/components/parts/PageFooter.vue';
export default {
    components: {
        LogButton,
        PageHeader,
        PageFooter,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const user = ref(null);
        onBeforeMount(async () => {
            user.value = mixins.cloneObject(store.getters["core/getUser"]);
            if (user.value.userid != undefined) {
                mgqScores.value = await store.dispatch("gameApi/fetchMgqScores", {});
                await gameMixins.makeMissingMgqScoresChains(mgqScores.value);
            }
        });

        const isGeneric = computed(() => {
            if (user.value != null && !mixins.getIsEmpty(user.value)) {
                return user.value.email.indexOf("temp.mindglue.io") > -1;
            }
            else return false;
        });

        async function getCoordinates() {
            let coords = await LocationService.askForCoordinates();
            store.commit("core/setUserLatLong", coords);
            user.value = mixins.cloneObject(store.getters["core/getUser"]);

        }

        const totalScore = computed(() => mgqScores.value.reduce(function (accumulator, mgqScore) {
            return accumulator + mgqScore.score;
        }, 0));

        const mgqScores = ref([]);

        function goToSignUpGeneric() {
            store.commit("core/setTemporaryContent", { isSignup: true });
            router.push("/login");

        }

        async function changeUser() {
            store.commit("core/setUser", user.value);
            await DBProvider.writeDBKeyValue("user_info", "user", JSON.stringify(user.value));

        }



        return {
            user,
            mgqScores,
            totalScore,
            getCoordinates,
            changeUser,
            isGeneric,
            goToSignUpGeneric,
        };
    }
}
</script>
<style>
.user_wrapper {
    width: 50%;
    height: 100%;
    margin: auto;
}

/* .user_data {
    text-align: center;
    width: 50%;
} */

.duoplette {
    display: flex;
}
</style>