<template>
  <div>
    <div class="p-4">
      <div class="field">
        <label class="label">Achievement</label>
        <div class="control has-icons-left">
          <input
            class="input"
            :class="{ 'is-danger': wrongAchievement }"
            type="text"
            placeholder="Achievement"
            v-model="achievement"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-bullseye"></i>
          </span>
        </div>
      </div>
      <div class="field">
        <label class="label">Trophy content</label>
        <div class="control has-icons-left">
          <input
            class="input"
            :class="{ 'is-danger': wrongContent }"
            type="text"
            placeholder="Trophy content"
            v-model="content"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-gift"></i>
          </span>
        </div>
      </div>
      <div class="field">
        <label class="label">Trophy provider</label>
        <div class="control has-icons-left">
          <input
            class="input"
            :class="{ 'is-danger': wrongProviderName }"
            type="text"
            placeholder="Trophy provider"
            v-model="providerName"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-store-alt"></i>
          </span>
        </div>
      </div>
      <div class="control">
        <div class="button" @click="removeTrophy">-</div>
      </div>
      <hr />
    </div>
  </div>
</template>
<script>
import { onMounted, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";

export default {
  props: {
    trophy: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    let trophy = ref(null);
    trophy.value = toRefs(props).trophy;
    let achievement = ref("");
    let content = ref("");
    let providerName = ref("");

    let wrongContent = ref(false);
    let wrongAchievement = ref(false);
    let wrongProviderName = ref(false);
    watch(achievement, () => {
      setTrophy();
    });
    watch(content, () => {
      setTrophy();
    });
    watch(providerName, () => {
      setTrophy();
    });

    onMounted(()=>{
      if (trophy.value!=null){
        providerName.value=trophy.value.providerName;
        content.value=trophy.value.content;
        achievement.value=trophy.value.achievement;
      }
    });

    function validateFields() {
      if (!achievement.value) wrongAchievement.value = true;
      else wrongAchievement.value = false;
      if (!content.value) wrongContent.value = true;
      else wrongContent.value = false;
      if (!providerName.value) wrongProviderName.value = true;
      else wrongProviderName.value = false;
      return (
        !wrongAchievement.value && !wrongContent.value && !wrongProviderName.value
      );
    }

    async function removeTrophy() {
            let res = await mixins.alertConfirm("Are you sure you want to delete this trophy?",true)
       if (res ) 
      store.dispatch("core/removeTrophy", trophy.value);
    }

    function setTrophy() {
        store.commit("core/setTrophy", {
          trophy: trophy.value,
          achievement: achievement.value,
          content: content.value,
          providerName: providerName.value,
        });
    }

    return {
      achievement,
      content,
      providerName,
      wrongContent,
      wrongAchievement,
      wrongProviderName,
      validateFields,
      removeTrophy,
    };
  },
};
</script>