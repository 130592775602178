<template>
  <div style="width: 100%; height: 100%">
    <div v-if="isReady" style="width: 100%; height: 100%">
      <WebViewMap ref="webviewMap" :isWebDevice="isWebDevice" @setLocation="setSelectedLocation"/>
    </div>
  </div>
</template>
<script>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";
import WebViewMap from "@/components/parts/WebViewMap.vue";

export default {
  components: {
    WebViewMap,
  },
  setup() {
    /* window.addEventListener("message", handleMessage, false);

    function handleMessage(e) {
      let data = e.data;
    } */

    const store = useStore();

    const isReady = ref(false);
    const mgq = ref({});
    const getSelectedMgq = computed(
      () => store.getters["core/getterSelectedMgq"]
    );
    const message = computed(() => store.getters["core/getOuterMessage"]);
    const isWebDevice = ref(false);

    onBeforeMount(async () => {
      let messageObj = JSON.parse(message.value);
     
      if (messageObj.device == "web") {
        isWebDevice.value = true;
      }
      if (messageObj.mgqId != -1) {
        let mymap = await store.dispatch(
          "core/requestSelectedPresentation",
          messageObj.mgqId.toString()
        );
        mgq.value = JSON.parse(mymap["mgq"]);

        store.commit("core/setSelectedMgq", mixins.cloneObject(mgq.value));
        await retrieveMadeMindsets();
      } else {
        store.commit("core/setSelectedMgq", {
          mindgluequestId: -1,
          longitude: messageObj.longitude.toString(),
          latitude: messageObj.latitude.toString(),
        });
      }

      isReady.value = true;
    });

    const webviewMap = ref(null);

    function setSelectedLocation(location){
      window.parent.postMessage(location, "*");
    }

    function sendMessageToParent() {
      let toSend = {
        id: 123,
        data: "you man",
        engine: { id: 222, map: "iii" },
      };
      window.parent.postMessage(toSend, "*");
    }
    

    async function retrieveMadeMindsets() {
      let success = false;
      if (undefined != getSelectedMgq.value.mindgluequestId) {
        success = await store.dispatch("core/requestMadeMindsets", {
          mindgluequestId: getSelectedMgq.value.mindgluequestId.toString(),
        });
      }
      return success;
    }

    return {
      isReady,
      isWebDevice,
      webviewMap,
      setSelectedLocation,
      sendMessageToParent,
    };
  },
};
</script>
<style>
div {
  box-sizing: border-box;
}
body {
  margin: 0;
}
</style>