<template>
  <div class="boxy">
    <PageHeader :with_login="false" />
    <div class="rowy contenty">
      <div class="pt-4">
        <h1 class="title">{{ isLogin ? "Login" : "Sign up" }}</h1>
      </div>

      <div class="inner_wrapper mt-4">
        <div class="signin_buttons mt-6">
          <div class="circle" :class="{ 'email_selected': withEmail, 'email': !withEmail }"
            @click="withEmail = !withEmail">
            <i class="fas fa-envelope"></i>
          </div>
          <!--   <div class="circle google" id="buttonDivGoogle" @click="googleSignIn">
            <i class="fab fa-google"></i>
          </div> -->

          <!--  <div class="circle apple" @click="appleSignIn">
           <i class="fab fa-apple"></i>
          </div>-->
        </div>

        <div v-if="withEmail" class="mt-4">
          <div class="field">
            <label class="label">Email</label>
            <div class="control has-icons-left">
              <input class="input" type="email" placeholder="Email input" v-model="email" />
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
          </div>
          <div class="field" v-if="!isLogin">
            <label class="label">Username</label>
            <div class="control has-icons-left">
              <input class="input" type="text" placeholder="Username" v-model="username" />
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
          </div>
          <div class="field">
            <label class="label">Password</label>
            <div class="control has-icons-left">
              <input class="input" type="password" placeholder="Password" v-model="password" />
              <span class="icon is-small is-left">
                <i class="fas fa-key"></i>
              </span>
            </div>
          </div>
          <div class="field" v-if="!isLogin">
            <label class="label">Retype Password</label>
            <div class="control has-icons-left">
              <input class="input" type="password" placeholder="Password again" v-model="retypedPassword" />
              <span class="icon is-small is-left">
                <i class="fas fa-key"></i>
              </span>
            </div>
          </div>
          <div class="field" v-if="!isLogin">
            <label class="label">I am at least 16 years old.</label>
            <input type="checkbox" v-model="isAgeProper" />
          </div>
          <div class="control">
            <button class="button is-link mt-4" @click="emailLogin" v-if="isLogin">
              Submit
            </button>
            <button class="button is-link mt-4" @click="emailSignup" v-else>
              Submit
            </button>
          </div>
        </div>

        <div class="mt-6">
          <div class="emphasis" v-if="isLogin ||
            notSamePasswords ||
            notValidEmail ||
            notValidPassword ||
            notValidUsername ||
            notValidAge
          ">


            <p v-if="notSamePasswords" class="has-text-danger">
              The passwords you typed do not match.
            </p>
            <p v-if="notValidEmail" class="has-text-danger">
              The email address you provided is not valid.
            </p>
            <p v-if="notValidPassword" class="has-text-danger">
              The password you entered is not valid. It should consist of
              at least 8 characters and should contain uppercase latin, lowercase latin, numbers and special characters.
            </p>
            <p v-if="notValidUsername" class="has-text-danger">
              The username should not be empty and not contain irregular
              characters like %,^,&amp;,:,;,#.
            </p>
            <p v-if="notValidAge" class="has-text-danger">
              Unfortunately users younger than 16 years old are not allowed to
              join Mindgluequest.
            </p>
            <p v-if="failedLogin" class="has-text-danger">
              Your credentials are not correct. Please try again.
            </p>
            <p v-if="failedSignup" class="has-text-danger">
              Something went wrong with your sign-up attempt. Are you sure you
              haven't used these credentials again?
            </p>
            <p v-if="failedLogin" class="has-text-danger">
              If you have forgotten your password, you can go to your Mindglue
              <span><img src="@/assets/mindglue_logo.svg" width="30" /></span>
              app, in <span><i class="fas fa-cog"></i></span> Settings > Change
              User and then tap on "I have forgotten my password".
            </p>
          </div>
        </div>
        <div class="mt-2 mb-2">
          <p>
            {{
              isLogin
                ? "If you are a new user, you may"
                : "If you have already signed up, you may"
            }}<span class="pl-2"><a @click="isLogin = !isLogin">{{
              isLogin ? "sign up" : "log in"
                }}</a></span>.
          </p>
        </div>

        <div v-if="isLogin" class="mt-2 mb-2">
          <p>
            If you have forgotten your password<span class="pl-2"><a @click="changePassword">click here to change
                it.</a></span>
          </p>
        </div>
        <div v-if="!isLogin" class="mt-2 mb-4">
          <p>
            If you just want to join, you may have us<span class="pl-2"><a @click="makeGeneric">create a generic account
                for you.</a></span>
          </p>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import * as mixins from "@/utils/mixins";
import PageHeader from "@/components/parts/PageHeader.vue";
import PageFooter from "@/components/parts/PageFooter.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as LocationService from "@/utils/LocationService";
import * as DBProvider from "@/utils/DBProvider";
import * as gameMixins from "@/utils/gameMixins";

export default {
  components: {
    PageHeader,
    PageFooter,
  },

  setup() {
    const store = useStore();
    const email = ref("");
    const password = ref("");
    const username = ref("");
    const retypedPassword = ref("");
    const failedLogin = ref(false);
    const failedSignup = ref(false);
    const router = useRouter();
    const withEmail = ref(false);
    const isLogin = ref(true);
    const isAgeProper = ref(false);
    const notSamePasswords = ref(false);
    const notValidEmail = ref(false);
    const notValidPassword = ref(false);
    const notValidUsername = ref(false);
    const notValidAge = ref(false);

    onBeforeMount(() => {
      withEmail.value = true;
      let isSignup = store.getters["core/getTemporaryContent"].isSignup;
      if (isSignup != undefined && isSignup) {
        isLogin.value = false;
        store.commit("core/setTemporaryContent", {});
      }
    });

    async function googleSignIn() {
      let google = window.google;
      google.accounts.id.initialize({
        client_id:
          "783852731391-5e2ufu7rp31h1ct2291dmbs8avf7qbp4.apps.googleusercontent.com",
        callback: googleCallBack,
      });
      google.accounts.id.renderButton(
        document.getElementById("buttonDivGoogle"),
        { theme: "outline", size: "large" } // customization attributes
      );
      google.accounts.id.prompt();
    }

    async function googleCallBack(googleValue) {
      let jsonToken = parseJwt(googleValue["credential"]);

      for (let key in Object.keys(jsonToken)) {
        console.log("google key " + key + "value " + jsonToken[key]);
      }
      let email = jsonToken["email"];
      let username = jsonToken["name"];
      let password = jsonToken["sub"];
      console.log("google json " + jsonToken);

      if (isLogin.value) {
        await tryLogin(email, password, "google");
      } else {
        await trySignup(email, password, username, "", "", "google");
      }
    }

    async function makeGeneric() {
      await trySignup(undefined, undefined, undefined, "", "", "");
    }

    async function appleSignIn() {
      const response = await window.AppleID.auth.signIn();
      if (response) {
        if (response.authorization) {
          let id = response.authorization.id_token.toString();
          let code = response.authorization.code.toString();
          let payload = parseJwt(id);
          let userEmail = "";
          if (undefined != payload.email) {
            if (null != payload.email) {
              if (payload.email != "") {
                userEmail = payload.email;
              }
            }
          }
          let millis = Date.now().toString();
          let email = payload.sub.toString() + "!_*apple*_!" + userEmail;
          let username = "MindglueUser" + millis.substring(6);
          let password = code.length > 25 ? code.substring(0, 25) : code;
          let identityToken = id;
          let authorizationCode = code;
          if (isLogin.value) {
            await tryLogin(email, password, "apple");
          } else {
            await trySignup(
              email,
              password,
              username,
              authorizationCode,
              identityToken,
              "apple"
            );
          }
        }
      }
      return response;
    }

    async function changePassword() {
      let emailGiven = await mixins.makeModalDynamic("DynamicOneTextfield", { title: "Please enter your registered email", isPassword: false });
      let isInterimsSet = await store.dispatch("core/setInterimPassword", { email: emailGiven.content });
      if (isInterimsSet) {
        await mixins.alertConfirm("You were sent an email to change your password! Please refresh this page when you have used the link in the email.", false);
      }
      else {
        await mixins.alertConfirm("Email not found in our system or something else went wrong.", false);

      }
    }

    function parseJwt(token) {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = window.atob(base64).toString("utf8");
      let fixedJson = jsonPayload
        .replace(":false", ':"false"')
        .replace(":true", ':"true"');
      let payload = JSON.parse(fixedJson);
      return payload;
    }

    async function emailLogin() {
      const emailString = email.value.toLowerCase();
      const passString = password.value;
      if (!mixins.validateEmail(emailString)) {
        notValidEmail.value = true;
      }
      if (!notValidEmail.value) {
        await tryLogin(emailString, passString, "");
      }
    }

    async function emailSignup() {
      notValidAge.value = false;
      notValidEmail.value = false;
      notValidUsername.value = false;
      notValidPassword.value = false;
      notSamePasswords.value = false;

      let emailString = email.value.toLowerCase();
      let passString = password.value;
      let retypedPassString = retypedPassword.value;
      let usernameString = username.value;

      if (passString != retypedPassString) {
        notSamePasswords.value = true;
      }
      if (!mixins.validateEmail(emailString)) {
        notValidEmail.value = true;
      }
      if (!mixins.validateUsername(usernameString)) {
        notValidUsername.value = true;
      }
      if (!mixins.validatePassword(passString)) {
        notValidPassword.value = true;
      }
      if (!isAgeProper.value) {
        notValidAge.value = true;
      }
      if (
        !notValidAge.value &&
        !notValidPassword.value &&
        !notValidEmail.value &&
        !notValidUsername.value &&
        !notSamePasswords.value
      ) {
        await trySignup(emailString, passString, usernameString, "", "", "");
      }
    }

    async function tryLogin(email, password, social) {
      let loginAnswer = await store.dispatch("core/loginSignup", {
        email: email,
        password: password,
        social: social,
        comesFromLogin: true,
      });

      if (loginAnswer == "ok!") {
        const compIsLoggedIn = store.getters["core/getUser"].userid != undefined;
        if (compIsLoggedIn) {
          doAfterSuccessfulLoginSignup();
        } else {
          failedLogin.value = true;
        }
      }
      else if (loginAnswer == "askForCoordinates") {
        let coords = await LocationService.askForCoordinates();
        store.commit("core/setUserLatLong", coords);
        await DBProvider.writeDBKeyValue("user_info", "user", JSON.stringify(store.getters["core/getUser"]));
        doAfterSuccessfulLoginSignup();
      }
      else if (loginAnswer == "wrong_pass") {
        await mixins.alertConfirm("The password you provided is not correct.");
      }
      else if (loginAnswer == "checkEmailChangePassword") {
        await mixins.alertConfirm("Please check the email we sent you to change your password.");
      }
      else if (loginAnswer == "wrong_email") {
        await mixins.alertConfirm("The email you provided is not registered.");
      }
      else if (loginAnswer == "duplicate_email") {
        let changePassword = await mixins.alertConfirm("The email given is already registered. Have you forgotten your password?", true);
        if (changePassword) {
          let madeInterimsPass = await store.dispatch("core/setInterimPassword", { email: email });
          if (madeInterimsPass) {
            await mixins.alertConfirm("An email was sent to you to help you change your password.", false);
          }
        }
      }
      else if (loginAnswer == "interim_password") {
        await mixins.alertConfirm("You have to check the email we sent you to change your password (check the spam folder as well).");
      }
    }

    async function doAfterSuccessfulLoginSignup() {

      let mgqScores = await store.dispatch("gameApi/fetchMgqScores", {});
      await gameMixins.makeMissingMgqScoresChains(mgqScores);
      if (Object.keys(store.getters["core/getTemporaryContent"]).length != 0 && store.getters["core/getTemporaryContent"].temporaryRoute != undefined) {
        let tempRoute = store.getters["core/getTemporaryContent"].temporaryRoute;
        store.commit("core/setTemporaryContent", {});
        router.push(tempRoute);
      }
      else {
        router.push("dashboard");
      }
    }

    async function trySignup(
      email,
      password,
      username,
      authorizationCode,
      identityToken,
      social
    ) {
      let successSignup = await store.dispatch("core/loginSignup", {
        email: email,
        password: password,
        username: username,
        authorizationCode: authorizationCode,
        identityToken: identityToken,
        social: social,
        comesFromLogin: true,
        isSignup: true,
      });
      if (successSignup == "ok!") {
        const compIsLoggedIn = store.getters["core/getUser"].userid != undefined;
        if (compIsLoggedIn) {
          doAfterSuccessfulLoginSignup();
        } else {
          failedSignup.value = true;
        }
      }
      else if (successSignup == "askForCoordinates") {
        let coords = await LocationService.askForCoordinates();
        store.commit("core/setUserLatLong", coords);
        await DBProvider.writeDBKeyValue("user_info", "user", JSON.stringify(store.getters["core/getUser"]));
        doAfterSuccessfulLoginSignup();
      }
    }

    return {
      email,
      password,
      withEmail,
      retypedPassword,
      username,
      isLogin,
      notSamePasswords,
      notValidEmail,
      notValidPassword,
      notValidUsername,
      isAgeProper,
      notValidAge,
      emailLogin,
      googleSignIn,
      appleSignIn,
      makeGeneric,
      emailSignup,
      failedLogin,
      failedSignup,
      changePassword,
    };
  },
};
</script>

<style scoped>
.inner_wrapper {
  display: grid;
  justify-items: center;
}

.signin_buttons {
  display: flex;
  gap: 1em;
}

.circle {
  display: flex;
  width: 4em;
  height: 4em;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.google {
  background: #db4437;
  color: white;
}

.facebook {
  background: #4267b2;
  color: white;
}

.email {
  background: #03a8f4;
  color: white;
}

.email_selected {
  background: #83d8ff;
  color: white;
}

.apple {
  background: #000;
  color: white;
}

.emphasis {
  padding: 1em;
  background-color: seashell;
}
</style>
