<template>
    <div>
        <div class="title_descr">{{ mso.payload.title }}</div>
        <div v-html="mso.payload.content"></div>
    </div>
</template>

<script>
import {  onBeforeUnmount, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";

export default {
    setup() {
        const store = useStore();
        const mso = computed(() => store.getters["core/getModalDynamicObject"]);

        onBeforeMount(()=>{
            let obj = mso.value;
            obj.noCancel = true;
            store.commit("core/setModalDynamicObject", obj);
        });
        onBeforeUnmount(() => {
            let obj = mso.value;
            store.commit("core/setModalDynamicObject", obj);
        });


        /*eslint-disable no-unused-vars*/
        function checkSubmit(submitted) {
            return true;
        }

        return {
            mso,
            checkSubmit,
        };
    }
}
</script>
<style>
.title_descr{
    font-weight: bold;
    margin-bottom: .5em;
}
</style>