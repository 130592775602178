<template>
    <div style="height: 100%; position: relative">
        <div class="outer_map_div mb-4">
            <l-map id="map_div" ref="innerDrawMindsetsMap" :zoom="zoom" :center="startCoord" @click="mapClicked"
                @touchstart="mouseHovering" @mousemove="mouseHovering" :use-global-leaflet="true" @zoom="updateZoom"
                :style="'cursor:' + cursorMode" :crs="crs">

                <l-tile-layer :url="tilesUrl" ref="myTileLayer" :maxZoom=99
                    :attribution="openAttribution"></l-tile-layer>

                <!--   <l-tile-layer v-else url="" :minZoom=0 :maxZoom=99 ></l-tile-layer> -->
                <!--  <l-wms-tile-layer
            v-else-if="getSelectedMgq.properties != ''"
            :base-url="baseUrl"
            :layers="getSelectedMgq.properties"
          >
          </l-wms-tile-layer> -->

                <div v-for="(image, idx) in images" :key="idx">
                    <l-image-overlay :url="baseUrl + '/mgqmaps/' + image.properties.name" :bounds="image.latlngs"
                        :interactive="true" @click.left="selectDrawing(image)" @click.right="selectImageToScan(image)"
                        :opacity="selectedDrawing.id == image.id ? 0.6 : mixins.shouldShowOnMap(image.properties.zoomLevels == undefined ? 0 : image.properties.zoomLevels.min, image.properties.zoomLevels == undefined ? 19 : image.properties.zoomLevels.max, currentZoomLevel) ? 1 : 0.3" />
                </div>

                <div v-for="(polygon, idx) in polygons" :key="idx">
                    <l-polygon :lat-lngs="polygon.latlngs" :fill-color="selectedDrawing.id == polygon.id ? 'white' : polygon.fillColor
                " :fillOpacity="polygon.properties.opacity" :color="polygon.borderColor" :stroke="true"
                        :stroke-width="6" :fill="true" @click="selectDrawing(polygon)">
                    </l-polygon>
                </div>
                <div v-for="(rectangle, idx) in rectangles" :key="idx">
                    <l-rectangle :bounds="rectangle.latlngs" :color="rectangle.borderColor"
                        :fillOpacity="rectangle.properties.opacity" :stroke="true" :stroke-width="6" :fill-color="selectedDrawing.id == rectangle.id ? 'white' : rectangle.fillColor
                " :fill="true" @click="selectDrawing(rectangle)"></l-rectangle>
                </div>
                <div v-for="(circle, idx) in circles" :key="idx">
                    <l-circle :lat-lng="circle.latlngs[0]" :radius="mixins.makeDistanceFromLatLng(
                circle.latlngs[0][0],
                circle.latlngs[0][1],
                circle.latlngs[1][0],
                circle.latlngs[1][1],
                noMapBoard == null
            )
                " :fillOpacity="circle.properties.opacity" :color="circle.borderColor" :stroke="true" :stroke-width="6"
                        :fill-color="selectedDrawing.id == circle.id ? 'white' : circle.fillColor
                " :fill="true" @click="selectDrawing(circle)"></l-circle>
                </div>
                <div v-for="(polyline, idx) in polylines" :key="idx">
                    <l-polyline :lat-lngs="polyline.latlngs" :color="selectedDrawing.id == polyline.id ? 'white' : polyline.borderColor
                " :stroke="true" :stroke-width="6" @click="selectDrawing(polyline)">
                    </l-polyline>
                </div>
                <div v-for="(label, idx) in labels" :key="idx">
                    <l-marker :lat-lng="label.latlngs[0]" @click="selectDrawing(label)">
                        <l-icon>
                            <div class="label_icon" :style="'color:' + label.borderColor" style="font-size: 1.5em">
                                {{ label.properties.content }}
                            </div>
                        </l-icon>
                    </l-marker>
                </div>
                <div v-if="isLabelOn && clicksCounter > 0">
                    <l-marker :lat-lng="startPoint">
                        <l-popup>
                            <div class="popup_div">
                                <div>
                                    <div style="width: 5em">
                                        <input class="input" type="text" placeholder="Name" v-model="content" />
                                    </div>
                                </div>
                            </div>
                        </l-popup>
                    </l-marker>
                </div>
                <div v-for="(signal, idx) in signals" :key="idx">
                    <l-marker :lat-lng="signal.latlngs[0]" @click="selectDrawing(signal)" :icon="getSignalIcon(signal)">
                        <l-popup>
                            <div class="popup_div">
                                <div>
                                    <div style="width: 5em">
                                        {{ signal.properties.content }}
                                    </div>
                                </div>
                            </div>
                        </l-popup>
                    </l-marker>
                </div>
                <div v-if="isSignalOn && clicksCounter > 0">
                    <l-marker :lat-lng="startPoint" :icon="getSignalIcon()">
                        <l-popup>
                            <div class="popup_div">
                                <div>
                                    <div style="width: 5em">
                                        <input class="input" type="text" placeholder="Name" v-model="content" />
                                    </div>
                                </div>
                            </div>
                        </l-popup>
                    </l-marker>
                </div>


                <div v-for="(region, idx) in regions" :key="idx">
                    <l-polyline :lat-lngs="region.latlngs"
                        :color="selectedRegion.id == region.id ? 'white' : region.color" :stroke="true"
                        :stroke-width="6" @click="selectRegion(idx)" @popupclose="closeMgqMapRegionPopup">
                        <l-popup>
                            <div class="popup_div">
                                <div @click="
                selectedRegion.id == region.id
                    ? makeMgqMapRegionName(region.name)
                    : null
                " :class="{
                unset_mgq_map_region_name: region.name == '',
                mgq_map_region_name: region.name != '',
            }">
                                    {{
                region.name == "" ? "(Region name not set)" : region.name
            }}
                                </div>
                                <div v-if="showMgqMapNameEdit">
                                    <div>
                                        <input class="input" type="text" placeholder="Name"
                                            v-model="mgqMapRegionName" />
                                    </div>
                                    <div>
                                        <div class="button" @click="setMgqMapRegionName">
                                            <i class="fas fa-save" style="color: blue"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </l-popup>
                    </l-polyline>
                </div>
                <div v-for="(mindsetArray, arrayIndex) in getAllMindsets" :key="arrayIndex">
                    <div v-for="(mindset) in mindsetArray" :key="mindset.id">
                        <div>
                            <l-marker :lat-lng="[
                parseFloat(mindset.latitude),
                parseFloat(mindset.longitude),
            ]" :draggable="true" @click="getMindset(mindset)" @moveend="setCoordinates($event, mindset.id, arrayIndex)"
                                :icon="getIcon(mindset, arrayIndex)">
                                <l-tooltip>
                                    <!-- <div>{{ mindset.properties }}</div> -->
                                    <div>
                                        {{ mindset.title.substr(0, 70) + "..." }}
                                    </div>
                                </l-tooltip>
                            </l-marker>
                        </div>
                    </div>
                </div>
            </l-map>
        </div>
        <div id="mindset_attr" v-if="isMindsetMakerActive">
            <MindsetMaker ref="mindsetAttr" @close="closeMindsetMaker" />
        </div>
    </div>
</template>
<script>
import { getMarker } from "@/assets/marker";
import { getFlag } from "@/assets/getFlag";
import MindsetMaker from "@/components/parts/MindsetMaker.vue";
import L from "leaflet";
import { CRS } from "leaflet";
import { signalMarker } from "@/assets/signalMarker";
import {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    //LControlLayers,
    LTooltip,
    // LWmsTileLayer,
    LPolyline,
    LPolygon,
    LRectangle,
    LCircle,
    LIcon,
    LImageOverlay,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import { ref, computed, toRefs, watch, onBeforeMount, onBeforeUnmount, onUnmounted } from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";
export default {
    components: {
        LMap,
        LTileLayer,
        LMarker,
        // LControlLayers,
        // LWmsTileLayer,
        LTooltip,
        LPolyline,
        LPopup,
        MindsetMaker,
        LPolygon,
        LRectangle,
        LCircle,
        LIcon,
        LImageOverlay,
    },

    props: {
        isRegionDrawingOn: {
            type: Boolean,
            default: false,
        },
        /**
         * DrawingMap props from here on
         */
        isLabelOn: {
            type: Boolean,
            default: false,
        },
        isPolylineOn: {
            type: Boolean,
            default: false,
        },
        isPolygonOn: {
            type: Boolean,
            default: false,
        },
        isRectangleOn: {
            type: Boolean,
            default: false,
        },
        isCircleOn: {
            type: Boolean,
            default: false,
        },
        isImageOn: {
            type: Boolean,
            default: false,
        },
        isNoMapBoardOn: {
            type: Boolean,
            default: true,
        },
        isSignalOn: {
            type: Boolean,
            default: false,
        },
        borderColor: {
            type: String,
            default: "",
        },
        fillColor: {
            type: String,
            default: "",
        },
        opacity: {
            type: Number,
            default: 1.0,
        },
        /*  withCenterMarker: {
             type: Boolean,
             required: false,
             default: false,
         }, */
    },

    setup(props, { emit }) {
        /**
         * DrawingMap consts
         */
        const innerDrawMindsetsMap = ref(null);
        const myTileLayer = ref(null);
        const clicksCounter = ref(0);
        const numOfNecessaryClicks = ref(0);
        const startPoint = ref([0.0, 0.0]);
        const endPoint = ref([0.0, 0.0]);
        const constrainedEndPoint = ref([0.0, 0.0]);
        const isLabelOn = toRefs(props).isLabelOn;
        const isPolylineOn = toRefs(props).isPolylineOn;
        const isPolygonOn = toRefs(props).isPolygonOn;
        const isRectangleOn = toRefs(props).isRectangleOn;
        const isCircleOn = toRefs(props).isCircleOn;
        const isImageOn = toRefs(props).isImageOn;
        const isNoMapBoardOn = toRefs(props).isNoMapBoardOn;
        const isSignalOn = toRefs(props).isSignalOn;
        const borderColor = toRefs(props).borderColor;
        const fillColor = toRefs(props).fillColor;
        const opacity = toRefs(props).opacity;
        const imageWidthToHeightRatio = ref(1);
        const imageFile = ref(null);
        const imageName = ref("");
        const mgqId = ref(0);
        const centerMarkerCoords = ref([]);
        //        const getUser = computed(() => store.getters["core/getUser"]);
        const myDrawing = ref({
            latlngs: [],
            fillColor: fillColor,
            borderColor: borderColor,
            keys: [],
            properties: {},
        });
        const content = ref("");
        const latLongPhysicalRatio = Number(1.26);
        let hasRemovedAgain = false;

        //  const innerSlot = ref(null);

        /**
         * End of DrawingMap consts
         */
        const currentZoomLevel = ref(0);
        const store = useStore();
        const zoom = ref(12);
        const isMindsetMakerActive = ref(false);
        const getAllMindsets = computed(
            () => store.getters["core/getFirstMindsets"]
        );
        const getMindsetToShow = computed(
            () => store.getters["core/getMindsetToShow"]
        );
        const isRegionDrawingOn = toRefs(props).isRegionDrawingOn;
        /*  const withMapBackground = computed(
             () => getSelectedMgq.value.properties == "real"
         ); */
        //  const shouldOpenRVM = ref(true);

        /*
        DrawingMap watches
         */
        watch(
            () => isLabelOn.value,
            async () => {
                if (!isLabelOn.value && myDrawing.value.latlngs.length > 0) {
                    await saveDrawing("label");
                }
            }
        );
        watch(
            () => isPolylineOn.value,
            async () => {
                if (!isPolylineOn.value && myDrawing.value.latlngs.length > 0) {
                    await saveDrawing("polyline");
                }
            }
        );
        watch(
            () => isRectangleOn.value,
            async () => {
                if (!isRectangleOn.value && myDrawing.value.latlngs.length > 0) {
                    await saveDrawing("rectangle");
                }
            }
        );
        watch(
            () => isPolygonOn.value,
            async () => {
                if (!isPolygonOn.value && myDrawing.value.latlngs.length > 0) {
                    await saveDrawing("polygon");
                }
            }
        );
        watch(
            () => isCircleOn.value,
            async () => {
                if (!isCircleOn.value && myDrawing.value.latlngs.length > 0) {
                    await saveDrawing("circle");
                }
            }
        );
        watch(
            () => isNoMapBoardOn.value,
            async () => {

                if (isNoMapBoardOn.value) {
                    if (noMapBoard.value == null) {

                        await saveDrawing("noMapBoard");
                    }
                }
                else {
                    selectDrawing(noMapBoard.value)
                    emit("deleteDrawing");
                }
            }
        );
        watch(
            () => isImageOn.value,
            async () => {
                if (!isImageOn.value && myDrawing.value.latlngs.length > 0) {
                    await mixins.saveMgqMapImage(imageFile.value, imageName.value, getSelectedMgq.value.mindgluequestId, false);
                    await saveDrawing("image");
                    await deleteTempMgqImage();
                } else if (isImageOn.value) {
                    await pickImage();
                }
            }
        );
        watch(
            () => isSignalOn.value,
            async () => {
                if (!isSignalOn.value && myDrawing.value.latlngs.length > 0) {
                    await saveDrawing("signal");
                }
            }
        );
        /**
         * End of DrawingMap watches
         */


        watch(
            () => isRegionDrawingOn.value,
            () => {
                if (!isRegionDrawingOn.value && myPolyline.value.latlngs.length > 0) {
                    saveRegion();
                }
            }
        );

        watch(
            () => getMindsetToShow.value,
            () => {
                if (undefined != getMindsetToShow.value) {
                    if (Object.keys(getMindsetToShow.value).length > 0) {

                        innerDrawMindsetsMap.value.leafletObject.setView(new L.LatLng(parseFloat(getMindsetToShow.value.latitude), parseFloat(getMindsetToShow.value.longitude)), 17);
                    }
                }
            }
        );



        onBeforeUnmount(async () => {
            await deleteTempMgqImage();
        });

        onUnmounted(() => {
            if (null != innerDrawMindsetsMap.value) {
                innerDrawMindsetsMap.value.leafletObject.off();
                innerDrawMindsetsMap.value.leafletObject.remove();
            }
        });

        onBeforeMount(async () => {
            startCoord.value.push(parseFloat(getSelectedMgq.value.latitude));
            startCoord.value.push(parseFloat(getSelectedMgq.value.longitude));
            if (undefined != getSelectedMgq.value) {
                if (undefined != getSelectedMgq.value.mindgluequestId) {
                 //   await mixins.fetchMgqDrawings(getSelectedMgq.value);
                    if (noMapBoard.value != null) {
                        emit("noMapBoardOn");
                    }

                }
            }
            fetchMapRegions();
        });

        const myPolyline = ref({
            latlngs: [],
            color: "red",
        });
        const regions = ref([]);

        const selectedRegion = ref({});
        const selectedDrawing = ref({});
        const cursorMode = computed(() =>
            isRegionDrawingOn.value ? "crosshair" : "pointer"
        );

        /* function getSignalIcon() {
            let icon = new L.divIcon({
                className: "",
                html: `<div>${signalMarker(false)}</div>`,
            });
            return icon;
        } */

        const mgqMapRegionName = ref("");
        const showMgqMapNameEdit = ref(false);
        const colorUnmarked = [
            "0063B0",
            "FA8072",
            "808000",
            "800000",
            "008000",
            "00FFFF",
            "0000FF",
            "000080",
            "FF00FF",
            "808080",
            "C0C0C0",
            "FFFFFF",
        ];
        const polylines = ref(mixins.polylines);
        const circles = ref(mixins.circles);
        const rectangles = ref(mixins.rectangles);
        const polygons = ref(mixins.polygons);
        const labels = ref(mixins.labels);
        const noMapBoard = ref(mixins.noMapBoard);
        const tilesUrl = computed(() => noMapBoard.value == null ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" : "");
        const images = ref(mixins.images);
        const signals = ref(mixins.signals);
        const baseUrl = mixins.baseUrl;
        const openAttribution =
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors';
        const getSelectedMgq = computed(
            () => store.getters["core/getterSelectedMgq"]
        );


        // const baseUrl = "https://application.mindglue.io/geoserver/cite/wms?";

        /* const startIds = computed(() => {
          if (
            mixins.markedArray.value.length > 0 &&
            mixins.markedArray.value[0].length > 0
          ) {
            return mixins.markedArray.value[0];
          } else return [0];
        }); */

        const crs = computed(() => {
            let toReturn = CRS.EPSG3857;
            if (noMapBoard.value != null) {
                toReturn = CRS.Simple;
            }
            return toReturn;
        });

        const isRealMap = computed(() => getSelectedMgq.value.properties != "real");

        /* const getFirst = computed(() => {
          if (getAllMindsets.value[0].filter((x) => x.id == startIds.value[0])) {
            return getAllMindsets.value[0].filter((x) => x.id == startIds.value[0]);
          } else return null;
        }); */

        const startCoord = ref([

        ]);

        /*   const startFlagSvg = ref(startFlag);
        const myIconStart = L.divIcon({
          className: "",
          html: `<div>${startFlagSvg.value}</div>`,
          iconAnchor: [13, 37],
        }); */

        /*  const finishFlagSvg = ref(finishFlag);
        const myIconFinish = L.divIcon({
          className: "",
          html: `<div>${finishFlagSvg.value}</div>`,
          iconAnchor: [13, 37],
        }); */


        /**DrawingMap functions
         * 
         */
        function getSignalIcon(drawing) {
            let selected = false;
            if (null != drawing) {
                selected = selectedDrawing.value.id == drawing.id;
            }

            let icon = new L.divIcon({
                // iconAnchor: [20, 20],
                className: "",
                html: `<div>${signalMarker(selected)}</div>`,
            });
            return icon;
        }

        async function mapClicked(e) {
            if (
                isLabelOn.value ||
                isPolylineOn.value ||
                isPolygonOn.value ||
                isRectangleOn.value ||
                isCircleOn.value ||
                isImageOn.value ||
                isSignalOn.value
            ) {
                if (undefined != e.latlng) {
                    clicksCounter.value = clicksCounter.value + 1;

                    if (numOfNecessaryClicks.value == 0) {
                        if (isRectangleOn.value || isCircleOn.value || isImageOn.value) {
                            numOfNecessaryClicks.value = 2;
                        } else if (isPolylineOn.value || isPolygonOn.value) {
                            numOfNecessaryClicks.value = 1000;
                        } else if (isLabelOn) {
                            numOfNecessaryClicks.value = 1;
                        }
                    }
                    if (isLabelOn.value) {
                        makeLabel(e);
                    } else if (isPolylineOn.value) {
                        makePolyline(e);
                    } else if (isRectangleOn.value) {
                        makeRectangle(e);
                    } else if (isPolygonOn.value) {
                        makePolygon(e);
                    } else if (isCircleOn.value) {
                        makeCircle(e);
                    } else if (isImageOn.value) {
                        makeImage(e);
                    } else if (isSignalOn.value) {
                        makeSignal(e);
                    }
                }
            }
            else {
                if (isRegionDrawingOn.value) {
                    makeShape(e);
                }
            }
        }

        function renewClicks() {
            clicksCounter.value = 0;
            numOfNecessaryClicks.value = 0;
        }

        function renewDrawings() {
            renewClicks();
            myDrawing.value.latlngs = [];
            myDrawing.value.keys = [];
            clicksCounter.value = 0;
            numOfNecessaryClicks.value = 0;
            startPoint.value = [0.0, 0.0];
            endPoint.value = [0.0, 0.0];
            constrainedEndPoint.value = [0.0, 0.0];
            imageFile.value = null;
            imageName.value = "";
            content.value = "";
        }

        function mouseHovering(e) {
            if (
                clicksCounter.value < numOfNecessaryClicks.value &&
                clicksCounter.value > 0
            ) {
                if (e.latlng != undefined) {
                    endPoint.value = [e.latlng.lat, e.latlng.lng];
                    if (isPolylineOn.value) {
                        makeChangingPolyline(e);
                    } else if (isRectangleOn.value) {
                        makeChangingRectangle(e);
                    } else if (isPolygonOn.value) {
                        makeChangingPolygon(e);
                    } else if (isCircleOn.value) {
                        makeChangingCircle(e);
                    } else if (isImageOn.value) {
                        makeChangingImage(e);
                    }
                }
            }
        }

        function removeLayerBeforeLast(beforeTimes) {
            let entries = Object.entries(innerDrawMindsetsMap.value.leafletObject._layers);
            if (undefined != entries[entries.length - beforeTimes]) {
                let keyToDelete = entries[entries.length - beforeTimes][0];
                innerDrawMindsetsMap.value.leafletObject.removeLayer(
                    innerDrawMindsetsMap.value.leafletObject._layers[keyToDelete]
                );
            }
        }

        function getKeyOfJustAddedLayerObject() {
            let entries = Object.entries(innerDrawMindsetsMap.value.leafletObject._layers);
            if (undefined != entries[entries.length - 1]) {
                return entries[entries.length - 1][0];
            } else return "";
        }

        function makeChangingPolyline() {
            new L.Polyline([startPoint.value, endPoint.value]).addTo(
                innerDrawMindsetsMap.value.leafletObject
            );
            removeLayerBeforeLast(2);
        }

        function makeChangingPolygon() {
            new L.Polygon([startPoint.value, endPoint.value]).addTo(
                innerDrawMindsetsMap.value.leafletObject
            );
            removeLayerBeforeLast(2);
        }

        function makeChangingRectangle() {
            let bounds = new L.latLngBounds(startPoint.value, endPoint.value);
            let rectangle = new L.Rectangle(bounds);
            rectangle.addTo(innerDrawMindsetsMap.value.leafletObject);
            if (hasRemovedAgain) {
                removeLayerBeforeLast(2);
            } else {
                hasRemovedAgain = true;
            }
        }

        function makeChangingImage() {
            let isUpwards = endPoint.value[0] - startPoint.value[0] > 0;
            let isToEast = endPoint.value[1] - startPoint.value[1] > 0;

            let dist1 = mixins.makeDistanceFromLatLng(
                startPoint.value[0],
                startPoint.value[1],
                endPoint.value[0],
                endPoint.value[1],
                true
            );
            let dist2 = mixins.makeDistanceFromLatLng(
                startPoint.value[0],
                startPoint.value[1],
                endPoint.value[0],
                endPoint.value[1],
                true
            ) *
                imageWidthToHeightRatio.value;
            constrainedEndPoint.value = [
                mixins.makeOneCoordFromDistance(
                    startPoint.value[0],
                    isUpwards,
                    dist1
                ),
                mixins.makeOneCoordFromDistance(
                    startPoint.value[1],
                    isToEast,
                    dist2 *
                    latLongPhysicalRatio
                ),
            ];
            let bounds = new L.latLngBounds(
                startPoint.value,
                constrainedEndPoint.value
            );
            let rectangle = new L.Rectangle(bounds);
            rectangle.addTo(innerDrawMindsetsMap.value.leafletObject);

            let dimsMarker = L.marker([startPoint.value[0], startPoint.value[1]], {
                icon: L.divIcon({
                    iconSize: "auto",
                    className: 'dummy',
                    html: "H:" + Math.trunc(dist1) + "m " + "W:" + Math.trunc(dist2) + "m"
                })
            });
            dimsMarker.addTo(innerDrawMindsetsMap.value.leafletObject);
            if (hasRemovedAgain) {
                removeLayerBeforeLast(3);
                removeLayerBeforeLast(3);
            } else {
                hasRemovedAgain = true;
            }
        }

        function makeChangingCircle(e) {
            let radius = mixins.makeDistanceFromLatLng(
                startPoint.value[0],
                startPoint.value[1],
                e.latlng.lat,
                e.latlng.lng,
                noMapBoard.value == null
            );
            let circle = new L.Circle(startPoint.value, { radius: radius });
            circle.addTo(innerDrawMindsetsMap.value.leafletObject);
            if (hasRemovedAgain) {
                removeLayerBeforeLast(2);
            } else {
                hasRemovedAgain = true;
            }
        }

        function makeLabel(e) {
            startPoint.value = [e.latlng.lat, e.latlng.lng];
            if (clicksCounter.value == numOfNecessaryClicks.value) {
                if (e.latlng != undefined) {
                    myDrawing.value.latlngs.push([e.latlng.lat, e.latlng.lng]);
                    myDrawing.value.keys.push({
                        key: getKeyOfJustAddedLayerObject(),
                        drawingType: "label",
                    });
                }
            }
        }

        function makeSignal(e) {
            startPoint.value = [e.latlng.lat, e.latlng.lng];
            if (clicksCounter.value == numOfNecessaryClicks.value) {
                if (e.latlng != undefined) {
                    myDrawing.value.latlngs.push([e.latlng.lat, e.latlng.lng]);
                    myDrawing.value.keys.push({
                        key: getKeyOfJustAddedLayerObject(),
                        drawingType: "signal",
                    });
                }
            }
        }

        function makePolyline(e) {
            startPoint.value = [e.latlng.lat, e.latlng.lng];
            if (clicksCounter.value < numOfNecessaryClicks.value) {
                if (e.latlng != undefined) {
                    let polyline = new L.Polyline(e.latlng.lat, e.latlng.lng);
                    polyline.on("click", polyClicked);
                    polyline.addTo(innerDrawMindsetsMap.value.leafletObject);

                    myDrawing.value.latlngs.push([e.latlng.lat, e.latlng.lng]);
                    myDrawing.value.keys.push({
                        key: getKeyOfJustAddedLayerObject(),
                        drawingType: "polyline",
                    });
                    let clickPoint = L.circleMarker(e.latlng);
                    clickPoint.on("click", polyClicked);
                    clickPoint.addTo(innerDrawMindsetsMap.value.leafletObject);
                    let clickPoint1 = L.circleMarker(e.latlng);
                    clickPoint1.on("click", polyClicked);
                    clickPoint1.addTo(innerDrawMindsetsMap.value.leafletObject);
                }
            }
        }

        function makePolygon(e) {
            startPoint.value = [e.latlng.lat, e.latlng.lng];
            if (clicksCounter.value < numOfNecessaryClicks.value) {
                if (e.latlng != undefined) {
                    let polygon = new L.Polygon(e.latlng.lat, e.latlng.lng);
                    polygon.on("click", polyClicked);
                    polygon.addTo(innerDrawMindsetsMap.value.leafletObject);

                    myDrawing.value.latlngs.push([e.latlng.lat, e.latlng.lng]);
                    myDrawing.value.keys.push({
                        key: getKeyOfJustAddedLayerObject(),
                        drawingType: "polygon",
                    });
                    /* myDrawing.value.properties = JSON.stringify({
                        opacity: Number(opacity.value),
                    }); */
                    myDrawing.value.properties.opacity = Number(opacity.value);


                    let clickPoint = L.circleMarker(e.latlng);
                    clickPoint.on("click", polyClicked);
                    clickPoint.addTo(innerDrawMindsetsMap.value.leafletObject);
                    let clickPoint1 = L.circleMarker(e.latlng);
                    clickPoint1.on("click", polyClicked);
                    clickPoint1.addTo(innerDrawMindsetsMap.value.leafletObject);
                }
            }
        }

        function makeImage(e) {
            if (null != imageFile.value) {
                let baseTempImageUrl = baseUrl + "/tempmgqmaps/";
                if (clicksCounter.value > 1) {
                    if (clicksCounter.value == numOfNecessaryClicks.value) {
                        if (e.latlng != undefined) {
                            let bounds = new L.latLngBounds(
                                startPoint.value,
                                constrainedEndPoint.value
                            );
                            let image = new L.ImageOverlay(
                                baseTempImageUrl +
                                getSelectedMgq.value.mindgluequestId.toString() +
                                "_" +
                                imageName.value,
                                bounds,
                                { opacity: 1 }
                            );
                            image.addTo(innerDrawMindsetsMap.value.leafletObject);
                            myDrawing.value.latlngs.push([
                                startPoint.value[0],
                                startPoint.value[1],
                            ]);
                            myDrawing.value.latlngs.push([
                                constrainedEndPoint.value[0],
                                constrainedEndPoint.value[1],
                            ]);
                            /*  myDrawing.value.properties = JSON.stringify({
                                 name:
                                     getSelectedMgq.value.mindgluequestId.toString() +
                                     "_" +
                                     imageName.value,
                             }); */
                            myDrawing.value.properties.name =
                                getSelectedMgq.value.mindgluequestId.toString() + "_" + imageName.value;
                            myDrawing.value.keys.push({
                                key: getKeyOfJustAddedLayerObject(),
                                drawingType: "image",
                            });
                        }
                        hasRemovedAgain = false;
                    }
                } else {
                    startPoint.value = [e.latlng.lat, e.latlng.lng];
                }
            } else {
                emit("invalidateImageOn");
            }
        }

        function polyClicked() {
            if (clicksCounter.value < numOfNecessaryClicks.value) {
                removeLayerBeforeLast(1);
                removeLayerBeforeLast(1);
                removeLayerBeforeLast(1);
                removeLayerBeforeLast(1);
                myDrawing.value.latlngs.pop();
                numOfNecessaryClicks.value = clicksCounter.value;
            }
        }

        function makeRectangle(e) {
            if (clicksCounter.value > 1) {
                if (clicksCounter.value == numOfNecessaryClicks.value) {
                    if (e.latlng != undefined) {
                        let bounds = new L.latLngBounds(startPoint.value, endPoint.value);
                        let rectangle = new L.Rectangle(bounds);
                        rectangle.addTo(innerDrawMindsetsMap.value.leafletObject);
                        myDrawing.value.latlngs.push([
                            startPoint.value[0],
                            startPoint.value[1],
                        ]);
                        myDrawing.value.latlngs.push([e.latlng.lat, e.latlng.lng]);
                        myDrawing.value.keys.push({
                            key: getKeyOfJustAddedLayerObject(),
                            drawingType: "rectangle",
                        });
                        /*   myDrawing.value.properties = JSON.stringify({
                              opacity: Number(opacity.value),
                          }); */
                        myDrawing.value.properties.opacity = Number(opacity.value);

                    }
                    hasRemovedAgain = false;
                }
            } else {
                startPoint.value = [e.latlng.lat, e.latlng.lng];
            }
        }

        function makeCircle(e) {
            if (clicksCounter.value > 1) {
                if (clicksCounter.value == numOfNecessaryClicks.value) {
                    if (e.latlng != undefined) {
                        let radius = mixins.makeDistanceFromLatLng(
                            startPoint.value[0],
                            startPoint.value[1],
                            e.latlng.lat,
                            e.latlng.lng,
                            noMapBoard.value == null
                        );
                        let circle = new L.Circle(startPoint.value, { radius: radius });
                        circle.addTo(innerDrawMindsetsMap.value.leafletObject);
                        myDrawing.value.latlngs.push([
                            startPoint.value[0],
                            startPoint.value[1],
                        ]);
                        myDrawing.value.latlngs.push([e.latlng.lat, e.latlng.lng]);
                        myDrawing.value.keys.push({
                            key: getKeyOfJustAddedLayerObject(),
                            drawingType: "circle",
                        });
                        /*   myDrawing.value.properties = JSON.stringify({
                              opacity: Number(opacity.value),
                          }); */
                        myDrawing.value.properties.opacity = Number(opacity.value);
                    }
                    hasRemovedAgain = false;
                }
            } else {
                startPoint.value = [e.latlng.lat, e.latlng.lng];
            }
        }

        async function saveDrawing(type) {
            let goOn = false;
            if (type == "label" || type == "signal") {
                if (content.value != "") {
                   /*  myDrawing.value.properties = JSON.stringify({
                        content: content.value,
                    }); */
                    myDrawing.value.properties.content = content.value;
                    goOn = true;
                }
            }
            else if (type == "image") {
                let imgZoomLevels = await mixins.makeModalDynamic("DynamicImageZoomLevels", {});
                if (null == imgZoomLevels) {
                    imgZoomLevels = { min: 0, max: 19 };
                }
              /*   let imgProperties = JSON.parse(myDrawing.value.properties);
                imgProperties.zoomLevels = imgZoomLevels; */
                //myDrawing.value.properties = JSON.stringify(imgProperties);
                myDrawing.value.properties.zoomLevels = imgZoomLevels;
                goOn = true;
            }
            else if (type == "noMapBoard") {
                let noMapColor = await mixins.makeModalDynamic("DynamicColorChooser", { title: "Please select the map board color." });
                if (noMapColor == null || noMapColor.content.length < 7) {
                    noMapColor = { content: "#ffffff" };
                }
                //  myDrawing.value.properties = JSON.stringify({ noMapColor: noMapColor.content });
                myDrawing.value.properties.noMapColor = noMapColor.content;
                goOn = true;
            }
            else {
                goOn = true;
            }
            if (goOn) {
                await store.dispatch("core/saveMgqDrawing", {
                    id: 0,
                    mindgluequestId: getSelectedMgq.value.mindgluequestId,
                    coordinates: JSON.stringify(myDrawing.value.latlngs),
                    borderColor: borderColor.value,
                    fillColor: fillColor.value,
                    properties: JSON.stringify(myDrawing.value.properties),
                    drawingType: type,
                });
                renewDrawings();
                mixins.removeAllLayersFromMap(innerDrawMindsetsMap, myTileLayer);
                await mixins.fetchMgqDrawings(getSelectedMgq.value);
            }
        }

        function selectDrawing(drawing) {
            if (selectedDrawing.value.id == drawing.id) {
                selectedDrawing.value = {};
            } else {
                selectedDrawing.value = drawing;
            }
        }

        async function pickImage() {
            var input = document.createElement("input");
            input.type = "file";
            input.accept = ".svg,.jpeg,.jpg,.png";
            input.onchange = async (e) => {
                imageFile.value = e.target.files[0];
                imageName.value =
                    (images.value.length + 1).toString() + "_" + e.target.files[0].name;
                if (null != e.target.files[0].name) {
                    mgqId.value = getSelectedMgq.value.mindgluequestId;
                    await mixins.saveMgqMapImage(imageFile.value, imageName.value, getSelectedMgq.value.mindgluequestId, true);
                    imageWidthToHeightRatio.value = mixins.makeImageWidthHeightRatio(imageFile.value);
                    /* let reader = new FileReader();
                    reader.readAsDataURL(imageFile.value);
                    reader.onload = function (e) {
                        let image = new Image();
                        image.src = e.target.result;
                        image.onload = function () {
                            imageWidthToHeightRatio.value = Number(
                                Number(this.width) / Number(this.height)
                            );
                        };
                    }; */

                } else {
                    isImageOn.value = false;
                }
            };
            input.click();
        }



        async function deleteTempMgqImage() {
            if (myDrawing.value.properties) {
                let parsedProperties = myDrawing.value.properties;
                if (parsedProperties.name) {
                    store.dispatch("core/deleteTempMgqMapImage", parsedProperties.name);
                }
            }
        }

        function getFirstCoordinates(e) {
            mixins.showMapRight(true, innerDrawMindsetsMap.value);
            centerMarkerCoords.value = [
                mixins.makeCoordinates(e.target._latlng.lat),
                mixins.makeCoordinates(e.target._latlng.lng),
            ];
            emit("centerMarkerChanged", centerMarkerCoords.value);
        }





        async function fetchMapRegions() {
            selectedRegion.value = {};
            let savedRegions = await store.dispatch("core/requestMgqMapRegions");
            regions.value = [];
            if (savedRegions) {
                for (let mapRegion of savedRegions) {
                    let ltlngs = [];
                    try {
                        ltlngs = JSON.parse(mapRegion.coordinates);
                    }
                    catch (e) {
                        console.log("fetchMapRegions error");
                    }
                    ltlngs =
                        regions.value.push({
                            latlngs: ltlngs,
                            color: mapRegion.color,
                            id: mapRegion.id,
                            name: mapRegion.name,
                        });
                }
            }
        }



        function makeShape(e) {
            if (isRegionDrawingOn.value) {
                if (e.latlng != undefined) {
                    myPolyline.value.latlngs.push([e.latlng.lat, e.latlng.lng]);
                    // eslint-disable-next-line no-unused-vars
                    var polyline = new L.Polyline(myPolyline.value.latlngs).addTo(
                        innerDrawMindsetsMap.value.leafletObject
                    );
                }
            }
        }

        async function saveRegion() {
            regions.value.push(myPolyline.value);
            await store.dispatch("core/saveMgqMapRegion", {
                id: 0,
                mindgluequestId: getSelectedMgq.value.mindgluequestId,
                coordinates: JSON.stringify(myPolyline.value.latlngs),
                color: "#" + getColorFromColors(regions.value.length - 1),
                name: "",
            });
            myPolyline.value.latlngs = [];
            await fetchMapRegions();
        }

        function selectRegion(idx) {
            if (!isRegionDrawingOn.value) {
                if (selectedRegion.value == regions.value[idx]) {
                    selectedRegion.value = {};
                } else {
                    selectedRegion.value = regions.value[idx];
                }
            }
        }

        async function selectImageToScan(image) {
            const img = new Image();
            img.onload = async function () {
                let payload = {
                    imageName: image.name,
                    coordinates: image.latlngs,
                    width: this.width,
                    height: this.height,
                };
                store.commit("core/setTemporaryContent", payload);
            };
            img.src = baseUrl + "/mgqmaps/" + image.name;
        }

        function getIcon(mindset, arrayIndex) {
            if (mindset.isStart) return getFlagIcon(true, mindset.isSelected);
            else if (mindset.targeted) return getFlagIcon(false, mindset.isSelected);
            else {
                const markerSvg = mindset.isSelected
                    ? ref(getMarker("FF3333", true))
                    : mindset.marked
                        ? ref(getMarker("8B008B", true))
                        : ref(getMarker(getColorFromColors(arrayIndex), true));
                const myIcon = L.divIcon({
                    className: "",
                    html: `<div>${markerSvg.value}</div>`,
                    iconAnchor: [13, 13],
                });
                return myIcon;
            }
        }

        function getFlagIcon(isStart, isSelected) {
            let color = isStart
                ? isSelected
                    ? "1cedd1"
                    : "00cf16"
                : isSelected
                    ? "cf7700"
                    : "f50000";
            const myFlag = ref(getFlag(color));
            const myIcon = L.divIcon({
                className: "",
                html: `<div>${myFlag.value}</div>`,
                iconAnchor: [13, 37],
            });
            return myIcon;
        }

        function getColorFromColors(index) {
            if (index > 11) return colorUnmarked[index % 11];
            else return colorUnmarked[index];
        }


        function setCoordinates(event, id, arrayIndex) {
            store.commit("core/setSelectedMindsetsArrayIndex", { index: arrayIndex });
            mixins.showMapRight(true, innerDrawMindsetsMap.value);
            let payload = {
                latitude: makeCoordinates(event.target._latlng.lat),
                longitude: makeCoordinates(event.target._latlng.lng),
                mindsetId: id,
            };
            store.commit("core/setMindsetCoordinates", payload);
        }

        function makeMgqMapRegionName(name) {
            mgqMapRegionName.value = name;
            showMgqMapNameEdit.value = true;
        }

        function closeMgqMapRegionPopup() {
            showMgqMapNameEdit.value = false;
            mgqMapRegionName.value = "";
        }

        async function setMgqMapRegionName() {
            if (
                selectedRegion.value != undefined &&
                Object.keys(selectedRegion.value).length > 0
            ) {
                if (checkIfCanUseMapRegionName(mgqMapRegionName.value)) {
                    let success = await store.dispatch("core/saveMgqMapRegionName", {
                        mgqMapRegionName: mgqMapRegionName.value.trim(),
                        mgqMapRegionId: selectedRegion.value.id,
                    });
                    if (success) {
                        closeMgqMapRegionPopup();
                        await fetchMapRegions();
                        await mixins.alertConfirm("Saved region name", false);
                    } else {
                        await mixins.alertConfirm("Unfortunately an error occured.", false);
                    }
                } else {
                    await mixins.alertConfirm(
                        "You cannot use the same name for two regions.",
                        false
                    );
                }
            } else {
                await mixins.alertConfirm(
                    "No region is selected. Please click on a region to mark it selected.",
                    false
                );
            }
        }

        function checkIfCanUseMapRegionName(newname) {
            let toReturn = true;
            for (let region of regions.value) {
                if (region.name == newname) {
                    toReturn = false;
                    break;
                }
            }
            return toReturn;
        }

        function makeCoordinates(coord) {
            let thisCoord = String(coord);
            let parts = thisCoord.split(".");
            if (parts.length > 1) {
                if (parts[1].length < 6) {
                    let missingLength = 6 - parts[1].length;
                    for (let i = 0; i < missingLength - 1; i++) {
                        thisCoord = thisCoord.concat("0");
                    }
                    thisCoord = thisCoord.concat("1");
                } else {
                    let decimals = parts[1].substr(0, 6);
                    thisCoord = parts[0].concat(".").concat(decimals);
                }
            } else {
                thisCoord = thisCoord.concat(".000001");
            }

            return thisCoord;
        }

        function setSearchedCoordinates(coordsPayload) {
            innerDrawMindsetsMap.value.leafletObject.setView(new L.LatLng(parseFloat(coordsPayload.lat), parseFloat(coordsPayload.long)), 17);
        }


        function getMindset(mindset) {
            mixins.setSelectedArrayMindset(mindset, true);
            isMindsetMakerActive.value = true;
        }

        function closeMindsetMaker() {
            isMindsetMakerActive.value = false;
            innerDrawMindsetsMap.value.leafletObject.eachLayer(function (l) {
                if (l.getTooltip()) {
                    var tooltip = l.getTooltip();
                    l.unbindTooltip().bindTooltip(tooltip, {
                        permanent: true,
                    });
                    l.unbindTooltip().bindTooltip(tooltip, {
                        permanent: false,
                    });
                }
            });
        }


        function updateZoom() {
            currentZoomLevel.value = innerDrawMindsetsMap.value.leafletObject.getZoom();
            emit("updateZoom", currentZoomLevel.value);
        }


        const noMapBackground = computed(() => noMapBoard.value != null ? noMapBoard.value.properties.noMapColor : "#ffffff");

        function editImage() {
            console.log("down");
        }


        return {
            innerDrawMindsetsMap,
            getSelectedMgq,
            clicksCounter,
            mouseHovering,
            startPoint,
            endPoint,
            crs,
            numOfNecessaryClicks,
            myDrawing,
            selectedDrawing,
            mapClicked,
            content,
            renewDrawings,
            startCoord,
            circles,
            labels,
            polylines,
            polygons,
            rectangles,
            noMapBoard,
            images,
            signals,
            mixins,
            myTileLayer,
            imageWidthToHeightRatio,
            imageFile,
            imageName,
            baseUrl,
            getSignalIcon,
            openAttribution,
            getFirstCoordinates,
            centerMarkerCoords,
            selectDrawing,
            zoom,
            setCoordinates,
            getAllMindsets,
            getMindset,
            closeMindsetMaker,
            isMindsetMakerActive,
            getIcon,
            isRealMap,
            myPolyline,
            regions,
            cursorMode,
            selectedRegion,
            selectRegion,
            fetchMapRegions,
            mgqMapRegionName,
            makeMgqMapRegionName,
            closeMgqMapRegionPopup,
            setMgqMapRegionName,
            showMgqMapNameEdit,
            selectImageToScan,
            setSearchedCoordinates,
            updateZoom,
            currentZoomLevel,
            tilesUrl,
            noMapBackground,
            editImage,
        };
    },
};
</script>
<style scoped>
.leaflet-container {
    background-color: v-bind('noMapBackground')
}

#mindset_attr {
    z-index: 10;
}

#map_div {
    z-index: 1;
}

.popup_div {
    text-align: center;
}

.unset_mgq_map_region_name {
    color: grey;
    font-style: oblique;
    text-align: center;
}

.mgq_map_region_name {
    color: black;
    text-align: center;
}

.outerdiv {
    /*   height: 550px; 
    width: 900px;*/
    margin: auto;
}
</style>