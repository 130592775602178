import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router'
import { createMyI18n } from "./utils/i18n";
//import * as mixins from "@/utils/mixins";
import * as DBProvider from "@/utils/DBProvider";
import * as Encr from "@/utils/Encr";
import * as gameMixins from "@/utils/gameMixins";

require('@/assets/main.scss');

const thisrouter = router;
const app = createApp(App);
await DBProvider.startDB();
await DBProvider.initializeDB();
let isEmptyUser = await DBProvider.checkIsEmpty("user_info");
if (!isEmptyUser) {
  let userJSONStringFromDB = await DBProvider.readDBKeyValue("user_info", "user");
  let userDB = JSON.parse(userJSONStringFromDB);
  let passwdDecr = userDB.password;
  if (userDB.password != "!@changePassword@!") {
    passwdDecr = await Encr.dencr(userDB.password);
  }
  store.commit("core/setUser", userDB);
  if (store.getters['core/getJwt'] == "") {
    let successLogin = await store.dispatch("core/loginSignup", {
      email: userDB.email,
      password: passwdDecr,
      username: userDB.username,
      social: userDB.social == undefined ? "" : (userDB.social ? "true" : ""),
      comesFromLogin: true,
      longitude: userDB.longitude != undefined ? userDB.longitude : 0.0,
      latitude: userDB.latitude != undefined ? userDB.latitude : 0.0,
      userDB:userDB,
    });
    if (successLogin == "wrong_pass" && passwdDecr == "!@changePassword@!") {
      let commands = [];
      commands.push("checkPasswordEmail");
      store.commit("core/setTemporaryContent", { commands: commands });
    }
    else if ((successLogin == "ok!" || successLogin == "askForCoordinates") && passwdDecr == "!@changePassword@!") {
      let commands = [];
      commands.push("newPassword");
      if (successLogin == "askForCoordinates") {
        commands.push("askForCoordinates");
      }
      store.commit("core/setTemporaryContent", { commands: commands });
    }
    else if ((successLogin == "ok!" || successLogin == "askForCoordinates") && passwdDecr != "!@changePassword@!") {
      let mgqScores = await store.dispatch("gameApi/fetchMgqScores", {});
      await gameMixins.makeMissingMgqScoresChains(mgqScores);
      if (store.getters['gameApi/getCurrentMgq'].mindgluequestId == 0) {
        await store.dispatch("gameApi/requestCurrentMgq");
      }
    }
  }
}
app.use(store).use(thisrouter).use(createMyI18n());
app.mount('#app');
/* if (shouldGoToLogin){
  router.push("/login");
} */


