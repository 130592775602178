<template>
    <div>
        <div><span>{{ seconds +'sec&nbsp;'}} </span> 
            
                <span>{{  'x&nbsp;'+mso.payload.starsPerSecond +'&nbsp;'}}</span> 
                <span class="icon is-small is-left">
                <i class="fas fa-star" style="color:yellow;  text-shadow: 1px 1px #1d1717;"></i>
            </span>&nbsp;&nbsp;
                <span  class="icon is-small is-left">
                <i class="fas fa-angle-double-right"></i></span>
                <span style="color:purple; font-weight: bold;">{{ '&nbsp;&nbsp;'+finalScore +'&nbsp;'}}</span>
                <span class="icon is-small is-left">
                <i class="fas fa-star" style="color:yellow;  text-shadow: 1px 1px #1d1717;"></i>
            </span> </div>
    </div>
</template>
<script>
import { ref, onBeforeUnmount, onMounted, computed, onBeforeMount, } from "vue";
import { useStore } from "vuex";
export default {
    setup() {
        const store = useStore();
        const mso = computed(() => store.getters["core/getModalDynamicObject"]);
        const finalScore = ref(0);
        const seconds = ref(0);
        let x = null;
        onBeforeMount(async () => {
            finalScore.value = mso.value.payload.startScore;
            let obj = mso.value;
            obj.noCancel = true;
            store.commit("core/setModalDynamicObject", obj);
        });

        onMounted(async () => {
            x = await setInterval(makeCountDown, 10);
           store.commit("gameApi/setEffectsAudio",require('@/assets/monet.mp3'));


        });
        onBeforeUnmount(() => {
            store.commit("gameApi/stopEffectsAudio");

            clearInterval(x);
            let obj = mso.value;
            obj.finalScore = finalScore.value;
            store.commit("core/setModalDynamicObject", obj);
        });


        function makeCountDown() {
            if (seconds.value <= mso.value.payload.seconds) {
                seconds.value = seconds.value += 1;
                finalScore.value = finalScore.value + mso.value.payload.starsPerSecond;
            }
            else {
                store.commit("gameApi/stopEffectsAudio");

                clearInterval(x);
            }
        }

        /*eslint-disable no-unused-vars*/
        function checkSubmit(submitted) {
            return true;
        }

        return {
            mso,
            seconds,
            checkSubmit,
            finalScore,
        };
    }
}
</script>