<template>
  <div class="boxy">
    <div class="rowy contenty">
          <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.list {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: left;
  margin: auto;
}

.outer_list {
  overflow: scroll;
  display: flex;
}

/* @media only screen and (max-height: 700px) {
  .outer_list {
    height: 500px;
  }
}
@media only screen and (max-height: 400px) {
  .outer_list {
    height: 300px;
  }
} */
@media only screen and (max-width: 1200px) {
  .outer_list {
    width: 150px;
  }
}
@media only screen and (max-width: 1000px) {
  .outer_list {
    width: 130px;
  }
}
@media only screen and (max-width: 700px) {
  .outer_list {
    width: 100px;
  }
}
@media only screen and (max-width: 500px) {
  .outer_list {
    width: 80px;
  }
}
@media only screen and (max-width: 400px) {
  .outer_list {
    width: 70px;
  }
}
</style>