import * as apiPost from '@/utils/ApiPost';
import * as compareMyObjects from '@/utils/CompareMyObjects';
import * as mixins from "@/utils/mixins";
import * as gameMixins from "@/utils/gameMixins";
import * as DBProvider from "@/utils/DBProvider";
import * as Encr from "@/utils/Encr";
//import { raw } from 'core-js/core/string';
//import {toRaw} from 'vue';



export async function getAllMgqs({ commit }) {
  const url = mixins.serverIp + '/mgq/MgqWebFetcher';
  const rawResponse = await apiPost.myFetch(url);
  if (rawResponse) {
    const content = await rawResponse.json();
    commit('setMindgluequests', content);
    return true;
  }
}


export async function loginSignup({ commit, getters, rootState, dispatch }, payload) {
  //const url = mixins.serverIp + '/mgq/MgqAuthorizer';
  let user = mixins.cloneObject(getters["getUser"]);
  let genericUseridString = "-1";
  if (!mixins.getIsEmpty(user) && user.email.indexOf("temp.mindglue.io") > -1) {
    genericUseridString = user.userid.toString();
  }

  let toReturn = "error";
  let url = mixins.serverIp + '/mobile/Authorizer';
  if (payload.isSignup != undefined && payload.isSignup) {
    url = mixins.serverIp + '/mobile/SignUper';
  }
  if ((payload.username == undefined && payload.email == undefined && payload.password == undefined) && payload.isSignup != undefined && payload.isSignup) {
    toReturn = await createGeneric(dispatch);
  }
  else {

    let comesFromLogin = null != payload.comesFromLogin ? (payload.comesFromLogin == true ? "1" : "0") : "0";
    let body = new URLSearchParams({
      'email': payload.email,
      'password': payload.password,
      'username': payload.isLogin ? "" : payload.username,
      'authorizationCode': payload.isLogin ? "" : payload.authorizationCode,
      'identityToken': payload.isLogin ? "" : payload.identityToken,
      'social': payload.social,
      'isLogin': payload.isLogin,
      'comes_from_login': comesFromLogin,
    });
    if (payload.isSignup) {
      let birthDates = makeBirthDates();
      body.append('generic_userid', genericUseridString);
      body.append('birthday', birthDates.birthday);
      body.append('birthmonth', birthDates.birthmonth);
      body.append('birthyear', birthDates.birthyear);
      let language = navigator.language;
      if (language.length > 2) {
        language = language.substring(0, 2);
      }
      body.append('locale', language);
    }
    const rawResponse = await apiPost.myFetch(url, {
      method: 'POST',
      cors: 'no-cors',
      // body: JSON.stringify({
      body: body
    });

    if (rawResponse) {
      const content = await rawResponse.text();
      if (content == "wrong_email" || content == "wrong_pass") {
        toReturn = content;
      }
      else if (content == "duplicate_email") {
        toReturn = content;
      }
      else {
        const answerSplit = content.split(":");

        if (answerSplit[0] === "ok!") {
          //commit('setLoggedIn', true);
          toReturn = "ok!";
          let passwEncrypted = await Encr.encr(payload.password);

          if (payload.isSignup) {
            let loggedUser = { username: payload.username, userid: answerSplit[1], email: payload.email, password: passwEncrypted, social: payload.social==undefined?false:payload.social, longitude: payload.longitude, latitude: payload.latitude };
            commit('setUser', loggedUser);
            commit('setJwtToken', answerSplit[3]);
          }
          else {
            
            let loggedUser = { username: answerSplit[2], userid: answerSplit[1], email: payload.email, password: passwEncrypted,
               social: payload.social==undefined?false:payload.social,
                longitude: payload.longitude, latitude: payload.latitude };
            if (payload.userDB!=undefined){
              if (payload.userDB.email == loggedUser.email && payload.userDB.username == loggedUser.username && payload.userDB.password == loggedUser.password){
                loggedUser = payload.userDB;
              }
            }
            commit('setUser',loggedUser);
            commit('setJwtToken', answerSplit[4]);
          }
          if (getters["getUser"].longitude == undefined || getters["getUser"].longitude == 0.0) {
            toReturn = "askForCoordinates"
          }
          let userInfo = getters["getUser"];
          await DBProvider.writeDBKeyValue("user_info", "user", JSON.stringify(userInfo));

          if (rootState.gameApi.currentMgq.mindgluequestId == 0) {
            await dispatch("gameApi/requestCurrentMgq", null, { root: true });
          }
        }
      }
    }
  }
  return toReturn;
}

export async function setInterimPassword({ commit }, payload) {
  let url = mixins.serverIp + '/mobile/PasswordChanger';
  let body = new URLSearchParams({ email: payload.email });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    body: body
  });
  let toReturn = false;
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content.substring(0, 3) == "ok!") {
      let myUser = { email: payload.email, password: "!@changePassword@!" };
      await DBProvider.writeDBKeyValue("user_info", "user", JSON.stringify(myUser));
      commit("setUser", myUser);
      toReturn = true;
    }
  }
  return toReturn;
}


export async function renewPassword({ getters,commit }, payload) {
  let url = mixins.serverIp + '/mobile/PasswordSetter';
  let user = getters["getUser"];
  let body = new URLSearchParams({ userid: user.userid, password: payload.password });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    body: body
  });
  let toReturn = false;
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content.substring(0, 3) == "ok!") {
      let passwEncrypted = await Encr.encr(payload.password);
      user.password = passwEncrypted;
      await DBProvider.writeDBKeyValue("user_info", "user", JSON.stringify(user));
      commit("setUser", user);
      toReturn = true;
    }
  }
  return toReturn;
}


async function createGeneric(dispatch) {
  let today = new Date();
  let mill = today.getTime().toString();
  let username = "user" + mill;
  let email = mill + "@temp.mindglue.io";
  let password = "";
  let payload = { username: username, email: email, password: password, isSignup: true, comesFromLogin: true };
  let toReturn = await dispatch("loginSignup", payload);
  return toReturn;
}

function makeBirthDates() {
  let today = new Date();
  let birthday = String(today.getDate()).padStart(2, "0");
  let birthmonth = String(today.getMonth() + 1).padStart(2, "0");
  let birthyear = String(today.getFullYear());
  return { birthday: birthday, birthmonth: birthmonth, birthyear: birthyear };
}


export async function requestCreateMgq({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MgqCreator';
  let rbody = "";
  if (undefined != payload.password) {
    rbody = JSON.stringify({ 'createdmgq': payload.mgq, password: payload.password });
  }
  else {
    rbody = JSON.stringify({ 'createdmgq': payload.mgq });
  }
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content !== "error" && content !== "duplicate_code") {
      const answerSplit = content.split(":");
      if (answerSplit.length > 0) {
        commit('setMgqId', answerSplit[1]);
        commit('setMgqCode', answerSplit[2]);
        if (null != payload.imageFile) {
          let responseImage = await saveImage(payload.imageFile, payload.mgq.image.name, answerSplit[1], "image");
          if (responseImage == "ok!") return "true";
          else return "false";
        }
        else {
          return "true";
        }
      }
    }
    else if (content == "duplicate_code") {
      await mixins.alertConfirm("The game code you entered has already been used by you. Please enter another game code.", false)
      return "false";
    }
  }
}

/*eslint-disable no-unused-vars*/
export async function requestEditMgq({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MgqEditor';
  let rbody = "";
  if (undefined != payload.password) {
    rbody = JSON.stringify({ 'editedmgq': payload.mgq, mindgluequestId: payload.mindgluequestId.toString(), password: payload.password });
  }
  else {
    rbody = JSON.stringify({ 'editedmgq': payload.mgq, mindgluequestId: payload.mindgluequestId.toString() });
  }
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") {
      if (null != payload.imageFile) {
        let responseImage = await saveImage(payload.imageFile, payload.mgq.image.name, payload.mindgluequestId, "image");
        if (responseImage == "ok!") return true;
        else return false;
      }
      else return true;
    }
    else if (content == "duplicate_code") {
      await mixins.alertConfirm("The game code you entered has already been used by you. Please enter another game code.", false)
      return false;
    }
    else return false;
  }
}

export async function saveImage(imageFile, imageName, mindgluequestId, useType) {
  const url = mixins.serverIp + '/mgqAuth/MgqImageSaver';
  const formData = new FormData();
  formData.append("mindgluequestId", mindgluequestId);
  formData.append("imageName", imageName);
  formData.append("imageFile", imageFile);
  formData.append("useType", useType);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: formData,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    return content;
  }
}


export async function requestFirstMindsets({ commit, getters }, payload) {
  const url = mixins.serverIp + '/mgqAuth/FirstMindsetsCreator';
  const formData = new FormData();
  /*   formData.append("radius", payload.radius);
    formData.append("mindsetsNumber", payload.mindsetsNumber);
    formData.append("numOfExistingMindsets", getNumberOfExistingMindsets(getters));
    formData.append("textFile", payload.textFile);
    formData.append("gamecode", (undefined != getters["getterCreatedMgq"].mindgluequestId ? getters["getterCreatedMgq"].mindgluequestId : getters["getterSelectedMgq"].mindgluequestId) + "_" + (payload.setName == "" ? makeGamecode(getters) : payload.setName));
    formData.append("latitude", payload.latitude);
    formData.append("longitude", payload.longitude);
    formData.append("mgqMapRegion", JSON.stringify(payload.mgqMapRegion)); */
  let gamecode = (undefined != getters["getterCreatedMgq"].mindgluequestId ? getters["getterCreatedMgq"].mindgluequestId : getters["getterSelectedMgq"].mindgluequestId) + "_" + (payload.properties.groupName == "" ? makeGamecode(getters) : payload.properties.groupName);
  payload.properties["gamecode"] = gamecode;
  payload.properties["numberOfExistingMindsets"] = getNumberOfExistingMindsets(getters);
  formData.append("textFile", payload.textFile);
  formData.append("properties", JSON.stringify(payload.properties));

  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: formData,
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    if (content !== "error") {
      if (content.mindsetsList == "error") {
        return false;
      }
      else {
        /* commit('pushFirstMindsets', content.mindsetsList);
        commit('setTextSentences', content.sentencesList);
        commit('setMgqLanguage', content.language); */
        handleIncomingFromFirstMindsetsAndScan(commit, content);
        return true;
      }
    }
    else return false;
  }
}

function handleIncomingFromFirstMindsetsAndScan(commit, content) {
  commit('pushFirstMindsets', content.mindsetsList);
  commit('setTextSentences', content.sentencesList);
  commit('setMgqLanguage', content.language);
}

function makeGamecode(getters) {
  let name = Number(getters["getFirstMindsets"].length);
  while (mixins.checkAlreadyExistingGroupName(name.toString(), getters["getFirstMindsets"])) {
    name += 1;
  }
  return name.toString();
}

function getLastId(getters) {
  if (getters["getFirstMindsets"].length == 0) return Number(0);
  else {
    let lastFirst = getters["getFirstMindsets"][getters["getFirstMindsets"].length - 1];
    return Math.abs(lastFirst[lastFirst.length - 1].id);
  }
}

function getNumberOfExistingMindsets(getters) {
  if (getters["getFirstMindsets"].length == 0) return Number(0);
  else {
    return Number(getters["getFirstMindsets"].flat().length);
  }
}

export async function requestMyAppMindsets({ commit }) {
  const url = mixins.serverIp + '/mobile/AllQSetsGetter';
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    commit("setMyAppMindsets", content);
  }
}




export async function getMyMindgluequests({ commit }) {
  const url = mixins.serverIp + '/mgqAuth/MyMgqFetcher';
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
  })
  if (rawResponse) {
    const content = await rawResponse.json();
    commit('setMindgluequests', content);
  }
}

export async function requestMadeMindsets({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MyMindsetsFetcher';
  let rbody = JSON.stringify(payload);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    const clone = [];
    for (let mindsetArray of content) {
      const cloneArray = [];
      for (let mindset of mindsetArray) {
        let clonedMindset = mixins.cloneObject(mindset)
        cloneArray.push(clonedMindset);
      }
      clone.push(cloneArray);
    }
    if (content && content.length > 0 && content[0].length > 0) {
      commit('setFirstMindsets', content);
      commit('setOriginalMindsets', clone);
      return true;
    }
    else return false;
  }
}

export async function requestMindsetsOfMgq({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MyMindsetsFetcher';
  let rbody = JSON.stringify(payload);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    const toReturn = [];
    for (let mindsetArray of content) {
      for (let mindset of mindsetArray) {
        mindset = gameMixins.fixJsonValuesOfMindset(mindset);
        toReturn.push(mindset);
      }
    }
    return toReturn;
  }
}


export async function saveMindsets({ getters }) {
  let success = false;
  let allMarked = getAllMarkedNow(getters);
  let markedBack = [[], [], []];
  let arrayToSend = [];
  let arrayOfMono = [];
  for (let mindsetsArray of getters["getFirstMindsets"]) {
    if (mindsetsArray[0].id < 1) arrayToSend.push(mindsetsArray);
    else {
      for (let mindset of mindsetsArray) {
        if (mindset.id < 0) {
          arrayOfMono.push(mindset);
        }
      }
    }
  }
  if (arrayOfMono.length > 0) {
    arrayToSend.push(arrayOfMono);
  }
  if (arrayToSend.length > 0) {
    markedBack = await saveNewMindsets(getters, arrayToSend, allMarked[0]);
  }
  let mergedMarked = mergeMarkedArrays(markedBack, allMarked[1])
  success = saveMindsetEdits(getters, mergedMarked);
  return success;
}


async function saveNewMindsets(getters, totalMindsetsArray, newMarked) {
  const url = mixins.serverIp + '/mgqAuth/MgqMindsetsSaver';
  let payload = {
    mindgluequestId: (undefined != getters["getterCreatedMgq"].mindgluequestId ? getters["getterCreatedMgq"].mindgluequestId : getters["getterSelectedMgq"].mindgluequestId).toString(),
    mindsets: totalMindsetsArray,
    language: getters['getMgqLanguage']
  };
  if (newMarked[0].length > 0 || newMarked[1].length > 0 || newMarked[2].length > 0) {
    payload["marked"] = newMarked;
    /*  if (newMarked[0].length > 0){
       let startMindset = totalMindsetsArray.flat().filter((mindset)=>mindset.id==newMarked[0]);
       payload["startLatLng"] = startMindset.latitude+","+startMindset.longitude;
     } */
  }
  let rbody = JSON.stringify(payload);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") {
      return [[], [], []];
    }
    else if (content.substring(0, 1) == "[") {
      return JSON.parse(content);
    }
    else await mixins.alertConfirm(
      "Something went wrong.", false
    );
  }
}

async function saveMindsetEdits(getters, marked) {
  const url = mixins.serverIp + '/mgqAuth/MgqMindsetsUpdater';
  let changesArray = [];
  for (let i = 0; i < getters["getFirstMindsets"].length; i++) {
    if (getters["getFirstMindsets"][i][0].id < 1) {
      continue;
    }
    for (let j = 0; j < getters["getFirstMindsets"][i].length; j++) {
      let compObj = compareMyObjects.compareqsets(getters["getFirstMindsets"][i][j], getters["getOriginalMindsets"][i][j]);
      if (Object.keys(compObj).length > 0) {
        changesArray.push(compObj);
      }
    }
  }
  let payload = {};
  if (changesArray.length > 0) {
    payload["changes"] = changesArray;
    if (marked[0].length > 0) {
      let startMindsetArray = [];
      /*    if (undefined != changesArray[0]["questionset"]) {
           startMindsetArray = changesArray.filter((mindset) => mindset["questionset"]["qsetid"] == marked[0][0]);
           if (startMindsetArray.length > 0) {
             payload["startLatLng"] = startMindsetArray[0]["questionset"]["qsetlatitude"] + "," + startMindsetArray[0]["questionset"]["qsetlongitude"];
           }
         } */
      for (let changeObj of changesArray) {
        if (undefined != changeObj["questionset"]) {
          if (changeObj["questionset"]["qsetid"] == marked[0][0]) {
            payload["startLatLng"] = changeObj["questionset"]["qsetlatitude"] + "," + changeObj["questionset"]["qsetlongitude"];
            break;
          }
        }
      }
    }
  }
  if (changedOldMarked(getters, marked)) {
    payload["marked"] = marked;
    if (marked[0].length > 0) {
      let startMindset = getters["getFirstMindsets"].flat().filter((mindset) => mindset.id == marked[0][0]);
      if (startMindset.length == 0) {
        startMindset = getters["getFirstMindsets"].flat().filter((mindset) => mindset.isStart == true);
      }
      payload["startLatLng"] = startMindset[0].latitude + "," + startMindset[0].longitude;
    }
  }
  if ("startLatLng" in payload) {
    if (!("marked" in payload)) {
      payload["marked"] = marked;
    }
  }
  if (Object.keys(payload).length > 0) {
    payload["mindgluequestId"] = (undefined != getters["getterCreatedMgq"].mindgluequestId ? getters["getterCreatedMgq"].mindgluequestId : getters["getterSelectedMgq"].mindgluequestId).toString();
  }
  if (Object.keys(payload).length > 0) {
    let rbody = JSON.stringify(payload);
    const rawResponse = await apiPost.myFetch(url, {
      method: 'POST',
      cors: 'no-cors',
      headers: apiPost.makeHeaders(),
      body: rbody,
    });
    if (rawResponse) {
      const content = await rawResponse.text();
      if (content == "ok!")
        return true;
      else return false;
    }
  }
  else return true;
}

function mergeMarkedArrays(newMarked, oldMarked) {
  let isStart = oldMarked[0].concat(newMarked[0]);
  let markedReal = oldMarked[1].concat(newMarked[1]);
  let targeted = oldMarked[2].concat(newMarked[2]);
  return [isStart, markedReal, targeted];
}

function getAllMarkedNow(getters) {
  let allMarkedNow = [[[], [], []], [[], [], []]];
  for (let mindsetArray of getters["getFirstMindsets"]) {
    for (let mindset of mindsetArray) {
      if (mindset.id < 1) {
        if (mindset.isStart) allMarkedNow[0][0].push(mindset.id);
        if (mindset.marked) allMarkedNow[0][1].push(mindset.id);
        if (mindset.targeted) allMarkedNow[0][2].push(mindset.id);
      }
      else {
        if (mindset.isStart) allMarkedNow[1][0].push(mindset.id);
        if (mindset.marked) allMarkedNow[1][1].push(mindset.id);
        if (mindset.targeted) allMarkedNow[1][2].push(mindset.id);
      }
    }
  }
  return allMarkedNow;
}

function changedOldMarked(getters, newMarked) {
  if (undefined == getters["getterSelectedMgq"].marked) return true;
  else {
    const tripleArr = JSON.parse(getters["getterSelectedMgq"].marked);
    if (tripleArr.length == 0) return true;
    else {
      if (tripleArr[0].length != newMarked[0].length || tripleArr[1].length != newMarked[1].length || tripleArr[2].length != newMarked[2].length) return true;
      else {
        for (let i = 0; i < newMarked.length; i++) {
          for (let j = 0; j < newMarked[i].length; j++) {
            if (!tripleArr[i].includes(newMarked[i][j])) {
              return true;
            }
          }
        }
        return false;
      }
    }
  }
}


export async function deleteMindsets({ getters, commit }, payload) {
  let answer = "ok!";
  if (getters["getFirstMindsets"].length > 0) {
    let mindsetsToDeleteOld = [];
    let mindsetsToDeleteNew = [];
    let flagDeleteStart = false;
    for (let mindsetArray of getters["getFirstMindsets"]) {
      for (let mindset of mindsetArray) {
        if (mindset.isSelected) {
          if (mindset.id < 1) {
            /*    if (mindset.id==0){
                 flagDeleteStart = true;
               }
               else { */
            mindsetsToDeleteNew.push(mindset);
            // }
          }
          else {
            mindsetsToDeleteOld.push(mindset);
          }
        }
      }
    }
    if (mindsetsToDeleteNew.length > 0) {

      commit("deleteFirstMindsets", mindsetsToDeleteNew);

    }

    if (mindsetsToDeleteOld.length > 0) {

      answer = await sendDeleteRequest(commit, getters, mindsetsToDeleteOld);
    }
  }
  return answer;
}


async function sendDeleteRequest(commit, getters, mindsetsToDelete) {
  const markedAfterDelete = makeMarkedAfterDelete(getters, mindsetsToDelete);
  let ownMindsetsToDelete = mindsetsToDelete.filter(x => x.gamecode.indexOf("#!soc!#") == -1);
  let socialMindsetsToDesocialize = mindsetsToDelete.filter(x => x.gamecode.indexOf("#!soc!#") > -1);
  let payload = { mindsetsToDelete: ownMindsetsToDelete.map(x => x.id) };
  payload["socialMindsetsToDesocialize"] = socialMindsetsToDesocialize.map(x => x.id);
  if (JSON.stringify(markedAfterDelete) != getters["getterSelectedMgq"].marked) {
    payload["marked"] = markedAfterDelete;
    payload["mindgluequestId"] = getters["getterSelectedMgq"].mindgluequestId.toString();
  }
  if (mindsetsToDelete.length == getters["getFirstMindsets"][getters["getSelectedMindsetsArrayIndex"]].length) {
    payload["mgqGamecodeToRemove"] = mindsetsToDelete[0].gamecode;
  }
  const url = mixins.serverIp + '/mgqAuth/MindsetsDeleter';
  const rbody = JSON.stringify(payload);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") {
      commit("deleteFirstMindsets", mindsetsToDelete);
      commit("setSelectedMgqMarkedAfterDelete", JSON.stringify(markedAfterDelete));
      return "ok!";
    }
    else {
      return "Something went wrong.";
    }
  }

}

function makeMarkedAfterDelete(getters, mindsetsToDelete) {
  let markedArray = [];
  if (undefined != getters["getterSelectedMgq"].marked) {
    let markedArray = JSON.parse(getters["getterSelectedMgq"].marked);
    for (let mindset of mindsetsToDelete) {
      for (let array of markedArray) {
        let index = array.indexOf(mindset.id);
        if (index != -1) {
          array.splice(index, 1);
        }
      }
    }
  }
  return markedArray;
}

export async function deleteMindgluequest({ getters }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MgqDeleter';
  const rbody = JSON.stringify(payload);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") return true;
    else return false;
  }
}

export async function saveUpdateTrophies({ getters }) {
  if (getters["getMgqTrophies"].length > 0) {
    const newTrophies = getters["getMgqTrophies"].filter(x => x.id < 1);
    const changedTrophies = getters["getMgqTrophies"].filter(x => x.id > 1 && !checkSameTrophy(getters, x));
    let toReturn = false;
    if (newTrophies.length > 0 || changedTrophies.length > 0) {
      if (newTrophies.length > 0) {
        toReturn = await sendSaveUpdateTrophiesRequest(getters, "save", newTrophies);
      }
      if (changedTrophies.length > 0) {
        toReturn = await sendSaveUpdateTrophiesRequest(getters, "update", changedTrophies);
      }
      return toReturn;
    }
    else return true;
  }
  else return true;
}

async function sendSaveUpdateTrophiesRequest(getters, mode, trophies) {
  const url = mixins.serverIp + '/mgqAuth/MgqTrophiesSaver';
  let rbody = JSON.stringify({ mgqTrophies: trophies, mindgluequestId: (undefined != getters["getterCreatedMgq"].mindgluequestId ? getters["getterCreatedMgq"].mindgluequestId : getters["getterSelectedMgq"].mindgluequestId).toString(), mode: mode });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!")
      return true;
    else return false;
  }
}

function checkSameTrophy(getters, trophy) {
  const originalTrophy = getters["getMgqOriginalTrophies"].filter(x => x.id == trophy.id)
  return compareMyObjects.areSame(trophy, originalTrophy, []);
}

export async function requestTrophies({ getters, commit }) {
  const url = mixins.serverIp + '/mgqAuth/MgqTrophiesFetcher';
  let rbody = JSON.stringify({ mindgluequestId: (undefined != getters["getterCreatedMgq"].mindgluequestId ? getters["getterCreatedMgq"].mindgluequestId : getters["getterSelectedMgq"].mindgluequestId).toString() });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    commit("setTrophies", content);
    let originalTrophies = [];
    for (let trophy of content) {
      originalTrophies.push(mixins.cloneObject(trophy));
    }
    commit("setOriginalTrophies", originalTrophies);
  }
}

export async function removeTrophy({ getters, commit }, payload) {
  const originalIds = getters["getMgqOriginalTrophies"].map(x => x.id);
  if (originalIds.includes(payload.id)) {
    const url = mixins.serverIp + '/mgqAuth/MgqTrophiesDeleter';
    const rbody = JSON.stringify({ trophyId: payload.id.toString() });
    const rawResponse = await apiPost.myFetch(url, {
      method: 'POST',
      cors: 'no-cors',
      headers: apiPost.makeHeaders(),
      body: rbody,
    });
    if (rawResponse) {
      const content = await rawResponse.text();
      if (content == "ok!") {
        commit("removeMgqTrophy", payload);
      }
    }
  }
  else {
    commit("removeMgqTrophy", payload);
  }
}

export async function getContestResults({ commit }, payload) {
  const url = mixins.serverIp + '/mgq/MgqContestResultFetcher/' + payload;
  const rawResponse = await apiPost.myFetch(url);
  if (rawResponse) {
    const content = await rawResponse.json();
    return content;
  }
}

export async function requestSelectedPresentation({ commit }, payload) {
  const url = mixins.serverIp + '/mgq/MgqAllForSelectedFetcher/' + payload;
  const rawResponse = await apiPost.myFetch(url);
  if (rawResponse) {
    const content = await rawResponse.json();
    return content;
  }
}



export async function saveMapImage({ getters, commit }, payload) {
  let responseMapImage = await saveImage(payload.imageFile, payload.imageName, payload.mindgluequestId, payload.useType);
  if (responseMapImage == "ok!") return true;
  else return false;
}


export async function fetchPaymentId({ getters }) {
  const url = mixins.serverIp + '/mgqAuth/MgqUserCredsFetchUpdater';
  let rbody = JSON.stringify({ requested: "payment_id" });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    return content;
  }
}


export async function fetchPaypalCreds({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MgqUserCredsFetchUpdater';
  let rbody = JSON.stringify({ requested: "ppcrs" });
  let headers = {};
  if (payload != undefined) {
    if (payload.reqid != undefined) {
      headers = { "requestid": payload.reqid, "mytoken": payload.jwt };
    }
    else {
      headers = apiPost.makeHeaders();
    }
  }
  else {
    headers = apiPost.makeHeaders();
  }
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: headers,
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    return content;
  }
  else return null;
}

export async function requestPaypalAccessToken({ commit }, paypalCreds) {
  const paypalUrl =
    mixins.paypalDomain + "/v1/oauth2/token"
  const rawResponse = await fetch(paypalUrl, {
    method: "POST",
    cors: "no-cors",
    headers: {
      Authorization: "Basic " + window.btoa(paypalCreds["ppca"] + ":" + paypalCreds["ppcb"]),
      Accept: "application/json",
    },
    body: new URLSearchParams({
      'grant_type': 'client_credentials'
    })
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    let accessToken = content["access_token"];
    return accessToken;
  }
}

/*
 * confirmPaypalPayment not necessary
 */
export async function confirmPaypalPayment({ commit }, payload) {
  const url =
    mixins.paypalDomain + "/v2/checkout/orders/" + payload.orderId + "/confirm-payment-source";
  const rawResponse = await fetch(url, {
    method: "POST",
    cors: "no-cors",
    headers: {
      "Authorization": "Bearer " + payload.accessToken,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: JSON.stringify({ "payment_source": { "paypal": {} } }),

  });
  if (rawResponse) {
    const content = await rawResponse.json();

    return content;
  }
}

export async function authorizePaypalPayment({ commit }, payload) {
  const url =
    mixins.paypalDomain + "/v2/checkout/orders/" + payload.orderId + "/capture";
  const rawResponse = await fetch(url, {
    method: "POST",
    cors: "no-cors",
    headers: {
      "Authorization": "Bearer " + payload.accessToken,
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    return content;
  }
}

export async function savePaypalApproval({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/OrderApprovalPaymentPoint';
  let rbody = new URLSearchParams({
    requested: "approval",
    mindgluequest_id: payload.mgq.mindgluequestId.toString(),
    mqgVersionId: payload.mgq.mgqVersion.id.toString(),
    amount: payload.mgq.price.toString(),
    currency: payload.mgq.currency.toString(),
    orderId: payload.orderId,
    payeeId: payload.payeeId,
    payerOutsideId: payload.payerOutsideId,
    paymentId: payload.paymentId,
    paymentSource: payload.AcceptpaymentSource,
    completed: false,
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  let toReturn = false;
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") {
      toReturn = true;
    }
  }
  return toReturn;
}

export async function savePaypalPayment({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/OrderApprovalPaymentPoint';
  let rbody = new URLSearchParams({
    requested: "payment",
    orderId: payload,
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  let toReturn = false;
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") {
      toReturn = true;
    }
  }
  return toReturn;
}




export async function paypalCodeForToken({ commit }, payload) {
  const paypalUrl =
    mixins.paypalDomain + "/v1/oauth2/token?grant_type=authorization_code&code=" +
    payload.accessCode;
  const rawResponse = await fetch(paypalUrl, {
    method: "POST",
    cors: "no-cors",
    headers: {
      Authorization: "Basic " + window.btoa(payload.clientId + ":" + payload.secret),
    }
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    let accessToken = content["access_token"];
    return accessToken;
  }
}

export async function requestPaymentId({ commit }, payload) {
  const url =
    mixins.paypalDomain + "/v1/identity/oauth2/userinfo?schema=paypalv1.1";
  const rawResponse = await fetch(url, {
    method: "GET",
    cors: "no-cors",
    headers: {
      Authorization: "Bearer " + payload.accessToken,
      "Content-Type": "application/json"
    }
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    let emails = content["emails"];
    return emails;
  }
}


export async function savePaymentId({ commit }, payload) {
  //console.log("in savePaymentId ");

  const url = mixins.serverIp + '/mgqAuth/MgqUserCredsFetchUpdater';
  let rbody = JSON.stringify({ requested: "savePaymentId", payment_id: payload.paypalEmail });

  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: { "requestid": payload.reqid, "mytoken": payload.jwt },
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") {
      return true;
    }
    else return false;
  }
  else return false;
}



export async function saveMgqMapRegion({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MgqMapRegionSaver';
  let rbody = JSON.stringify({ mgqMapRegion: payload });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") {
      return true;
    }
    else return false;
  }
  else return false;
}

/**
 * here problem if in create
 * getterSelectedMgq undefined
 * 
 */

export async function requestMgqMapRegions({ getters }) {
  const url = mixins.serverIp + '/mgqAuth/MgqMapRegionsFetcher';
  let rbody = JSON.stringify({ mgqId: getters["getterSelectedMgq"].mindgluequestId.toString() });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    return content;
  }
  else return null;

}

export async function deleteMgqMapRegion({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MgqMapRegionDeleter';
  let rbody = JSON.stringify(payload);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") {
      return true;
    }
    else {
      return false;
    }
  }
  else return false;
}

export async function moveMindsetsInRegion({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MgqCoordinatesMaker';
  let rbody = JSON.stringify(payload);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    return content;
  }
  else {
    return [];
  }
}

export async function retrieveDeteledMindgluequests({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MgqDeletedHandler';
  let rbody = JSON.stringify({ mode: "fetch" });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  })
  if (rawResponse) {
    const content = await rawResponse.json();
    commit('setDeletedMgqs', content);
  }
  else return [];
}

export async function restoreOrDelete({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MgqDeletedHandler';
  let rbody = JSON.stringify({
    mode: payload.toRestore ? "restore" : "delete",
    mgq: payload.mgq
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  })
  if (rawResponse) {
    const content = await rawResponse.text();
    return content == "ok!" ? true : "false";
  }
  else return false;
}

export async function saveMgqMapRegionName({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MgqMapRegionNameSetter';
  let rbody = JSON.stringify(payload);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  })
  if (rawResponse) {
    const content = await rawResponse.text();
    return content == "ok!" ? true : "false";
  }
  else return false;
}

export async function requestAllMgqMapRegions({ getters, commit }) {
  let mgqsList = getters["getMindgluequests"].filter(
    (x) => x.mindgluequestId != getters["getterSelectedMgq"].mindgluequestId
  );
  let idsList = mgqsList.map((x) => x.mindgluequestId);
  const url = mixins.serverIp + '/mgqAuth/MgqAllMapRegionsFetcher';
  let rbody = JSON.stringify({ mgqIds: idsList });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  })
  if (rawResponse) {
    const content = await rawResponse.json();
    let array = makeMapRegionArrays(content);
    commit("setAllMgqMapRegions", array);
    return true;
  }
  else {
    return false;
  }
}

function makeMapRegionArrays(allMgqMapRegionsMap) {
  let array = [];
  for (let mgq of mixins.myOtherMgqs.value) {
    let mapRegions = allMgqMapRegionsMap[mgq.mindgluequestId.toString()];
    let item = { mgq: mgq, mapRegions: mapRegions };
    array.push(item);
  }
  return array;
}

export async function copyMindsets({ getters, commit }, payload) {
  let mindsetList = mixins.getAllSelectedMindsets();
  const url = mixins.serverIp + '/mgqAuth/MgqMindsetsCopier';
  let rbody = JSON.stringify({ mindsetList: mindsetList, mgqId: payload.mgq.mindgluequestId.toString(), mgqMapRegionId: payload.mgqMapRegion.id.toString() });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  })
  if (rawResponse) {
    commit("setAllMgqMapRegions", []);
    const content = await rawResponse.text();
    if (content == "ok!") {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    commit("setAllMgqMapRegions", []);
    return false;
  }
}

export async function saveMgqDrawing({ commit }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MgqDrawingsSaver';
  let rbody = JSON.stringify({ mgqDrawing: payload });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") {
      return true;
    }
    else return false;
  }
  else return false;
}

export async function editMgqDrawing({ commit }, payload) {
  const url = mixins.serverIp + '/mobile/MgqDrawingsEditor';
  let mobileBody = new URLSearchParams(payload);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") {
      return true;
    }
    else return false;
  }
  else return false;
}


export async function requestMgqDrawings({ getters }, mgq) {
  const url = mixins.serverIp + '/mgqAuth/MgqDrawingsFetcher';
  let rbody = "";
//  if (isUserCreator) {
    rbody = JSON.stringify({ mgqId: mgq.mindgluequestId.toString() });
   // rbody = JSON.stringify({ mgqId: getters["getterSelectedMgq"].mindgluequestId.toString() });
 /* }
   else {
    rbody = JSON.stringify({ mgqId: rootState.gameApi.currentMgq.mindgluequestId.toString() });
  } */
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    let responseText = await rawResponse.text();
    if (responseText == "reauthorize") {
      return responseText;
    }
    else {
      const content = JSON.parse(responseText);
      return content;
    }
  }
  else return null;
}

export async function deleteMgqDrawing({ commit, getters }, payload) {
  const url = mixins.serverIp + '/mgqAuth/MgqDrawingDeleter';
  let thisPayload = { mgqDrawingId: payload };
  if (payload == -1) {
    thisPayload["mgqId"] = getters["getterSelectedMgq"].mindgluequestId.toString();
  }
  let rbody = JSON.stringify(thisPayload);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") {
      return true;
    }
    else {
      return false;
    }
  }
  else return false;
}

export async function deleteTempMgqMapImage({ commit }, imageName) {
  const url = mixins.serverIp + '/mgqAuth/MgqTempImageDeleter';
  let payload = { imageName: imageName };
  let rbody = JSON.stringify(payload);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.text();
    if (content == "ok!") {
      return true;
    }
    else {
      return false;
    }
  }
  else return false;
}

export async function scanImage({ commit, getters }, payload) {
  const url = mixins.serverIp + '/mgqAuth/ImageScanner';
  let toReturn = "";
  let gamecode = (undefined != getters["getterCreatedMgq"].mindgluequestId ? getters["getterCreatedMgq"].mindgluequestId : getters["getterSelectedMgq"].mindgluequestId) + "_" + makeGamecode(getters);
  payload["gamecode"] = gamecode;
  payload["numberOfExistingMindsets"] = getNumberOfExistingMindsets(getters);
  let rbody = JSON.stringify(payload);
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: rbody,
  });
  if (rawResponse) {
    const content = await rawResponse.json();
    handleIncomingFromFirstMindsetsAndScan(commit, content);
  }
  return toReturn;
}


