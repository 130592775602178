<template>
  <div>
    <div class="card">
      <div class="card-image"></div>
      <div class="card-content">
        <div class="media centerAligned">
          <div class="media-left">
            <figure class="image is-48x48" @click="showSelected">
              <img v-if="mgq.image.name != ''" :src="mixins.baseUrl + mixins.mgqImagesDir + mgq.image.name"
                class="roundedCorners" />
              <div v-else class="roundedCorners" :style="'background-color:' + colors[mgq.color]">
                <img src="@/assets/sherlock.svg" class="p-2" style="opacity: 0.5" />
              </div>
            </figure>
            <div class="columns pt-4">
              <figure v-if="mgq.virtual" class="image is-48x48 pt-2">
                <i class="fas fa-mobile is-size-4"></i>
              </figure>
              <figure v-else class="image is-48x48 pt-2">
                <i class="fas fa-walking is-size-4"></i>
              </figure>
              <figure v-if="mgq.price>0" class="image is-48x48 pt-2">
                <i class="fas fa-coins is-size-4" style="color: grey; font-weight: bold"></i>
              </figure>
              <figure v-if="shouldShowNowIcon" class="image is-48x48 pt-2" @click="showContest">
                <i class="fas fa-burn is-size-4" style="color: red; font-weight: bold"></i>
              </figure>
              <figure v-else-if="shouldShowContestIcon" class="image is-48x48 pt-2" @click="showContest">
                <i class="fas fa-burn is-size-4" style="color: blue; font-weight: bold"></i>
              </figure>
            </div>
            <div v-if="thisOuter" class="columns">
              <i class="fas fa-user"></i>
              <div class="is-size-6">
                &nbsp;
                {{ mgq.creator.username }}
              </div>
            </div>
            <div class="columns">
              <i class="fas fa-clock"></i>&nbsp;
              <div class="is-size-7">
                {{
                  mixins.setDate(mgq.mgqVersion.startTime) +
                  " " +
                  mixins.setTime(mgq.mgqVersion.startTime)
                }}
              </div>
            </div>
            <div class="columns">
              <i class="fas fa-stopwatch"></i>&nbsp;
              <div class="is-size-7">
                {{
                  mixins.setDate(mgq.mgqVersion.endTime) +
                  " " +
                  mixins.setTime(mgq.mgqVersion.endTime)
                }}
              </div>
            </div>
          </div>
          <div class="media-content">
            <div class="pl-4" @click="setInitialSelected">
              <p class="is-4" :class="{
                title: mgqStatus == 'normal',
                deleted_mgq: mgqStatus == 'deleted',
              }" style="cursor: pointer">
                {{ mgq.title }}
              </p>
              <p class="subtitle is-4">{{ mgq.location }}</p>
              <p class="shortDescr_p">{{ mgq.shortDescr }}</p>
            </div>
          </div>

          <div class="media-right" style="cursor: pointer">
            <div v-if="mgqStatus == 'normal'" class="pl-4 editContainer" v-show="!thisOuter" @click="setSelected()">
              <i class="fas fa-edit is-size-4"></i>
            </div>
            <div v-else-if="mgqStatus == 'deleted'" @click="restoreOrDelete(true)">
              <i class="fas fa-arrow-circle-up is-size-4"></i>
            </div>
            <div class="pl-4" v-if="mgqStatus == 'normal'" v-show="!thisOuter" @click="copyToNew">
              <i class="fas fa-copy is-size-4"></i>
            </div>
            <div class="pl-4" v-if="mgqStatus == 'normal'" v-show="!thisOuter" @click="notifyDelete">
              <i class="fas fa-trash is-size-4" style="color: red"></i>
            </div>
            <div v-else-if="mgqStatus == 'deleted'" @click="restoreOrDelete(false)">
              <i class="fas fa-trash-alt is-size-4" style="color: darkred"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, nextTick } from "vue";
import * as mixins from "@/utils/mixins";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  props: {
    mgq: {},
    outer: {
      type: Boolean,
      required: false,
      default: false,
    },
    mgqStatus: {
      type: String,
      required: false,
      default: "normal",
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const thisOuter = computed(() => props.outer);
    const thisMgq = computed(() => props.mgq);
    const colors = mixins.colors;


    const shouldShowNowIcon = computed(() => {
      const datenow = new Date();
      if (
        thisOuter.value &&
        thisMgq.value.mgqVersion.startTime < datenow &&
        datenow < thisMgq.value.mgqVersion.endTime
      )
        return true;
      else return false;
    });
    const shouldShowContestIcon = computed(() => {
      const datenow = new Date();
      if (thisMgq.value.mgqVersion.endTime < datenow) return true;
      else return false;
    });

    async function notifyDelete() {
      let res = await mixins.alertConfirm(
        "Are you sure you want to delete this mindgluequest?",
        true
      );
      if (res) {
        const success = await store.dispatch("core/deleteMindgluequest", {
          mgqId: thisMgq.value.mindgluequestId,
        });
        if (success) {
        //  setTimeout(() => {
          await nextTick();
          await store.dispatch("core/getMyMindgluequests");
         await  store.dispatch("core/retrieveDeteledMindgluequests");
        //  }, 100);
        } else
          await mixins.alertConfirm("Unfortunately an error occured.", false);
      }
    }

    async function setInitialSelected(){
      if (props.outer){
        showSelected();
      }
      else {
        setSelected();
      }
    }

    async function setSelected() {
      if (props.mgqStatus == "normal") {
        store.commit("core/setSelectedMgq", thisMgq.value);
        router.push("createEditMgq");
      } else {
        await mixins.alertConfirm(
          "This is a deleted mindgluequest. If you want to edit it, you have to restore it by clicking on the restore icon (upper arrow) on the right.",
          false
        );
      }
    }

    async function restoreOrDelete(toRestore) {
      let reallyDelete = false;
      if (!toRestore) {
        reallyDelete = await mixins.alertConfirm(
          "You are about to delete forever the mindgluequest and all its parts. This cannot be undone. Are you sure you want to go through with it?",
          true
        );
      }
      if (toRestore || reallyDelete) {
        const success = await store.dispatch("core/restoreOrDelete", {
          mgq: thisMgq.value,
          toRestore: toRestore,
        });
        if (success) {
          setTimeout(() => {
            store.dispatch("core/getMyMindgluequests");
            store.dispatch("core/retrieveDeteledMindgluequests");
          }, 100);
          await mixins.alertConfirm(
            toRestore
              ? "Mindgluequest restored."
              : "Mindgluequest forever deleted.",
            false
          );
        } else {
          await mixins.alertConfirm("Unfortunately an error occured.", false);
        }
      }
    }

    function showContest() {
      router.push("/contestResults/" + thisMgq.value.mindgluequestId);
    }

    function showSelected() {
      if (thisOuter.value) {
        router.push("/qd/" + thisMgq.value.mindgluequestId);
      }
    }


    async function copyToNew() {
      let shouldCopy = false;
      shouldCopy = await mixins.alertConfirm(
        "Are you sure you want to create a copy of the selected mindgluequest?",
        true
      );
      if (shouldCopy) {
        store.commit("core/setCreatedMgq", thisMgq.value);
        router.push("createEditMgq");
      }
    }

    return {
      thisOuter,
      shouldShowNowIcon,
      shouldShowContestIcon,
      mixins,
      colors,
      setInitialSelected,
      notifyDelete,
      setSelected,
      showContest,
      showSelected,
      restoreOrDelete,
      copyToNew,
    };
  },
};
</script>
<style scoped>
.shortDescr_p {
  text-align: center;
}

.deleted_mgq {
  color: red;
  font-size: 1.5em;
  font-weight: bold;
}

.centerAligned {
  align-items: center;
}

.roundedCorners {
  border-radius: 50%;
}
</style>