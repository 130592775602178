<template>
  <div class="boxy">
    <PageHeader :with_login="true" />
    <div class="rowy contenty">
      <div class="pt-4">
        <h1 class="title">Dashboard</h1>
      </div>
      <div @click="goToCreate" class="button is-primary m-4">Create</div>
      <div @click="createFromImages" class="button is-primary m-4">Create from image files
        <input type="file" style="display:none" id="createFromImages" multiple accept="image/png, image/jpeg"
          @change="setImageFiles" />
      </div>
     
      <div class="select m-2" style="align-self: center;">
        <select v-model="sorting" @change="compareMgqs">
          <option value="title">Title</option>
          <option value="startTime">Start time</option>
          <option value="endTime">End time</option>
          <option value="price">Price</option>
          <option value="active">Active</option>
          <option value="virtual">Virtual</option>
          <option value="showPoints">Show mindsets</option>
          <option value="searchable">Searchable</option>
          <option value="editable">Editable</option>
          <option value="forContest">Is contest</option>
        </select>
      </div>
      <div class="p-2">
        <div v-for="item in mindgluequests" :key="item.mindgluequestId">
          <MgqTile :mgq="item" :mgqStatus="'normal'" class="pb-2" />
        </div>
      </div>
      <div>
        <div class="button mb-4" @click="openDeleted">
          <i class="fas fa-dumpster" style="color: red"></i>
        </div>
        <div v-if="dumpsterIsOpen" class="p-2">
          <div v-for="deletedMgq in deletedMgqs" :key="deletedMgq.mindgluequestId">
            <MgqTile :mgq="deletedMgq" :mgqStatus="'deleted'" class="pb-2" />
          </div>
        </div>
      </div>
      <div v-if="showModal">
        <PageModal @close="closeModal" @confirm="goOn">
          <TermsPrivacyAgreement />
          <div class="pt-2" style="color: yellow">
            I have read the above Terms of Service and the Privacy statement and
            I agree.<input type="checkbox" v-model="hasAgreed" class="ml-2" />
          </div>
        </PageModal>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import PageHeader from "@/components/parts/PageHeader.vue";
import MgqTile from "@/components/parts/MgqTile.vue";
import PageModal from "@/components/parts/PageModal.vue";
import PageFooter from "@/components/parts/PageFooter.vue";
import TermsPrivacyAgreement from "@/components/parts/TermsPrivacyAgreement.vue";
import { onBeforeMount, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as mixins from "@/utils/mixins";
import * as gameMixins from "@/utils/gameMixins";

export default {
  components: {
    PageHeader,
    MgqTile,
    PageFooter,
    PageModal,
    TermsPrivacyAgreement,
  },
  setup() {
    /*eslint-disable no-unused-vars*/

    const store = useStore();
    /*eslint-disable no-unused-vars*/

    const router = useRouter();
    onBeforeMount(async () => {
      store.commit("core/renewAfterCreate");
      await store.dispatch("core/getMyMindgluequests");
      compareMgqs();

    });

    const hasAgreed = ref(false);
    const showModal = ref(false);
    const dumpsterIsOpen = ref(false);
    const deletedMgqs = computed(() => store.getters["core/getDeletedMgqs"]);

    const mindgluequestsGetter = computed(
      () => store.getters["core/getMindgluequests"]
    );

    watch(
      () => mindgluequestsGetter.value,
      () => {
        mindgluequests.value = mindgluequestsGetter.value;
      }
    );

    const mindgluequests = ref([]);
    const sorting = ref("title");

    async function goToCreate() {
      if (store.getters["core/getUser"].email.indexOf("temp.mindglue.io") > -1) {
        let shouldGoToSignup = await mixins.alertConfirm("You are logged in with a generic account. If you want to create content, you have to sign up. Would you like to go to the Sign Up page?", true);
        if (shouldGoToSignup) {
          store.commit("core/setTemporaryContent", { isSignup: true });
          router.push('/login');
        }
      }
      else {
        showModal.value = true;
      }
    }


    function compareMgqs() {
      let mgqs = mixins.cloneArray(mindgluequestsGetter.value);
      if (sorting.value == "title") {
        mgqs.sort(function (a, b) { return (a.title > b.title) ? 1 : -1 });
      }
      else if (sorting.value == "startTime") {
        mgqs.sort(function (a, b) { return a.startTime - b.startTime });
      }
      else if (sorting.value == "endTime") {
        mgqs.sort(function (a, b) { return a.endTime - b.endTime });
      }
      else if (sorting.value == "price") {
        mgqs.sort(function (a, b) { return a.price - b.price });
      }
      else if (sorting.value == "active") {
        mgqs.sort(function (a, b) { return a.active != b.active });
      }
      else if (sorting.value == "virtual") {
        mgqs.sort(function (a, b) { return a.virtual != b.virtual });
      } else if (sorting.value == "showPoints") {
        mgqs.sort(function (a, b) { return a.showPoints != b.showPoints });
      } else if (sorting.value == "searchable") {
        mgqs.sort(function (a, b) { return a.searchable != b.searchable });
      } else if (sorting.value == "editable") {
        mgqs.sort(function (a, b) { return a.editable != b.editable });
      }
      else if (sorting.value == "forContest") {
        mgqs.sort(function (a, b) { return a.forContest != b.forContest });
      }
      mindgluequests.value = mgqs;
    }

    function goOn() {
      if (hasAgreed.value) {
        store.commit("core/removeSelectedMgq");
        store.commit("core/removeCreatedMgq");
        router.replace("createEditMgq");
      }
    }

    function closeModal() {
      if (router.currentRoute.value.path != "/dashboard") {
        router.replace("dashboard");
      }
      showModal.value = false;
    }

    async function openDeleted() {
      dumpsterIsOpen.value = !dumpsterIsOpen.value;
      if (dumpsterIsOpen.value) {
        await store.dispatch("core/retrieveDeteledMindgluequests");
      }
    }

   

    async function createFromImages() {
      let element = document.getElementById("createFromImages");
      element.click();
    }

    const images = ref([]);

    async function setImageFiles(event) {
      images.value = [];
      if (event.target.files.length > 0) {
        for (let file of event.target.files) {
          let extensionsList = file.name.split(".");
          let extension = "";
          let onlyName = file.name;
          if (extensionsList.length > 1) {
            let lastExt = extensionsList.pop().toLowerCase();
            if (lastExt == "jpg" || lastExt == "jpeg" || lastExt == "png") {
              extension = lastExt;
              onlyName = extensionsList.toString().replaceAll(",", " ");
            }
          }
          let imageFile = { name: onlyName.split("_"), file: file, extension: extension };
          images.value.push(imageFile);
        }
        images.value.sort(function (a, b) { return b.name[0] > a.name[0] });

        let previousMgqId = -1;

        for (let i = images.value.length - 1; i > -1; i--) {
          images.value[i].name[1] = images.value[i].name[1].replaceAll("-", " ");
          let isFirst = false;
          if (i == 0) {
            isFirst = true;
          }
          let chain = null;
          if (previousMgqId != -1) {
            chain = previousMgqId;
          }
          let mgq = gameMixins.makeEmptyMgq(images.value[i].name[1], images.value[i].name[2], isFirst, chain);
          store.commit("core/setCreatedMgq", mgq);
          let succeeded = await store.dispatch("core/requestCreateMgq", {
            mgq: mgq,
            imageFile: null,
          });
          if (succeeded == "true") {
            previousMgqId = store.getters["core/getterCreatedMgq"].mindgluequestId;

            await saveMgqDrawingImageForMgq(mgq, images.value[i].file, images.value[i].name,i, previousMgqId);
            await saveMgqDrawingNoBackgroundForMgq(previousMgqId);

          }

        }
      }

    }

    async function saveMgqDrawingImageForMgq(mgq,imageFile, imageName, i,previousMgqId) {
      await mixins.saveMgqMapImage(imageFile, imageName, previousMgqId, false);
      let imageWidthToHeightRatio = mixins.makeImageWidthHeightRatio(imageFile);
      let myDrawing = { latlngs: [], keys: [] };

      let startCoordLat = mixins.makeOneCoordFromDistance(Number(mgq.latitude), false, 5000);
      let startCoordLng = mixins.makeOneCoordFromDistance(Number(mgq.longitude), false, 5000 * imageWidthToHeightRatio);
      myDrawing.latlngs.push([
        startCoordLat,
        startCoordLng,
      ]);
      let endCoordLat = mixins.makeOneCoordFromDistance(Number(mgq.latitude), true, 5000);
      let endCoordLng = mixins.makeOneCoordFromDistance(Number(mgq.longitude), true, 5000 * imageWidthToHeightRatio);
      myDrawing.latlngs.push([
        endCoordLat,
        endCoordLng,
      ]);
      myDrawing.properties = JSON.stringify({
        name:
          previousMgqId.toString() +
          "_" +
          imageName,
        zoomLevels: { min: 0, max: 99 }
      });
      myDrawing.keys.push({
        key: (100 + i).toString(),
        drawingType: "image",
      });

      await store.dispatch("core/saveMgqDrawing", {
        id: 0,
        mindgluequestId: previousMgqId.toString(),
        coordinates: JSON.stringify(myDrawing.latlngs),
        borderColor: "",
        fillColor: "",
        properties: myDrawing.properties,
        drawingType: "image",
      });
    }

    async function saveMgqDrawingNoBackgroundForMgq(previousMgqId) {
      let mgqNoBackgroundDrawing = {
        id: 0,
        mindgluequestId: previousMgqId,
        coordinates: "[]",
        borderColor: "#ed008c",
        fillColor: "#6495ed",
        properties: "{\"noMapColor\":\"#000000\"}",
        drawingType: "noMapBoard"
      };
      await store.dispatch("core/saveMgqDrawing", mgqNoBackgroundDrawing);
    }


    return {
      mindgluequests,
      hasAgreed,
      showModal,
      dumpsterIsOpen,
      deletedMgqs,
      images,
      sorting,
      compareMgqs,
      openDeleted,
      closeModal,
      goToCreate,
      goOn,
      createFromImages,
      setImageFiles,
    };
  },
};
</script>
