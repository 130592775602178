<template>
  <div>
    <div class="these_two">
      <div class="control">

        <div style="display: flex; justify-content: center; align-items: center;">
          <div><input type="checkbox" v-model="editorToggle" @change="updateContentToggled" />&nbsp; Editor</div>
          <div v-if="!editorToggle" class="pl-4" style="font-size: small; color: grey;">characters:&nbsp;{{ totalLength }}
          </div>
        </div>
        <div class="mb-6" v-show="editorToggle">
          <QuillEditor :options="options" ref="quillEditorRef" :content="mindchunkInner.questionname" contentType="html">
          </QuillEditor>
        </div>
        <div v-show="!editorToggle">
          <textarea class="textarea mindchunk_area" :class="{ mindchunk_empty: isEmpty || isOver }" rows="4"
            :disabled="isSocial" v-model="mindchunkInner.questionname"></textarea>
        </div>
        <div v-for="answer in mindchunkInner.answerlist" :key="answer.answerid">
          <MindChunkReaction :answer="answer" :isSocial="isSocial" :ref="setReactionRef" @removeReaction="removeReaction"
            @changeAnswersInturn="changeAnswersInturn">
          </MindChunkReaction>
        </div>
      </div>
      <div>
        <div :class="{ button: !isSocial, '': !isSocial }" @click="removeMindchunk">
          <i class="fas fa-minus is-size-5"></i>
        </div>
        <div :class="{ 'button is-small is-rounded': !isSocial }" @click="addReactionHere">
          +
        </div>
        <div style="margin-top:0.5em; display: flex; justify-content: center;">
          <div class="is-small" @click="changeInturn(true)" style="background-color: white;">
            <i class="fas fa-angle-up is-size-5"></i>
          </div>
          &nbsp;
          <div class="is-small" @click="changeInturn(false)" style="background-color: white;">
            <i class="fas fa-angle-down is-size-5"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  onBeforeMount,
  onBeforeUpdate, computed, ref,
} from "vue";
import MindChunkReaction from "@/components/parts/MindChunkReaction.vue";
import * as mixins from "@/utils/mixins";
import { Quill, QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import BlotFormatter from 'quill-blot-formatter';

Quill.register('modules/blotFormatter', BlotFormatter);
const alignClass = Quill.import('attributors/style/align');
const backgroundClass = Quill.import('attributors/style/background');
const colorClass = Quill.import('attributors/style/color');
const directionClass = Quill.import('attributors/style/direction');
const fontClass = Quill.import('attributors/style/font');
const sizeClass = Quill.import('attributors/style/size');

Quill.register(alignClass, true);
Quill.register(backgroundClass, true);
Quill.register(colorClass, true);
Quill.register(directionClass, true);
Quill.register(fontClass, true);
Quill.register(sizeClass, true);

export default {
  components: {
    MindChunkReaction,
    QuillEditor,
  },
  props: {
    mindchunkProp: {
      type: Object,
      required: true,
    },
    isSocial: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const mindchunkInner = ref(null);


    const isEmpty = computed(() => {
      if (undefined != mindchunkInner.value) {
        if (undefined != mindchunkInner.value.questionname) {
          return mindchunkInner.value.questionname.trim() == "";
        } else return true;
      } else return true;
    });

    const isOver = computed(() => {
      if (undefined != mindchunkInner.value) {
        if (undefined != mindchunkInner.value.questionname) {
          return mindchunkInner.value.questionname.length > 10000;
        } else return false;
      } else return false;
    });

    const editorToggle = ref(true);

    const quillEditorRef = ref(null);
    const options = ref({
      modules: {
        toolbar: {

          container: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            //   [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['image'], ['video'], ['link'],
            ['clean']                                         // remove formatting button
          ],
          handlers: {
            image: imageHandler
          }

        },
        blotFormatter: {
          module: BlotFormatter,
          options: {/* options */ }
        }


      },
      readOnly: false,
    });


    let reactionRefs = [];
    const setReactionRef = (el) => {
      if (el) {
        reactionRefs.push(el);
      }
    };

    const totalLength = computed(() => {
      if (undefined != mindchunkInner.value.questionname) {
        return mindchunkInner.value.questionname.length;
      }
      else return 0;
    });


    function imageHandler() {
      let range = quillEditorRef.value.getQuill().getSelection();
      let value = prompt('What is the image URL');
      quillEditorRef.value.getQuill().insertEmbed(range.index, 'image', value);
    }


    /*  const toolbarOptions = computed(() => [
       ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
       ['blockquote', 'code-block'],
 
       [{ 'header': 1 }, { 'header': 2 }],               // custom button values
       [{ 'list': 'ordered' }, { 'list': 'bullet' }],
       [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
       [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
       [{ 'direction': 'rtl' }],                         // text direction
 
    //   [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
       [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
 
       [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
       [{ 'font': [] }],
       [{ 'align': [] }],
 
       ['clean']                                         // remove formatting button
     ]);
  */
    onBeforeUpdate(() => {
      reactionRefs = [];
    });

    onBeforeMount(() => {
      mindchunkInner.value = mixins.cloneObject(props.mindchunkProp);
      sortAnswerListByInturns();
    });



    function removeMindchunk() {
      if (!props.isSocial) {
        emit("removeMindchunk", mindchunkInner.value.questionid);
      }
    }

    function changeInturn(isUp) {
      if (!props.isSocial) {
        emit("changeInturn", mindchunkInner.value.questionid, isUp);
      }
    }


    async function changeAnswersInturn(answerId, isUp) {
      await gatherReactions();
      let reaction = mindchunkInner.value.answerlist.filter(
        (x) => x.answerid == answerId
      )[0];
      if (isUp) {
        if (reaction.inturn > 0) {
          for (let reac of mindchunkInner.value.answerlist) {
            if (reac.inturn == reaction.inturn - 1) {
              let requestedInturn = reac.inturn;
              reac.inturn = reaction.inturn;
              reaction.inturn = requestedInturn;
              break;
            }
          }
        }
      }
      else {
        if (reaction.inturn < mindchunkInner.value.answerlist.length) {
          for (let reac of mindchunkInner.value.answerlist) {
            if (reac.inturn == reaction.inturn + 1) {
              let requestedInturn = reac.inturn;
              reac.inturn = reaction.inturn;
              reaction.inturn = requestedInturn;
              break;
            }
          }
        }
      }
      await gatherReactions();
      sortAnswerListByInturns();
    }

    function normalizeInturns() {
      sortAnswerListByInturns();
      for (let i = 0; i < mindchunkInner.value.answerlist.length; i++) {
        mindchunkInner.value.answerlist[i].inturn = i;
      }
    }

    async function sortAnswerListByInturns() {
      if (mindchunkInner.value.answerlist) {
        if (mindchunkInner.value.answerlist.length > 0) {
          mindchunkInner.value.answerlist.sort(function (a, b) { return (a.inturn - b.inturn) })
        }
      }
    }



    function addReactionHere() {
      if (!props.isSocial) {
        if (!mindchunkInner.value.answerlist) {
          makeReactionlist();
        } else {
          addReaction();
        }
      }
    }


    function makeReactionlist() {
      mindchunkInner.value["answerlist"] = [{ answername: "", answerid: -1, inturn: 0 }];
    }
    function addReaction() {
      let smallestIdx = -1;
      let biggestInturn = 0;
      for (let ansob of mindchunkInner.value.answerlist) {
        if (ansob.answerid <= smallestIdx) {
          smallestIdx = Number(ansob.answerid) - 1;
        }
        if (ansob.inturn >= biggestInturn) {
          biggestInturn = Number(ansob.inturn) + 1;
        }
      }
      mindchunkInner.value.answerlist.push({
        answername: "",
        answerid: smallestIdx,
        answernameid: -1,
        inturn: biggestInturn,
        grade: 50,
      });
    }

    async function removeReaction(reactionId) {
      await gatherReactions();
      let reaction = mindchunkInner.value.answerlist.filter(
        (x) => x.answerid == reactionId
      )[0];
      let idx = mindchunkInner.value.answerlist.indexOf(reaction);
      mindchunkInner.value.answerlist.splice(idx, 1);
      normalizeInturns();
    }

    function setQuestionname(questionname) {
      mindchunkInner.value.questionname = questionname;
    }

    async function gatherReactions() {
      let reactList = [];
      for (let reac of reactionRefs) {
        let gatheredReac = await reac.provideReaction();
        reactList.push(gatheredReac);
      }
      mindchunkInner.value.answerlist = reactList;
    }

    function updateContentToggled() {
      if (!editorToggle.value) {
        mindchunkInner.value.questionname = quillEditorRef.value.getContents();
      }
    }

    async function provideMindchunk() {
      mindchunkInner.value.questionname = quillEditorRef.value.getContents();
      let quname = quillEditorRef.value.getContents();
      mindchunkInner.value.questionname = quname;
      if (isOver.value) {
        let showMessage = await mixins.alertConfirm("The mindchunk starting with" + mindchunkInner.value.questionname.substring(0, 50) + "... is too long. Each mindchunk should not as a whole (check raw output turning editor off) be more than 10.000 characters long. It was trimmed at that size.", false);
        if (showMessage) {
          mindchunkInner.value.questionname = mindchunkInner.value.questionname.substring(0, 9995);
        }
        else {
          mindchunkInner.value.questionname = "dummy mchk";
        }
      }
      await gatherReactions();
      return mindchunkInner.value;
    }

    return {
      mindchunkInner,
      isEmpty,
      removeMindchunk,
      setQuestionname,
      addReactionHere,
      removeReaction,
      setReactionRef,
      provideMindchunk,
      quillEditorRef,
      options,
      editorToggle,
      updateContentToggled,
      isOver,
      totalLength,
      changeInturn,
      changeAnswersInturn,
    };
  },
};
</script>
<style scoped>
.these_two {
  display: grid;
  grid-template-columns: 8fr 1fr;
  align-items: top;
  gap: 0.3rem;
}

.mindchunk_empty {
  border: red;
  border-style: solid;
}
</style>