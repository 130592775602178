import AppHome from '@/components/pages/AppHome.vue'
import LogIn from '@/components/pages/LogIn.vue'
import DashBoard from '@/components/pages/DashBoard.vue'
import CreateEditMgq from '@/components/pages/CreateEditMgq.vue'
import MgqAttributes from '@/components/viewcomponents/MgqAttributes.vue'
import MgqTrophies from '@/components/viewcomponents/MgqTrophies.vue'
import TermsOfService from '@/components/parts/TermsOfService.vue'
import OurPrivacy from '@/components/parts/OurPrivacy.vue'
//import ContestResults from '@/components/pages/ContestResults.vue'
import ScoreAllUsers from '@/components/pages/ScoreAllUsers.vue'
import SelectedPresenter from '@/components/pages/SelectedPresenter.vue'
import PaypalAuth from '@/components/pages/PaypalAuth.vue'
import NotFoundComponent from '@/components/pages/NotFoundComponent.vue'
import OuterAuthenticator from '@/components/pages/OuterAuthenticator.vue'
import DrawAframe from '@/components/viewcomponents/DrawAframe.vue'
import DrawMindsets from '@/components/viewcomponents/DrawMindsets.vue'
import OuterMap from '@/components/pages/OuterMap.vue'
import OuterMapAlone from '@/components/pages/OuterMapAlone.vue'
import OuterAframe from '@/components/pages/OuterAframe.vue'
import OuterGame from '@/components/pages/OuterGame.vue'
import AccountPage from '@/components/pages/AccountPage.vue'
import TermsPrivacyWrapper from '../components/widgets/TermsPrivacyWrapper.vue'

export default [
  {
    path: '/',
    component: AppHome,
  },
  {
    path: '/login',
    component: LogIn,
  },
  {
    path: '/dashboard',
    component: DashBoard,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/quesdom-terms',
        component: TermsOfService,
      },
      {
        path: '/quesdom-privacy',
        component: OurPrivacy,
      },
    ],
  },
  {
    path: '/createEditMgq',
    name:'createEditMgqName',
    component: CreateEditMgq,
       meta: { requiresAuth: true },
    children: [
      {
        path: '/mgq_attributes',
        component: MgqAttributes,

      },
      {
        path: '/drawmindsets',
        component: DrawMindsets,
      },
      {
        path: '/drawaframe',
        component: DrawAframe,
      },
      {
        path: '/mgq_trophies',
        component: MgqTrophies
      },
    ]
  },
  {
    path: '/contestResults/:mgqid/:useridhashed?',
    component: ScoreAllUsers,
  },
  {
    path: '/qd/:mgqid',
    meta: { requiresAuth: false },

    component: SelectedPresenter,
  },
  {
    path: '/paypalAuth/:code',
    component: PaypalAuth,
  },
  {
    path: '/outerauth/:email/:password/:route/:message',
    component: OuterAuthenticator,
  },
  {
    path: '/outermap',
   // meta: { requiresAuth: true },
    component: OuterMap,
  },
  {
    path: '/terms-privacy',
    component: TermsPrivacyWrapper,
    meta: { requiresAuth: false },
    children: [
      {
        path: 'quesdom-terms',
        component: TermsOfService,
      },
      {
        path: 'quesdom-privacy',
        component: OurPrivacy,
      },
    ],
  },
  
  {
    path: '/outermapalone/:useridhashed/:jwt/:message',
    component: OuterMapAlone,
  },
  {
    path: '/outeraframe/:useridhashed/:jwt/:message',
    component: OuterAframe,
  },
  {
    path: '/outergame',
    component: OuterGame,
    meta: { requiresAuth: true },

  },
  {
    path: '/account',
  component: AccountPage,
    meta: { requiresAuth: false },

  },
  { 
    path: '/:catchAll(.*)', 
    component: NotFoundComponent
  }
]

