<template>
  <div class="rowy footery my_footer">
      <div>
        <p><a href="https://www.mindglue.io/mindglue_company" style="color:white; cursor:pointer;">Quesdom</a> - 2024. All Rights Reserved<a  aria-current="page" href="/terms-privacy/quesdom-privacy" class="router-link-active router-link-exact-active" style="color: white; font-weight: bold;">- Privacy Policy</a><a  aria-current="page" href="/terms-privacy/quesdom-terms" class="router-link-active router-link-exact-active" style="color: white; font-weight: bold;">- Terms</a></p>
      </div>
    </div>
</template>
<style scoped>
.my_footer {
  color: white;
  background-color: royalblue;
}
</style>