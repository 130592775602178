<template>
  <div>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div>
          <div class="outerdiv">
            <div v-if="isRealMap" style="height: 100%">
              <l-map
                ref="realMap"
                :zoom="zoom"
                :use-global-leaflet="true"
                :center="coord"
              >
                <l-tile-layer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                ></l-tile-layer>
                <l-control-layers />
                <l-marker
                  :lat-lng="coord"
                  draggable
                  @moveend="getFirstCoordinates"
                >
                </l-marker>
              </l-map>
            </div>
            <div v-else style="height: 100%">
              <DrawMindsetsMap
                ref="thisMap"
                :withCenterMarker="true"
                @centerMarkerChanged="centerMarkerChanged"
              />
            </div>
          </div>
          <button class="button is-primary mt-4" @click="closeModal">OK</button>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closeModal"
      ></button>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import DrawMindsetsMap from "@/components/parts/DrawMindsetsMap.vue";
import {
  LMap,
  LTileLayer,
  LControlLayers,
  LMarker,
} from "@vue-leaflet/vue-leaflet";
import * as mixins from "@/utils/mixins";

export default {
  components: {
    DrawMindsetsMap,
    LMap,
    LTileLayer,
    LControlLayers,
    LMarker,
  },
  props: {
    oneMarkerCoords: {
      type: Array,
    },
    isRealMap: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const zoom = ref(10);
    const realMap = ref(null);
    const thisMap = ref(null);
    let coord = ref(props.oneMarkerCoords);
    const isRealMapHere = ref(props.isRealMap);
    /* function showMapRight() {
      setTimeout(function () {
        if (isRealMapHere.value) {
          realMap.value.leafletObject.invalidateSize(true);
        } 
       
      }, 5);
    } */

    function getFirstCoordinates(e) {
      mixins.showMapRight(isRealMapHere.value, realMap.value);
      coord.value = [
        mixins.makeCoordinates(e.target._latlng.lat),
        mixins.makeCoordinates(e.target._latlng.lng),
      ];
    }

    function centerMarkerChanged(coordsFromDrawingMap) {
      coord.value = coordsFromDrawingMap;
    }

    function closeModal() {
      emit("close");
    }

    return {
      zoom,
      thisMap,
      realMap,
      getFirstCoordinates,
      closeModal,
      coord,
      isRealMapHere,
      centerMarkerChanged,
    };
  },
};
</script>
<style scoped>
.outerdiv {
  height: 500px;
  width: 100%;
  background-color: beige;
}
</style>