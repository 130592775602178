<template>
  <div>
    <div v-if="showModalDynamic">
      <div class="modal is-active">
        <div class="modal-background"></div>

        <div class="modal-content alert_confirm_content">
          <div style="display: flex; flex-flow: column; align-items: center;">
            <component ref="componentRef" v-bind:is="modalDynamicComponent"></component>
            <div>
              <button class="button is-primary mt-6" @click="closeModalDynamic(true)">
                OK
              </button>
              <button v-if="!modalDynamicObject.noCancel" class="button ml-4 mt-6" @click="closeModalDynamic(false)">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref, } from "vue";
import DynamicImageZoomLevels from "@/components/widgets/dynamicComponents/DynamicImageZoomLevels.vue";
import DynamicMultipleRadiobuttons from "@/components/widgets/dynamicComponents/DynamicMultipleRadiobuttons.vue";
import DynamicOneTextfield from "@/components/widgets/dynamicComponents/DynamicOneTextfield.vue";
import DynamicOneMarkerMap from "@/components/widgets/dynamicComponents/DynamicOneMarkerMap.vue";
import DynamicMindset from "@/components/widgets/dynamicComponents/DynamicMindset.vue";
import DynamicColorChooser from "@/components/widgets/dynamicComponents/DynamicColorChooser.vue";
import DynamicHtmlContentPresenter from "@/components/widgets/dynamicComponents/DynamicHtmlContentPresenter.vue";
import DynamicSecondsScoreCountDown from "@/components/widgets/dynamicComponents/DynamicSecondsScoreCountDown.vue";


export default {
  components: {
    DynamicImageZoomLevels,
    DynamicOneTextfield,
    DynamicMultipleRadiobuttons,
    DynamicOneMarkerMap,
    DynamicMindset,
    DynamicColorChooser,
    DynamicHtmlContentPresenter,
    DynamicSecondsScoreCountDown,
  },
  setup() {
    const store = useStore();
    const modalDynamicObject = computed(
      () => store.getters["core/getModalDynamicObject"]
    );

    const componentRef = ref(null);
    const modalDynamicComponent = computed(
      () => store.getters["core/getModalDynamicComponent"]
    );

    const showModalDynamic = computed(
      () =>
        modalDynamicObject.value.show);

    async function closeModalDynamic(val) {
      let isChecked = await componentRef.value.checkSubmit(val);
      if (val) {
        if (isChecked) {
          let temp = modalDynamicObject.value;
          temp.submitted = val;
          temp.show = false;
          store.commit("core/setModalDynamicObject", temp);
        }
        else {
          console.log("not checked");
        }
      }
      else {
        let temp = modalDynamicObject.value;
        temp.submitted = val;
        temp.show = false;
        store.commit("core/setModalDynamicObject", temp);
      }
    }

    return {
      showModalDynamic,
      modalDynamicObject,
      modalDynamicComponent,
      componentRef,
      closeModalDynamic,
    };
  },
};
</script>
<style scoped>
.alert_confirm_content {
  background-color: white;
  padding: 2em;
  border-radius: 0.5em;
  z-index: 10182;
}

.modal-background {
z-index: 10181;
}

.modal {
  z-index: 10180;
}
</style>