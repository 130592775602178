<template>
    <div>
        <div>{{ mso.payload.title }}</div>
        <div>
            <input class="input" v-model="content" :type="mso.payload.isPassword ? 'password' : 'text'"
                @change="checkContent" />
        </div>

    </div>
</template>
<script>
import { ref, onBeforeUnmount, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";

export default {
    setup() {
        const store = useStore();
        const content = ref("");
        const mso = computed(() => store.getters["core/getModalDynamicObject"]);

        onBeforeMount(() => {
            if (mso.value.payload.initialContent != undefined) {
                content.value = mso.value.payload.initialContent;
            }
        })
        onBeforeUnmount(() => {
            let obj = mso.value;
            obj.content = content.value;
            store.commit("core/setModalDynamicObject", obj);
        });

        async function checkContent() {
            if (content.value == "") {
                await mixins.alertConfirm("You have left the input field empty, this will likely cause problems.", false);
            }
        }

        async function checkSubmit(submitted) {
            if (submitted) {
                if (content.value == "") {
                    console.log("nonon");
                    await mixins.alertConfirm("The field cannot be left empty.", false);
                    return false;
                }
                else {
                    return true;
                }
            }
        }

        return {
            mso,
            content,
            checkContent,
            checkSubmit,
        };
    }
}
</script>