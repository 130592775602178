<template>
    <div class="boxy">
        <PageHeader :with_login="true" />
        <div class="rowy contenty">
            <div class="score_wrapper mt-4">
                <div>
                    <div class="title is-3">
                        Score
                    </div>
                    <div class="subtitle is-3" v-if="mgqTitle!=''">
                        {{ mgqTitle }}
                    </div>
                    <div v-for="(mgqScore, idx) of mgqScores" :key="idx">
                        <div class="expl_row card p-2">
                            <div>{{ mgqScore.username }}</div>
                            <div>{{ mgqScore.title }}</div>
                            <div>{{ mgqScore.score }}<span class="icon is-small is-left">
                                    <i class="fas fa-star" style="color:yellow;  text-shadow: 1px 1px #1d1717;"></i>
                                </span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PageFooter />
    </div>

</template>
<script>
import { onBeforeMount, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import PageHeader from '@/components/parts/PageHeader.vue';
import PageFooter from '@/components/parts/PageFooter.vue';
export default {
    components: {
        PageHeader,
        PageFooter,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const mgqId = router.currentRoute.value.params.mgqid;

        onBeforeMount(async () => {

            mgqScores.value = await store.dispatch("gameApi/fetchScoreAllUsers", mgqId);
        });

        const mgqScores = ref([]);
        const mgqTitle = computed(() => {
            let toReturn = "";
            if (store.getters["core/getMindgluequests"] != undefined) {
                let sameMgq = store.getters["core/getMindgluequests"].filter((x) => x.mindgluequestId == mgqId);
                if (sameMgq.length > 0) {
                    toReturn = sameMgq[0].title;
                }
            }
            return toReturn;
        });

        return {
            mgqScores,
            mgqTitle,
        };
    }
}
</script>
<style>
.score_wrapper {
    width: 50%;
    height: 100%;
    margin: auto;
}

/* .user_data {
    text-align: center;
    width: 50%;
} */

.duoplette {
    display: flex;
}
</style>