export function getFlag(color){


let finishFlag = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="29.122px" height="32.011px" viewBox="0 0 29.122 32.011" enable-background="new 0 0 29.122 32.011" xml:space="preserve">
<g>
<path fill-rule="evenodd" clip-rule="evenodd" fill="#${color}" d="M1.582,3.579C3.113,2.757,4.52,1.833,6.05,1.215
   c3.861-1.559,7.747-1.764,11.557,0.251c0.043,0.022,0.086,0.044,0.128,0.068c3.323,1.917,6.444,1.271,9.459-0.742
   c0.241-0.161,0.462-0.374,0.726-0.475c0.294-0.113,0.655-0.223,0.932-0.145c0.152,0.043,0.27,0.479,0.271,0.739
   c0.002,5.745-0.02,11.49-0.015,17.236c0,0.545-0.206,0.824-0.684,1.039c-2.867,1.291-5.796,1.572-8.83,0.654
   c-1.859-0.563-3.755-1.003-5.709-0.661c-2.116,0.371-3.834,1.542-5.402,2.941c-0.132,0.118-0.102,0.527-0.022,0.757
   c0.906,2.622,1.836,5.235,2.759,7.851c0.064,0.182,0.202,0.383,0.166,0.544c-0.059,0.265-0.176,0.61-0.377,0.718
   c-0.152,0.081-0.547-0.094-0.702-0.271c-0.195-0.223-0.266-0.561-0.371-0.855C6.719,21.896,3.504,12.926,0.291,3.957
   C0.258,3.866,0.239,3.771,0.213,3.677C0.084,3.209-0.288,2.653,0.422,2.38C1.154,2.098,1.198,2.8,1.417,3.216
   C1.496,3.365,1.557,3.523,1.582,3.579z M10.517,13.274c0.123,0.217,0.275,0.423,0.362,0.654c0.523,1.388,1.031,2.781,1.476,3.988
   c1.794,0,3.418,0,5.111,0c-0.462-1.785-0.894-3.456-1.326-5.127c1.376,0.317,2.752,0.635,4.236,0.978
   c-0.41-1.863-0.805-3.66-1.217-5.534c1.639,0.71,3.244,0.901,4.962,0.539c0.132,1.737,0.257,3.369,0.393,5.142
   c-1.378,0-2.664,0-3.996,0c0,0.132-0.029,0.301,0.005,0.456c0.291,1.32,0.612,2.633,0.882,3.958
   c0.114,0.561,0.395,0.752,0.95,0.717c0.652-0.042,1.308-0.027,1.962-0.014c0.465,0.009,0.635-0.144,0.583-0.647
   c-0.136-1.324-0.206-2.655-0.316-3.982c-0.03-0.359,0.03-0.551,0.445-0.663c0.813-0.22,1.595-0.55,2.395-0.819
   c0.374-0.125,0.505-0.345,0.498-0.751c-0.028-1.576-0.015-3.152-0.023-4.729c0-0.151-0.043-0.302-0.061-0.412
   c-1.229,0.577-2.417,1.134-3.67,1.723c-0.157-1.731-0.298-3.288-0.45-4.956c-2.025,0.489-3.866,0.137-5.767-0.701
   c0.388,1.741,0.741,3.332,1.101,4.949c-1.521-0.565-2.983-1.108-4.445-1.651c-0.061,0.079-0.122,0.157-0.182,0.235
   c0.579,2.062,1.158,4.123,1.736,6.183c-1.931-0.422-3.818-0.324-5.647,0.469c-0.101-0.345-0.18-0.699-0.306-1.034
   C9.601,10.62,8.979,9.001,8.348,7.346c1.982-0.757,3.981-0.912,6.081-0.847c-0.439-1.671-0.821-3.214-1.271-4.737
   c-0.058-0.195-0.458-0.405-0.704-0.41c-1.826-0.041-3.608,0.23-5.351,0.805c-0.599,0.197-0.659,0.47-0.44,1.002
   c0.485,1.183,0.908,2.392,1.392,3.575C8.234,7.17,8.126,7.347,7.72,7.566C6.594,8.172,5.506,8.85,4.397,9.491
   c-0.445,0.258-0.567,0.563-0.354,1.07c0.364,0.866,0.654,1.763,0.979,2.646c0.346,0.939,0.697,1.877,1.005,2.708
   C7.578,15.003,9.047,14.139,10.517,13.274z"/>
<path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M16.165,12.81c-0.579-2.062-1.158-4.124-1.737-6.185
   c0.06-0.078,0.121-0.156,0.182-0.235c1.462,0.543,2.924,1.086,4.445,1.651c-0.36-1.618-0.713-3.208-1.101-4.949
   c1.9,0.837,3.741,1.19,5.767,0.701c0.151,1.667,0.292,3.225,0.45,4.956c1.254-0.589,2.441-1.146,3.67-1.723
   c0.017,0.11,0.06,0.261,0.061,0.412c0.008,1.576-0.005,3.153,0.023,4.729c0.008,0.407-0.124,0.626-0.498,0.751
   c-0.8,0.27-1.583,0.6-2.395,0.819c-0.416,0.112-0.475,0.304-0.445,0.663c0.11,1.327,0.181,2.658,0.316,3.982
   c0.051,0.503-0.119,0.656-0.583,0.647c-0.654-0.013-1.311-0.028-1.962,0.014c-0.555,0.036-0.835-0.156-0.95-0.717
   c-0.271-1.324-0.592-2.638-0.882-3.958c-0.034-0.155-0.005-0.323-0.005-0.456c1.332,0,2.618,0,3.996,0
   c-0.136-1.773-0.26-3.405-0.393-5.142c-1.718,0.363-3.323,0.171-4.962-0.539c0.412,1.874,0.807,3.67,1.217,5.534
   c-1.484-0.342-2.86-0.66-4.237-0.979C16.14,12.787,16.165,12.81,16.165,12.81z"/>
<path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.517,13.275c-1.47,0.864-2.939,1.729-4.489,2.64
   c-0.309-0.832-0.66-1.769-1.005-2.708c-0.325-0.883-0.615-1.78-0.979-2.646c-0.213-0.507-0.09-0.812,0.354-1.07
   C5.506,8.85,6.594,8.172,7.72,7.566C8.126,7.347,8.234,7.17,8.056,6.734C7.571,5.551,7.149,4.342,6.664,3.16
   c-0.219-0.532-0.159-0.805,0.44-1.002c1.743-0.575,3.525-0.846,5.351-0.805c0.246,0.005,0.646,0.215,0.704,0.41
   c0.449,1.523,0.832,3.065,1.271,4.737c-2.1-0.065-4.099,0.09-6.081,0.847c0.63,1.655,1.252,3.273,1.862,4.896
   c0.126,0.335,0.205,0.689,0.306,1.034L10.517,13.275z"/>
<path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M10.516,13.277c1.829-0.793,3.716-0.891,5.647-0.468
   c0.001,0.001-0.024-0.022-0.023-0.021c0.433,1.673,0.865,3.344,1.327,5.129c-1.693,0-3.317,0-5.111,0
   c-0.445-1.208-0.953-2.601-1.476-3.988c-0.087-0.23-0.24-0.437-0.362-0.653L10.516,13.277z"/>
</g>
</svg>`;
return finishFlag;
}