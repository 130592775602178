
import router from '../router'
import * as DBProvider from '@/utils/DBProvider';
import * as mixins from '@/utils/mixins';
import store from '../store';


export async function setUsMindgluequest(mgq, latitude, longitude) {
  let toReturn = "joined";
  mgq = await handleContinuing(mgq);
  let success = await store.dispatch("gameApi/setUsMindgluequestApi", { mindgluequest: mgq });
  if (success) {
    if (store.getters["gameApi/getCurrentMgq"].mindgluequestId == mgq.mindgluequestId) {
      let longToTake = longitude == "" ? store.getters["core/getUser"].longitude : longitude;
      let latToTake = latitude == "" ? store.getters["core/getUser"].latitude : latitude;
      await handleScoreDeletion(mgq);
      await takeMeToPlace(mgq, longToTake, latToTake, 17);
    }
    else { toReturn = "stopped"; }
  }
  else {
    await mixins.alertConfirm("Something went wrong", false);
  }
  return toReturn;
}


export function makeResultFromImmediateGrading(mindset, targets, mgqScore) {
  let res = {};
  let success = mindset.properties.isImmediateGrading == "true" ? true : false;
  let grade = success ? 100 : 0;
  if (mindset.properties.negativeGrading != undefined) {
    if (mindset.properties.negativeGrading <= 100) {
      grade = -Math.abs(mindset.properties.negativeGrading);
    }
    else {
      grade = -100;
    }
  }
  let checkResult = checkIfMgqFinish(mindset, targets, mgqScore);
  if (checkResult == "not") {
    res.isInBetweenGamecode = false;
    res.isFinishGamecode = false;
  }
  else if (checkResult == "isInBetweenGamecode") {
    res.isInBetweenGamecode = true;
    res.isFinishGamecode = false;
  }
  else if (checkResult == "isFinishGamecode") {
    res.isInBetweenGamecode = false;
    res.isFinishGamecode = true;
  }
  res.grade = grade;
  res.success = success;
  return res;
}

function checkIfMgqFinish(mindset, targets, mgqScore) {
  let toReturn = "not";
  let targetsClone = mixins.cloneArray(targets);
  if (targetsClone.indexOf(mindset.id) > -1) {
    toReturn = "isInBetweenGamecode";
    if (mgqScore != undefined) {
      for (let mindsetGraded in mgqScore) {
        let indexOfMindsetGraded = targetsClone.indexOf(Number(mindsetGraded));
        if (indexOfMindsetGraded > -1) {
          targetsClone.splice(indexOfMindsetGraded, 1);
        }
      }
    }
    if (targetsClone.length == 1 && targetsClone[0] == mindset.id) {
      toReturn = "isFinishGamecode";
    }
  }
  return toReturn;
}


async function handleContinuing(mgq) {
  let toReturnMgq = mgq;
  if (mgq.properties.allowContinuing != undefined) {
    let mgqScore = await DBProvider.readDBKeyValue("mgq_scores", mgq.mindgluequestId.toString());
    if (mgqScore != undefined && mgqScore.chain != undefined) {
      let msg = "You have entered this quest before. Would you like to enter from your previous place";
      if (mgq.properties.allowContinuing != "0") {
        msg = msg + ", with a penalty on your score of " + mgq.properties.allowContinuing;
        await handlePenalty(mgqScore, mgq);
      }
      msg = msg + " stars? Press Cancel to start anew.";
      let shouldStartBack = await mixins.alertConfirm(msg, true);
      if (shouldStartBack) {

        let chainedMgqArrAndScore = await retrieveChainedMgqIdArrAndScoreFromFirstMgqId([mgq.mindgluequestId], { totalScore: 0, penalty: null });
        let mgqNext = await store.dispatch("gameApi/requestOneMgq", chainedMgqArrAndScore.chainedMgqIdArr[chainedMgqArrAndScore.chainedMgqIdArr.length - 1]);
        if (mgqNext.mindgluequestId == store.getters["gameApi/getCurrentMgq"].mindgluequestId) {
          await store.dispatch("gameApi/setUsMindgluequestApi", { mindgluequest: mgqNext });
        }
        store.commit("gameApi/incrementCurrentScore", chainedMgqArrAndScore.scoreAndPenalty.totalScore);
        toReturnMgq = mgqNext;
      }
    }
  }
  return toReturnMgq;
}

export async function preloadImage(url) {
  //export async function preloadImage(url,mindgluequestId) {
    store.commit("core/setIsLoading", true);
  const img = new Image();
  img.src = url;
  await img.decode();
 // await DBProvider.writeDBKeyValue("mgq_assets", mindgluequestId.toString()+"_"+url, img);
  store.commit("core/setIsLoading", false);
  return true;
}


async function handlePenalty(mgqScore, mgq) {
  if (mgq.properties.allowContinuing.indexOf("%") > -1) {
    mgqScore["penalty"] = { type: "percentage", amount: Number(mgq.properties.allowContinuing.replace("%", "")) }
  }
  else {
    mgqScore["penalty"] = { type: "subtraction", amount: Number(mgq.properties.allowContinuing) };
  }
  await DBProvider.writeDBKeyValue("mgq_scores", mgq.mindgluequestId.toString(), mgqScore);
}

async function retrieveChainedMgqIdArrAndScoreFromFirstMgqId(chainedMgqIdArr, scoreAndPenalty) {
  let mgqIdToSearch = chainedMgqIdArr[chainedMgqIdArr.length - 1].toString();
  let toReturn = { chainedMgqIdArr: chainedMgqIdArr, scoreAndPenalty: scoreAndPenalty };
  let mgqScore = await DBProvider.readDBKeyValue("mgq_scores", mgqIdToSearch);
  let madeScorePenalty = makeScorePenaltyFromMgqScore(mgqScore);
  scoreAndPenalty.totalScore = scoreAndPenalty.totalScore + madeScorePenalty.totalScore;
  scoreAndPenalty.penalty = madeScorePenalty.penalty != null ? madeScorePenalty.penalty : scoreAndPenalty.penalty;
  if (mgqScore != undefined && mgqScore.chain != undefined) {
    chainedMgqIdArr.push(mgqScore.chain);
    return retrieveChainedMgqIdArrAndScoreFromFirstMgqId(chainedMgqIdArr, scoreAndPenalty);
  }
  else {
    toReturn.scoreAndPenalty.totalScore = enforcePenalty(toReturn.scoreAndPenalty.totalScore, toReturn.scoreAndPenalty.penalty);
    return toReturn;
  }
}


export async function retrieveChainedMgqIdArrAndScoreFromLastMgqId(chainedMgqIdArr, scoreAndPenalty) {
  let mgqIdToSearch = chainedMgqIdArr[chainedMgqIdArr.length - 1].toString();
  let mgqScore = await DBProvider.readDBKeyValue("mgq_scores", mgqIdToSearch);
  if (mgqScore != undefined) {
    let madeScoreAndPenalty = makeScorePenaltyFromMgqScore(mgqScore);
    scoreAndPenalty.totalScore = scoreAndPenalty.totalScore + madeScoreAndPenalty.totalScore;
    scoreAndPenalty.penalty = madeScoreAndPenalty.penalty != null ? madeScoreAndPenalty.penalty : scoreAndPenalty.penalty;
  }
  let toReturn = { chainedMgqIdArr: chainedMgqIdArr, scoreAndPenalty: scoreAndPenalty };
  let mgqChain = await DBProvider.readDBKeyValue("mgq_chains", chainedMgqIdArr[chainedMgqIdArr.length - 1].toString());
  if (mgqChain != undefined) {
    chainedMgqIdArr.push(mgqChain);
    return retrieveChainedMgqIdArrAndScoreFromLastMgqId(chainedMgqIdArr, scoreAndPenalty);
  }
  else {
    toReturn.scoreAndPenalty.totalScore = enforcePenalty(toReturn.scoreAndPenalty.totalScore, toReturn.scoreAndPenalty.penalty);
    return toReturn;
  }
}

function enforcePenalty(totalScore, penalty) {
  if (penalty != null) {
    if (penalty.type == "percentage") {
      totalScore = Math.ceil(totalScore - totalScore * (penalty.amount / 100));
    }
    else {
      totalScore = totalScore - penalty.amount;
    }
  }
  return totalScore;
}

function makeScorePenaltyFromMgqScore(mgqScore) {
  let totalScore = 0;
  let penalty = null;
  for (let res in mgqScore) {
    if (res != "chain") {
      if (res == "penalty") {
        penalty = mgqScore[res];
      }
      else {
        if (res != "timeBonus") {
          totalScore = totalScore + mgqScore[res].grade;
        }
        else {
          totalScore = totalScore + mgqScore[res];
        }
      }
    }
  }
  let toReturn = { totalScore: totalScore, penalty: penalty };
  return toReturn;
}

async function handleScoreDeletion(mgq) {
  await DBProvider.deleteKeyValue("mgq_scores", mgq.mindgluequestId.toString());

}

export async function takeMeToPlace(mgq, outerLongitude, outerLatitude, zoom) {
  if (mgq.mindgluequestId != store.getters["gameApi/getCurrentMgq"].mindgluequestId) {
    mixins.alertConfirm("You haven't entered this mindgluequest yet, to get to the start of it.", false);
  }
  else {
    let latitude = Number(outerLatitude);
    let longitude = Number(outerLongitude);
    store.commit("core/setUserLatLong", { latitude: latitude, longitude: longitude });
    store.commit("gameApi/setZoomLevel", zoom);
    let absolutePathRoute = router.currentRoute.value.fullPath;
    if (absolutePathRoute.indexOf("outergame") < 0) {
      router.push("/outergame");
    }
  }
}

export function whichTransitionEvent() {
  var t;
  var el = document.createElement('fakeelement');
  var transitions = {
    'transition': 'transitionend',
    'OTransition': 'oTransitionEnd',
    'MozTransition': 'transitionend',
    'WebkitTransition': 'webkitTransitionEnd'
  }

  for (t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
}

export const MgqStatus = {
  UNSUBSCRIBED: "UNSUBSCRIBED", PLAYING_CURRENT: "PLAYING", STOPPED: "STOPPED", UNSUBSCRIBED_OLD: "UNSUBSCRIBED_OLD", PLAYED: "PLAYED", PLAYING_OLD: "PLAYING_OLD"
};


export function makeMgqStatus(mgq) {
  let status = MgqStatus.UNSUBSCRIBED;
  let currentMgq = store.getters["gameApi/getCurrentMgq"];
  if (mgq.mindgluequestId == currentMgq.mindgluequestId) {
    if (mgq.usEndTime > mgq.mgqVersion.endTime) {
      status = MgqStatus.PLAYING_OLD;
    }
    else {
      status = MgqStatus.PLAYING_CURRENT;
    }
  }
  else {
    if (mgq.usEndTime > mgq.mgqVersion.endTime) {
      status = MgqStatus.PLAYED;
    }
    else {
      status = MgqStatus.STOPPED;
    }
  }
  return status;
}

export function makeChainListFromChain(chain) {
  let toReturn = [];
  let thisChains = JSON.parse(chain);
  for (let firstChainMap of thisChains) {
    let madeChainMap = {};
    madeChainMap.grade = firstChainMap["grade"];
    let targetList = firstChainMap["target"].split("|");
    madeChainMap.target = targetList;
    toReturn.push(madeChainMap);
  }
  return toReturn;
}

export function fixJsonValuesOfMindset(rawMindset) {
  rawMindset.visibilityProps = JSON.parse(rawMindset.visibilityProps);
  rawMindset.properties = JSON.parse(rawMindset.properties);
  return rawMindset;
}



export function makeEmptyMgq(title, seconds, isFirst, chain) {
  let mgq = {};
  let startTime = new Date().getTime();
  console.log("stasrtTime " + startTime);
  let endTime = startTime + (Number(seconds) * 1000);
  console.log("endTime " + endTime);

  mgq.active = isFirst ? true : false;
  mgq.activeDistance = 0;
  mgq.advertisingLatitude = "0.0";
  mgq.advertisingLongitude = "0.0";
  mgq.code = "";
  mgq.color = 7;
  mgq.currency = "EUR";
  mgq.editable = false;
  mgq.forContest = true;
  mgq.image = { name: "" };
  mgq.latitude = store.getters["core/getUserLatitude"];
  mgq.location = "***";
  mgq.longDescr = "";
  mgq.longitude = store.getters["core/getUserLongitude"];
  mgq.mgqVersion = { versionNr: 0, id: -1, startTime: startTime, endTime: endTime };
  mgq.price = 0;
  mgq.properties = chain == null ? "{}" : "{\"chain\":\"" + chain + "\"}";
  mgq.searchable = false;
  mgq.shortDescr = "";
  mgq.showPoints = true;
  mgq.title = title;
  mgq.virtual = true;
  mgq.withPassword = false;
  mgq.withinDistance = 0;

  return mgq;
}


export async function dbSaveChains(currentMgqScores, nextMgqId) {
  currentMgqScores.chain = nextMgqId;
  await DBProvider.writeDBKeyValue("mgq_scores", store.getters["gameApi/getCurrentMgq"].mindgluequestId.toString(), currentMgqScores);
  await DBProvider.writeDBKeyValue("mgq_chains", nextMgqId.toString(), store.getters["gameApi/getCurrentMgq"].mindgluequestId.toString());
}

export async function makeMissingMgqScoresChains(serverMgqScores) {
  let localMgqScores = await DBProvider.readAllKeys("mgq_scores");
  for (let servSc of serverMgqScores) {
    if (localMgqScores.indexOf(servSc.mgqId) < 0) {
      let res = {};
      res.isInBetweenGamecode = false;
      res.isFinishGamecode = true;
      res.grade = servSc.score;
      res.success = true;
      let currentMgqScore = { 0: res };
      if (servSc.lastMgqId!=servSc.mgqId){
        currentMgqScore.chain = servSc.lastMgqId.toString();
        await DBProvider.writeDBKeyValue("mgq_chains", servSc.lastMgqId.toString(), servSc.mgqId);
      }
      await DBProvider.writeDBKeyValue("mgq_scores", servSc.mgqId.toString(), currentMgqScore);
    }
  }

  return localMgqScores;
}