<template>
  <div class="boxy">
    <PageHeader :with_login="true" />
    <MiddleSection/>
    <PageFooter />
  </div>
</template>

<script>
import PageHeader from "@/components/parts/PageHeader.vue";
import MiddleSection from "@/components/parts/MiddleSection.vue";
import PageFooter from "@/components/parts/PageFooter.vue";
export default {
  components:{
    PageHeader,
    MiddleSection,
    PageFooter,
  },
}
</script>
