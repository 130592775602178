<template>
  <div>
    <div v-if="showAlertConfirm">
      <div class="modal is-active">
        <div class="modal-background"></div>

        <div class="modal-content alert_confirm_content">
          <div>
            <div>{{ alertConfirmMessage }}</div>
            <div>
              <button
                class="button is-primary mt-6"
                @click="closeAlertConfirm(true)"
              >
                OK
              </button>
              <button
                v-if="isConfirm"
                class="button ml-4 mt-6"
                @click="closeAlertConfirm(false)"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed,
 //onMounted 
 //onUnmounted
 } from "vue";
//import * as mixins from "@/utils/mixins";

export default {
  setup() {
    const store = useStore();
    const alertConfirmMessage = computed(
      () => store.getters["core/getAlertConfirmMessage"]
    );

    const showAlertConfirm = computed(
      () =>
        alertConfirmMessage.value != "" &&
        alertConfirmMessage.value != "true" &&
        alertConfirmMessage.value != "false"
    );
    function closeAlertConfirm(value) {
      store.commit("core/setAlertConfirmMessage", value.toString());
    }

    const isConfirm = computed(() => store.getters["core/getIsConfirm"]);
    return {
      showAlertConfirm,
      alertConfirmMessage,
      isConfirm,
      closeAlertConfirm,
    };
  },
};
</script>
<style scoped>
.alert_confirm_content {
  background-color: white;
  padding: 2em;
  border-radius: 0.5em;
  z-index: 10197;
}
.modal-background {
  z-index: 10196;
}
.modal {
  z-index: 10195;
}
</style>