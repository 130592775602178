<template>
    <div v-if="anim != null" id="anim_player_wrapper"
        :class="{ 'outer_anim': anim.shouldCenter, 'outer_anim2': !anim.shouldCenter, }" style="pointer-events: none; ">
        <div class="anim_player">
            <lottie-player class="player_class" id="animation_player" :src="anim.src"
                background="transparent" speed="1" direction="1" mode="normal" autoplay
                :style="'top:' + startY + 'px;left:' + startX + 'px;'"></lottie-player>
        </div>
    </div>
</template>
<script>
import { watch, ref, computed, } from 'vue';
import { useStore } from 'vuex';
export default {
    setup() {
        const store = useStore();
        let anim = computed(() => store.getters["gameApi/getVectorAnimation"]);
        let player = null;



        watch(
            () => anim.value,
            () => {
                if (anim.value != null) {
                    if (anim.value.src != undefined) {
                        setTimeout(() => {
                            player = document.getElementById("animation_player");
                            if (player != null) {
                                player.addEventListener("complete", makeShowVectorAnimation, { once: true });
                            }
                        }, 200);
                    }

                }
            }
        );





        const startY = computed(() => {
            let toReturn = 0;
           
            if (anim.value.shouldCenter) {
                toReturn = anim.value.center.y - anim.value.src.h / 2;
            }
            else {
                toReturn = (store.getters["gameApi/getGameScreenWidthHeight"].height - anim.value.src.h)/2;
            }
            return toReturn;

        });
        const startX = computed(() => {
            let toReturn = 0;
            if (anim.value.shouldCenter) {
                toReturn = anim.value.center.x - anim.value.src.w / 2;
            }
            else {
                toReturn = (store.getters["gameApi/getGameScreenWidthHeight"].width - anim.value.src.w)/2;
            }
            return toReturn;
        });

        function makeShowVectorAnimation() {
            store.commit("gameApi/setVectorAnimation", null);
        }

        const showVectorAnimation = ref(true);
        return {
            showVectorAnimation,
            anim,
            startX,
            startY,
            makeShowVectorAnimation,
        };
    }
}
</script>
<style>
.outer_anim {
    z-index: 9999;
    position: absolute;
}

.outer_anim2 {
    z-index: 9999;
    position: absolute;
}

.player_class {
    position: relative;
}
</style>