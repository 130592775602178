<template>
    <div>
        <div class="has-text-weight-bold">
            Privacy
        </div>
        <div class="has-text-justified">
         For the Quesdom Service to be available to you we process the information you provide to us. We keep the user account information that is demanded on signing up to the application along with the alterations you make to this information<br>
        We also collect the information that stems from your use of the application, that means we collect the content you create in the from of mindsets, mindchunks and reactions, along with the interactions with other users of the Quesdom platform you enter using this content. The mindsets you reply to, the Quesdom connections you create and the chats with these connections are also information that we keep in our data system.<br>
        Your device platform (android or iphone) that you use to connect to the Quesdom platform is also collected, so that we can provide our Service properly.<br><br>
        Quesdom strives to bring people together in a real way, and that has also to do with the location its users are. If you don't wish to give the Quesdom application permissions to collect your location data, you may manually supply a location. Of course that can be your true physical location or whichever location you wish to set as the one our system can use to provide you with content of neighboring users or location tagged content.<br>
       <br>
  <!--    <div>
        Your personal data will be processed and information from your device (cookies, unique identifiers, and other device data) may be stored by, accessed by and shared with vendor(s), or used specifically by this site or app.
Some vendors may process your personal data on the basis of legitimate interest, which you can object to by managing your options below. Look for a link at the bottom of this page or in our privacy policy where you can withdraw consent.
     </div> -->
        </div>
    </div>
</template>