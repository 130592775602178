<template>
  <div style="height: 100%; width: 100%; position: relative">
    <div v-if="isReady" class="outer_map_div mb-4">
      <l-map id="map_div" ref="thisMap" :zoom="zoomLevel" :center="startCoord" @click="mapClicked"
        :use-global-leaflet="true" :options="mapOptions" :crs="crs" @zoom="updateZoom">
        <l-tile-layer :url="tilesUrl" ref="myTileLayer" :maxZoom=99 :attribution="openAttribution"></l-tile-layer>

        <!--  <l-wms-tile-layer
            v-else-if="getCurrentMgq.properties != ''"
            :base-url="baseUrl"
            :layers="getCurrentMgq.properties"
          >
          </l-wms-tile-layer> -->
        <l-layer-group ref="groupImages">
          <div v-for="(image, idx) in images" :key="idx">
            <l-image-overlay
              v-if="mixins.shouldShowOnMap(image.properties.zoomLevels == undefined ? 0 : image.properties.zoomLevels.min, image.properties.zoomLevels == undefined ? 19 : image.properties.zoomLevels.max, currentZoomLevel)"
              :url="baseUrl + '/mgqmaps/' + image.properties.name" :bounds="image.latlngs" :interactive="true"
              :opacity="1" />
          </div>
        </l-layer-group>
        <div v-for="(polygon, idx) in polygons" :key="idx">
          <l-polygon :lat-lngs="polygon.latlngs" :fill-color="polygon.fillColor"
            :fillOpacity="polygon.properties.opacity" :color="polygon.borderColor" :stroke="true" :stroke-width="6"
            :fill="true">
          </l-polygon>
        </div>
        <div v-for="(rectangle, idx) in rectangles" :key="idx">
          <l-rectangle :bounds="rectangle.latlngs" :color="rectangle.borderColor"
            :fillOpacity="rectangle.properties.opacity" :stroke="true" :stroke-width="6"
            :fill-color="rectangle.fillColor" :fill="true"></l-rectangle>
        </div>
        <div v-for="(circle, idx) in circles" :key="idx">
          <l-circle :lat-lng="circle.latlngs[0]" :radius="mixins.makeDistanceFromLatLng(
      circle.latlngs[0][0],
      circle.latlngs[0][1],
      circle.latlngs[1][0],
      circle.latlngs[1][1]
    )
      " :fillOpacity="circle.properties.opacity" :color="circle.borderColor" :stroke="true" :stroke-width="6"
            :fill-color="circle.fillColor" :fill="true"></l-circle>
        </div>
        <div v-for="(polyline, idx) in polylines" :key="idx">
          <l-polyline :lat-lngs="polyline.latlngs" :color="polyline.borderColor" :stroke="true" :stroke-width="6">
          </l-polyline>
        </div>
        <div v-for="(label, idx) in labels" :key="idx">
          <l-marker :lat-lng="label.latlngs[0]">
            <l-icon>
              <div class="label_icon" :style="'color:' + label.borderColor" style="font-size: 1.5em">
                {{ label.properties.content }}
              </div>
            </l-icon>
          </l-marker>
        </div>

        <l-marker :lat-lng="[
      parseFloat(selectedLocation.latitude),
      parseFloat(selectedLocation.longitude),
    ]" :draggable="false" :icon="getMyLocationIcon(false)">
        </l-marker>
        <div v-if="hasGuessed">
          <l-marker :lat-lng="[
      parseFloat(guessedPosition.latitude),
      parseFloat(guessedPosition.longitude),
    ]" :draggable="false" :icon="getMyLocationIcon(true)" opacity="0.5">
          </l-marker>
        </div>
        <l-layer-group ref="markers">
          <div v-for="mindset in mindsets" :key="mindset.id">
            <l-circle-marker v-if="mindset.visible" :lat-lng="[
      parseFloat(mindset.latitude),
      parseFloat(mindset.longitude),
    ]" :radius="isWebDeviceHere ? 25 : 50" :stroke="true" color="#FFFFFF" :weight="5" :fill="true" :fillColor="selectedMindset.id == mindset.id ? '#55d3ff' : '#e345ff'
      " :fillOpacity="Number(0.7)" @click="setSelectedMindset(mindset)" :options="{ pane: 'markerPane' }" />
          </div>
        </l-layer-group>
      </l-map>
    </div>
  </div>
</template>

<script>
import { getMarker } from "@/assets/marker";
import { getMarkerWithHeading } from "@/assets/markerWithHeading";
//import { getMindglueMarker } from "@/assets/mindglueMarker";
import { CRS } from "leaflet";
//import { signalMarker } from 
import * as gameMixins from "@/utils/gameMixins";
"@/assets/signalMarker";
import * as DBProvider from '@/utils/DBProvider';

import L from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LCircleMarker,
  //LTooltip,
  // LWmsTileLayer,
  // LPopup,
  LLayerGroup,
  LPolyline,
  LPolygon,
  LRectangle,
  LCircle,
  LImageOverlay,
  LIcon,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import {
  ref, computed, onBeforeMount, watch, onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
//import { useRouter } from "vue-router";
import * as mixins from "@/utils/mixins";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircleMarker,
    // LWmsTileLayer,
    LPolyline,
    LPolygon,
    LRectangle,
    LCircle,
    LImageOverlay,
    LIcon,
    LLayerGroup,
  },

  props: {

    zoomLevel: {
      type: Number,
      default: 13,
      required: false,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const thisMap = ref(null);
    const isReady = ref(false);
    //const router = useRouter();
    const getCurrentMgq = computed(
      () => store.getters["gameApi/getCurrentMgq"]
    );
    const isModePositionCorrecting = ref(false);
    const isWebDeviceHere = ref(true);
    const mindsets = ref([])
    const clickedOnMarker = ref(false);

    const tilesUrl = computed(() => noMapBoard.value == null ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" : "");

    const heading = ref(-1);
    const currentZoomLevel = ref(0);
    onBeforeMount(async () => {
      await loadAll();
    });
    //onMounted(() => handleBackgroundAudio());

    const guessedPosition = ref("");
    const hasGuessed = ref(false);
    const mapOptions = computed(() => {
      return {
        zoomControl: true,
      };
    });

    const openAttribution =
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors';
    const tileOptions = computed(() => {
      if (isWebDeviceHere.value) {
        return {
          zoomOffset: 0,
        };
      } else {
        return {
          zoomOffset: -1,
        };
      }
    });

    const isReal = false;

    const polylines = ref(mixins.polylines);
    const circles = ref(mixins.circles);
    const rectangles = ref(mixins.rectangles);
    const polygons = ref(mixins.polygons);
    const images = ref(mixins.images);
    const labels = ref(mixins.labels);
    const noMapBoard = ref(mixins.noMapBoard);

    const crs = computed(() => {
      let toReturn = CRS.EPSG3857;
      if (noMapBoard.value != null) {
        toReturn = CRS.Simple;
      }
      return toReturn;
    });


    const baseUrl = mixins.baseUrl;

    let startCoord = ref([
    ]);

    const selectedLocation = computed(() => {
      return {
        latitude: store.getters["core/getUser"].latitude,
        longitude: store.getters["core/getUser"].longitude,
      }
    });

    const getTileSize = computed(() => (isWebDeviceHere.value ? 256 : 512));

    const selectedMindset = ref({
      id: 0,
    });


    function renewStartCoord() {
      startCoord.value = [
        parseFloat(selectedLocation.value.latitude),
        parseFloat(selectedLocation.value.longitude),
      ];
    }

    watch(
      () => mindsets.value,
      () => {
        updateMindetsVisibility();
      }
    );

    async function loadAll() {
     // handleBackgroundAudio();
      startCoord.value = [
        parseFloat(selectedLocation.value.latitude),
        parseFloat(selectedLocation.value.longitude),
      ];
      mindsets.value = await store.dispatch("gameApi/fetchMindsetsMgq", selectedLocation.value);
      if (getCurrentMgq.value.marked != undefined && getCurrentMgq.value.marked != "") {
        let targetArr = JSON.parse(getCurrentMgq.value.marked);
        if (targetArr.length > 0) {
          targetedArray.value = targetArr[2];
        }
      }
      isReady.value = true;
    }

    onBeforeUnmount(() => {
      store.commit("gameApi/stopBackgroundAudio");
    });
    const targetedArray = ref([]);

    

    async function mapClicked(e) {
      if (
        getCurrentMgq.value.mindgluequestId == -1 ||
        getCurrentMgq.value.virtual
      ) {
        if (!clickedOnMarker.value) {
          if (undefined != e.latlng) {
            store.commit("core/setUserLatLong", {
              latitude: e.latlng.lat.toString(),
              longitude: e.latlng.lng.toString(),
            });
            if (getCurrentMgq.value.withinDistance != 0) {
              mindsets.value = await store.dispatch("gameApi/fetchMindsetsMgq", {
                latitude: e.latlng.lat.toString(),
                longitude: e.latlng.lng.toString(),
              })
            }
            else {
              updateMindetsVisibility();
              await checkIfImmediateSuccess(e);
            }
          }
        } else {
          clickedOnMarker.value = false;
        }
      }
    }

    async function checkIfImmediateSuccess(e) {
      for (let mindset of mindsets.value) {
        if (mindset.properties.isImmediateGrading != undefined) {
          if (mindset.shouldBeVisible) {
            await getAnswer(mindset, e);
          }
        }
      }
    }

    function zoomIn() {
      thisMap.value.leafletObject.zoomIn();
    }
    function zoomOut() {
      thisMap.value.leafletObject.zoomOut();
    }

    /* 
    *
    *
    function setGuessedPosition(coordinates) {
       guessedPosition.value = coordinates;
       hasGuessed.value = true;
       setTimeout(() => {
         hasGuessed.value = false;
       }, 4000);
     } */

    /*  function setLocationFromSensors(coordinates) {
       selectedLocation.value = coordinates;
       thisMap.value.leafletObject.panTo([
         parseFloat(selectedLocation.value.split(",")[0]),
         parseFloat(selectedLocation.value.split(",")[1]),
       ]);
     } 
     *
     *
     */

    function setHeadingFromSensors(updatedHeading) {
      console.log("set heading from sensors " + updatedHeading);
      heading.value = updatedHeading;
    }



    function getMyLocationIcon(isGuessed) {
      let color = isModePositionCorrecting.value ? "00c72a" : "2251ff";
      if (isGuessed) {
        color = "ff0000";
      }
      if (heading.value != -1) {
        const markerSvg = ref(
          getMarkerWithHeading(color, isWebDeviceHere.value, heading.value)
        );
        const myIcon = L.divIcon({
          className: "my_location_marker_big",
          html: `<div>${markerSvg.value}</div>`,
          iconAnchor: isWebDeviceHere.value ? [12, 12] : [36, 36],
        });
        return myIcon;
      } else {
        const markerSvg = ref(getMarker(color, isWebDeviceHere.value));
        const myIcon = L.divIcon({
          className: "my_location_marker_big",
          html: `<div>${markerSvg.value}</div>`,
          iconAnchor: isWebDeviceHere.value ? [12, 12] : [36, 36],
        });
        return myIcon;
      }
    }


    function showMapRight() {
      setTimeout(function () {
        thisMap.value.leafletObject.invalidateSize(true);
      }, 50);
    }

    async function setSelectedMindset(mindset) {
      clickedOnMarker.value = true;
      selectedMindset.value = mindset;
      let mindsetReacted = await mixins.makeModalDynamic("DynamicMindset", { mindset: mindset });
      if (mindsetReacted != null) {
        await getAnswer(mindsetReacted.content);
      }
    }

    function updateZoom() {
      currentZoomLevel.value = thisMap.value.leafletObject.getZoom();
      updateMindetsVisibility();
    }

    function updateMindetsVisibility() {
      for (let mindset of mindsets.value) {
        if (mindset["shouldBeVisible"] != undefined) {
          delete mindset["shouldBeVisible"];
        }
        if (mixins.shouldShowOnMap(Number(mindset.visibilityProps[2]), Number(mindset.visibilityProps[3]), currentZoomLevel.value, {
          latitude1: Number(selectedLocation.value.latitude), longitude1: Number(selectedLocation.value.longitude), latitude2: Number(mindset.latitude), longitude2: Number(mindset.longitude), minDistance: mindset.visibilityProps[0],
          // isRealMap: noMapBoard.value == null
          isRealMap: true
        })) {
          if (mindset.properties.isImmediateGrading == undefined) {
            mindset["visible"] = true;
          }
          else {
            mindset["visible"] = false;
            mindset["shouldBeVisible"] = true;
          }
        }
        else {
          mindset["visible"] = false;
        }
      }
    }


    async function getAnswer(originalMindset, e) {
      let mindset = mixins.cloneObject(originalMindset);
      let currentMgqScore = await DBProvider.readDBKeyValue("mgq_scores", getCurrentMgq.value.mindgluequestId.toString());
      let res = {};
      if (currentMgqScore != undefined) {
        if (currentMgqScore[mindset.id] != undefined) {
          res = currentMgqScore[mindset.id];
          res.previouslyChecked = true;
        }
        else {
          if (mindset.properties.isImmediateGrading != undefined) {
            res = gameMixins.makeResultFromImmediateGrading(mindset, targetedArray.value, currentMgqScore);
          }
          else {
            res = await store.dispatch("gameApi/getSuccessOrFailure", mindset);
          }
          store.commit("gameApi/incrementCurrentScore", res.grade);
          currentMgqScore[mindset.id] = res;
          res.previouslyChecked = false;
        }
      }
      else {
        if (mindset.properties.isImmediateGrading != undefined) {
          res = gameMixins.makeResultFromImmediateGrading(mindset, targetedArray.value, currentMgqScore);
        }
        else {
          res = await store.dispatch("gameApi/getSuccessOrFailure", mindset);
        }
        res.previouslyChecked = false;
        store.commit("gameApi/incrementCurrentScore", res.grade);
        currentMgqScore = { [mindset.id]: res };
      }

      await DBProvider.writeDBKeyValue("mgq_scores", getCurrentMgq.value.mindgluequestId.toString(), currentMgqScore);
      if (mindset.chain == "") {
        if (res.success) {
          if (res.isInBetweenGamecode) {
            store.commit("gameApi/setEffectsAudio", require('@/assets/xylophone.mp3'));
            await mixins.makeVectorAnimation({ src: require("@/assets/checkMarkAnim.json"), center: { x: e.originalEvent.x, y: e.originalEvent.y }, shouldCenter: true });
            if (!res.previouslyChecked) {
              if (mindset.properties.isImmediateGrading != undefined) {
                let successClickPoint = L.circleMarker(e.latlng, { fillColor: "#39ff33", fillOpacity: Number(0.7), stroke: false, radius: 20 });
                successClickPoint.addTo(thisMap.value.leafletObject);
              }
            }

            if (mindset.properties.msgAfterSuccess != undefined && mindset.properties.msgAfterSuccess != "") {
              await mixins.makeToast(mindset.properties.msgAfterSuccess, 3000);
            }
          }
          else if (res.isFinishGamecode) {
            emit("makeFinishGame", { currentMgqScore: currentMgqScore, nextMindset: null });
          }
        }
        else {
          if (!res.previouslyChecked) {
            if (mindset.properties.isImmediateGrading != undefined) {
              let failClickPoint = L.circleMarker(e.latlng, { fillColor: "#ff4c4c", fillOpacity: Number(0.7), stroke: false, radius: 20 });
              failClickPoint.addTo(thisMap.value.leafletObject);
            }
          }
          if (mindset.properties.msgAfterNoSuccess != undefined && mindset.properties.msgAfterNoSuccess != "") {
            await mixins.alertConfirm(mindset.properties.msgAfterNoSuccess, false);
          }
        }
      }
      else {
        let chains = gameMixins.makeChainListFromChain(mindset.chain);
        let chainMap = {};
        for (let tmp of chains) {
          if (tmp["grade"] == res.grade) {
            chainMap = tmp;
          }
        }
        if (chainMap["target"][1] != "") {
          let chainMindsetId = chainMap["target"][1];
          let chainedMindset = await store.dispatch("gameApi/getOneMindset", { mindsetId: chainMindsetId, usQsetsId: 0, withGrades: false });
          if (chainedMindset.gamecode != mindset.gamecode) {
            let splitChainedGamecode = chainedMindset.gamecode.split("_");
            let splitMindsetGamecode = mindset.gamecode.split("_");
            if (splitChainedGamecode[0] == splitMindsetGamecode[0]) {
              goToChainedMindset(chainedMindset);
            }
            else {
              await gameMixins.dbSaveChains(currentMgqScore, splitChainedGamecode[0]);
              goToChainedMgq(chainedMindset, currentMgqScore);
            }
          }
          else {
            setSelectedMindset(chainedMindset)
          }
        }
      }

    }





    function goToChainedMindset(nextMindset) {
      console.log("next mgq " + nextMindset.id + " " + nextMindset.gamecode);

      thisMap.value.leafletObject.setView(
        new L.LatLng(
          parseFloat(nextMindset["latitude"]),
          parseFloat(nextMindset["longitude"])
        ),
        nextMindset.visibilityProps[1]
      );
      // selectedLocation.value = { latitude: nextMindset["latitude"], longitude: nextMindset["longitude"] };
      store.commit("core/setUserLatLong", { latitude: nextMindset["latitude"], longitude: nextMindset["longitude"] });
    }

    function goToChainedMgq(nextMindset, currentMgqScore) {
      //  emit("changeMgq", nextMindset);
      //emit("makeFinishGame",currentMgqScore);
      emit("makeFinishGame", { currentMgqScore: currentMgqScore, nextMindset: nextMindset });

    }



    const noMapBackground = computed(() => noMapBoard.value != null ? noMapBoard.value.properties.noMapColor : "#ffffff");


    return {
      setSelectedMindset,
      thisMap,
      showMapRight,
      //getAllMindsets,
      getCurrentMgq,
      startCoord,
      //getMindsetsIcon,
      getMyLocationIcon,
      baseUrl,
      mixins,
      polygons,
      polylines,
      rectangles,
      circles,
      labels,
      // signals,
      mapClicked,
      images,
      getTileSize,
      mapOptions,
      tileOptions,
      isWebDeviceHere,
      selectedLocation,
      selectedMindset,
      zoomOut,
      zoomIn,
      //  moveToMindset,
      guessedPosition,
      hasGuessed,
      tilesUrl,
      // setGuessedPosition,
      mindsets,
      openAttribution,
      isReal,
      //  getSignalIcon,
      crs,
      isModePositionCorrecting,
      //   setLocationFromSensors,
      setHeadingFromSensors,
      updateZoom,
      currentZoomLevel,
      isReady,
      noMapBackground,
      loadAll,
      renewStartCoord,
    //  handleBackgroundAudio,
    };
  },
};
</script>

<style scoped>
.leaflet-container {
  background-color: v-bind('noMapBackground')
}

.my_location_marker_big {
  width: 200px;
  height: 200px;
}

#mindset_attr {
  z-index: 10;
}

#map_div {
  z-index: 1;
}

.popup_div {
  text-align: center;
}

.unset_mgq_map_region_name {
  color: grey;
  font-style: oblique;
  text-align: center;
}

.mgq_map_region_name {
  color: black;
  text-align: center;
}

.outerdiv {

  margin: auto;
}
</style>