import * as apiPost from '@/utils/ApiPost';
import { toRaw } from 'vue';
//import * as compareMyObjects from '@/utils/CompareMyObjects';
import * as mixins from "@/utils/mixins";
import * as gameMixins from "@/utils/gameMixins";
import * as DBProvider from "@/utils/DBProvider";
//import * as LocationService from "@/utils/LocationService";

/*eslint-disable no-unused-vars*/
export async function fetchIfPayedPayeeId({ getters }, payload) {
  const url = mixins.serverIp + "/mobile/UserCredsFetchUpdater";
  const mgq = toRaw(payload.mindgluequest);
  let mobileBody = new URLSearchParams({
    'creatorId': mgq.creator.userid.toString(),
    'mgqId': mgq.mindgluequestId.toString(),
    'mgqVersNr': mgq.mgqVersion.versionNr.toString(),
    'requested': "orderState_payeeId",
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,
  });
  if (rawResponse) {
    const orderStateCredMap = await rawResponse.json();
    return orderStateCredMap;
  }
  else {
    return null;
  }
}

export async function checkMgqPassword({ commit }, payload) {
  const url = mixins.serverIp + "/mgqAuth/MgqPasswordChecker";
  let mobileBody = new URLSearchParams({
    'mgqId': payload.mgq.mindgluequestId.toString(),
    'mgqPassword': payload.givenPassword
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,
  });
  if (rawResponse) {
    const rbody = await rawResponse.text();
    if (rbody == "ok!") {
      if (payload.isNewPassword) {
        window.localStorage.setItem("mgq_" + payload.mgq.mindgluequestId.toString(), payload.givenPassword);
      }
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return false;
  }
}

export async function makeStartNotificationSubscription({ commit }, payload) {
  const url = mixins.serverIp + "/mobile/NotificationsSubscriber";
  let toReturn = "";
  let parametersMap = {};
  parametersMap.mgqId = payload.mgq.mindgluequestId.toString();
  parametersMap.startTime = payload.mgq.mgqVersion.startTime.toString();
  parametersMap.whatNotification = payload.whatToSend;
  let paramsString = JSON.stringify(parametersMap);
  let mobileBody = new URLSearchParams({
    'parameters': paramsString,
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,
  });
  if (rawResponse) {
    toReturn = await rawResponse.text();
  }
  return toReturn;
}



export async function setUsMindgluequestApi({ getters, commit, rootState }, payload) {
  /* 
 *
 *
 * await DBProvider.deleteSearchable();
  */
  const url = mixins.serverIp + "/mgqAuth/UsMindgluequestsSetter";
  const mgq = toRaw(payload.mindgluequest);
  let success = false;
  let getCurMgq = getters["getCurrentMgq"];
  let nextMgqId = mgq.mindgluequestId;
  if (mgq.mindgluequestId == getCurMgq.mindgluequestId) {
    nextMgqId = mgq.mindgluequestId == getCurMgq.mindgluequestId ? 1 : mgq.mindgluequestId;
  }

  let mobileBody = new URLSearchParams({
    'nextMindgluequestId': nextMgqId.toString(),
    'longitude': rootState.core.user.longitude.toString(),
    'latitude': rootState.core.user.latitude.toString(),
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,

  });
  if (rawResponse) {
    let rbody = await rawResponse.text();
    if (rbody != "error") {
      const mapMgqLocationMap = JSON.parse(rbody);
      let mgqJson = mapMgqLocationMap["currentMgq"];
      let coordsJson = mapMgqLocationMap["coords"];
      let _mgq = mgqJson;
      let coords = coordsJson.toString();
      if (coords != "" && _mgq.virtual) {
        let coordsList = coords.split(",");
        _mgq.latitude = coordsList[0];
        _mgq.longitude = coordsList[1];
        commit("core/setUserLatLong", { latitude: coordsList[0], longitude: coordsList[1] }, { root: true });
      }
      commit("setCurrentMgq", _mgq);
      success = true;
      /* if (nextMgqId != 1) {
       
        * 
      * await fetchAllSearchable();
      
      }*/
    }
  }
  return success;
}



export async function fetchMindsetsMgq({ commit }, payload) {
  //let mindsetsList = [];
  let rbody = {};
  const url = mixins.serverIp + "/mobile/GameObjSender";
  let mobileBody = new URLSearchParams({
    'longitude': payload.longitude,
    'latitude': payload.latitude,
    'daysInterval': "10",
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,

  });
  if (rawResponse) {
    rbody = await rawResponse.json();
  }
  commit("setCurrentMgq", rbody.mgq);
  let mindsetsLocated = [];
  let mindsetNonLocated = [];
  for (let mdt of rbody.mindsetsList) {
    mdt.visibilityProps = JSON.parse(mdt.visibilityProps);
    mdt.properties = JSON.parse(mdt.properties);
    if (mdt.longitude == undefined || mdt.longitude == "") {
      mindsetNonLocated.push(mdt);
    }
    else {
      mindsetsLocated.push(mdt);
    }
  }

  /*   rbody.mindsetsList.forEach((x) =>{
  
    } x.visibilityProps = JSON.parse(x.visibilityProps)); */
  commit("setMindsetsNonLocated", mindsetNonLocated);

  return mindsetsLocated;

}

export async function getAllMgqsLogged({ commit, rootState }) {
  let rbody = {};
  const url = mixins.serverIp + "/mgqAuth/MgqFetcher";
  let mobileBody = new URLSearchParams({
    'longitude': rootState.core.user.longitude.toString(),
    'latitude': rootState.core.user.latitude.toString(),
    'currentMgqId': rootState.gameApi.currentMgq.mindgluequestId.toString(),
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,

  });
  if (rawResponse) {
    rbody = await rawResponse.json();
    let currentMgq = rbody["currentMgq"];
    let mgqlist = rbody["mgqList"];
    let madeMgqList = [];
    for (let mgq of mgqlist) {
      let status = gameMixins.makeMgqStatus(mgq);
      mgq.mgqStatus = status;
      madeMgqList.push(mgq);
    }
    commit("setCurrentMgq", currentMgq);
    commit("core/setMindgluequests", madeMgqList, { root: true });

    return true;
  }
}

export async function requestSelectedPresentationLogged({ commit }, payload) {
  let rbody = {};
  const url = mixins.serverIp + "/mgqAuth/MgqSelectedFetcher";
  let mobileBody = new URLSearchParams({
  });
  if (typeof (payload.mgq) == "number") {
    mobileBody.append('mindgluequest_id', payload.mgq.toString());
  }
  else if (typeof (payload.mgq) == "object") {
    mobileBody.append('image', payload.mgq.image.name);
    mobileBody.append('mindgluequest_id', payload.mgq.mindgluequestId.toString());
  }
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,
  });
  if (rawResponse) {
    rbody = await rawResponse.json();
    return rbody;
  }
  else {
    return "error";
  }
}

export async function requestCurrentMgq({ commit }) {
  let currentMgq = {};
  const url = mixins.serverIp + "/mgqAuth/MgqFetcher";
  let mobileBody = new URLSearchParams({
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,
  });
  if (rawResponse) {
    currentMgq = await rawResponse.json();
    if (currentMgq.latitude == "undefined") {
      currentMgq.latitude = "0.0";
      currentMgq.longitude = "0.0";
    }
    commit("setCurrentMgq", currentMgq);
    return "ok!";
  }
  else {
    return "error";
  }
}

export async function requestOneMgq({ commit }, mgqId) {
  let mgq = {};
  const url = mixins.serverIp + "/mgqAuth/MgqFetcher";
  let mobileBody = new URLSearchParams({
    'mgqId': mgqId,
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,
  });
  if (rawResponse) {
    mgq = await rawResponse.json();
    mgq.properties = JSON.parse(mgq.properties);
    return mgq;
  }
  else {
    return "error";
  }
}

export async function getSuccessOrFailure({ commit, rootState }, payload) {
  const url = mixins.serverIp + "/mobile/AnswersGrader";
  let mindset = mixins.cloneObject(payload);
  mindset.visibilityProps = JSON.stringify(mindset.visibilityProps);
  mindset.properties = JSON.stringify(mindset.properties);
  let mobileBody = new URLSearchParams({
    'qsetAnsweredString': JSON.stringify(mindset),
    'ref_us_qsets_id': "0",
    'longitude': rootState.core.user.longitude.toString(),
    'latitude': rootState.core.user.latitude.toString(),
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,
  });
  if (rawResponse) {
    let res = await rawResponse.json();
    return res;
  }
  else {
    return "error";
  }
}


export async function getOneMindset({ commit }, payload) {
  const url = mixins.serverIp + "/mobile/OneQSetGetter";
  let mobileBody = new URLSearchParams({
    'qsetid': payload.mindsetId.toString(),
    'us_qsets_id': payload.usQsetsId.toString(),
    'with_grades': payload.withGrades.toString()
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,
  });
  if (rawResponse) {
    let mindsetArrived = await rawResponse.json();
    mindsetArrived = gameMixins.fixJsonValuesOfMindset(mindsetArrived);
    return mindsetArrived;
  }
  else {
    return "error";
  }
}

export async function saveMgqScore({ commit }, payload) {
  const url = mixins.serverIp + "/mobile/ScoreSaver";
  let mobileBody = new URLSearchParams({
    'firstMgqId': payload.firstMgqId.toString(),
    'lastMgqId': payload.lastMgqId.toString(),
    'score': payload.score.toString()
  });
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,
  });
  if (rawResponse) {
    let answer = await rawResponse.text();
    if (answer == "ok!") {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return false;
  }
}

export async function fetchMgqScores({ commit }, payload) {
  const url = mixins.serverIp + "/mobile/ScoreFetcher";
  let mobileBody = new URLSearchParams({
  });
  if (payload.firstMgqId != undefined) {
    mobileBody.append('firstMgqId', payload.firstMgqId.toString());
  }
  const rawResponse = await apiPost.myFetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: apiPost.makeHeaders(),
    body: mobileBody,
  });
  if (rawResponse) {
    let answer = await rawResponse.json();
    return answer;
  }
  else {
    return null;
  }
}

export async function fetchScoreAllUsers({ commit }, payload) {
  const url = mixins.serverIp + '/mgq/MgqScoreAllUsersFetcher/' + payload;
  const rawResponse = await apiPost.myFetch(url);
  if (rawResponse) {
    const content = await rawResponse.json();
    return content;
  }
  else {
    return "error";
  }
}

export async function fetchExternalAsset({ commit }, url) {
  console.log("fetching "+url);
  commit("core/setIsLoading", true, { root: true });
  
  const rawResponse = await fetch(url, {});
  if (rawResponse.status == 200) {
    console.log("response 200")
    let bl = await rawResponse.blob();
    await DBProvider.writeDBKeyValue("mgq_assets", url, bl);
    commit("core/setIsLoading", false, { root: true });
    return bl;
  }
  else {
    commit("core/setIsLoading", false, { root: true });
    return null;
  }
}

export async function download({ commit }, payload) {
  fetch(payload.url, {
    mode: 'no-cors',
    method: 'GET',
  })
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = payload.filename;
      link.click();
    })
    .catch(console.error);
  /*var xhr = new XMLHttpRequest();
  xhr.open('GET', payload.url);
  xhr.mode = 'no-cors';
  xhr.responseType = 'blob';
  xhr.onload = e => console.log(xhr.response);
  xhr.send();*/
}

/* export async function downloadFile({ commit }, url) {
  let res = await fetch(url, { method: "get", mode: "cors", referrerPolicy: "no-referrer" });
  let cont = await res.blob();

  const aElement = document.createElement("a");
  aElement.setAttribute("download", 'helloAudio.ogg');
  const href = URL.createObjectURL(cont);
  aElement.href = href;
  aElement.setAttribute("target", "_blank");
  aElement.click();
  URL.revokeObjectURL(href);

} */