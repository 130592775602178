<template>
  <div class="login_wrapper">
    <PageHeader :with_login="false" />
    <div class="login_inner">
      <div class="pt-4">
        <h1 class="title">Paypal Authentication</h1>
      </div>
      <div class="inner_wrapper mt-4">
        {{ message }}
      </div>
    </div>
    <PageFooter />
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import PageHeader from "@/components/parts/PageHeader.vue";
import PageFooter from "@/components/parts/PageFooter.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    PageHeader,
    PageFooter,
  },

  setup() {
    const router = useRouter();
    const store = useStore();
    const success = ref(false);
    const message =
      "Please wait until your Authentication from Paypal is finished.";

    let reqid = "";
    let jwt = "";
    let myCode = "";
    window.onmessage = function (e) {
      if (
        e.origin == "http://127.0.0.1:8081" ||
        e.origin == "http://localhost:8081" ||
        e.origin == "https://mindgluequest.com"
      ) {
        if (undefined != e.data["reqid"]) {
          reqid = e.data["reqid"];
          jwt = e.data["jwt"];
          makePaypalAfterAuth();
        } 
      } 
    };

    onMounted(async () => {
      myCode = router.currentRoute.value.params.code;
      let parentWindow = window.opener;
      parentWindow.postMessage("reqid", "*");
      /*  let reqid = parentWindow.localStorage.getItem("reqid");
      console.log("reqid from localstorage " + reqid);
      parentWindow.localStorage.removeItem("reqid");
      let jwt = parentWindow.localStorage.getItem("jwt");
      parentWindow.localStorage.removeItem("jwt"); */
    });

    async function makePaypalAfterAuth() {
      let paypalCreds = await store.dispatch("core/fetchPaypalCreds", {
        reqid: reqid,
        jwt: jwt,
      });
   /*    console.log(
        "paypal creds clientId " +
          paypalCreds["ppca"] +
          " secret " +
          paypalCreds["ppcb"]
      ); */
      let accessToken = await store.dispatch("core/paypalCodeForToken", {
        accessCode: myCode,
        reqid: reqid,
        jwt: jwt,
        clientId: paypalCreds["ppca"],
        secret: paypalCreds["ppcb"],
      });
   //   console.log("paypal accessToken " + accessToken);

      let primaryEmail = "";
      let paypalEmails = await store.dispatch("core/requestPaymentId", {
        accessToken: accessToken,
      });
      for (let email of paypalEmails) {
        if (email["primary"]) {
        //  console.log("paypal Emails " + email);
          primaryEmail = email["value"];
        }
      }
      success.value = await store.dispatch("core/savePaymentId", {
        paypalEmail: primaryEmail,
        reqid: reqid,
        jwt: jwt,
      });
      if (success.value) {
        window.close();
      } else {
        message.value = "Something went wrong.";
      }
    }

    return {};
  },
};
</script>

<style scoped>
.inner_wrapper {
  display: grid;
  justify-items: center;
}
.login_wrapper {
  height: 100%;
}
.login_inner {
  min-height: 85%;
}
.signin_buttons {
  display: flex;
  gap: 1em;
}

.emphasis {
  padding: 1em;
  background-color: seashell;
}
</style>
