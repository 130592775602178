<template>
  <div>
    <div class="field">
      <div class="control has-icons-left">
        <div class="select">
          <select v-model="myMindset" @change="setSelectedMyMindset">
            <option
              :value="myMindset"
              v-for="myMindset in myMindsets"
              :key="myMindset.id"
            >
            Id: {{ myMindset.id }}, Name: {{ myMindset.name }}, Title: {{ myMindset.title }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
//import * as mixins from "@/utils/mixins";

export default {
  setup(props,{emit}) {
    const store = useStore();
    const myMindset = ref(null);
    const getMindsetToShow = computed(
      () => store.getters["core/getMindsetToShow"]
    );
    const myMindsets = computed(() => store.getters["core/getMyAppMindsets"]);
    onMounted(() => {
      loadMindsetsAndSet();
    });

    function setSelectedMyMindset() {
      emit("setSelectedMyMindset", myMindset.value);
    }

    async function loadMindsetsAndSet() {
      await store.dispatch("core/requestMyAppMindsets");
    }

    return {
      myMindsets,
      myMindset,
      getMindsetToShow,
      setSelectedMyMindset,
    };
  },
};
</script>