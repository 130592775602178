<template>
  <div class="rightside_container">
    <div class="what_is_quesdom">
      <div class="is-align-items-center card what_question p-2" @click="changeShow">
        <div class="image is-64x64 is-center p-2">
          <img src="@/assets/mindgluequest_logo.svg" width="50" />
        </div>
        <div>What is Quesdom?</div>
      </div>
      <div class="is-align-items-center ml-2 quesdom_explanation">
        <p>Quesdom is a quest platform: you can create games, contests and adventures, 2D or 3D (stay tuned,
        Augmented Reality coming soon!). </p><p>It's free to use, just log in and set up your new fun creation!</p> 
      </div>
    </div>
  </div>
</template>
<script>
//import { computed } from "vue";
export default {
  setup(props,{emit}) {

    function changeShow() {
     emit("moveExplanation");
    }
    return {
      changeShow,
    };
  }
}
</script>
<style scoped>
.rightside_container {
  background-color: rgb(0, 0, 0);
  padding: .5em;
}

.what_is_quesdom {
  display: flex;
}

.quesdom_explanation {
  color: white;
  font-size: .9em;
  text-align: justify;
}

.what_question {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

 @media only screen and (max-width: 450px) {
  .what_question {
    display: flex;
    cursor: pointer;
    flex-direction: column;

  }
} 
</style>