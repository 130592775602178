<template>
  <div class="mt-2" id="paypal_div" style="height:500px; width: 500px;">
  </div>
</template>
<script>
import { onMounted, ref } from 'vue';
import { useStore } from "vuex";
import * as PaypalServices from "@/utils/PaypalServices";
import * as mixins from '@/utils/mixins';
export default {
  props: {
    paypalCreds: {
      type: Object
    },
    paypalUrl: {
      type: String
    },
    mgq: {
      type: Object
    },
    orderStateCreds: {
      type: Object
    },
  },
  setup(props,{emit}) {

    onMounted(async () => {
      let paypalThing = document.createElement('script');
      paypalThing.setAttribute('src', props.paypalUrl);
      document.body.appendChild(paypalThing);
      paypalThing.addEventListener('load', () => setLoaded())
    });
    const store = useStore();
    const approved = ref({});
    const paypalFinalized = ref({});

    const purchaseUnits = ref([]);
    function setLoaded() {
      purchaseUnits.value = PaypalServices.makePurchaseUnits(props.mgq, props.orderStateCreds["payeeId"]);
      window.paypal.Buttons({


        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: purchaseUnits.value,
          })

        },
        onApprove: async (stuff) => {
          approved.value = stuff;
          if (approved.value.orderID != undefined) {
            let savedApproval = await store.dispatch("core/savePaypalApproval", {
              mgq: props.mgq,
              orderId: approved.value.orderID,
              payeeId: props.orderStateCreds["payeeId"],
              payerOutsideId: approved.value.payerID,
              paymentId: approved.value.paymentID,
              paymentSource: approved.value.paymentSource
            });
            if (savedApproval) {
              let accessToken = await store.dispatch("core/requestPaypalAccessToken", props.paypalCreds);
              paypalFinalized.value = await store.dispatch("core/authorizePaypalPayment", { accessToken: accessToken, orderId: approved.value.orderID });
              if (paypalFinalized.value.status == "COMPLETED") {
                let savedPayment = await store.dispatch("core/savePaypalPayment", approved.value.orderID);
                if (savedPayment) {
                  await mixins.alertConfirm("Saved", false);
                  emit("paymentDone")

                }
              }
            }
          }

        },
        onError: err => {
          console.log(err)
        }
      })
        .render("#paypal_div")
    }




    return {
      purchaseUnits,
      approved,
      paypalFinalized,
    };

  }
}


</script>
