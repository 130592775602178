<template>
  <div class="rowy headery header_grid">
    <div class="logo button" @click="goToStart">
      <div class="logo_image">
        <img src="@/assets/mindgluequest_logo.svg" />
      </div>
      <div class="quesdom_letters">Quesdom</div>
    </div>
    <div class="some_buttons pr-4">
      <div >
        <div v-if="isNotInOuter && isLoggedIn" class="button is-primary" @click="goToOuter">
          <div class="dashboard_word">Enter</div>
          <div><i class="fas fa-play"></i></div>
        </div>
      </div>
      <div v-if="isNotInDashBoard && isLoggedIn" class="pr-4">
        <div class="button is-primary" @click="goToDashboard">
          <div class="dashboard_word">Dashboard</div>
          <div><i class="fas fa-list"></i></div>
        </div>
      </div>
    <!--   <div v-if="with_login">
        <LogButton />
      </div> -->
      <div class="pr-4">
        <div class="button is-primary" @click="goToAccountPage">
          <div><i class="fas fa-user"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import LogButton from "@/components/parts/LogButton.vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    with_login: {
      type: Boolean,
      default: true,
    },
  },
 /*  components: {
    LogButton,
  }, */

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const isLoggedIn = computed(()=>store.getters["core/getUser"].userid!=undefined);
    const isNotInDashBoard = computed(
      () =>
        !route.matched.some(({ path }) => path == "/dashboard") 
    );
    const isNotInOuter = computed(
      () =>
        !route.matched.some(({ path }) => path == "/outergame") 
    );

    function goToStart() {
      router.push("/");
    }

    function goToDashboard() {
      store.commit("core/renewAfterCreate");
      router.push("/dashboard");
    }

    function goToOuter() {
      store.commit("gameApi/initializeBackgroundAudio");
      store.commit("gameApi/initializeEffectsAudio");
      router.push("/outergame");
    }
    function goToAccountPage() {
      router.push("/account");
    }
    

    return {
      isLoggedIn,
      goToStart,
      isNotInDashBoard,
      isNotInOuter,
      goToDashboard,
      goToOuter,
      goToAccountPage,
    };
  },
};
</script>

<style scoped>
.header_grid {
  display: flex;
  background-color: #e6f9ff;
  align-items: center;
  justify-content: space-between;
}
.some_buttons {
  display: flex;
  padding: 0.3em;
  column-gap: 1em;
}
.logo {
  display: flex;
  margin-left: 20px;
  align-items: center;
  cursor: pointer;
}
.logo_image {
  width: 30px;
  padding: 0.3em;
  padding-top: 0.4em;
}
.quesdom_letters {
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 1rem;
  color: #2c9efc;
  margin-left: 0.1em;
}

.dashboard_word {
  margin-right: 0.5em;
}
@media only screen and (max-width: 500px) {
.quesdom_letters{
  display: none;
}

  .dashboard_word {
    display: none;
  }
}
</style>