
import store from '../store';
import * as mixins from '@/utils/mixins'

/* export async function getLocationFromLocationSensor(){
    let toReturn = false;
    const successCallback = async(position) => {
        store.commit("core/setUserLatLong", {
          latitude: position.coords.latitude.toString(),
          longitude: position.coords.longitude.toString(),
        });
        return true;
      };

      const errorCallback = (error) => {
        console.log("User denied automatic localization " + error);
        toReturn =   false;
      };

      navigator.geolocation.getCurrentPosition(
        successCallback,
        errorCallback
      );
  }
 */

export function getCoordinates() {
  return new Promise(function (resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
}

export async function getLocationFromLocationSensor() {
  const position = await getCoordinates();
  let latitude = position.coords.latitude;
  let longitude = position.coords.longitude;
  let toReturn = { latitude: latitude, longitude: longitude };
  return toReturn;
}

export const defaultCoordinates = [40.741468, -73.9923189];

export async function askForCoordinates() {
  let coords = {};
  let howToLocalize = await mixins.makeModalDynamic("DynamicMultipleRadiobuttons", { title: "A start location is needed. Would you like to get it from your device's sensors or to select it on the map?", checkboxTitles: ["From sensors", "Select on map"] });
  if (null == howToLocalize) {
    howToLocalize = { content: "Select on map" };
  }
  if (howToLocalize.content == "From sensors") {
    coords = await getLocationFromLocationSensor();
  }
  else if (howToLocalize.content == "Select on map") {
    let startCoord = defaultCoordinates;
    if (store.getters["core/getUser"].latitude!=undefined){
      startCoord = [Number(store.getters["core/getUser"].latitude),Number(store.getters["core/getUser"].longitude)]
    }
    let modalContent = await mixins.makeModalDynamic("DynamicOneMarkerMap", { startCoords: startCoord });
    if (modalContent == null) {
      coords = { latitude:"40.741468", longitude: "-73.9923189" };
    }
    else coords = { latitude:modalContent.content[0], longitude: modalContent.content[1]};
  }
  return coords;
}


