<template>
    <div id="outer_toast" class="modal is-active" style="pointer-events: none;">
        <div class="modal-content">
            <div id="inner_toast" class="toast_content">
                <div>{{ toast.message }}</div>
            </div>
        </div>
    </div>

</template>
<script>
import { useStore } from "vuex";
import {
    computed,
    onBeforeMount,
    watch,
    ref,
} from "vue";
//import * as mixins from "@/utils/mixins";

export default {
    setup() {
        const store = useStore();
        const toast = computed(
            () => store.getters["core/getToast"]
        );
        //const shouldShow = ref(false);
        let outerToast = ref(null);

        onBeforeMount(() => {
            /*  outerToast.value = document.getElementById("outerToast");
             if (outerToast.value!=null){
                 outerToast.value.style.opacity = 0;
             } */
        });

        watch(
            () => toast.value.message,
            () => {

                if (toast.value.message != "") {
                    outerToast.value = document.getElementById("inner_toast");
                    outerToast.value.style.opacity = 1;
                    setTimeout(() => {
                        outerToast.value.style.opacity = 0;
                        setTimeout(() => {
                            store.commit("core/setToast", { message: "", milliseconds: 0 });
                        }, 100);
                    }, toast.value.milliseconds);

                }

            });


        return {
            toast,
            //  shouldShow,
        };
    },
};
</script>
<style scoped>
.toast_content {
    background-color: white;
    padding: .5em;
    color:black;
    border-radius: 0.5em;
    z-index: 10298;
    opacity: 0;
    transition: opacity 1s;
}
</style>