<template>
    <div style="width: 100%; height: 100%">
      <div v-if="isReady" style="width: 100%; height: 100%">
        <MyAframe v-if="bareQSets.length > 0" :allMindsets="bareQSets"/>
      </div>
    </div>
  </template>
  <script>
  import { useStore } from "vuex";
  
  import { ref, watch, computed, onBeforeMount, onMounted } from "vue";
  import { useRouter } from "vue-router";
  import * as mixins from "@/utils/mixins";
  import * as gameMixins from "@/utils/gameMixins";
  import MyAframe from "@/components/parts/MyAframe.vue";
  import * as apiPost from "@/utils/ApiPost";
  
  export default {
    components: {
      MyAframe,
    },
    setup() {
      const bareQSets = ref([]);
  
      const store = useStore();
  
      function setBareQSets(data) {
        console.log("beforre setBare");
        bareQSets.value = gameMixins.makeAllReadyMindsets(data,data[0]);
        for (let b of bareQSets.value){
          console.log("aftttter setBare");

          console.log(b.title);
        }
      }



      const getAllMindsets = computed(() =>
      store.getters["core/getFirstMindsets"].flat()
    );
      const shouldReauthorize = computed(
        () => store.getters["outermap/getShouldReauthorize"]
      );
      watch(
        () => shouldReauthorize.value,
        () => {
          if (shouldReauthorize.value) {
            window.parent.postMessage(
              JSON.stringify({ command: "reauthorize" }),
              "*"
            );
          }
        }
      );
      const signals = ref([]);
  
      const router = useRouter();
      const useridHashed = router.currentRoute.value.params.useridhashed;
      const jwt = router.currentRoute.value.params.jwt;
      const message = router.currentRoute.value.params.message;
      const zoomLevel = ref(13);
      const isReady = ref(false);
      const mgq = ref(null);
      const getSelectedMgq = computed(
        () => store.getters["core/getterSelectedMgq"]
      );
      const isWebDevice = ref(false);
  
      onMounted(() => {
        window.onmessage = (e) => {
           console.log("in on message");
          console.log("incoming");
          if (e.data != "") {
            if (null != e.data.type || typeof e.data != "string") {
              console.log(" is null or");
  
              return;
            }
            let message = JSON.parse(e.data);
            console.log(" message " + message.command);
  
            if (message["command"] == "setJwtToken") {
              store.commit("core/setJwtToken", message["data"]);
            } else if (message["command"] == "requestLocation") {
              sendSelectedLocation();
            } else if (message["command"] == "setBareQSets") {
              console.log("beforrrr");
              setBareQSets(message["data"]);
            } else if (message["command"] == "zoomIn") {
              webviewMap.value.zoomIn();
            } else if (message["command"] == "zoomOut") {
              webviewMap.value.zoomOut();
            } else if (message["command"] == "moveToMindset") {
              moveToMindset(message["data"]);
            } else if (message["command"] == "moveToLocation") {
              webviewMap.value.moveToLocation(message["data"]);
            }else if (message["command"] == "setGuessedPosition") {
              webviewMap.value.setGuessedPosition(message["data"]);
            } else if (message["command"] == "setModePositionCorrecting") {
              webviewMap.value.isModePositionCorrecting = true;
            } else if (message["command"] == "unsetModePositionCorrecting") {
              webviewMap.value.isModePositionCorrecting = false;
            } else if (message["command"] == "setLocationFromSensors") {
              webviewMap.value.setLocationFromSensors(message["data"]);
            }
            else if (message["command"] == "setHeadingFromSensors") {
              webviewMap.value.setHeadingFromSensors(message["data"]);
            }
          }
        };
      });
      onBeforeMount(async () => {
        store.commit("outermap/setIsOuterMap", true);
        const userid = apiPost.getUseridFromH(useridHashed);
        store.commit("core/setUser", {
          username: "",
          userid: userid,
          email: "",
          password: "",
          social: "",
        });
        store.commit("core/setJwtToken", jwt);
        let messageObj = JSON.parse(message);
        if (messageObj.device == "web") {
          isWebDevice.value = true;
        }
        zoomLevel.value = Number(parseFloat(messageObj.zoom));
        if (messageObj.mgqId != -1) {
          await doIfMindgluequest(
            messageObj.mgqId.toString(),
            messageObj.latitude.toString(),
            messageObj.longitude.toString()
          );
        } else {
          store.commit("core/setSelectedMgq", {
            mindgluequestId: -1,
            longitude: messageObj.longitude.toString(),
            latitude: messageObj.latitude.toString(),
          });
        }
  
        isReady.value = true;
        sendInformation("informMounted");
      });
  
      const webviewMap = ref(null);
  
      async function doIfMindgluequest(mgqIdString, latitude, longitude) {
        let myMgqMap = await store.dispatch(
          "core/requestSelectedPresentation",
          mgqIdString
        );
        mgq.value = JSON.parse(myMgqMap["mgq"]);
        mgq.value.latitude = latitude;
        mgq.value.longitude = longitude;
        store.commit("core/setSelectedMgq", mixins.cloneObject(mgq.value));
        console.log("doIfMindgluequest requestSelectedPresentation mgq.value.longitude "+mgq.value.longitude);
        await mixins.fetchMgqDrawings(store.getters["gameApi/getCurrentMgq"]);
        signals.value = mixins.signals.value;
      }
  
      async function moveToMindset(ifTargetMap) {
        let mgqIdString = ifTargetMap["target"]["gamecode"].split("_")[0];
        if (mgqIdString == getSelectedMgq.value.mindgluequestId) {
          webviewMap.value.moveToMindset(ifTargetMap);
        } else {
          await doIfMindgluequest(
            mgqIdString,
            ifTargetMap["target"]["latitude"],
            ifTargetMap["target"]["longitude"]
          );
          webviewMap.value.moveToMindset(ifTargetMap);
          sendInformation("informMoved");
        }
      }
  
      function sendInformation(info) {
        let toSend = {
          command: info,
          direction: "toClient",
        };
        if (mixins.signals.value.length > 0) {
          toSend["data"] = "hasSignals";
        }
        console.log(" sendInformation "+info);
        window.parent.postMessage(JSON.stringify(toSend), "*");
      }
  
      function sendSelectedLocation() {
        let toSend = {
          command: "setLocation",
          direction: "toClient",
  
          data: webviewMap.value.selectedLocation,
        };
        window.parent.postMessage(JSON.stringify(toSend), "*");
      }
  
      function sendSelectedMindset(mindsetId) {
        let toSend = {
          command: "setSelectedMindset",
          direction: "toClient",
          data: mindsetId,
        };
        window.parent.postMessage(JSON.stringify(toSend), "*");
      }
  
      return {
        isReady,
        isWebDevice,
        webviewMap,
        mgq,
        bareQSets,
        zoomLevel,
        getAllMindsets,
        sendSelectedMindset,
        sendSelectedLocation,
      };
    },
  };
  </script>
  <style>
  div {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
  }
  </style>