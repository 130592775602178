<template>
    <div style="display: flex; gap:1em;">

        <input :disabled="isSocial" class="input" type="text" placeholder="Key" v-model="propKey" />
        <input :disabled="isSocial" class="input" type="text" placeholder="Value" v-model="propValue" />
        <div :class="{ button: !isSocial, '': !isSocial }" @click="removeProperty">
        <i class="fas fa-minus is-size-5"></i>
      </div>
    </div>
</template>
<script>
import { onBeforeMount, ref } from 'vue';
import * as mixins from "@/utils/mixins";
export default {
    props: {
        propertyKey: {
            type: String,
            required: true,
        },
        propertyValue: {
            type: String,
            required: true,
        },
        isSocial: {
            type: Boolean,
            required: true,
        },

    },
    setup(props,{emit}) {
        const propKey = ref("");
        const propValue = ref("");

        onBeforeMount(() => {
            propKey.value = props.propertyKey;
            propValue.value = props.propertyValue;
        });

        async function provideProperty() {
            let toReturn = {};
            if (propKey.value != "" && propValue.value!="") {
                toReturn = { propertyKey: propKey.value.toString(), propertyValue: propValue.value };
            }
            else {
                await mixins.alertConfirm("Both fields should be filled.", false);
            }
            return toReturn;
        }

        function removeProperty(){
            emit("removeProperty",propKey.value);
        }



        return {
            propKey,
            propValue,
            provideProperty,
            removeProperty,
        };
    }
}
</script>