<template>
  <div>
    <div class="card p-4">
        In order to create a quest you must agree with the <router-link to="quesdom-terms"><span class="has-text-weight-semibold">Terms of Service</span></router-link> 
        and the <router-link to="quesdom-privacy"><span class="has-text-weight-semibold">Privacy Statement</span></router-link> of Quesdom. Please check the box underneath to confirm this agreement of yours.
           
           <div> <br><router-view></router-view></div>

    </div>
  </div>
</template>
<script>
export default {
  setup() {
    
  },
}
</script>