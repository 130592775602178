<template>
    <div>
        <div>{{ mso.payload.title }}</div>
        <div>
            <div v-for="checkboxTitle, idx in mso.payload.checkboxTitles" :key="idx">
                <input name="localize" class="radio ml-4 mt-6" type="radio" @change="selectedCheckbox(checkboxTitle)"
                    :checked="idx == 0" />
                {{ checkboxTitle }}
            </div>
        </div>

    </div>
</template>
<script>
import { ref, onBeforeUnmount, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
/**
 * 
 * mso with payload with title and array of checkboxes, first checked
 * 
*/
export default {
    setup() {
        const store = useStore();
        const mso = computed(() => store.getters["core/getModalDynamicObject"]);
        const selected = ref("");

        onBeforeMount(() => selected.value = mso.value.payload.checkboxTitles[0]);
        onBeforeUnmount(() => {
            let obj = mso.value;
            obj.content = selected.value;
            store.commit("core/setModalDynamicObject", obj);
        });

        function selectedCheckbox(value) {
            selected.value = value;
        }
        
 /*eslint-disable no-unused-vars*/
        function checkSubmit(submitted) {
            return true;
        } 

        return {
            mso,
            selectedCheckbox,
            checkSubmit,
        };
    }
}
</script>