<template>
  <div>
    <div class="reaction_row">
      <div class="reaction_text">
        <textarea class="textarea" :class="{ reaction_empty: isEmpty || isOver }" :disabled="isSocial" rows="2"
          v-model="answerInner.answername"></textarea>
      </div>
      <div class="ml-2">
        <div :class="{ 'button is-rounded is-small': !isSocial, '': isSocial }" @click="removeReaction">
          -
        </div>
        <div class="control">
          <div style="display:flex">
            <select v-model="answerInner.grade" :disabled="isSocial">
              <option style="background-color: red">0</option>
              <option style="background-color: orange">25</option>
              <option style="background-color: yellow">50</option>
              <option style="background-color: greenyellow">75</option>
              <option style="background-color: green">100</option>
            </select>
            <input type="number" v-model="answerInner.grade" min="0" max="100" step="1" />
          </div>
        </div>
        <div style="margin-top:0.2em; display: flex; justify-content: center;">
          <div class="is-small" @click="changeInturn(true)" style="background-color: white;">
            <i class="fas fa-angle-up is-size-5"></i>
          </div>
          &nbsp;
          <div  class="is-small" @click="changeInturn(false)" style="background-color: white;">
            <i class="fas fa-angle-down is-size-5"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onBeforeMount } from "vue";
import * as mixins from "@/utils/mixins";

export default {
  props: {
    answer: {
      type: Object,
      required: true,
    },
    isSocial: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const answerInner = ref(null);
    const isEmpty = computed(() => answerInner.value.answername.trim() == "");
    const isOver = computed(() => answerInner.value.answername.length > 10000);



    onBeforeMount(() => {
      answerInner.value = mixins.cloneObject(props.answer);
    });


    function removeReaction() {
      if (!props.isSocial) {
        emit("removeReaction", answerInner.value.answerid);
      }
    }

    function changeInturn(isUp) {
      if (!props.isSocial) {
        emit("changeAnswersInturn", answerInner.value.answerid, isUp);
      }
    }

    async function provideReaction() {
      if (answerInner.value.grade == "") {
        answerInner.value.grade = 0;
      }
      else if (answerInner.value.grade > 100) {
        answerInner.value.grade = 100;
      }
      if (isOver.value) {
        let showMessage = await mixins.alertConfirm("The reaction starting with" + answerInner.value.answername.substring(0, 50) + "... is too long. Each reaction should not be more than 10.000 characters long. It was trimmed at that size.", false);
        if (showMessage) {
          answerInner.value.answername = answerInner.value.answername.substring(0, 9995);
        }
        else {
          answerInner.value.answername = "dummy react";
        }
      }
      return answerInner.value;
    }


    return {
      answerInner,
      isEmpty,
      removeReaction,
      provideReaction,
      isOver,
      changeInturn,
    };
  },
};
</script>
<style scoped>
.reaction_row {
  padding-top: 0.2em;
  display: flex;
}

.reaction_text {
  width: 90%;
}

.reaction_empty {
  border: red;
  border-style: solid;
}
</style>