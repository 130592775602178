<template>
    <div>Image visible between zoom levels
        <div>min:
            <input class="input" v-model="minZoom" min=0 max="18" type="number" @change="checkZoomLevels" />
        </div>
        <div> max: <input class="input" v-model="maxZoom" min=0 max="18" type="number" @change="checkZoomLevels" />
        </div>
    </div>
</template>
<script>
import { ref, onBeforeUnmount, computed } from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";

export default {
    setup() {
        const store = useStore();
        const minZoom = ref(0);
        const maxZoom = ref(19);
        const mso = computed(() => store.getters["core/getModalDynamicObject"]);

        onBeforeUnmount(() => {
            let obj = mso.value;
            obj.min = minZoom.value;
            obj.max = maxZoom.value;
            store.commit("core/setModalDynamicObject", obj);
        });

        async function checkZoomLevels() {
            if (minZoom.value == maxZoom.value || minZoom.value > maxZoom.value) {
                mixins.alertConfirm("Min value should be smaller than max value, values kept default", false); 
                minZoom.value = 0;
                maxZoom.value = 19;
            }
        }
/*eslint-disable no-unused-vars*/
        function checkSubmit(submitted) {
           return true;
        }
    
        return {
        minZoom,
        maxZoom,
        checkZoomLevels,
        checkSubmit,
    };
}
}
</script>