
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'


const state = {
  isOuterMap:false,
  shouldReauthorize:false,
  currentMgq:{mindgluequestId:0,title:""},
  zoomLevel:10,
  mindsetsNonLocated:[],
  vectorAnimation:null,
  currentScore:0,
  gameScreenWidthHeight:{},
  backgroundAudio:null,
  effectsAudio:null,
  startedAudioUrl:"", 
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}