<template>
    <div v-if="shouldShow" class="score_div">
        <span class="icon is-small is-left">
            <i class="fas fa-star" style="color:yellow;  text-shadow: 1px 1px #1d1717;"></i>
        </span> {{ currentScore }}
    </div>
</template>

<script>

import { ref, onBeforeMount, computed, onBeforeUnmount, } from 'vue';
import { useStore } from 'vuex';
import * as gameMixins from '@/utils/gameMixins';
export default {

    setup() {
        const store = useStore();
        const currentScore = computed(() => store.getters["gameApi/getCurrentScore"]);

        onBeforeMount(async () => {
            let chainedScoreFromLast = await gameMixins.retrieveChainedMgqIdArrAndScoreFromLastMgqId([store.getters["gameApi/getCurrentMgq"].mindgluequestId],{totalScore:0,penalty:null});
            store.commit("gameApi/setCurrentScore",chainedScoreFromLast.scoreAndPenalty.totalScore);
            shouldShow.value = true;
        });
        onBeforeUnmount(() => store.commit("gameApi/renewCurrentScore"));
        const shouldShow = ref(false);
        return {

            currentScore,
            shouldShow,
        };
    }
}
</script>

<style>
.score_div {
    background-color: white;
    padding: .5em;
    border-radius: 0.5em;
}
</style>