
<template>
    <div class="mb-2">
        <div class="m-2 reaction_row card" @click="sendSelected">
            <div :class="{ 'invisible': !selected, 'visible': selected }" class="ml-2"><i class="fas fa-check-circle"
                    style="color:green;"></i></div>
            <div class="ml-2 p-2">{{ mindset.questionlist[mindChunkIdx].answerlist[mindChunkReactionIdx].answername }}</div>
        </div>
    </div>
</template>
<script>
import { computed } from 'vue';
export default {
    props: {
        mindset: {
            type: Object
        },
        mindChunkIdx: {
            type: Number
        },
        mindChunkReactionIdx: {
            type: Number
        },
    },
    setup(props, { emit }) {
        const selected = computed(() => {
            let toReturn = false;
            if (props.mindset.questionlist[props.mindChunkIdx].answeredid != undefined) {
                if (props.mindset.questionlist[props.mindChunkIdx].answeredid == props.mindset.questionlist[props.mindChunkIdx].answerlist[props.mindChunkReactionIdx].answerid) {
                    toReturn = true;
                }
            }
            return toReturn;
        });

        function sendSelected() {
            emit("reactionSelected", {mindChunkIdx:props.mindChunkIdx,mindChunkReactionIdx:props.mindChunkReactionIdx,toSelect:!selected.value});
        }
        return {
            selected,
            sendSelected,
        };
    }
}
</script>
<style>
.reaction_row {
    display: flex;
    align-items: center;
}

.invisible {
    opacity: 0;
    transition: opacity .5s;
}

.visible {
    opacity: 1;
    transition: opacity .5s;
}
</style>