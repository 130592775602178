<template>
  <div>
    <div class="button" @click="showMap" :class="{ 'is-danger': isDanger }">
      <div>
        <i
          :class="{
            'fas fa-map-marker': !isDisabled,
            'fas fa-lock': isDisabled,
          }"
        ></i
        >{{ attrCoordinates[0] == "0.000011" ? "" : attrCoordinates[0] }}
        {{ attrCoordinates[1] == "0.000011" ? "" : attrCoordinates[1] }}
      </div>
    </div>
    <div v-show="isMapActive">
    <!--   <div v-if="isRealMap"> -->
        <OneMarkerMap
          ref="attrMap"
          @close="closeMap"
          :oneMarkerCoords="myOuterCoords"
          :isRealMap="isRealMapHere"
        />
     <!--  </div> -->
     <!--  <div v-else>
        <OneMarkerImageMap
          ref="attrMap"
          @close="closeMap"
          :oneMarkerCoords="myOuterCoords"
          :properties="outerMapName"
        />
      </div> -->
    </div>
  </div>
</template>
<script>
import OneMarkerMap from "@/components/parts/OneMarkerMap.vue";
//import OneMarkerImageMap from "@/components/parts/OneMarkerImageMap.vue";
import { ref, toRefs } from "vue";
import * as mixins from "@/utils/mixins";

export default {
  components: {
    OneMarkerMap,
   // OneMarkerImageMap,
  },
  props: {
    isRealMap: {
      type: Boolean,
      default: true,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    outerCoords: {
      type: Array,
    },
   
  },
  setup(props) {
    const isDanger = toRefs(props).danger;
    const isRealMapHere = toRefs(props).isRealMap;
    const isDisabled = toRefs(props).disabled;
    const attrCoordinates = ref([]);
    const myOuterCoords = toRefs(props).outerCoords;

    if (myOuterCoords.value.length > 0) {
      attrCoordinates.value = myOuterCoords.value;
    }
    const attrMap = ref(null);
    const isMapActive = ref(false);
    function showMap() {
      if (!isDisabled.value) {
        isMapActive.value = true;
        mixins.showMapRight(isRealMapHere.value ,attrMap.value.realMap);
      }
    }
    function closeMap() {
      attrCoordinates.value = attrMap.value["coord"];
      isMapActive.value = false;
    }
    return {
      isDanger,
      isDisabled,
      showMap,
      closeMap,
      attrMap,
      myOuterCoords,
      isMapActive,
      attrCoordinates,
      isRealMapHere,
    };
  },
};
</script>