<template>
    <div class="modal_dynamic_max_inner" style="height:100%; overflow-y:auto;" >
        <div style="max-height: 30%;padding:.5em; overflow-y:auto; background-color:#f4ffff;">{{ mso.payload.mindset.title
            }}
        </div>
        <div v-if="mso.payload.mindset.questionlist.length > 0"
            style="min-height: 70%; margin-top: .5em;  display: flex; align-items: center;">
            <div class="arrow_button" v-if="mindchunkIdx != 0"><i class="fas fa-angle-left"
                    @click="changeIdx(false)"></i>
            </div>
            <MindchunkGame id="mindchunkDivId" class="card" :mindset="mindset" :index="mindchunkIdx"
                @reactionSelected="reactionSelected" />
            <div class="arrow_button" @click="changeIdx(true)" v-if="!isLast"><i class="fas fa-angle-right"></i></div>


        </div>
    </div>
</template>
<script>
import { ref, onBeforeUnmount, onBeforeMount, computed, } from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";
import * as gameMixins from "@/utils/gameMixins";
import MindchunkGame from "@/components/parts/MindchunkGame.vue";
/**
 * 
 * 
 * 
*/
export default {
    components: {
        MindchunkGame,
    },
    setup() {
        const store = useStore();
        const mso = computed(() => store.getters["core/getModalDynamicObject"]);

        const toIncr = ref(false);
        const mindchunkIdx = ref(0);
        const isLast = computed(() => mindset.value.questionlist.length == mindchunkIdx.value + 1);
        const divChunk = ref(null);
        const mindset = ref(null);
        onBeforeMount(() => {
            mindset.value = mixins.cloneObject(mso.value.payload.mindset);
            if (mindset.value.questionlist) {
                if (mindset.value.questionlist.length > 0) {
                    mindset.value.questionlist.sort(function (a, b) { return (a.inturn - b.inturn) })
                }
            }

        });

        onBeforeUnmount(() => {
            let obj = mso.value;
            obj.content = mindset.value;
            store.commit("core/setModalDynamicObject", obj);
        });

        function changeIdx(toIncrement) {
            if (toIncrement) {
                if (!isLast.value) {
                    toIncr.value = true;
                    makeTransition();
                }
            }
            else {
                if (mindchunkIdx.value > 0) {
                    toIncr.value = false;
                    makeTransition();

                }
            }
        }

        function makeTransition() {
            divChunk.value = document.getElementById("mindchunkDivId");
            let event = gameMixins.whichTransitionEvent();
            divChunk.value.addEventListener(event, changeIdxAfter, { once: true });
            divChunk.value.style.transition = "all .5s";
            divChunk.value.style.opacity = 0;
        }

        function changeIdxAfter() {
            if (toIncr.value) {
                mindchunkIdx.value += 1;
            }
            else {
                mindchunkIdx.value -= 1;
            }
            //  divChunk.value.removeEventListener(event, changeIdxAfter, false);

            divChunk.value.style.opacity = 1;
            divChunk.value.style.transform = "translate(0em, 0)";
        }

        function reactionSelected(payload) {
            console.log("payload " + payload.mindChunkIdx + " " + payload.mindChunkReactionIdx);
            if (payload.toSelect) {
                mindset.value.questionlist[payload.mindChunkIdx].answeredid = mindset.value.questionlist[payload.mindChunkIdx].answerlist[payload.mindChunkReactionIdx].answerid;
            }
            else {
                mindset.value.questionlist[payload.mindChunkIdx].answeredid = 0;
            }
        }



        async function checkSubmit(submitted) {
            if (submitted) {
                if (mindset.value.properties.allChecked != undefined && mindset.value.properties.allChecked == "true") {
                    let allChecked = true;
                    for (let i = 0; i < mindset.value.questionlist.length; i++) {
                        if (mindset.value.questionlist[i].answeredid == 0) {
                            allChecked = false;
                        }
                    }
                    if (allChecked) {
                        return true;
                    }
                    else {
                        await mixins.alertConfirm("Please set a reaction for all the mindchunks of the mindset (navigate left and right using the arrows)", false);
                        return false;
                    }
                }
                else {
                    return true;
                }
            }
        }

        return {
            mso,
            mindchunkIdx,
            isLast,
            mindset,
            reactionSelected,
            changeIdx,
            checkSubmit,
        };
    }
}
</script>
<style>
.arrow_button {
    height: 100%;
    text-align: center;
    padding-top: 30%;
    background-color: rgb(243, 255, 255);
}

.arrow_button:hover {
    background-color: rgb(205, 255, 255);

}
</style>