  export function getMindgluequests ( state) {
    return state.mindgluequests; 
  }

  export function getterCreatedMgq ( state) {
    return state.createdMgq; 
  }
  export function getterSelectedMgq ( state) {
    return state.selectedMgq; 
  }

  export function getJwt ( state) {
    return state.jwt; 
  }

  export function getUser ( state) {
    return state.user; 
  }
  export function getFirstMindsets ( state) {
    return state.firstMindsets; 
  }
  export function getSelectedMindsetsArrayIndex ( state) {
    return state.selectedMindsetsArrayIndex; 
  }

  export function getOriginalMindsets ( state) {
    return state.originalMindsets; 
  }
  
  export function getTextSentences ( state) {
    return state.textSentences; 
  }
  export function getMindsetToShow ( state) {
    return state.mindsetToShow; 
  }
  export function getMyAppMindsets ( state) {
    return state.myAppMindsets; 
  }

  export function getMgqLanguage ( state) {
    return state.mgqLanguage; 
  }
  export function getMgqTrophies ( state) {
    return state.mgqTrophies; 
  }
  export function getMgqOriginalTrophies ( state) {
    return state.mgqOriginalTrophies; 
  }
  export function getIsLoading ( state) {
    return state.isLoading; 
  }
  export function getIsConfirm ( state) {
    return state.isConfirm; 
  }
  export function getAlertConfirmMessage ( state) {
    return state.alertConfirmMessage; 
  }
  export function getDeletedMgqs ( state) {
    return state.deletedMgqs; 
  }
  export function getAllMgqMapRegions ( state) {
    return state.allMgqMapRegions; 
  }
  export function getOuterMessage ( state) {
    return state.outerMessage; 
  }

  export function getUserLatitude ( state) {
    return state.user.latitude; 
  }

  export function getUserLongitude ( state) {
    return state.user.longitude; 
  }

  export function getTemporaryContent ( state) {
    return state.temporaryContent;
  }



  export function getModalDynamicObject( state) {
    return state.modalDynamicObject;
  }

  export function getModalDynamicComponent( state) {
    return state.modalDynamicComponent;
  }

  export function getToast( state) {
    return state.toast;
  }
