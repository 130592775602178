<template>
<LoadingSign/>
<ToastComponent/>
<AlertConfirm/>
<ModalDynamic/>
<VectorAnimationPlayer/>
  <router-view></router-view>
</template>

<script>
import LoadingSign from "@/components/parts/LoadingSign.vue";
import AlertConfirm from "@/components/parts/AlertConfirm.vue";
import ModalDynamic from "@/components/parts/ModalDynamic.vue";
import VectorAnimationPlayer from "./components/parts/VectorAnimationPlayer.vue";
import ToastComponent from "./components/parts/ToastComponent.vue";

export default {
  name: "App",
  components: {
    LoadingSign,
    AlertConfirm,
    ModalDynamic,
    ToastComponent,
    VectorAnimationPlayer,
},
};
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.0/css/bulma.min.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
  height: 100%;
}

html, body {
  height: 100%;
  margin: 0;

}
</style>
