<template>
  <div class="field">
    <label class="label">Properties</label>
    <div @click="addProperty" :class="{ button: !isSocial, '': !isSocial }">
      <i class="fas fa-plus is-size-4"></i>
    </div>
    <div class="mt-2" v-for="(value, key) of properties" :key="key">
      <PropertyComponent :ref="setPropertiesRef" :propertyKey="key" :propertyValue="value" :isSocial="isSocial"
        @removeProperty="removeProperty" />

    </div>
  </div>
</template>

<script>
import PropertyComponent from "@/components/parts/PropertyComponent.vue";
import { onBeforeMount,onBeforeUpdate,ref,nextTick } from "vue";

export default {
  components: {
    PropertyComponent,
  },
  props: {
    outerProperties: {
      type: Object,
    },
    isSocial:{
      type:Boolean,
    },
  },
  setup(props) {
    const properties = ref({});
    let propertiesRefs = [];
    const setPropertiesRef = (el) => {
      if (el) {
        propertiesRefs.push(el);
      }
    };
    onBeforeMount(() => properties.value = props.outerProperties);
    onBeforeUpdate(async () => {
      propertiesRefs = [];
    });
    async function addProperty() {
      if (Object.keys(properties.value).length == 0) {
        properties.value[''] = '';
      }
      else {
        let props = await gatherProperties();
        properties.value = props;
        await nextTick();
        properties.value[''] = '';
      }
    }

    async function removeProperty(key) {
      let props = await gatherProperties();
      delete (props[key]);
      properties.value = {};
      await nextTick();
      for (let key in props) {
        let value = props[key];
        console.log("key " + key + ", value " + value);
        properties.value[key] = value;
      }
    }


    async function gatherProperties() {
      let props = {};
      for (let prpRef of propertiesRefs) {
        let gatheredPr = await prpRef.provideProperty();
        if (Object.keys(gatheredPr).length != 0) {
          props[gatheredPr.propertyKey] = gatheredPr.propertyValue;
        }
      }
      return props;
    }

    async function provideProperties() {
     let toReturn = await gatherProperties();
      return toReturn;
    }

    return {
      addProperty,
      removeProperty,
      properties,
      provideProperties,
      setPropertiesRef,
    };
  }
}
</script>