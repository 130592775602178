<template>
  <div>
    <div class="loggin_wrapper">
      <div @click="onTap" class="button is-primary">
        <div class="text_and_icon">
          <div class="button_text">{{
        buttonText
      }}</div>
          <div>
            <i :class="{ 'fas fa-sign-in-alt': !isLoggedIn, 'fas fa-sign-out-alt': isLoggedIn }"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";
import * as DBProvider from "@/utils/DBProvider";
import * as mixins from "@/utils/mixins";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const isLoggedIn = computed(() => store.getters["core/getUser"].userid != undefined);
    const buttonText = computed(() => isLoggedIn.value ? "Logout" : "Login");
    async function onTap() {
      if (isLoggedIn.value) {
        let shouldReallyLogOut = true;
        let shouldSignUpGeneric = false;
        if (store.getters["core/getUser"].email.indexOf("temp.mindglue.io") > -1) {
          shouldReallyLogOut = false;
          shouldSignUpGeneric = await mixins.alertConfirm("Your account is a generic one. If you log out you won't be able to retrieve the content you might have created or your adventure status. Would you like to sign up first and keep your data?", true);
          if (shouldSignUpGeneric) {
           store.commit("core/setTemporaryContent",{isSignup:true});
            shouldReallyLogOut = false;
          }
          else{
            await logOut();
          }
        }
        if (shouldReallyLogOut) {
         await logOut();
        }
        else {
          if (shouldSignUpGeneric){
            router.push("/login");
          }
        }
      }
      router.push("/login");
    }

    async function logOut() {
      if (store.getters["gameApi/getCurrentMgq"].mindgluequestId!=1) {
        await store.dispatch("gameApi/setUsMindgluequestApi", { mindgluequest: store.getters["gameApi/getCurrentMgq"]});
      }
      window.localStorage.clear();
      await DBProvider.clearAll();
      store.commit('core/renewLog');
    }
    return {
      buttonText,
      isLoggedIn,
      onTap,
    };
  },
};
</script>

<style scoped>
.login_wrapper {
  padding: 1em;
}

.text_and_icon {
  display: flex;
  gap: .5em;
}

@media only screen and (max-width: 500px) {
  .button_text {
    display: none;
  }
}
</style>