<template>
  <div>
    <div class="next_div">
      <div class="button is-primary has-tooltip-right" @click="getdata"
        :data-tooltip="withTooltips ? $t('tlt_mgqattributes_next') : null">
        {{ $t("Next") }}
      </div>
    <!--   <div class="button is-primary has-tooltip-right" @click="getdata3D"
        :data-tooltip="withTooltips ? $t('tlt_mgqattributes_next') : null">
        3D
      </div> -->

      <div class="next_div">
        <div class="">Advanced</div>
        <div class="field pl-2">
          <input v-model="isAdvanced" type="checkbox" />
        </div>
      </div>
    </div>
    <div class="create_container">
      <div class="ml-4 mr-4">
        <div class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_title') : null">Title</label>
          <div class="control has-icons-left">
            <input class="input" :class="{ 'is-danger': wrongTitle }" type="text" placeholder="Title" v-model="title" />
            <span class="icon is-small is-left">
              <i class="fas fa-pen"></i>
            </span>
          </div>
        </div>
        <div v-if="isAdvanced" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_short_descr') : null
          ">Short Description</label>
          <div class="control">
            <textarea class="textarea" :class="{ 'is-danger': wrongShortDescr }" placeholder="Short Description"
              rows="2" v-model="shortDescr"></textarea>
          </div>
        </div>
        <div v-if="isAdvanced" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_long_descr') : null
          ">Long Description</label>
          <div class="control">
            <!--  <textarea class="textarea" :class="{ 'is-danger': wrongLongDescr }" placeholder="Long Description" rows="6"
              v-model="longDescr"></textarea> -->


            <div style="display: flex; justify-content: center; align-items: center;">
              <div><input type="checkbox" v-model="editorToggle" @change="updateContentToggled" />&nbsp; Editor</div>
              <div v-if="!editorToggle" class="pl-4" style="font-size: small; color: grey;">characters:&nbsp;{{
          longDescr.length }}
              </div>
            </div>
            <div class="mb-6" v-show="editorToggle">
              <QuillEditor :options="options" ref="quillEditorRef" :content="longDescr" contentType="html">
              </QuillEditor>
            </div>
            <div v-show="!editorToggle">
              <textarea class="textarea mindchunk_area" :class="{ 'is-danger': wrongLongDescr }" rows="4"
                v-model="longDescr"></textarea>
            </div>






          </div>
        </div>
        <div v-if="isAdvanced" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_location') : null
          ">Location</label>
          <div class="control has-icons-left">
            <input class="input" :class="{ 'is-danger': wrongLocation }" type="text" placeholder="Location"
              v-model="location" />
            <span class="icon is-small is-left">
              <i class="fas fa-globe"></i>
            </span>
          </div>
        </div>
        <div v-if="isAdvanced" class="field">
          <PropertiesComponent ref="propsComponent" :outerProperties="properties" />
        </div>
      </div>
      <div class="ml-4 mr-4">
        <!-- <div class="field map_selection">
          <div class="control" :class="{ 'is-danger': wrongCoordinates }">
            <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_base_map') : null
              ">Base map</label>
            <div class="select" :class="{ 'is-danger': wrongCoordinates }">
              <select v-model="properties" class="select" :class="{ 'is-danger': wrongCoordinates }" @change="changeMapName">
                <option value="none"></option>
                <option value="real">With real map</option>
                <option value="drawing">Without real map</option>
              </select>
            </div>
          </div>

          <div v-if="showMapButton">
            <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_center_coordinates') : null
              ">Center</label>
            <MapButton ref="mapButtonMap" :outerCoords="outerCoords" :isRealMap="isRealMap" :properties="properties" />
          </div>
        </div> -->
        <div v-if="isAdvanced" class="field">
          <label class="label" :data-tooltip="withTooltips
          ? $t('tlt_mgqattributes_advertising_coordinates')
          : null
          ">Advertising Coordinates</label>
          <MapButton ref="advertisingMap" :outerCoords="advertisingCoords" :isRealMap="true" />
        </div>

        <div v-if="isAdvanced || mgqMode == 'contest'" class="field">
          <label class="label" :data-tooltip="withTooltips
          ? $t('tlt_mgqattributes_mindsets_shown_distance')
          : null
          ">Mindsets shown within distance</label>
          <div class="control has-icons-left" style="display:flex; justify-content: center;">
            <div class="select">
              <select v-model="withinDistance">
                <option value="0">All ordered</option>
                <option value="50">50m</option>
                <option value="100">100m</option>
                <option value="200">200m</option>
                <option value="500">500m</option>
                <option value="1000">1000m</option>
                <option value="2000">2000m</option>
                <option value="5000">5000m</option>
                <option value="10000">10000m</option>
              </select>
            </div>
            <input type="number" v-model="withinDistance" min="0" max="1000000" step="1" />
          </div>
        </div>

        <div v-if="isAdvanced || mgqMode == 'contest'" class="field">
          <label class="label" :data-tooltip="withTooltips
          ? $t('tlt_mgqattributes_mindsets_active_distance')
          : null
          ">Mindsets active in distance</label>
          <div class="control has-icons-left" style="display:flex; justify-content: center;">
            <div class="select">
              <select v-model="activeDistance">
                <option value="0">Everywhere</option>
                <option value="50">50m</option>
                <option value="100">100m</option>
                <option value="200">200m</option>
                <option value="500">500m</option>
                <option value="1000">1000m</option>
                <option value="2000">2000m</option>
                <option value="5000">5000m</option>
                <option value="10000">10000m</option>
              </select>
            </div>
            <input type="number" v-model="activeDistance" min="0" max="1000000" step="1" />
          </div>
        </div>

        <div v-if="isAdvanced || mgqMode == 'contest'" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_start_date_time') : null
          ">Start Date</label>
          <div class="control has-icons-left">
            <input class="input" :class="{ 'is-danger': isWrongStartDatetime }" type="date" v-model="startDate" />
          </div>
        </div>
        <div v-if="isAdvanced || mgqMode == 'contest'" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_start_date_time') : null
          ">Start Time</label>
          <div class="control has-icons-left">
            <input class="input" :class="{ 'is-danger': isWrongStartDatetime }" type="time" step="1"
              v-model="startTime" />
          </div>
        </div>

        <div v-if="isAdvanced || mgqMode == 'contest'" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_end_date_time') : null
          ">End Date</label>
          <div class="control has-icons-left">
            <input class="input" :class="{ 'is-danger': isWrongEndDatetime }" type="date" v-model="endDate" />
          </div>
        </div>
        <div v-if="isAdvanced || mgqMode == 'contest'" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_end_date_time') : null
          ">End Time</label>
          <div class="control has-icons-left">
            <input class="input" :class="{ 'is-danger': isWrongEndDatetime }" type="time" step="1" v-model="endTime" />
          </div>
        </div>
      </div>
      <div class="ml-4 mr-4">
        <div v-if="isAdvanced || mgqMode == 'contest'" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_show_mindsets_map') : null
          ">Show mindsets on map</label>
          <div class="control has-icons-left">
            <div class="select">
              <select v-model="showPoints">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="isAdvanced || mgqMode == 'contest'" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_virtual_real') : null
          ">Virtual / Real</label>
          <div class="control has-icons-left">
            <div class="select">
              <select v-model="virtual">
                <option value="true">Virtual</option>
                <option value="false">Real</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_active') : null">Active /
            Inactive</label>
          <div class="control has-icons-left">
            <div class="select">
              <select v-model="active">
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="isAdvanced || mgqMode == 'social'" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_editable') : null
          ">Editable</label>
          <div class="control has-icons-left">
            <div class="select">
              <select v-model="editable">
                <option value="true">Others can add mindsets</option>
                <option value="false">Others cannot add mindsets</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="isAdvanced" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_searchable') : null
          ">Searchable</label>
          <div class="control has-icons-left">
            <div class="select">
              <select v-model="searchable">
                <option value="true">Can search in mindsets</option>
                <option value="false">Cannot search in mindsets</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="isAdvanced" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_for_contest') : null
          ">For Contest</label>
          <div class="control has-icons-left">
            <div class="select">
              <select v-model="forContest">
                <option value="true">It is a contest</option>
                <option value="false">Not a contest</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="isAdvanced" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_image_file') : null
          ">Image file</label>
          <div class="file has-name is-justify-content-center" :class="{ 'is-danger': wrongImageNameFile }">
            <label class="file-label">
              <input class="file-input" type="file" name="resume" accept="image/png, image/jpeg"
                @change="setImageFile" />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">Choose</span>
              </span>
              <span class="file-name">
                {{ imageName }}
              </span>
            </label>
          </div>
        </div>
        <div v-if="isAdvanced" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_color') : null">Color</label>
          <div class="control has-icons-left">
            <div class="select" :class="{ 'is-danger': wrongColor }">
              <select v-model="color" :style="'background-color:' + colors[color]">
                <option :value="index" :style="'background-color:' + itemcolor" v-for="(itemcolor, index) in colors"
                  :key="itemcolor">
                  &nbsp;&nbsp;
                </option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_password') : null
          ">Password protected</label>
          <div class="field">
            <input v-model="withPassword" @change="handlePasswordCheck" type="checkbox" />
          </div>
          <div v-if="withPassword" class="control has-icons-left" @click="makePasswordInputActive">
            <input class="input" type="text" placeholder="password" :disabled="passwordInputDisabled"
              v-model="password" />
            <span class="icon is-small is-left">
              <i class="fas fa-key"></i>
            </span>
          </div>
        </div>
        <div v-if="isAdvanced" class="field">
          <label class="label" :data-tooltip="withTooltips ? $t('tlt_mgqattributes_discerning_code') : null
          ">Discerning code (optional)</label>
          <div class="control has-icons-left">
            <input class="input" type="text" placeholder="Discerning code" v-model="code" />
            <span class="icon is-small is-left">
              <i class="fas fa-barcode"></i>
            </span>
          </div>
        </div>
        <div v-show="shouldShowPaypalAuthButton" @click="hitPaypalEndpoint" class="paypal_button">
          <div :data-tooltip="withTooltips ? $t('tlt_mgqattributes_connect_paypal') : null
          ">
            Connect with
          </div>
          <div>
            <img
              src="https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/C2/logos-buttons/optimize/34_Grey_PayPal_Pill_Button.png" />
          </div>
        </div>

        <div v-if="isAdvanced || mgqMode == 'contest'" class="field">
          <label class="label has-tooltip-left"
            :data-tooltip="withTooltips ? $t('tlt_mgqattributes_price') : null">Price
            / Currency (optional)</label>

          <div class="price_currency">
            <div class="price_field">
              <div class="control has-icons-left" @click="checkPaypal">
                <input class="input" :class="{ 'is-danger': wrongPrice }" type="number" min="1" step="any"
                  v-model="price" />
                <span class="icon is-small is-left">
                  <i class="fas fa-coins"></i>
                </span>
              </div>
            </div>
            <div class="currency_field ml-2">
              <div class="select" :class="{ 'is-danger': wrongCurrency }">
                <select v-model="currency">
                  <option :value="currencyItem.initials" v-for="currencyItem in currencies" :key="currencyItem">
                    {{ currencyItem.name + " " + currencyItem.symbol }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showContractModal">
          <PageModal @close="closeContractModal" @confirm="closeContractModal">
            <ContractText />
            <div class="pt-2" style="color: yellow">
              Hereby I agree to the above terms and my contract with Mindglue UG
              (haftungsbeschränkt).<input type="checkbox" v-model="hasAgreed" class="ml-2" />
            </div>
          </PageModal>
        </div>
      </div>
    </div>
    <div v-if="showMgqModeModal && !hereIsEdit">
      <PageModal @close="closeMgqModeModal" @confirm="mgqModeSelected">
        <div class="card p-4">
          <label class="label">What kind of mindgluequest do you want to create?</label>
          <div class="select">
            <select v-model="mgqMode" class="select">
              <option value="searchable">Searchable</option>
              <option value="contest">Contest</option>
              <option value="social">Social</option>
            </select>
          </div>
        </div>
      </PageModal>
    </div>
  </div>
</template>

<script>
//import MapUploader from "@/components/parts/MapUploader.vue";
import MapButton from "@/components/parts/MapButton.vue";
import PageModal from "@/components/parts/PageModal.vue";
import ContractText from "@/components/parts/ContractText.vue";
import { useRouter } from "vue-router";
import { onBeforeMount, ref, computed } from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";
import * as compareObjects from "@/utils/CompareMyObjects";
import * as apiPost from "@/utils/ApiPost";
import { Quill, QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import BlotFormatter from 'quill-blot-formatter';
import PropertiesComponent from "../parts/PropertiesComponent.vue";

Quill.register('modules/blotFormatter', BlotFormatter);
const alignClass = Quill.import('attributors/style/align');
const backgroundClass = Quill.import('attributors/style/background');
const colorClass = Quill.import('attributors/style/color');
const directionClass = Quill.import('attributors/style/direction');
const fontClass = Quill.import('attributors/style/font');
const sizeClass = Quill.import('attributors/style/size');

Quill.register(alignClass, true);
Quill.register(backgroundClass, true);
Quill.register(colorClass, true);
Quill.register(directionClass, true);
Quill.register(fontClass, true);
Quill.register(sizeClass, true);

export default {
  components: {
    MapButton,
    QuillEditor,
    PageModal,
    ContractText,
    PropertiesComponent,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const mapButtonMap = ref();
    const advertisingMap = ref();
    const currencies = ref(mixins.currencies);
    const startDate = ref("");
    const startTime = ref("");
    const endDate = ref("");
    const endTime = ref("");
    const price = ref(0);
    const currency = ref("EUR");
    const title = ref("");
    const location = ref("");
    const shortDescr = ref("");
    const longDescr = ref("");
    const code = ref("");
    //const baseMapValue = ref("none");
    const virtual = ref(true);
    const showPoints = ref(false);
    const active = ref(true);
    const editable = ref(true);
    const searchable = ref(true);
    const forContest = ref(true);
    const withinDistance = ref(0);
    const activeDistance = ref(0);
    const imageName = ref("");
    const color = ref(-1);
    const password = ref("");
    const withPassword = ref(false);
    const passwordInputDisabled = ref(true);
    const outerCoords = ref([]);
    const advertisingCoords = ref([]);
    const hereIsEdit = ref(mixins.isEdit);
    outerCoords.value = [];
    advertisingCoords.value = [];
    // const showMapUploader = ref(false);
    let paypalID = "";
    let paypalClientId = "";
    const showMapButton = ref(false);
    const isAdvanced = ref(false);

    const isWrongStartDatetime = ref(false);
    const isWrongEndDatetime = ref(false);
    const wrongTitle = ref(false);
    const wrongLocation = ref(false);
    const wrongShortDescr = ref(false);
    const wrongLongDescr = ref(false);
    const wrongImageNameFile = ref(false);
    const wrongCoordinates = ref(false);
    const wrongPrice = ref(false);
    const wrongColor = ref(false);
    const wrongCurrency = ref(false);
    const isRealMap = ref(true);
    const hasAgreed = ref(false);
    const showContractModal = ref(false);
    const showMgqModeModal = ref(true);
    const mgqMode = ref("searchable");
    const properties = ref({});
    let imageFile = ref(null);
    startDate.value = mixins.setDate();
    startTime.value = mixins.setTime();
    endDate.value = mixins.setDate();
    endTime.value = mixins.setEndTime();
    const propsComponent = ref(null);
    const colors = mixins.colors;
    let confirmedCreateNewVersion = false;
    let confirmedPaypalAccount = false;
    //let deniedPaypalConfirmation = false;
    const getSelectedMgq = computed(
      () => store.getters["core/getterSelectedMgq"]
    );
    const getCreatedMgq = computed(
      () => store.getters["core/getterCreatedMgq"]
    );
    const getUsername = computed(() => store.getters["core/getUser"].username);
    const shouldShowPaypalAuthButton = ref(false);
    const openedWindow = ref(null);
    const withTooltips = computed(() => store.getters["core/getUser"].showTooltips);

    onBeforeMount(() => {
      isAdvanced.value = store.getters["core/getUser"].isAdvanced;
      if (mixins.isEdit.value) {
        title.value = getSelectedMgq.value.title;
        shortDescr.value = getSelectedMgq.value.shortDescr;
        longDescr.value = getSelectedMgq.value.longDescr;
        code.value = getSelectedMgq.value.code;
        virtual.value = getSelectedMgq.value.virtual;
        showPoints.value = getSelectedMgq.value.showPoints;
        active.value = getSelectedMgq.value.active;
        editable.value = getSelectedMgq.value.editable;
        searchable.value = getSelectedMgq.value.searchable;
        forContest.value = getSelectedMgq.value.forContest;
        location.value = getSelectedMgq.value.location;
        color.value = getSelectedMgq.value.color;
        price.value = getSelectedMgq.value.price;
        currency.value = getSelectedMgq.value.currency;
        withinDistance.value = getSelectedMgq.value.withinDistance;
        activeDistance.value = getSelectedMgq.value.activeDistance;
        imageName.value = getSelectedMgq.value.image.name;
        password.value = "***hidden***";
        withPassword.value = getSelectedMgq.value.withPassword;

        if (typeof getSelectedMgq.value.properties === 'object') {
          properties.value = getSelectedMgq.value.properties;
        }
        else if (typeof getSelectedMgq.value.properties === 'string') {
          properties.value = JSON.parse(getSelectedMgq.value.properties);
        }
        startDate.value = mixins.setDate(
          getSelectedMgq.value.mgqVersion.startTime
        );
        startTime.value = mixins.setTime(
          getSelectedMgq.value.mgqVersion.startTime
        );
        endDate.value = mixins.setDate(getSelectedMgq.value.mgqVersion.endTime);
        endTime.value = mixins.setTime(getSelectedMgq.value.mgqVersion.endTime);
        outerCoords.value[0] = Number(getSelectedMgq.value.latitude).toString();
        outerCoords.value[1] = Number(
          getSelectedMgq.value.longitude
        ).toString();
        advertisingCoords.value[0] = Number(
          getSelectedMgq.value.advertisingLatitude
        ).toString();
        advertisingCoords.value[1] = Number(
          getSelectedMgq.value.advertisingLongitude
        ).toString();
      } else if (getCreatedMgq.value.mindgluequestId) {
        showMgqModeModal.value = false;
        mgqMode.value = "copy";
        title.value = getCreatedMgq.value.title;
        shortDescr.value = getCreatedMgq.value.shortDescr;
        longDescr.value = getCreatedMgq.value.longDescr;
        virtual.value = getCreatedMgq.value.virtual;
        showPoints.value = getCreatedMgq.value.showPoints;
        active.value = getCreatedMgq.value.active;
        editable.value = getCreatedMgq.value.editable;
        searchable.value = getCreatedMgq.value.searchable;
        forContest.value = getCreatedMgq.value.forContest;
        location.value = getCreatedMgq.value.location;
        color.value = getCreatedMgq.value.color;
        price.value = getCreatedMgq.value.price;
        currency.value = getCreatedMgq.value.currency;
        withinDistance.value = getCreatedMgq.value.withinDistance;
        activeDistance.value = getCreatedMgq.value.activeDistance;
        imageName.value = getCreatedMgq.value.image.name;
        password.value = "***hidden***";
        withPassword.value = getCreatedMgq.value.withPassword;
        properties.value = getCreatedMgq.value.properties;
        startDate.value = mixins.setDate(
          getCreatedMgq.value.mgqVersion.startTime
        );
        startTime.value = mixins.setTime(
          getCreatedMgq.value.mgqVersion.startTime
        );
        endDate.value = mixins.setDate(getCreatedMgq.value.mgqVersion.endTime);
        endTime.value = mixins.setTime(getCreatedMgq.value.mgqVersion.endTime);
        outerCoords.value[0] = Number(getCreatedMgq.value.latitude).toString();
        outerCoords.value[1] = Number(
          getCreatedMgq.value.longitude
        ).toString();
        advertisingCoords.value[0] = Number(
          getCreatedMgq.value.advertisingLatitude
        ).toString();
        advertisingCoords.value[1] = Number(
          getCreatedMgq.value.advertisingLongitude
        ).toString();
        store.commit("core/setCreatedMgq", {});
      }
      else {
        const successCallback = (position) => {
          store.commit("core/setUserLatLong", {
            latitude: position.coords.latitude.toString(),
            longitude: position.coords.longitude.toString(),
          });
        };

        const errorCallback = (error) => {
          console.log("User denied automatic localization " + error);
        };

        navigator.geolocation.getCurrentPosition(
          successCallback,
          errorCallback
        );

        outerCoords.value = [
          mixins.getUserLatitude(),
          mixins.getUserLongitude(),
        ];
        advertisingCoords.value = [
          mixins.getUserLatitude(),
          mixins.getUserLongitude(),
        ];
        color.value = mixins.randomizeColorSelection();
      }
    });

    window.onmessage = function (e) {
      // console.log("mgq_Attr came "+e.data);
      if (
        e.origin == "http://127.0.0.1:8081" ||
        e.origin == "http://localhost:8081" ||
        e.origin == "https://mindgluequest.com"
      ) {
        if (e.data == "reqid") {
          openedWindow.value.postMessage(
            {
              reqid: apiPost.buildRequestID(),
              jwt: store.getters["core/getJwt"],
            },
            e.origin
          );
        }
      }
    };

    function mgqModeSelected() {
      showMgqModeModal.value = false;
      if (mgqMode.value == "searchable") {
        prepareForSearchable();
      } else if (mgqMode.value == "contest") {
        prepareForContest();
      } else if (mgqMode.value == "social") {
        prepareForSocial();
      }
    }

    function prepareForSocial() {
      location.value = "***";
    }

    function prepareForContest() {
      location.value = "***";
    }

    function prepareForSearchable() {
      outerCoords.value[0] = mixins.getUserLatitude();
      outerCoords.value[1] = mixins.getUserLongitude();
      advertisingCoords.value[0] = mixins.getUserLatitude();
      advertisingCoords.value[1] = mixins.getUserLongitude();
      isRealMap.value = true;
      properties.value = {};
      startTime.value = "01:00";
      endTime.value = "23:59";
      location.value = "My location";
      showPoints.value = true;
      editable.value = false;
      forContest.value = false;
    }

    async function validateDateTime() {
      let shouldCreateNewVersion = true;
      let madeStartDate = new Date(startDate.value + "T" + startTime.value);
      let madeStartDateUTC = new Date(madeStartDate.getTime());
      let madeEndDate = new Date(endDate.value + "T" + endTime.value);
      if (!mixins.isEdit.value) {
        if (mgqMode.value == "contest") {
          if (madeStartDateUTC.getTime() < Date.now()) {
            isWrongStartDatetime.value = true;
          } else isWrongStartDatetime.value = false;
        }
      }
      else {
        if (
          madeStartDateUTC.getTime() > Date.now() &&
          getSelectedMgq.value.mgqVersion.startTime < Date.now()
        ) {
          if (!confirmedCreateNewVersion) {
            shouldCreateNewVersion = await mixins.alertConfirm(
              "You are setting the mindgluequest to the future. This will create a new contest of it. Please check if you have previously set trophies and remove them, if you don't intend to provide them again.",
              true
            );
            confirmedCreateNewVersion = true;
          }
          if (!shouldCreateNewVersion) {
            isWrongStartDatetime.value = true;
            return false;
          } else {
            store.commit("core/makeNewVersionNr");
          }
        } else {
          isWrongStartDatetime.value = false;
        }
      }
      if (madeEndDate <= madeStartDate) {
        isWrongEndDatetime.value = true;
      } else isWrongEndDatetime.value = false;
      return !isWrongStartDatetime.value && !isWrongEndDatetime.value;
    }

    async function validateFields() {
      if (null != quillEditorRef.value) {
        if (quillEditorRef.value.getContents()) {
          longDescr.value = quillEditorRef.value.getContents();
        }
      }
      if (isAdvanced.value) {
        getAdvertisingCoordinates();
      }
      let isRightDates = await validateDateTime();
      if (!title.value || title.value.length > 100) {
        if (title.value.length > 100)
          await mixins.alertConfirm(
            "The title cannot be bigger than 100 characters.",
            false
          );
        wrongTitle.value = true;
      } else wrongTitle.value = false;
      if (!location.value) wrongLocation.value = true;
      else wrongLocation.value = false;
      if (shortDescr.value.length > 300) {
        await mixins.alertConfirm(
          "The short description cannot be bigger than 300 characters.",
          false
        );
        wrongShortDescr.value = true;
      } else wrongShortDescr.value = false;
      if (longDescr.value.length > 3000) {
        await mixins.alertConfirm(
          "The long description cannot be bigger than 3000 characters.",
          false
        );
        wrongLongDescr.value = true;
      } else wrongLongDescr.value = false;
      if (!(await mixins.validateSize(imageFile.value, 2)))
        wrongImageNameFile.value = true;
      else wrongImageNameFile.value = false;
      if (outerCoords.value.length == 0) {
        wrongCoordinates.value = true;
      } else wrongCoordinates.value = false;
      if (
        price.value < 0 ||
        (price.value.toString().includes(".") &&
          (currency.value == "HUF" ||
            currency.value == "JPY" ||
            currency.value == "TWD"))
      )
        wrongPrice.value = true;
      else wrongPrice.value = false;
      if (price.value > 0 && currency.value === "") wrongCurrency.value = true;
      else wrongCurrency.value = false;
      if (color.value < 0) wrongColor.value = true;
      else wrongColor.value = false;
      return (
        isRightDates &&
        !wrongTitle.value &&
        !wrongLocation.value &&
        !wrongShortDescr.value &&
        !wrongLongDescr.value &&
        !wrongImageNameFile.value &&
        !wrongCoordinates.value &&
        !wrongPrice.value &&
        !wrongCurrency.value &&
        !wrongColor.value
      );
    }

    /*   function changeMapName() {
        if (properties.value == "real") {
          showMapButton.value = true;
        }
      } */

    const editorToggle = ref(true);

    const quillEditorRef = ref(null);
    const options = ref({
      modules: {
        toolbar: {

          container: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            //   [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['image'], ['video'], ['link'],
            ['clean']                                         // remove formatting button
          ],
          handlers: {
            image: imageHandler
          }

        },
        blotFormatter: {
          module: BlotFormatter,
          options: {/* options */ }
        }


      },
      readOnly: false,
    });


    function imageHandler() {
      let range = quillEditorRef.value.getQuill().getSelection();
      let value = prompt('What is the image URL');
      quillEditorRef.value.getQuill().insertEmbed(range.index, 'image', value);
    }


    function updateContentToggled() {
      if (!editorToggle.value) {
        longDescr.value = quillEditorRef.value.getContents();
      }
    }

    function setImageFile(event) {
      if (event.target.files[0]) {
        imageName.value = new Date().getMilliseconds().toString() + Math.floor(Math.random() * 10000).toString() + "_" + event.target.files[0].name;
        imageFile.value = event.target.files[0];
      } else imageName.value = "";
    }

    async function checkPaypal() {
      if (!hasAgreed.value) {
        showContractModal.value = true;
      }
      if (paypalID == "") {
        let paypalIdClientID = await store.dispatch("core/fetchPaymentId");
        paypalID = paypalIdClientID["paymentId"];
        paypalClientId = paypalIdClientID["ppca"];
      }
      if (paypalID != "" && hasAgreed.value) {
        if (!confirmedPaypalAccount) {
          confirmedPaypalAccount = await mixins.alertConfirm(
            "Please confirm that the Paypal ID used for the incoming payments will be " +
            paypalID +
            ".",
            true
          );
          if (!confirmedPaypalAccount) {
            shouldShowPaypalAuthButton.value = true;
          }
        } else {
          shouldShowPaypalAuthButton.value = false;
        }
      } else {
        price.value = 0;
        shouldShowPaypalAuthButton.value = true;
        await mixins.alertConfirm(
          "To set a price, you have to set a Paypal account for this amount to be deposited into.",
          false
        );
      }
    }

    async function hitPaypalEndpoint() {
      window.localStorage.setItem("reqid", apiPost.buildRequestID());
      window.localStorage.setItem("jwt", store.getters["core/getJwt"]);

      openedWindow.value = window.open(
        "https://www.paypal.com/connect?flowEntry=static&client_id=" +
        // "https://www.sandbox.paypal.com/connect?flowEntry=static&client_id=" +
        paypalClientId +
        "&scope=openid email profile&redirect_uri=https://mindgluequest.com/mgq_attributes"
        //  "&scope=openid email profile&redirect_uri=http://127.0.0.1:8081/mgq_attributes"
      );
    }

    function closeContractModal() {
      showContractModal.value = false;
    }

    function closeMgqModeModal() {
      showMgqModeModal.value = false;
    }

    async function makeMgq() {
      let startDateTimeLong = new Date(
        startDate.value + "T" + startTime.value
      );
      startDateTimeLong = new Date(startDateTimeLong.getTime());
      let endDateTimeLong = new Date(endDate.value + "T" + endTime.value);
      endDateTimeLong = new Date(endDateTimeLong.getTime());
      if (undefined != mapButtonMap.value) {
        outerCoords.value[0] = mapButtonMap.value["attrCoordinates"][0];
        outerCoords.value[1] = mapButtonMap.value["attrCoordinates"][1];
      }
      if (withinDistance.value == "") {
        withinDistance.value = 0;
      }
      else if (withinDistance.value > 10000000) {
        withinDistance.value = 10000000;
      }
      if (activeDistance.value == "") {
        activeDistance.value = 0;
      }
      else if (activeDistance.value > 10000000) {
        activeDistance.value = 10000000;
      }
      let propsGathered = JSON.stringify(properties.value);
      if (propsComponent.value != null) {
        let propsGatheredTemp = await propsComponent.value.provideProperties();
        propsGathered = JSON.stringify(propsGatheredTemp);
      }

       let lat = outerCoords.value[0];
       let long = outerCoords.value[1];

      if (!mixins.isEdit.value){
       long = mixins.getUserLongitude();
       lat = mixins.getUserLatitude();
      }
      
      let mgq = {
        mgqVersion: {
          versionNr: mixins.isEdit.value
            ? getSelectedMgq.value.mgqVersion.versionNr
            : 0,
          id: mixins.isEdit.value
            ? getSelectedMgq.value.mgqVersion.id
            : -1,
          startTime:
            Number(startDateTimeLong).toString(),
          endTime:
            Number(endDateTimeLong).toString(),
        },
        price: price.value,
        currency: currency.value,
        latitude:
          lat,
        longitude:
          long,
        advertisingLatitude:
          advertisingCoords.value[0],
        advertisingLongitude:
          advertisingCoords.value[1],
        title: title.value,
        location: location.value,
        shortDescr: shortDescr.value,
        longDescr: longDescr.value,
        image: { name: imageName.value },
        code: code.value,
        virtual: virtual.value,
        showPoints: showPoints.value,
        active: active.value,
        editable: editable.value,
        searchable: searchable.value,
        forContest: forContest.value,
        withPassword: withPassword.value,
        color: color.value,
        withinDistance: withinDistance.value,
        activeDistance: activeDistance.value,
        properties: propsGathered,
      };
      return mgq;
    }

    function confirmRealMapLocation() {
      outerCoords.value[0] = mapButtonMap.value["attrCoordinates"][0];
      outerCoords.value[1] = mapButtonMap.value["attrCoordinates"][1];
      showMapButton.value = false;
    }

    function getAdvertisingCoordinates() {
      advertisingCoords.value[0] = advertisingMap.value["attrCoordinates"][0];
      advertisingCoords.value[1] = advertisingMap.value["attrCoordinates"][1];
    }

    function handlePasswordCheck() {
      if (!withPassword.value) {
        password.value = "";
      }
    }

    function makePasswordInputActive() {
      passwordInputDisabled.value = false;
      password.value = "";
    }
    async function getdata3D() {
      router.push("/drawaframe");
    }

    async function getdata() {
      let validated = false;
      let confirmedPassword = false;
      validated = await validateFields();
      if (withPassword.value && password.value != "***hidden***") {
        confirmedPassword = await mixins.alertConfirm(
          'Are you sure you want to use password:"' + password.value + '" ?',
          true
        );
      }
      if (validated) {
        let mgq = await makeMgq();
        store.commit("core/setCreatedMgq", mgq);
        let succeeded = "false";
        if (!mixins.isEdit.value) {
          if (withPassword.value && confirmedPassword) {
            succeeded = await store.dispatch("core/requestCreateMgq", {
              mgq: mgq,
              imageFile: imageFile.value,
              password: password.value,
            });
          } else {
            succeeded = await store.dispatch("core/requestCreateMgq", {
              mgq: mgq,
              imageFile: imageFile.value,
            });
          }
        } else {

          let areSame1 =
            getSelectedMgq.value.image.name == getCreatedMgq.value.image.name && JSON.stringify(getSelectedMgq.value.properties) == getCreatedMgq.value.properties;
          
          let areSame2 = compareObjects.areSame(getSelectedMgq.value, getCreatedMgq.value, [
              "mindgluequestId",
              "distance",
              "content",
              "tag",
              "creationTime",
              "usStartTime",
              "usEndTime",
              "onoff",
              "marked",
              "mgqStatus",
              "creator",
              "mindsetsDuration",
              "properties",
              "lastLatitude",
              "lastLongitude",
            ]);
            let areSame = areSame1 && areSame2;
          if (areSame && !confirmedPassword) {
            succeeded = "unnecessary";
          } else {
            let payload = {
              mgq: mgq,
              imageFile: imageFile.value,
              mindgluequestId: getSelectedMgq.value.mindgluequestId,
            };
            if (withPassword.value && confirmedPassword) {
              payload["password"] = password.value;
            } else if (
              !withPassword.value &&
              getSelectedMgq.value.withPassword !=
              getCreatedMgq.value.withPassword
            ) {
              payload["password"] = "";
            }

            let realSucceeded = await store.dispatch(
              "core/requestEditMgq",
              payload
            );

            if (realSucceeded) {
              succeeded = "true";
            } else {
              succeeded = "false";
            }
          }
        }

        if (succeeded == "true" || succeeded == "unnecessary") {
          if (succeeded == "true") {
            if (!mixins.isEdit.value) {
              mgq.code = getCreatedMgq.value.code;
              mgq.mindgluequestId = getCreatedMgq.value.mindgluequestId;
              store.commit("core/setSelectedMgq", mixins.cloneObject(mgq));
              code.value = mgq.code;
              //     showMapUploader.value = true;
            }

            /*   
               else if (!mixins.isEdit.value && mgq.properties == "real") {
                 mgq.code = getCreatedMgq.value.code;
                 mgq.mindgluequestId = getCreatedMgq.value.mindgluequestId;
                 store.commit("core/setSelectedMgq", mixins.cloneObject(mgq));
                 code.value = mgq.code;
                 router.push("/drawmindsets");
               }
               */

            else {
              if (mixins.isEdit.value) {
                if (
                  mgq.latitude != getSelectedMgq.value.latitude ||
                  mgq.longitude != getSelectedMgq.value.longitude ||
                  mgq.properties != getSelectedMgq.value.properties
                ) {
                  store.commit("core/setMgqCoordinates", {
                    latitude: mgq.latitude,
                    longitude: mgq.longitude,
                    properties: mgq.properties,
                  });
                }
                mgq.mindgluequestId = getSelectedMgq.value.mindgluequestId;
                if (getSelectedMgq.value.marked!=undefined){
                  mgq.marked = getSelectedMgq.value.marked;
                }
                store.commit("core/setSelectedMgq", mixins.cloneObject(mgq));
              } else {
                mgq.code = getCreatedMgq.value.code;
                mgq.mindgluequestId = getCreatedMgq.value.mindgluequestId;
                store.commit("core/setSelectedMgq", mixins.cloneObject(mgq));
                store.commit("core/setMgqCoordinates", {
                  latitude: mgq.latitude,
                  longitude: mgq.longitude,
                  properties: mgq.properties,
                });
              }
              await mixins.alertConfirm("Saved!", false);
              router.push("/drawmindsets");
            }
          } else if (succeeded == "unnecessary") {
            router.push("/drawmindsets");
          }
        } else await mixins.alertConfirm("Something went wrong.", false);
      } else
        await mixins.alertConfirm(
          "Please fill or correct the fields marked with red color.",
          false
        );
    }
    return {
      hereIsEdit,
      startDate,
      startTime,
      endDate,
      endTime,
      price,
      currency,
      title,
      location,
      shortDescr,
      longDescr,
      withinDistance,
      activeDistance,
      imageName,
      imageFile,
      outerCoords,
      advertisingCoords,
      code,
      virtual,
      showPoints,
      active,
      editable,
      searchable,
      forContest,
      color,
      isWrongStartDatetime,
      isWrongEndDatetime,
      wrongTitle,
      wrongLocation,
      wrongShortDescr,
      wrongLongDescr,
      wrongImageNameFile,
      wrongCoordinates,
      wrongPrice,
      wrongColor,
      wrongCurrency,
      getdata,
      mapButtonMap,
      advertisingMap,
      setImageFile,
      colors,
      getUsername,
      shouldShowPaypalAuthButton,
      //  showMapUploader,
      //  closeMapUploader,
      // arrangeBaseMap,
      //  baseMapValue,
      // changeMapName,
      showMapButton,
      password,
      withPassword,
      confirmRealMapLocation,
      isRealMap,
      properties,
      checkPaypal,
      currencies,
      hitPaypalEndpoint,
      hasAgreed,
      showContractModal,
      closeContractModal,
      handlePasswordCheck,
      passwordInputDisabled,
      makePasswordInputActive,
      mgqMode,
      showMgqModeModal,
      mgqModeSelected,
      closeMgqModeModal,
      isAdvanced,
      withTooltips,
      quillEditorRef,
      options,
      editorToggle,
      updateContentToggled,
      getdata3D,
      propsComponent,
    };
  },
};
</script>

<style scoped>
input[disabled] {
  pointer-events: none;
}

.create_container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.paypal_button {
  display: flex;
  gap: 0.2em;
  align-items: center;
}

.price_currency {
  display: flex;
}

.mgq_mode_style {
  background-color: white;
  padding: 1em;
  border-radius: 1em;
}



@media only screen and (max-width: 800px) {
  .create_container {
    grid-template-columns: 1fr;
  }
}
</style>