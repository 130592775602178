<template>
  <div>
    <div class="field" @focus="handleFocus"
        @focusout="handleFocusOut"
        tabindex="0">
      <div class="control has-icons-left" style="z-index: 13;">
        <div v-for="item in allMgqMapRegions" :key="item.mgq.mindgluestId">
          <div
            class="mgqTile"
            style="cursor: pointer"
          >
            <div style="color: darkmagenta"  @click="selectMgqOrRegion(item.mgq, null)">{{ item.mgq.title }}</div>
            <div
              v-for="mapRegion in item.mapRegions"
              :key="mapRegion.id"
              @click="selectMgqOrRegion(item.mgq, mapRegion)"
            >
              <div class="mapRegionTile">
                {{
                  mapRegion.name == ""
                    ? "unnamed " + mapRegion.id
                    : mapRegion.name
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, computed, onMounted } from "vue";
import { useStore } from "vuex";
import * as mixins from "@/utils/mixins";

export default {
  setup(props,{ emit }) {
    const store = useStore();
    const allMgqMapRegions = computed(
      () => store.getters["core/getAllMgqMapRegions"]
    );
    onBeforeMount(() => {
      getRegions();
    });

    onMounted(()=> emit("setOpenedMgqsRegionsSelector"));
  

    async function getRegions() {
      if (Object.keys(allMgqMapRegions.value).length == 0) {
        let success = await store.dispatch("core/requestAllMgqMapRegions");
        if (!success) {
          mixins.alertConfirm("Unfortunately something went wrong.", false);
        }
      }
    }

    function selectMgqOrRegion(mgq, mgqMapRegion) {
      emit("copySelected", { mgq: mgq, mgqMapRegion: mgqMapRegion });
    }
    

    

    return {
      allMgqMapRegions,
      selectMgqOrRegion,
    };
  },
};
</script>
<style>
.mgqTile {
  background-color: white;
  padding: 0.3em;
  border-radius: 5%;
  box-shadow: 0.1em 0.1em 0.1em gray;
}
.mapRegionTile {
  background-color: azure;
  padding-left: 0.2em;
  font-style: oblique;
  margin-bottom: 0.2em;
  font-size: 0.8em;
}
</style>