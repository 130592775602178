<template>
  <div  style="height:100%;">
    <MndButtonsList>
      <div v-for="(array, index) in getAllMindsets" :key="index">
        <div
          class="button list_item"
          @click="setGroupSelected(index)"
          :class="{ 'is-primary': selectedArray[index] }"
        >
          <div v-if="array.length>0" class="listname">
            {{ array[0].gamecode.split("_")[1]=="#!soc!#"?"social":  array[0].gamecode.split("_")[1]}}
          </div>
        </div>
      </div>
    </MndButtonsList>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import MndButtonsList from "@/components/parts/MndButtonsList.vue";

export default {
  components: {
    MndButtonsList,
  },
  setup() {
    onMounted(() => {
      makeSelectedArray();
    });
    const store = useStore();
    const getAllMindsets = computed(
      () => store.getters["core/getFirstMindsets"]
    );
    const selectedArray = ref([]);

    const allGroupsLength = computed(() => getAllMindsets.value.length);
    watch(allGroupsLength, () => {
      makeSelectedArray();
    });

    function makeSelectedArray() {
      selectedArray.value = [];
      for (let i = 0; i < getAllMindsets.value.length; i++) {
        selectedArray.value.push(false);
      }
    }

    function setGroupSelected(arrayIndex) {
      store.commit("core/setSelectedMindsetsArrayIndex", { index: arrayIndex });
      for (let j = 0; j < selectedArray.value.length; j++) {
        if (j == arrayIndex) {
          selectedArray.value[j] = !selectedArray.value[j];
          store.commit("core/setAllMindsetsOfArraySelected", {
            index: arrayIndex,
            value: selectedArray.value[j],
          });
        } else {
          selectedArray.value[j] = false;
        }
      }
    }
    return {
      getAllMindsets,
      selectedArray,
      setGroupSelected,
    };
  },
};
</script>
<style scoped>
.list_item {
  margin: auto;
  display: block;
  justify-self: start;
  max-width: 100%;
  padding-left: 0.3em;
  padding-right: 0.3em;
  overflow: hidden;
  min-width: 2em;
}
.listname {
  justify-self: start;
  font-size: 0.7em;
}
</style>