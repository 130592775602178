<template>
  <div>
    <div v-if="isLoading"> 
        <div class="modal is-active">
          <div class="modal-content">
            <div>
             <img src="@/assets/loading.gif" width="40" />
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import {  computed } from "vue";

export default {
  setup() {
    const store = useStore();
    const isLoading = computed(
      () => store.getters["core/getIsLoading"]
    );

    return {
 isLoading
    };
  },
};
</script>