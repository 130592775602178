<template>
  <div>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div>
          <slot></slot>
                    <div>
                      <button class="button is-primary mt-4" @click="confirm">OK</button>
                      <button class="button ml-4 mt-4" @click="closeModal">Cancel</button>
                    </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closeModal"
      ></button>
    </div>
  </div>
</template>
<script>

export default {
  setup(props, { emit }) {
    function closeModal() {
      emit("close");
    }
    function confirm() {
      emit("confirm");
    }
    return {
      closeModal,
      confirm,
    };
  },
};
</script>
