<template>
    <div>
        <div class="has-text-weight-bold">
            Terms of Service
        </div>
        <div class="has-text-justified">
        We are glad to have you here in Quesdom!<br><br>
        The Quesdom application is created to bring people together, in a safe and secure way. It's a means to express yourself and get to know other people that feel or think the same way as you and are or feel geographically close to you. This service is provided from us to you for free.<br><br>
        You may use the Quesdom application to create content that reflects your current thoughts, ideas and interests and you may publish this content for others to see, under your username or under a random username. The content you can create must not<br>1.  breach these Terms and policies that apply to the use of Quesdom.<br>
        2. infringe other people's rights<br>3. be fraudulent, misleading or against the law<br>4. disrespect the decency that should characterize every human relation and especially must not promote discriminatory beliefs and actions<br>
        Content that falls into the above categories will be removed and the publishing user's account may be blocked.<br><br> We provide the above services for free and to everyone. Sole exceptions are children of less than 13 years of age and people with accounts already suspended due to violating these Terms.
        Moreover if your base is an EEA country, only then may you use the Quesdom application, if you are over an age that you can provide lawful (judged by the laws of your country) consent to the processing of your data.<br><br>
        The Quesdom service and all content on our platform is provided on an "as is" basis without warranty of any kind, whether express or implied. Quesdom disclaims any and all warranties and conditions of fitness for a particular purpose, and non-infringement, and any warranties arising out of course of dealing or usage of trade.
        The right to suspend an account due to misguiding, unlawful, deceitful, fraudulent or discriminatory content, or to remove that content made by the user of the account remains always at our disposal, in order to keep the Quesdom community safe.<br><br>
        You grant Quesdom and Quesdom users a non-exclusive, royalty-free, transferable, sublicensable, worldwide license to use, reproduce, modify, create derivative content from your User Content on Quesdom only for the purposes of operating and using Quesdom.
        Quesdom assumes no responsibility or liability for user content of any type that you or any other person or third party publishes or sends using the Quesdom platform. You understand and agree that you may be exposed to user content that’s objectionable, inaccurate, inappropriate for children, or otherwise unsuited to your purpose.<br><br>
        For disputes you may have with Quesdom, you agree to first contact us and try to resolve the dispute with us informally. If we need to contact you, we will do so at the email address on your account. Should an informal resolution of the dispute not be fruitful, we each agree to resolve any claim, dispute, or controversy (excluding claims for injunctive or other equitable relief) arising out of or in connection with or relating to these Terms through binding arbitration or (for qualifying claims) in small claims court.<br><br>
        In order to use Quesdom you must give at least internet permissions to the application. For an ideal experience with our application, it would be necessary for you to give location permissions to the Quesdom application as well. The Quesdom application is strongly tied to the location of each user, to where each user is at a particular moment, because the application tries to find people sharing your mindset that are close to you. That way you can create a physical connection as well, in the case of matching mindsets.<br><br>
        Nevertheless Quesdom does not keep track of your locations history. We do not keep your previous locations in our data systems. We only have your current location (or the one you have selected) and evey new location overwrites the previous one. More about this in our Privacy Policy.
        We do not share any of your personal information to anyone. Our Privacy Policy describes the way we handle your data.<br><br>
        Limitation of Liability<br><br>
        These Terms of Service do not intend to limit our liability for death, personal injury or fraudulent misrepresentation caused by negligence of ours, or to affect your statutory rights.<br>
        We make a great effort to provide a service to you characterized by quality, safety and security. In the case that we have acted with professional diligence, we do not accept responsibility for losses not caused by our breach of these Terms or otherwise by our acts; losses that are not reasonably foreseeable by you and us at the time of entering into these Terms; and events beyond our reasonable control.<br>
        To the maximum by law permitted extend, Quesdom will not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your access to or use of or inability to access or use the service; (b) any conduct or content of any third party on the service, including without limitation, any defamatory, offensive or illegal conduct of other users or third parties; or (c) unauthorized access, use or alteration of your transmissions or content. In no event shall Quesdom's aggregate liability for all claims relating to the service exceed one hundred u.s. dollars (u.s. $100.00).<br>
        Content and data collection from our application with automated means is prohibited. You may also not access data that you have no permission to access.<br><br>
     
        </div>
    </div>
</template>