<template>
  <div style="height: 100%">
    <MndButtonsList>
      <div
        v-for="(array, index) in getAllMindsets"
        :key="index"
        class="mindsetlist_wrapper"
      >
        <div
          v-for="(mindset,mindsetIndex) in array"
          :key="mindsetIndex"
          class="button list_item_mindset is-block"
          @click="setSelected($event, mindset)"
          :class="{ 'is-primary': mindset.isSelected }"
        >
          
          <div class="listtitle">
            {{ mindset.title.substring(0, 40) + "..." }}
          </div>
        </div>
        <div>&nbsp;</div>
      </div>
    </MndButtonsList>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import * as mixins from "@/utils/mixins";
import MndButtonsList from "@/components/parts/MndButtonsList.vue";

export default {
  components: {
    MndButtonsList,
  },
  setup() {
    const store = useStore();
    const getAllMindsets = computed(
      () => store.getters["core/getFirstMindsets"]
    );
    function setSelected(ev, mindset) {
      let exclusive = true;
      if (ev.shiftKey) {
        exclusive = false;
      }
      mixins.setSelectedArrayMindset(mindset, exclusive);
    }
    return {
      getAllMindsets,
      setSelected,
    };
  },
};
</script>
<style >
.mindsetlist_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  margin: auto;
  max-width: 100%;
  min-width: 2em;
}
.list_item_mindset {
  justify-self: start;
  min-height: 4.5em;
  max-width: 100%;
  min-width: 100%;
  padding-left: 0.3em;
  padding-right: 0.3em;
  overflow: hidden;
}

.listtitle {
  white-space: normal;
  justify-self: start;
  font-size: 0.7em;
  line-height: 1.5em;
  min-height: 4em;
  width: 100%;
}

</style>