<template>
    <div style="height: 100%; width:100%; overflow-y:auto; ">
        <div v-html="mindset.questionlist[index].questionname" class="p-2"></div>
        <div v-if="mindset.questionlist[index].answerlist.length > 0">
            <div v-for="(reaction, idx) in mindset.questionlist[index].answerlist" :key="idx">
                <MindChunkReactionGame :mindset="mindset" :mindChunkIdx="index" :mindChunkReactionIdx="idx" @reactionSelected="reactionSelected"/>
            </div>
        </div>
    </div>
</template>
<script>
import MindChunkReactionGame from './MindChunkReactionGame.vue';

export default {
    props: {
        index: {
            type: Number,
        },
        mindset:{
            type: Object
        },
    },
    components: {
        MindChunkReactionGame
    },
    setup(props,{emit}) {

        function reactionSelected(payload){
            emit("reactionSelected",payload);
        }
        return {
            reactionSelected,
        };
    },
}
</script>