import { computed, ref } from "vue";
import store from '../store';

const isDebug = false;
export const baseUrl = isDebug ? "http://127.0.0.1" : 'https://application.mindglue.io';

export const serverIp = isDebug ? baseUrl + ':8080/App' : baseUrl + '/App';
export const mgqImagesDir = "/mgqimages/"
export const qstImagesDir = "/qstimages/"

export const paypalDomain = isDebug ? "https://api-m.sandbox.paypal.com" : "https://api-m.paypal.com";


export function setDate(dateTime) {
  let datenow = null;
  if (dateTime != null) datenow = new Date(Number(dateTime));
  else datenow = new Date();
  let dd = String(datenow.getDate()).padStart(2, "0");
  let mm = String(datenow.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = datenow.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
}

export function setTime(dateTime) {
  let datenow = null;
  if (dateTime != null) datenow = new Date(Number(dateTime));
  else {
    datenow = new Date();
    datenow.setMinutes(datenow.getMinutes() + 5);
  }
  let hh = String(datenow.getHours()).padStart(2, "0");
  let mmm = String(datenow.getMinutes()).padStart(2, "0");
  let sec = String(datenow.getSeconds()).padStart(2, "0");
  return hh + ":" + mmm + ":" + sec;
}

export function setEndTime() {
  let datenow = new Date();
  datenow.setHours(datenow.getHours() + 1);
  datenow.setMinutes(datenow.getMinutes() + 5);
  let hh = String(datenow.getHours()).padStart(2, "0");
  let mmm = String(datenow.getMinutes()).padStart(2, "0");
  let sec = String(datenow.getSeconds()).padStart(2, "0");
  return hh + ":" + mmm + ":" + sec;
}

export function setExactTime(dateTime) {
  let datenow = null;
  if (dateTime != null) datenow = new Date(dateTime);
  else datenow = new Date();
  let hh = String(datenow.getHours()).padStart(2, "0");
  let mmm = String(datenow.getMinutes()).padStart(2, "0");
  let ss = String(datenow.getSeconds()).padStart(2, "0");
  let ms = String(datenow.getMilliseconds()).padStart(3, "0");
  return hh + ":" + mmm + ":" + ss + "." + ms;
}

export function millisToDaysHoursSeconds(ms) {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);
  let toReturn = { days: days, hours: hours, minutes: minutes, seconds: sec };
  return toReturn;
}

export function checkStringInputForSpecial(input) {
  let toReturn = false;
  var format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  if (format.test(input)) {
    toReturn = true;
  } else {
    toReturn = false;
  }
  return toReturn;
}

const getSelectedMgq = computed(() => store.getters["core/getterSelectedMgq"]);
export const isEdit = computed(() => {
  if (
    getSelectedMgq.value !== undefined &&
    getSelectedMgq.value !== null &&
    Object.keys(getSelectedMgq.value).length > 0
  )
    return true;
  else return false;
});

export const markedArray = computed(() => {
  if (getSelectedMgq.value.marked && getSelectedMgq.value.marked[0].length > 0) {
    return JSON.parse(getSelectedMgq.value.marked);
  } else return [];
});


export function setSelectedArrayMindset(mindset, exclusive) {
  let firstArray = store.getters["core/getFirstMindsets"];
  let arrayIndex = 0;
  let foundMindset = {};
  for (let i = 0; i < firstArray.length; i++) {
    for (let j = 0; j < firstArray[i].length; j++) {
      if (firstArray[i][j].id == mindset.id) {
        arrayIndex = i;
        foundMindset = firstArray[i][j];
      }
    }
  }
  store.commit("core/setSelectedMindsetsArrayIndex", { index: arrayIndex });
  store.commit("core/setMindsetToShow", foundMindset);
  store.commit("core/setMindsetSelected", { exclusive: exclusive });
}

export function checkAlreadyExistingGroupName(name, allMindsets) {
  if (allMindsets.length == 0) return false;
  else {
    let namesArray = [];
    for (let mindsetsArray of allMindsets) {
      namesArray.push(mindsetsArray[0].gamecode.split("_")[1]);
    }
    if (namesArray.includes(name)) return true;
  }
}

export function cloneObject(objectToClone) {
  let clonedObject = {};
  let arrayNames = [];
  for (let key in objectToClone) {
    if (objectToClone[key] instanceof Array) {
      arrayNames.push(key);
    }
    else {
      clonedObject[`${key}`] = JSON.parse(JSON.stringify(objectToClone[key]));
    }
  }
  for (let arrayName of arrayNames) {
    clonedObject[arrayName] = [];
    for (let arrayObj of objectToClone[arrayName]) {
      clonedObject[arrayName].push(cloneObject(arrayObj));
    }
  }
  return clonedObject;
}



export function cloneArray(arrayToClone) {
  let clonedArray = [];
  for (let i of arrayToClone) {
    if (i instanceof Object) {
      clonedArray.push(cloneObject(i));
    }
    else if (i instanceof Array) {
      clonedArray.push(cloneArray(i));
    }
    else {
      clonedArray.push(i);
    }
  }
  return clonedArray;
}

export async function saveMgqMapImage(imageFile, imageName, mindgluequestId, isTemp) {
  await store.dispatch("core/saveMapImage", {
    imageFile: imageFile,
    imageName: imageName,
    mindgluequestId: mindgluequestId,
    useType: isTemp ? "temp_map" : "map",
  });
}

export function makeImageWidthHeightRatio(imageFile) {
  let imgWtoH = 1;
  let reader = new FileReader();
  reader.readAsDataURL(imageFile);
  reader.onload = function (e) {
    let image = new Image();
    image.src = e.target.result;
    image.onload = function () {
      imgWtoH = Number(
        Number(this.width) / Number(this.height)
      );
    };
  };
  return imgWtoH;
}

export async function validateSize(file, numberOfMiB) {
  if (null != file) {
    const fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > Number(numberOfMiB)) {
      await alertConfirm(
        "File size exceeds " + numberOfMiB + " MiB. Please use an file of less than " + numberOfMiB + " MiB size.", false
      );
      return false;
    } else {
      return true;
    }
  }
  else return true;
}

export async function alertConfirm(message, isConfirm) {
  window.removeEventListener("keypress", alertKeyListener);
  window.addEventListener("keypress", alertKeyListener);
  while (store.getters["core/getAlertConfirmMessage"] != "") {
    await resolveAfterMilliseconds(1000);
  }
  store.commit("core/setIsConfirm", isConfirm);
  store.commit("core/setAlertConfirmMessage", message);
  while (store.getters["core/getAlertConfirmMessage"] == message) {
    await resolveAfterMilliseconds(1000);
  }
  if (store.getters["core/getAlertConfirmMessage"] == "true") {
    store.commit("core/setAlertConfirmMessage", "");
    window.removeEventListener("keypress", alertKeyListener);
    return true;
  }
  else if (store.getters["core/getAlertConfirmMessage"] == "false") {
    window.removeEventListener("keypress", alertKeyListener);

    store.commit("core/setAlertConfirmMessage", "");
    return false;
  }
}

function alertKeyListener(event) {
  if (event.defaultPrevented) {
    return;
  }
  var key = event.key || event.keyCode;
  if (key === "Escape" || key === "Esc" || key === 27) {
    store.commit("core/setAlertConfirmMessage", "false");
    window.removeEventListener("keypress", alertKeyListener);

  } else if (key === "Enter" || key === "Enter" || key === 13) {
    store.commit("core/setAlertConfirmMessage", "true");
    window.removeEventListener("keypress", alertKeyListener);

  }
}


export async function makeModalDynamic(component, payload) {
  store.commit("core/setModalDynamicComponent", component);
  store.commit("core/setModalDynamicObject", { show: true, payload: payload });
  //window.removeEventListener("keypress", modalKeyListener);
  //window.addEventListener("keypress", modalKeyListener);
  while (store.getters["core/getModalDynamicObject"].show == true) {
    await resolveAfterMilliseconds(1000);
  }
  let toReturn = null;
  if (store.getters["core/getModalDynamicObject"].submitted == true) {
    toReturn = store.getters["core/getModalDynamicObject"];
    delete toReturn.show;
    delete toReturn.submitted;
  }
  else if (store.getters["core/getModalDynamicObject"].submitted == false) {
    toReturn = null;
  }
  store.commit("core/setModalDynamicObject", {});
  store.commit("core/setModalDynamicComponent", "");
  //window.removeEventListener("keypress", modalKeyListener);
  return toReturn;
}



/* function modalKeyListener(event) {
  if (event.defaultPrevented) {
    return;
  }
  var key = event.key || event.keyCode;
  let tempModOb = store.getters["core/getModalDynamicObject"];
  if (key === "Escape" || key === "Esc" || key === 27) {
    tempModOb.submitted = false;
    tempModOb.show = false;
    store.commit("core/setModalDynamicObject", tempModOb);
    window.removeEventListener("keypress", modalKeyListener);
    return false;

  } else if (key === "Enter" ||  key === 13) {
    tempModOb.submitted = false;
    tempModOb.show = false;
    store.commit("core/setModalDynamicObject", tempModOb);
    window.removeEventListener("keypress", modalKeyListener);
    return true;
  }
} */


export async function makeVectorAnimation(payload) {
  store.commit("gameApi/setVectorAnimation", payload);
  if (payload.shouldWait) {
    while (store.getters["gameApi/getVectorAnimation"] != null) {
      await resolveAfterMilliseconds(200);
    }
  }
  return true;
}


export async function makeToast(message, milliseconds) {
  store.commit("core/setToast", { message: message, milliseconds: milliseconds });
}

export async function resolveAfterMilliseconds(milliseconds) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve('resolved');
    }, milliseconds);
  });
}

export function validateEmail(email) {
  let found = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  if (null == found) return false;
  else return true;
}

export function validatePassword(password) {
  let found = String(password)
    .match(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$&*~]).{8,}$/
    );
  if (null == found) return false;
  else return true;
}

export function validateUsername(username) {
  let found = String(username)
    .match(
      /[%,^,&,:,;,#]/
    );
  if (null == found) {
    if (username != "") {
      return true;
    }
  }
  else return false;
}

export async function checkIfCorrectAnswers(mindset) {
  let isCorrect = false;
  if (
    undefined == mindset.questionlist ||
    null == mindset.questionlist ||
    mindset.questionlist.length == 0
  ) {
    await alertConfirm(
      "There are no mindchunks in this mindset! Please create at least one.",
      false
    );
    return false;
  } else {
    let counterProperlyAnsweredMindchunks = mindset.questionlist.length;
    for (let quob of mindset.questionlist) {
      if (
        undefined == quob.answerlist ||
        null == quob.answerlist ||
        quob.answerlist.length == 0
      ) {
        counterProperlyAnsweredMindchunks -= 1;
      } else {
        if (!checkAnswerlist(quob.answerlist)) {
          counterProperlyAnsweredMindchunks -= 1;
        }
      }
    }
    isCorrect = counterProperlyAnsweredMindchunks > 0;
    if (!isCorrect) await alertConfirm(
      "There is no mindchunk with given reactions to provide the outcome of this mindset. Please create reactions for at least one mindchunk.",
      false
    );
    return isCorrect;
  }
}

function checkAnswerlist(answerlist) {
  let msg = "";
  let outcome = true;
  let biggergrade = 0;
  let countofbiggergrade = 0;

  // NOT HIGHER POSSIBILITY THAN 50%
  for (let thisansob of answerlist) {
    if (thisansob.grade > biggergrade) {
      biggergrade = Number(thisansob.grade);
      countofbiggergrade = 1;
      continue;
    }
    if (thisansob.grade == biggergrade) {
      countofbiggergrade += 1;
    }
  }
  // AT LEAST 50%
  if (biggergrade < 50) {
    msg = "Please assign a grade of 50 or more to at least one reaction.";
    outcome = false;
  }
  // ONLY ONE MORE CORRECT
  if (countofbiggergrade > 1) {
    msg =
      "There should be only one reaction with a higher grade than the others.";
    outcome = false;
  }
  if (msg != "") alertConfirm(msg, false);
  return outcome;
}

export function makeEmptyMindet(id, latitude, longitude, gamecode) {
  let image = {
    content: "",
    name: "",
    tag: ""
  };
  return {
    chain: "",
    distance: 0,
    embedContent: "",
    gamecode: gamecode,
    id: id,
    image: image,
    content: "",
    tag: "",
    latitude: latitude,
    longitude: longitude,
    name: "",
    onoff: true,
    placeTag: true,
    questionlist: [],
    randomize: false,
    receiveAnswers: false,
    shareContent: false,
    title: "",
    userId: 0,
    visibilityProps: "[0,16,0,99]",
    properties: "{}",
  }
}

/* export function getCid() {
  let datenow = new Date();
  let hh = String(datenow.getHours() - 2);
  let mm = String(datenow.getMinutes());
  let dd = String(datenow.getDate());
  return mm + "_" + hh + "_" + dd;
} */

export function randomizeColorSelection() {
  return Math.floor(Math.random() * (Number(colors.length)));
}

export function setMgqMapName(name) {
  return "cite:" + getSelectedMgq.value.mindgluequestId.toString() + "_" + name;
}

export const myOtherMgqs = computed(() => {
  let list = store.getters["core/getMindgluequests"];
  list = list.filter(
    (x) => x.mindgluequestId != getSelectedMgq.value.mindgluequestId
  );
  return list;
});

export function getAllSelectedMindsets() {
  let array = store.getters["core/getFirstMindsets"];
  let arrayToSend = [];
  for (let mindsetsArray of array) {
    for (let mindset of mindsetsArray) {
      if (mindset.isSelected) {
        arrayToSend.push(mindset);
      }
    }
  }
  return arrayToSend;
}

export const colors = [
  "red",
  "orange",
  "purple",
  "grey",
  "brown",
  "olive",
  "rebeccapurple",
  "cyan",
  "teal",
  "green",
];

export const currencies = [
  { "name": "Australian dollar", "initials": "AUD", "symbol": "$" },
  { "name": "Brazilian real ", "initials": "BRL", "symbol": "R$" },
  { "name": "Canadian dollar", "initials": "CAD", "symbol": "$" },
  { "name": "Chinese Renmenbi", "initials": "CNY", "symbol": "¥" },
  { "name": "Czech koruna", "initials": "CZK", "symbol": "Kč" },
  { "name": "Danish krone", "initials": "DKK", "symbol": "kr" },
  { "name": "Euro", "initials": "EUR", "symbol": "€" },
  { "name": "Hong Kong dollar", "initials": "HKD", "symbol": "$" },
  { "name": "Hungarian forint", "initials": "HUF", "symbol": "Ft" },
  { "name": "Israeli new shekel", "initials": "ILS", "symbol": "₪" },
  { "name": "Japanese yen", "initials": "JPY", "symbol": "¥" },
  { "name": "Malaysian ringgit", "initials": "MYR", "symbol": "RM" },
  { "name": "Mexican peso", "initials": "MXN", "symbol": "$" },
  { "name": "New Taiwan dollar", "initials": "TWD", "symbol": "NT$" },
  { "name": "New Zealand dollar", "initials": "NZD", "symbol": "$" },
  { "name": "Norwegian krone", "initials": "NOK", "symbol": "kr" },
  { "name": "Philippine peso", "initials": "PHP", "symbol": "₱" },
  { "name": "Polish złoty", "initials": "PLN", "symbol": "zł" },
  { "name": "Pound sterling", "initials": "GBP", "symbol": "£" },
  { "name": "Russian ruble", "initials": "RUB", "symbol": "₽" },
  { "name": "Singapore dollar", "initials": "SGD", "symbol": "$" },
  { "name": "Swedish krona", "initials": "SEK", "symbol": "kr" },
  { "name": "Swiss franc", "initials": "CHF", "symbol": "CHF" },
  { "name": "Thai baht", "initials": "THB", "symbol": "฿" },
  { "name": "United States dollar", "initials": "USD", "symbol": "$" }
];

export function getCurrencySymbol(currencyInitials) {
  let symbol = "";
  symbol = currencies.filter(x => x.initials == currencyInitials)[0].symbol;
  return symbol;
}

//export const startCoordinate = "0.000001";
export function getUserLatitude() {
  let coord = computed(() => store.getters["core/getUserLatitude"]);
  if (undefined != coord.value) return coord.value;
  else return "40.765470";
}
export function getUserLongitude() {
  let coord = computed(() => store.getters["core/getUserLongitude"]);
  if (undefined != coord.value) return coord.value;
  else return "-73.986346";
}


export const labels = ref([]);
export const polylines = ref([]);
export const circles = ref([]);
export const rectangles = ref([]);
export const polygons = ref([]);
export const images = ref([]);
export const signals = ref([]);
export const noMapBoard = ref({});

export async function fetchMgqDrawings(mgq) {
  labels.value = [];
  polylines.value = [];
  polygons.value = [];
  rectangles.value = [];
  circles.value = [];
  images.value = [];
  signals.value = [];
  noMapBoard.value = null;
  let drawings = [];

  drawings = await store.dispatch("core/requestMgqDrawings", mgq);

  if (drawings == "reauthorize") {
    return;
  }
  else {
    for (let drawing of drawings) {
      if (drawing.drawingType == "label") {
        labels.value.push({
          latlngs: JSON.parse(drawing.coordinates),
        //  content: JSON.parse(drawing.properties).content,
          properties: drawing.properties != "" ? JSON.parse(drawing.properties) : {},
          borderColor: drawing.borderColor,
          id: drawing.id,
        });
      }
      else if (drawing.drawingType == "polyline") {
        polylines.value.push({
          latlngs: JSON.parse(drawing.coordinates),
          borderColor: drawing.borderColor,
          id: drawing.id,
          properties: drawing.properties != "" ? JSON.parse(drawing.properties) : {},

        });
      } else if (drawing.drawingType == "polygon") {
        polygons.value.push({
          latlngs: JSON.parse(drawing.coordinates),
          borderColor: drawing.borderColor,
          fillColor: drawing.fillColor,
       //   opacity: JSON.parse(drawing.properties).opacity,
          id: drawing.id,
          properties: drawing.properties != "" ? JSON.parse(drawing.properties) : {},

        });
      } else if (drawing.drawingType == "rectangle") {
        rectangles.value.push({
          latlngs: JSON.parse(drawing.coordinates),
          borderColor: drawing.borderColor,
          fillColor: drawing.fillColor,
       //   opacity: JSON.parse(drawing.properties).opacity,
          id: drawing.id,
          properties: drawing.properties != "" ? JSON.parse(drawing.properties) : {},

        });
      } else if (drawing.drawingType == "circle") {
        circles.value.push({
          latlngs: JSON.parse(drawing.coordinates),
          borderColor: drawing.borderColor,
          fillColor: drawing.fillColor,
        //  opacity: JSON.parse(drawing.properties).opacity,
          id: drawing.id,
          properties: drawing.properties != "" ? JSON.parse(drawing.properties) : {},

        });
      }
      else if (drawing.drawingType == "noMapBoard") {
        noMapBoard.value = {
          latlngs: JSON.parse(drawing.coordinates),
          properties: drawing.properties != "" ? JSON.parse(drawing.properties) : {},
          id: drawing.id,
        };
      } else if (drawing.drawingType == "image") {
        images.value.push({
          latlngs: JSON.parse(drawing.coordinates),
          borderColor: drawing.borderColor,
          fillColor: drawing.fillColor,
          id: drawing.id,
          properties: drawing.properties != "" ? JSON.parse(drawing.properties) : {},
         // name: JSON.parse(drawing.properties).name,
         // zoomLevels: JSON.parse(drawing.properties).zoomLevels != undefined ? JSON.parse(drawing.properties).zoomLevels : { min: 0, max: 19 },
        });
      }
      else if (drawing.drawingType == "signal") {
        signals.value.push({
          latlngs: JSON.parse(drawing.coordinates),
          id: drawing.id,
         // content: JSON.parse(drawing.properties).content,
          properties: drawing.properties != "" ? JSON.parse(drawing.properties) : {},
        });
      }
    }
  }

}

export function makeDistanceFromLatLng(lat1, lon1, lat2, lon2, realMap) {
  let R = 6371000;
  if (!realMap) {
    R = 100;
  }
  let dLat = deg2rad(lat2 - lat1); // deg2rad below
  let dLon = deg2rad(lon2 - lon1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c; // Distance in m
  return d;
}




export function makeOneCoordFromDistance(startCoord, isPlusDirection, distance) {
  let R = 6371000; // Radius of the earth in m
  let distanceInRads = distance / R;
  let coordDiff = (distanceInRads * 180) / Math.PI;
  let result = isPlusDirection ? startCoord + coordDiff : startCoord - coordDiff;
  return result;
}


function deg2rad(deg) {
  return deg * (Math.PI / 180);
}


export function removeAllLayersFromMap(thisDrawMap, thisMyTileLayer) {
  if (undefined != thisDrawMap) {
    if (null != thisDrawMap) {
      if (undefined != thisDrawMap.leafletObject) {
        thisDrawMap.leafletObject.eachLayer(function (layer) {
          let baseId = -1;
          if (null != thisMyTileLayer) {
            baseId = thisMyTileLayer.leafletObject._leaflet_id;
          }
          if (
            layer._leaflet_id != baseId
          ) {
            thisDrawMap.leafletObject.removeLayer(layer);
          }
        });
      }
    }
  }
}

export async function divColorEmphasis(regionId, color) {
  let div = document.getElementById(regionId);
  if (null != div) {
    div.style.backgroundColor = color;
  }
  await new Promise(r => setTimeout(r, 1000));
  if (null != div) {
    div.style.backgroundColor = "white";
  }
  await new Promise(r => setTimeout(r, 500));
}


export function showMapRight(isRealMap, realMap) {
  setTimeout(function () {
    if (isRealMap) {
      realMap.leafletObject.invalidateSize(true);
    }
  }, 5);
}

export function makeCoordinates(coord) {
  let thisCoord = String(coord);
  let parts = thisCoord.split(".");
  if (parts[1].length < 6) {
    let missingLength = 6 - parts[1].length;
    for (let i = 0; i < missingLength - 1; i++) {
      thisCoord = thisCoord.concat("0");
    }
    thisCoord = thisCoord.concat("1");
  } else {
    let decimals = parts[1].substring(0, 6);
    thisCoord = parts[0].concat(".").concat(decimals);
  }
  return thisCoord;
}

export function getIsEmpty(object) {
   return Object.keys(object).length === 0
      && object.constructor === Object;
  }


export function shouldShowOnMap(
  minZoom, maxZoom, currentZoomLevel, coordinates
) {
  // console.log("shouldShowOnMap " + minZoom + " " + maxZoom + " " + currentZoomLevel);
  let shouldZoom = false;
  let shouldDistance = false;
  let toReturn = false
  if (minZoom <= currentZoomLevel) {
    if (maxZoom >= currentZoomLevel) {
      shouldZoom = true;
    }
  }
  if (coordinates != undefined) {
    let distance = makeDistanceFromLatLng(coordinates.latitude1, coordinates.longitude1, coordinates.latitude2, coordinates.longitude2, coordinates.isRealMap);
    if (distance < coordinates.minDistance) {
      shouldDistance = true;
    }
  }
  if (coordinates != undefined){
    toReturn = shouldDistance && shouldZoom;
  }
  else {
    toReturn = shouldZoom;
  }
  return toReturn;
}

export function latLonToWorld(lat, long, startLat, startLong) {
  var g = project(lat, long);
  return [g[0] - startLat, g[1] - startLong];
}

function project(lat, long) {
  const g = 20037508.34;
  return [long / 180 * g, Math.log(Math.tan((90 + lat) * Math.PI / 360)) / (Math.PI / 180) * g / 180];
}


export function makeAllReadyMindsets(allFlat, startMindset) {
  /* let allFlat = store.getters["core/getFirstMindsets"].flat();
  let startMindset = allFlat[0]; */
  let made = [];
  for (let md of allFlat) {
    let posOneThree = latLonToWorld(Number(md.latitude), Number(md.longitude), Number(startMindset.latitude), Number(startMindset.longitude));
    md.pos = posOneThree[0] + " 1 " + posOneThree[1];
    made.push(md);
  }
  return made;
}
